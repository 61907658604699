import { Form, Formik } from "formik"
import { useState } from "react"
import { ButtonSpinner, FormInput } from "../../components"
import LogoHeader from "../../components/LogoHeader"
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import alerts from "../../utils/alerts";
import { passwordReset } from "../../redux/actions";
import { useDispatch } from "react-redux";

export const SendPasswordResetPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("El email no es válido")
          .required("El email es requerido"),
      })}
      onSubmit={async (values) => {
        setIsLoading(true);
        const { payload } = await passwordReset(values.email)(dispatch);
        if (payload.successResetPassword) {
          await alerts.success({ title: 'Correo enviado', text: 'Si su dirección de correo electrónico existe en nuestra base de datos, recibirá un enlace de recuperación de contraseña en su dirección de correo electrónico en unos minutos. Revise su bandeja de correo no deseado o spam en caso de que no reciba el correo en la bandeja de entrada.' });
          navigate('/login');
        } else {
          alerts.error({ title: 'Houston, tenemos un problema', text: payload.error?.message });
        }
        setIsLoading(false);
      }}
    >
      {() => (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="max-w-md w-full p-12 rounded-[15px] bg-white">
            <button className="btn-gray p-2" onClick={() => navigate('/login')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </button>
            <LogoHeader />
            <Form
              className="mt-8 space-y-6"
            >
              <FormInput
                maxLength={255}
                name="email"
                placeholder="usuario@ejemplo.com"
                label="Correo electrónico"
              >
              </FormInput>
              <div>
                <ButtonSpinner isLoading={isLoading} text="Restablecer contraseña" type="submit" color="yellow" />
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  )
}