import { CampusSurveyAnswerRepository } from '../repositories';
import {
  CampusSurveyAnswerFilters,
  CampusSurveyAnswerReportFilters,
  CampusSurveyAnswerReportTable,
  CampusSurveyAnswerTable,
} from '../../interfaces';

export class CampusSurveyAnswerProcess {
  private campusSurveyAnswerRepository: CampusSurveyAnswerRepository;

  constructor() {
    this.campusSurveyAnswerRepository = new CampusSurveyAnswerRepository();
  }

  async getCampusSurveyAnswer(filters?: CampusSurveyAnswerFilters): Promise<CampusSurveyAnswerTable[]> {
    const response = await this.campusSurveyAnswerRepository.getCampusSurveyAnswer(filters);
    return response.data;
  }

  async getCampusSurveyAnswerReport(
    filters?: CampusSurveyAnswerReportFilters,
  ): Promise<CampusSurveyAnswerReportTable[]> {
    const response = await this.campusSurveyAnswerRepository.getCampusSurveyAnswerReport(filters);
    return response.data;
  }
}
