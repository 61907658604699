import { HolidaysRepository } from "../repositories";
import { HolidaysFilters, HolidaysGet, HolidaysPost, HolidaysPut, HolidaysTable } from "../../interfaces";

export class HolidaysProcess {
  private multipleServiceScheduleRepository: HolidaysRepository;

  constructor() {
    this.multipleServiceScheduleRepository = new HolidaysRepository();
  }

  async getHolidays(filters?: HolidaysFilters): Promise<HolidaysGet> {
    const response = await this.multipleServiceScheduleRepository.getHolidays(filters);
    return response.data;
  }

  async getHolidaysById(multiple_service_id: number): Promise<HolidaysGet> {
    const response = await this.multipleServiceScheduleRepository.getHolidaysById(multiple_service_id);
    return response.data;
  }

  async postHolidays(msSchedule: HolidaysPost): Promise<HolidaysTable> {
    const response = await this.multipleServiceScheduleRepository.postHolidays(msSchedule);
    return response.data;
  }

  async putHolidays(msSchedule: HolidaysPut): Promise<HolidaysTable> {
    const response = await this.multipleServiceScheduleRepository.putHolidays(msSchedule);
    return response.data;
  }

  // dont response data
  async deleteHolidays(id: number): Promise<any> {
    const response = await this.multipleServiceScheduleRepository.deleteHolidays(id);
    return response.data;
  }
}
