
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { EventTypesFilters, EventTypesGet, EventTypesTable } from "../../interfaces";

export class EventTypesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getEventTypes(filters?: EventTypesFilters): Promise<AxiosResponse<EventTypesGet>> {
    return this.lmBootApi.get<EventTypesGet>("/event-types/", { params: filters });
  }

  postEventTypes(eventTypes: FormData): Promise<AxiosResponse<EventTypesTable>> {
    return this.lmBootApi.post<EventTypesTable>("/event-types/", eventTypes);
  }

  putEventTypes(eventTypes: FormData): Promise<AxiosResponse<EventTypesTable>> {
    return this.lmBootApi.put<EventTypesTable>("/event-types/", eventTypes);
  }

  // dont response data
  deleteEventTypes(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/event-types/${id}/`);
  }
}

