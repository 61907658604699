
import { CampusSurveyAskProcess } from "../api";
import { CampusSurveyAskAction, CampusSurveyAskDelete, CampusSurveyAskFilters, CampusSurveyAskPost, CampusSurveyAskPut,  } from "../interfaces";
import { CampusSurveyAskTypes } from "../types";

export const getCampusSurveyAskPags = (filters?: CampusSurveyAskFilters) => async (dispatch: (state: CampusSurveyAskAction) => CampusSurveyAskAction) => {
  dispatch({
    type: CampusSurveyAskTypes.GET_CAMPUSSURVEYASKPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyAskProcess = new CampusSurveyAskProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyAskPag = await campusSurveyAskProcess.getCampusSurveyAsk(filters);
    return dispatch({
      type: CampusSurveyAskTypes.GET_CAMPUSSURVEYASKPAG_SUCCESS,
      payload: {
        pagCampusSurveyAsk: campusSurveyAskPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyAskTypes.GET_CAMPUSSURVEYASKPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getCampusSurveyAsk = (filters?: CampusSurveyAskFilters) => async (dispatch: (state: CampusSurveyAskAction) => CampusSurveyAskAction) => {
  dispatch({
    type: CampusSurveyAskTypes.GET_CAMPUSSURVEYASK_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyAskProcess = new CampusSurveyAskProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyAskPag = await campusSurveyAskProcess.getCampusSurveyAsk({
      ...filters,
      data_all: 'YES',
    });
    const campusSurveyAsk = campusSurveyAskPag.results;
    return dispatch({
      type: CampusSurveyAskTypes.GET_CAMPUSSURVEYASK_SUCCESS,
      payload: {
        listCampusSurveyAsk: campusSurveyAsk,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyAskTypes.GET_CAMPUSSURVEYASK_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postCampusSurveyAsk = (campusSurveyAsk: CampusSurveyAskPost) => async (dispatch: (state: CampusSurveyAskAction) => CampusSurveyAskAction) => {
  dispatch({
    type: CampusSurveyAskTypes.POST_CAMPUSSURVEYASK_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyAskProcess = new CampusSurveyAskProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyAskTable = await campusSurveyAskProcess.postCampusSurveyAsk(campusSurveyAsk);
    return dispatch({
      type: CampusSurveyAskTypes.POST_CAMPUSSURVEYASK_SUCCESS,
      payload: {
        campusSurveyAsk: campusSurveyAskTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyAskTypes.POST_CAMPUSSURVEYASK_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putCampusSurveyAsk = (campusSurveyAsk: CampusSurveyAskPut) => async (dispatch: (state: CampusSurveyAskAction) => CampusSurveyAskAction) => {
  dispatch({
    type: CampusSurveyAskTypes.PUT_CAMPUSSURVEYASK_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyAskProcess = new CampusSurveyAskProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyAskTable = await campusSurveyAskProcess.putCampusSurveyAsk(campusSurveyAsk);
    return dispatch({
      type: CampusSurveyAskTypes.PUT_CAMPUSSURVEYASK_SUCCESS,
      payload: {
        campusSurveyAsk: campusSurveyAskTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyAskTypes.PUT_CAMPUSSURVEYASK_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteCampusSurveyAsk = (campusSurveyAsk: CampusSurveyAskDelete) => async (dispatch: (state: CampusSurveyAskAction) => CampusSurveyAskAction) => {
  dispatch({
    type: CampusSurveyAskTypes.DELETE_CAMPUSSURVEYASK_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyAskProcess = new CampusSurveyAskProcess();
    // dont'n import the result of the process, only that dont't have error
    await campusSurveyAskProcess.deleteCampusSurveyAsk(campusSurveyAsk);
    return dispatch({
      type: CampusSurveyAskTypes.DELETE_CAMPUSSURVEYASK_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyAskTypes.DELETE_CAMPUSSURVEYASK_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

