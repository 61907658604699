import { HolidaysAction, HolidaysPayload } from "../interfaces";
import { HolidaysTypes } from "../types";

const initialState: HolidaysPayload = {
  loading: false,
  success: false,
  error: null,
  holidays: null,
  listHolidays: [],
};

export const holidays = (state = initialState, action: HolidaysAction): HolidaysPayload => {
  const { loading, success, error, holidays, listHolidays } = action.payload || {};
  switch (action.type) {
    case HolidaysTypes.GET_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HolidaysTypes.GET_HOLIDAYS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HolidaysTypes.GET_HOLIDAYS_SUCCESS:
      return {
        ...state,
        listHolidays,
        loading,
        success,
      };
    case HolidaysTypes.POST_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HolidaysTypes.POST_HOLIDAYS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HolidaysTypes.POST_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidays,
        loading,
        success,
      };
    case HolidaysTypes.PUT_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HolidaysTypes.PUT_HOLIDAYS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HolidaysTypes.PUT_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidays,
        loading,
        success,
      };
    case HolidaysTypes.DELETE_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HolidaysTypes.DELETE_HOLIDAYS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HolidaysTypes.DELETE_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidays,
        loading,
        success,
      };
    default:
      return state;
  }
};

