import { HolidaysPage } from "../../pages/boilerplate";
import { ServiceBookingsPage, ServiceCategoryNewPage, ServiceNewPage, ServiceReportPage, ServiceScheduleEditPage, ServiceScheduleNewPage, ServiceSchedulePage } from "../../pages/services";
import { UserRole } from "../../utils/enums/userRoles.enum";

export const multipleServicesRoutes = [
  // MULTPLE SERVICES MODULE
  {
    path: '/services/bookings',
    to: '/services/bookings',
    name: 'Reservaciones',
    secondMenuShow: true,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceBookingsPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
        </svg>
    )
},
{
    path: '/services/schedule',
    to: '/services/schedule',
    name: 'Agenda',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceSchedulePage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
        </svg>
    )
},
{
    path: '/services/schedule/new',
    to: '/services/schedule/new',
    name: 'Agendar',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceScheduleNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
    )
},
{
    path: '/services/new',
    to: '/services/new',
    name: 'Agregar Servicio',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
    )
},
{
    path: '/services/schedule/edit',
    to: '/services/schedule/edit',
    name: 'Editar Servicio',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceScheduleEditPage,
},
{
    path: '/services/category/new',
    to: '/services/category/new',
    name: 'Agregar Categorías',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceCategoryNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
    )
},
{
    path: '/services/report',
    to: '/services/report',
    name: 'Reporte',
    secondMenuShow: true,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: ServiceReportPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
        </svg>
    )
},
{
    path: '/services/holidays',
    to: '/services/holidays',
    name: 'Feriados',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: HolidaysPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M6 3a1 1 0 011-1h.01a1 1 0 010 2H7a1 1 0 01-1-1zm2 3a1 1 0 00-2 0v1a2 2 0 00-2 2v1a2 2 0 00-2 2v.683a3.7 3.7 0 011.055.485 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0A3.7 3.7 0 0118 12.683V12a2 2 0 00-2-2V9a2 2 0 00-2-2V6a1 1 0 10-2 0v1h-1V6a1 1 0 10-2 0v1H8V6zm10 8.868a3.704 3.704 0 01-4.055-.036 1.704 1.704 0 00-1.89 0 3.704 3.704 0 01-4.11 0 1.704 1.704 0 00-1.89 0A3.704 3.704 0 012 14.868V17a1 1 0 001 1h14a1 1 0 001-1v-2.132zM9 3a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm3 0a1 1 0 011-1h.01a1 1 0 110 2H13a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
    )
},
]