export enum HotelBankAccountTypes {
  GET_HOTELBANKACCOUNTPAG_LOADING = "GET_HOTELBANKACCOUNTPAG_LOADING",
  GET_HOTELBANKACCOUNTPAG_SUCCESS = "GET_HOTELBANKACCOUNTPAG_SUCCESS",
  GET_HOTELBANKACCOUNTPAG_FAILURE = "GET_HOTELBANKACCOUNTPAG_FAILURE",
  GET_HOTELBANKACCOUNT_LOADING = "GET_HOTELBANKACCOUNT_LOADING",
  GET_HOTELBANKACCOUNT_SUCCESS = "GET_HOTELBANKACCOUNT_SUCCESS",
  GET_HOTELBANKACCOUNT_FAILURE = "GET_HOTELBANKACCOUNT_FAILURE",
  GETONLY_HOTELBANKACCOUNT_LOADING = "GETONLY_HOTELBANKACCOUNT_LOADING",
  GETONLY_HOTELBANKACCOUNT_SUCCESS = "GETONLY_HOTELBANKACCOUNT_SUCCESS",
  GETONLY_HOTELBANKACCOUNT_FAILURE = "GETONLY_HOTELBANKACCOUNT_FAILURE",
  POST_HOTELBANKACCOUNT_LOADING = "POST_HOTELBANKACCOUNT_LOADING",
  POST_HOTELBANKACCOUNT_SUCCESS = "POST_HOTELBANKACCOUNT_SUCCESS",
  POST_HOTELBANKACCOUNT_FAILURE = "POST_HOTELBANKACCOUNT_FAILURE",
  PUT_HOTELBANKACCOUNT_LOADING = "PUT_HOTELBANKACCOUNT_LOADING",
  PUT_HOTELBANKACCOUNT_SUCCESS = "PUT_HOTELBANKACCOUNT_SUCCESS",
  PUT_HOTELBANKACCOUNT_FAILURE = "PUT_HOTELBANKACCOUNT_FAILURE",
  DELETE_HOTELBANKACCOUNT_LOADING = "DELETE_HOTELBANKACCOUNT_LOADING",
  DELETE_HOTELBANKACCOUNT_SUCCESS = "DELETE_HOTELBANKACCOUNT_SUCCESS",
  DELETE_HOTELBANKACCOUNT_FAILURE = "DELETE_HOTELBANKACCOUNT_FAILURE",
}