import { HotelWireTransfersRepository } from "../repositories";
import { HotelWireTransfersFilters, HotelWireTransfersGet, HotelWireTransfersTable } from "../../interfaces";

export class HotelWireTransfersProcess {
  private multipleServiceReservationRepository: HotelWireTransfersRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelWireTransfersRepository();
  }

  async getHotelWireTransfers(filters?: HotelWireTransfersFilters): Promise<HotelWireTransfersGet> {
    const response = await this.multipleServiceReservationRepository.getHotelWireTransfers(filters);
    return response.data;
  }

  async postHotelWireTransfers(hotelWireTransfers: FormData): Promise<HotelWireTransfersTable> {
    const response = await this.multipleServiceReservationRepository.postHotelWireTransfers(hotelWireTransfers);
    return response.data;
  }

  async putHotelWireTransfers(hotelWireTransfers: FormData): Promise<HotelWireTransfersTable> {
    const response = await this.multipleServiceReservationRepository.putHotelWireTransfers(hotelWireTransfers);
    return response.data;
  }

  // dont response data
  async deleteHotelWireTransfers(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotelWireTransfers(id);
    return response.data;
  }
}
