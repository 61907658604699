import { CampusSurveyAnswerAction, CampusSurveyAnswerPayload } from '../interfaces';
import { CampusSurveyAnswerTypes } from '../types';

const initialState: CampusSurveyAnswerPayload = {
  loading: false,
  success: false,
  error: null,
  campusSurveyAnswer: null,
  listCampusSurveyAnswer: [],
  listCampusSurveyAnswerReport: [],
};

export const campusSurveyAnswer = (
  state = initialState,
  action: CampusSurveyAnswerAction,
): CampusSurveyAnswerPayload => {
  const { loading, success, error, listCampusSurveyAnswerReport, listCampusSurveyAnswer } = action.payload || {};
  switch (action.type) {
    case CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWER_FAILURE:
      return {
        ...state,
        loading,
        success,
        error,
      };
    case CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWER_LOADING:
      return {
        ...state,
        loading,
        success,
      };
    case CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWER_SUCCESS:
      return {
        ...state,
        listCampusSurveyAnswer,
        loading,
        success,
      };
    case CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWERREPORT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error,
      };
    case CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWERREPORT_LOADING:
      return {
        ...state,
        loading,
        success,
      };
    case CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWERREPORT_SUCCESS:
      return {
        ...state,
        listCampusSurveyAnswerReport,
        loading,
        success,
      };
    default:
      return state;
  }
};
