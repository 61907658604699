import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelWireTransfersFilters, HotelWireTransfersGet, HotelWireTransfersTable } from "../../interfaces";

export class HotelWireTransfersRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotelWireTransfers(filters?: HotelWireTransfersFilters): Promise<AxiosResponse<HotelWireTransfersGet>> {
    return this.lmBootApi.get<HotelWireTransfersGet>("/hotel-wire-transfers/", { params: filters });
  }

  postHotelWireTransfers(hotelWireTransfers: FormData): Promise<AxiosResponse<HotelWireTransfersTable>> {
    return this.lmBootApi.post<HotelWireTransfersTable>("/hotel-wire-transfers/", hotelWireTransfers);
  }

  putHotelWireTransfers(hotelWireTransfers: FormData): Promise<AxiosResponse<HotelWireTransfersTable>> {
    return this.lmBootApi.put<HotelWireTransfersTable>("/hotel-wire-transfers/", hotelWireTransfers);
  }

  // dont response data
  deleteHotelWireTransfers(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/hotel-wire-transfers/${id}/`);
  }
}
