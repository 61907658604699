import { HotelWireTransfersAction, HotelWireTransfersPayload } from "../interfaces";
import { HotelWireTransfersTypes } from "../types";

const initialState: HotelWireTransfersPayload = {
  loading: false,
  success: false,
  error: null,
  hotelWireTransfers: null,
  listHotelWireTransfers: [],
  pagHotelWireTransfers: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotelWireTransfers = (state = initialState, action: HotelWireTransfersAction): HotelWireTransfersPayload => {
  const { loading, success, error, hotelWireTransfers, listHotelWireTransfers, pagHotelWireTransfers } = action.payload || {};
  switch (action.type) {
    case HotelWireTransfersTypes.GET_HOTELWIRETRANSFERSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelWireTransfersTypes.GET_HOTELWIRETRANSFERSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelWireTransfersTypes.GET_HOTELWIRETRANSFERSPAG_SUCCESS:
      return {
        ...state,
        pagHotelWireTransfers,
        loading,
        success,
      };
    case HotelWireTransfersTypes.GET_HOTELWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelWireTransfersTypes.GET_HOTELWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelWireTransfersTypes.GET_HOTELWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        listHotelWireTransfers,
        loading,
        success,
      };
    case HotelWireTransfersTypes.GETONLY_HOTELWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelWireTransfersTypes.GETONLY_HOTELWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelWireTransfersTypes.GETONLY_HOTELWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        hotelWireTransfers,
        loading,
        success,
      };
    case HotelWireTransfersTypes.POST_HOTELWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelWireTransfersTypes.POST_HOTELWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelWireTransfersTypes.POST_HOTELWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        hotelWireTransfers,
        loading,
        success,
      };
    case HotelWireTransfersTypes.PUT_HOTELWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelWireTransfersTypes.PUT_HOTELWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelWireTransfersTypes.PUT_HOTELWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        hotelWireTransfers,
        loading,
        success,
      };
    case HotelWireTransfersTypes.DELETE_HOTELWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelWireTransfersTypes.DELETE_HOTELWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelWireTransfersTypes.DELETE_HOTELWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        hotelWireTransfers,
        loading,
        success,
      };
    default:
      return state;
  }
};

