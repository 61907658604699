
export enum RestaurantDishesTypes {
  GET_RESTAURANTDISHESPAG_LOADING = "GET_RESTAURANTDISHESPAG_LOADING",
  GET_RESTAURANTDISHESPAG_SUCCESS = "GET_RESTAURANTDISHESPAG_SUCCESS",
  GET_RESTAURANTDISHESPAG_FAILURE = "GET_RESTAURANTDISHESPAG_FAILURE",
  GET_RESTAURANTDISHES_LOADING = "GET_RESTAURANTDISHES_LOADING",
  GET_RESTAURANTDISHES_SUCCESS = "GET_RESTAURANTDISHES_SUCCESS",
  GET_RESTAURANTDISHES_FAILURE = "GET_RESTAURANTDISHES_FAILURE",
  GETONLY_RESTAURANTDISHES_LOADING = "GETONLY_RESTAURANTDISHES_LOADING",
  GETONLY_RESTAURANTDISHES_SUCCESS = "GETONLY_RESTAURANTDISHES_SUCCESS",
  GETONLY_RESTAURANTDISHES_FAILURE = "GETONLY_RESTAURANTDISHES_FAILURE",
  POST_RESTAURANTDISHES_LOADING = "POST_RESTAURANTDISHES_LOADING",
  POST_RESTAURANTDISHES_SUCCESS = "POST_RESTAURANTDISHES_SUCCESS",
  POST_RESTAURANTDISHES_FAILURE = "POST_RESTAURANTDISHES_FAILURE",
  PUT_RESTAURANTDISHES_LOADING = "PUT_RESTAURANTDISHES_LOADING",
  PUT_RESTAURANTDISHES_SUCCESS = "PUT_RESTAURANTDISHES_SUCCESS",
  PUT_RESTAURANTDISHES_FAILURE = "PUT_RESTAURANTDISHES_FAILURE",
  DELETE_RESTAURANTDISHES_LOADING = "DELETE_RESTAURANTDISHES_LOADING",
  DELETE_RESTAURANTDISHES_SUCCESS = "DELETE_RESTAURANTDISHES_SUCCESS",
  DELETE_RESTAURANTDISHES_FAILURE = "DELETE_RESTAURANTDISHES_FAILURE",
}