
export enum UserGroupTypes {
  GET_USERGROUPPAG_LOADING = "GET_USERGROUPPAG_LOADING",
  GET_USERGROUPPAG_SUCCESS = "GET_USERGROUPPAG_SUCCESS",
  GET_USERGROUPPAG_FAILURE = "GET_USERGROUPPAG_FAILURE",
  GET_USERGROUP_LOADING = "GET_USERGROUP_LOADING",
  GET_USERGROUP_SUCCESS = "GET_USERGROUP_SUCCESS",
  GET_USERGROUP_FAILURE = "GET_USERGROUP_FAILURE",
  GETONLY_USERGROUP_LOADING = "GETONLY_USERGROUP_LOADING",
  GETONLY_USERGROUP_SUCCESS = "GETONLY_USERGROUP_SUCCESS",
  GETONLY_USERGROUP_FAILURE = "GETONLY_USERGROUP_FAILURE",
  POST_USERGROUP_LOADING = "POST_USERGROUP_LOADING",
  POST_USERGROUP_SUCCESS = "POST_USERGROUP_SUCCESS",
  POST_USERGROUP_FAILURE = "POST_USERGROUP_FAILURE",
  PUT_USERGROUP_LOADING = "PUT_USERGROUP_LOADING",
  PUT_USERGROUP_SUCCESS = "PUT_USERGROUP_SUCCESS",
  PUT_USERGROUP_FAILURE = "PUT_USERGROUP_FAILURE",
  DELETE_USERGROUP_LOADING = "DELETE_USERGROUP_LOADING",
  DELETE_USERGROUP_SUCCESS = "DELETE_USERGROUP_SUCCESS",
  DELETE_USERGROUP_FAILURE = "DELETE_USERGROUP_FAILURE",
}