import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from '../../components';
import { APIPayment } from '../../redux/api/configurations';
import {
  IPaymentValidation,
  MessageStatus,
  PaymentResponse,
  PaymentStatus,
} from '../../redux/interfaces/payment.interface';
import { validatePaymentResponse } from '../../utils/utils';
import { LoadingPage } from '../states';

export const SuccessfulPayment = () => {
  const [statePayment, setStatePayment] = useState<null | IPaymentValidation>(null);
  const params = useParams();
  const token = params.token || '';

  const fetch = async () => {
    const { data } = await APIPayment.get<PaymentResponse>(`/${token}`);
    setStatePayment(validatePaymentResponse(data));
  };

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className="grid h-screen place-items-center px-8">
      {statePayment === null && <LoadingPage />}
      {statePayment && statePayment.status === PaymentStatus.SUCCESS && (
        <Message
          status={MessageStatus.SUCCESS}
          title={statePayment.title}
          body={statePayment.message}
          footer="Puede regresar a la página principal haciendo clic en el botón superior."
        />
      )}
      {statePayment && statePayment.status === PaymentStatus.CREATED_ORDER && (
        <Message
          status={MessageStatus.INFO}
          title={statePayment.title}
          body={statePayment.message}
          footer="Puede regresar a la página principal haciendo clic en el botón superior."
        />
      )}
      {statePayment && statePayment.status === PaymentStatus.DECLINED && (
        <Message
          status={MessageStatus.ERROR}
          title={statePayment.title}
          body={statePayment.message}
          footer="Puede regresar a la página principal haciendo clic en el botón superior."
        />
      )}
      {statePayment && statePayment.status === PaymentStatus.ERROR && (
        <Message
          status={MessageStatus.ERROR}
          title={statePayment.title}
          body={statePayment.message}
          footer="Puede regresar a la página principal haciendo clic en el botón superior."
        />
      )}
    </div>
  );
};
