import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { putCampusNews } from '../../redux/actions';
import { CampusNewsPut, CampusNewsTable, User } from '../../redux/interfaces';
import { MiniCarouselImg } from '../../components';
import { RootState } from '../../redux/store';
import { FormikErrors, useFormik } from 'formik';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { testSizeImg } from '../../utils/utils';

type ImageType = {
  id?: number;
  url: string;
  restaurant?: number;
  file?: File
}

export const CampusNewsEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { news } = state as { news: CampusNewsTable };
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [images, setImages] = useState<ImageType[]>([
    { id: news?.id || undefined, url: news?.image_url || '' },
  ]);
  const [description, setDescription] = useState<any>(news.description || '');

  const {
    getFieldProps,
    handleSubmit,
    setFieldValue,
    touched,
    errors
  } = useFormik({
    initialValues: {
      title: news.title || '',
      description: '',
      status: news.status || '',
      // for errors
      cover_image: '',
    },
    validate: (values) => {
      const errors: FormikErrors<any> = {};
      if (!values.title) {
        errors.title = 'El título de la noticia es requerido';
      }
      if (!description) {
        errors.description = 'La descripción es requerida';
      }
      if (!images || images.length === 0) {
        errors.cover_image = 'Subir al menos una imagen';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      if (!images || images.length === 0) return;

      const formData = new FormData();
      const formDataFields: CampusNewsPut = {
        id: news.id,
        title: values.title,
        description: description,
        campus_id: user.campus.id.toString(),
        image_url: images[0]?.file || images[0]?.url,
        user_id: user.id.toString(),
        status: values.status,
      };

      toast.info(`Actualizando la noticia...`);

      Object.entries(formDataFields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const { payload } = await putCampusNews(formData)(dispatch);
      if (!payload.success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      toast.success(`Noticia actualizada con éxito.`);
      helpers.resetForm();
      setImages([]);
      navigate(-1);
    }
  });

  const handleCatchImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', { type: 'error' });
    }

    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
    setImages((prevState) => [...prevState, ...imagesList]);
  }

  const removeImage = (image: ImageType) => {
    setImages(images.filter((imageItem) => imageItem !== image));
  }

  return (
    <>
      <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      <div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
        >
          <h2 className="font-bold text-gray-500">
            Añadir Noticia
          </h2>
          <input
            id="title"
            type="text"
            maxLength={250}
            {...getFieldProps('title')}
            required
            className="input-yellow"
            placeholder="Título de la noticia"
          />
          <span className="text-rose-500">{ touched.title && errors.title && errors.title}</span>
          <div>
          <MDEditor
            id="description"
            value={description}
            onChange={setDescription}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />
          </div>
          { touched.description && errors.description && <span className="text-rose-500">{errors.description}</span> }
          <div>
            <h2 className="font-bold text-gray-500">
              Imágenes <span className="text-sm font-normal text-orange-500">(Tamaño máximo 10Mb, Dimensión mínima 640x640)</span>
            </h2>
            <div className="mt-4 flex flex-row justify-between">
              <MiniCarouselImg images={images} onRemoveImage={removeImage} />
              <div className="ml-8 shrink-0">
                <div className="block mb-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    onChange={handleCatchImages}
                    disabled={images.length > 0}
                    className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
                  />
                  <label htmlFor="file" className={`btn-gray ${images.length > 0 ? 'cursor-default' : ''}`}>Subir Imagen</label>
                </div>
              </div>
            </div>
            { errors.cover_image && <span className="text-rose-500">{errors.cover_image}</span> }
          </div>
          <div className="flex flex-row gap-4 justify-end">
            <button
              onClick={() => setFieldValue('status', 'SAVED')}
              type="submit"
              className="mt-2 w-28 btn-gray"
            >
              Guardar
            </button>
            <button
              onClick={() => setFieldValue('status', 'PUBLISHED')}
              type="submit"
              className="mt-2 w-28 btn-yellow"
            >
              Publicar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
