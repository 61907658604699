import React from "react";
import { Navigate } from "react-router-dom";

export type RouteProps = {
    isAuthenticated: boolean;
    children: React.ReactElement<any, any> | null;
}
export const PrivateRoute: React.FC<RouteProps> = ({ isAuthenticated, children }: RouteProps) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
};
