import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import LogoHeader from "../../components/LogoHeader";
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../utils/hooks/useForm';
import { loginUser } from '../../redux/actions';
import Alert from '../../utils/alerts';
import { ButtonSpinner, InputPassword } from "../../components";
import { Error } from "../../redux/interfaces";
import { RootState } from "../../redux/store";

export const LoginPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const { formValues, handleOnChangeInput } = useForm({
    email: '',
    password: ''
  });
  const { email, password } = formValues;

  useEffect(() => {
    if (!isLoggedIn) setIsLoading(false);
}, [isLoggedIn]); // eslint-disable-line


  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const resultLogin = await loginUser(email, password)(dispatch);

    if (!resultLogin.payload.success) {
      const error: Error = resultLogin.payload.error;
      error.message && Alert.error({ text: error.message });
      setIsLoading(false);
    }
  }


  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full p-12 rounded-[15px] bg-white">
          <LogoHeader />
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="block font-medium text-black mt-3">
                  Correo Electrónico
                </label>
                <div className="mt-1">
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    maxLength={255}
                    required
                    className="appearance-none rounded-lg relative block w-full px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 text-md"
                    placeholder="usuario@ejemplo.com"
                    value={email}
                    onChange={handleOnChangeInput}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block font-medium text-black mt-3">
                  Contraseña
                </label>
                <InputPassword
                  id="password"
                  name="password"
                  maxLength={255}
                  autoComplete="current-password"
                  required
                  placeholder="**************"
                  value={password}
                  onChange={handleOnChangeInput}
                />
              </div>
            </div>

            <NavLink to="/send-password-reset">
              <p className="text-sm text-gray-500 underline decoration-slate-500 hover:text-gray-800 mt-1 mb-3">Olvidé mi contraseña</p>
            </NavLink>

            <div>
              <ButtonSpinner isLoading={isLoading} text="Iniciar sesión" type="submit" color="yellow" />
            </div>

            <div>
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            </div>
            
            <div className="flex items-center px-4 py-2 justify-center border border-slate-300 hover:bg-slate-50 rounded-md">
              <Link to="/register" className="font-bold text-sm">
                  Crear cuenta
              </Link>
            </div>
            
          </form>          
        </div>
      </div>
    </>
  );
};