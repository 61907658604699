import { Dispatch, SetStateAction } from 'react';

interface Chip {
  id?: number;
  name: string;
}

interface Props {
  chips: Chip[];
  setChips: Dispatch<SetStateAction<Chip[]>>;
}

export const Chips = ({ chips, setChips }: Props) => {
  const handleDeleteChip = (chip: Chip) => {
    const newChips = chips.filter(c => c !== chip);
    setChips([...newChips]);
  }
  return (
    <>
      <div className="flex flex-row flex-wrap gap-4">
        {chips.map((chip, index) => (
          <div
            className="bg-yellow-500 px-4 py-2 rounded-lg text-sm flex flex-row gap-2 grow-0"
            key={index}
          >
            {chip.name}
            <span
              onClick={() => handleDeleteChip(chip)}
              className="bg-white text-black font-bold p-1 rounded-full cursor-pointer hover:bg-gray-100 active:bg-gray-300"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </>
  )
}
