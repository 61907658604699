
import { EventCoordinatorsProcess } from "../api";
import { EventCoordinatorsAction, EventCoordinatorsFilters, EventCoordinatorsPost, EventCoordinatorsPut,  } from "../interfaces";
import { EventCoordinatorsTypes } from "../types";

export const getEventCoordinatorsPags = (filters?: EventCoordinatorsFilters) => async (dispatch: (state: EventCoordinatorsAction) => EventCoordinatorsAction) => {
  dispatch({
    type: EventCoordinatorsTypes.GET_EVENTCOORDINATORSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventCoordinatorsProcess = new EventCoordinatorsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventCoordinatorsPag = await eventCoordinatorsProcess.getEventCoordinators(filters);
    return dispatch({
      type: EventCoordinatorsTypes.GET_EVENTCOORDINATORSPAG_SUCCESS,
      payload: {
        pagEventCoordinators: eventCoordinatorsPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventCoordinatorsTypes.GET_EVENTCOORDINATORSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getEventCoordinators = (filters?: EventCoordinatorsFilters) => async (dispatch: (state: EventCoordinatorsAction) => EventCoordinatorsAction) => {
  dispatch({
    type: EventCoordinatorsTypes.GET_EVENTCOORDINATORS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventCoordinatorsProcess = new EventCoordinatorsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventCoordinatorsPag = await eventCoordinatorsProcess.getEventCoordinators({
      ...filters,
      data_all: 'YES',
    });
    const eventCoordinators = eventCoordinatorsPag.results;
    return dispatch({
      type: EventCoordinatorsTypes.GET_EVENTCOORDINATORS_SUCCESS,
      payload: {
        listEventCoordinators: eventCoordinators,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventCoordinatorsTypes.GET_EVENTCOORDINATORS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postEventCoordinators = (eventCoordinators: EventCoordinatorsPost) => async (dispatch: (state: EventCoordinatorsAction) => EventCoordinatorsAction) => {
  dispatch({
    type: EventCoordinatorsTypes.POST_EVENTCOORDINATORS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventCoordinatorsProcess = new EventCoordinatorsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventCoordinatorsTable = await eventCoordinatorsProcess.postEventCoordinators(eventCoordinators);
    return dispatch({
      type: EventCoordinatorsTypes.POST_EVENTCOORDINATORS_SUCCESS,
      payload: {
        eventCoordinators: eventCoordinatorsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventCoordinatorsTypes.POST_EVENTCOORDINATORS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putEventCoordinators = (eventCoordinators: EventCoordinatorsPut) => async (dispatch: (state: EventCoordinatorsAction) => EventCoordinatorsAction) => {
  dispatch({
    type: EventCoordinatorsTypes.PUT_EVENTCOORDINATORS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventCoordinatorsProcess = new EventCoordinatorsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventCoordinatorsTable = await eventCoordinatorsProcess.putEventCoordinators(eventCoordinators);
    return dispatch({
      type: EventCoordinatorsTypes.PUT_EVENTCOORDINATORS_SUCCESS,
      payload: {
        eventCoordinators: eventCoordinatorsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventCoordinatorsTypes.PUT_EVENTCOORDINATORS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteEventCoordinators = (id: number) => async (dispatch: (state: EventCoordinatorsAction) => EventCoordinatorsAction) => {
  dispatch({
    type: EventCoordinatorsTypes.DELETE_EVENTCOORDINATORS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventCoordinatorsProcess = new EventCoordinatorsProcess();
    // dont'n import the result of the process, only that dont't have error
    await eventCoordinatorsProcess.deleteEventCoordinators(id);
    return dispatch({
      type: EventCoordinatorsTypes.DELETE_EVENTCOORDINATORS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventCoordinatorsTypes.DELETE_EVENTCOORDINATORS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

