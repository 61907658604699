import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelBankAccountFilters, HotelBankAccountGet, HotelBankAccountPost, HotelBankAccountPut, HotelBankAccountTable } from "../../interfaces";

export class HotelBankAccountRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotelBankAccount(filters?: HotelBankAccountFilters): Promise<AxiosResponse<HotelBankAccountGet>> {
    return this.lmBootApi.get<HotelBankAccountGet>("/bank-data/", { params: filters });
  }

  postHotelBankAccount(hotelBankAccount: HotelBankAccountPost): Promise<AxiosResponse<HotelBankAccountTable>> {
    return this.lmBootApi.post<HotelBankAccountTable>("/bank-data/", hotelBankAccount);
  }

  putHotelBankAccount(hotelBankAccount: HotelBankAccountPut): Promise<AxiosResponse<HotelBankAccountTable>> {
    return this.lmBootApi.put<HotelBankAccountTable>("/bank-data/", hotelBankAccount);
  }

  // dont response data
  deleteHotelBankAccount(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/bank-data/${id}/`);
  }
}
