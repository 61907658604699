
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { CampusNotificationsFilters, CampusNotificationsGet, CampusNotificationsPost, CampusNotificationsPut, CampusNotificationsTable } from "../../interfaces";

export class CampusNotificationsRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCampusNotifications(filters?: CampusNotificationsFilters): Promise<AxiosResponse<CampusNotificationsGet>> {
    return this.lmBootApi.get<CampusNotificationsGet>("/notifications/", { params: filters });
  }

  postCampusNotifications(campusNotifications: CampusNotificationsPost): Promise<AxiosResponse<CampusNotificationsTable>> {
    return this.lmBootApi.post<CampusNotificationsTable>("/notifications/", campusNotifications);
  }

  putCampusNotifications(campusNotifications: CampusNotificationsPut): Promise<AxiosResponse<CampusNotificationsTable>> {
    return this.lmBootApi.put<CampusNotificationsTable>("/notifications/", campusNotifications);
  }

  // dont response data
  deleteCampusNotifications(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/notifications/${id}/`);
  }
}

