import { MultipleServicesScheduleAction, MultipleServicesSchedulePayload } from "../interfaces";
import { MultipleServicesScheduleTypes } from "../types";

const initialState: MultipleServicesSchedulePayload = {
  loading: false,
  success: false,
  error: null,
  multipleServicesSchedule: null,
  listMultipleServicesSchedule: [],
};

export const multipleServicesSchedule = (state = initialState, action: MultipleServicesScheduleAction): MultipleServicesSchedulePayload => {
  const { loading, success, error, multipleServicesSchedule, listMultipleServicesSchedule } = action.payload || {};
  switch (action.type) {
    case MultipleServicesScheduleTypes.GET_MULTIPLESERVICESSCHEDULE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesScheduleTypes.GET_MULTIPLESERVICESSCHEDULE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesScheduleTypes.GET_MULTIPLESERVICESSCHEDULE_SUCCESS:
      return {
        ...state,
        listMultipleServicesSchedule,
        loading,
        success,
      };
    case MultipleServicesScheduleTypes.POST_MULTIPLESERVICESSCHEDULE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesScheduleTypes.POST_MULTIPLESERVICESSCHEDULE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesScheduleTypes.POST_MULTIPLESERVICESSCHEDULE_SUCCESS:
      return {
        ...state,
        multipleServicesSchedule,
        loading,
        success,
      };
    case MultipleServicesScheduleTypes.PUT_MULTIPLESERVICESSCHEDULE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesScheduleTypes.PUT_MULTIPLESERVICESSCHEDULE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesScheduleTypes.PUT_MULTIPLESERVICESSCHEDULE_SUCCESS:
      return {
        ...state,
        multipleServicesSchedule,
        loading,
        success,
      };
    case MultipleServicesScheduleTypes.DELETE_MULTIPLESERVICESSCHEDULE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesScheduleTypes.DELETE_MULTIPLESERVICESSCHEDULE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesScheduleTypes.DELETE_MULTIPLESERVICESSCHEDULE_SUCCESS:
      return {
        ...state,
        multipleServicesSchedule,
        loading,
        success,
      };
    default:
      return state;
  }
};

