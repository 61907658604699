import { RegisterRepository } from "../repositories";
import { Campus, MemberRegisterValues, MemberResponse, UserFilters } from "../../interfaces";

export class RegisterProccess {
  private registerRepository: RegisterRepository;

  constructor() {
    this.registerRepository = new RegisterRepository();
  }
  async getMemberByDocumentAndBirthday(document: string, birthday: string): Promise<MemberResponse> {
    const response = await this.registerRepository.getMemberByDocumentAndBirthday(document, birthday);
    return response.data;
  }

  async getMember(filters?: UserFilters): Promise<MemberResponse> {
    const response = await this.registerRepository.getMember(filters);
    return response.data;
  }

  async registerUser(member: MemberRegisterValues): Promise<any> {
    const { birthday, campusFrecuency, cellNumber, documentNumber,
      email, emergencyNumber, name, password } = member;
    const response = await this.registerRepository.registerUser({
      username: name,
      email,
      password,
      first_name: name,
      phone: cellNumber,
      document_number: documentNumber,
      date_birth: birthday,
      emergency_number: emergencyNumber,
      address: '',
      campus: campusFrecuency,
      user_group: 'common_admin',
      is_active: false
    });
    return response.data;
  }

  async getListCampus(): Promise<Array<Campus>> {
    const response = await this.registerRepository.getListCampus();
    return response.data.results;
  }

}
