import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addSeconMenuItems, getMultipleServicesCategory, getMultipleServicesReservation, getMultipleServicesReservationPags } from '../../redux/actions';
import {
    MultipleServicesReservationFilters,
    MultipleServicesReservationGet,
    MultipleServicesReservationPayload,
    SecondMenuItemsType,
    User,
} from '../../redux/interfaces';
// import xlsx
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TableBody } from "../../components";
import { InputDateRange } from "../../components/InputDateRange";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { RootState } from "../../redux/store";

export const ServiceReportPage = () => {
    const dispatch = useDispatch();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const [filterState, setFilterState] = useState('');
    const { pagMultipleServicesReservation, loading }: MultipleServicesReservationPayload = useSelector(
        (state: any) => state.multipleServicesReservation,
    );
    const {
        results: msReservation,
        count,
        next,
        previous,
    } = pagMultipleServicesReservation as MultipleServicesReservationGet;
    // pagination
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(6);
    const [msScheduleId, setMsScheduleId] = useState(-1);
    // new Date(), new Date() + one day
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(new Date().getTime() - 60 * 60 * 24 * 1000 * 7), new Date()]);
    const [startDate, endDate] = dateRange;
    // status values
    const statusCellOptions: {
        [key: string]: {
            label: string;
            color: string;
        };
    } = {
        APPROVED: {
            label: 'Aprobado',
            color: 'green'
        },
        FINISHED: {
            label: 'Finalizado',
            color: 'gray'
        },
        INCOMPLETED: {
            label: 'Incompleto',
            color: 'red'
        },
    };

    useEffect(() => {
        const filters: MultipleServicesReservationFilters = {
            page: pageCurrent,
            page_size: pageSize,
            multiple_service_schedule__multiple_service_id: msScheduleId,
            status: filterState,
            start_date__gte: '',
            end_date__lte: '',
            multiple_service_schedule__multiple_service__multiple_service_category__campus_id: user.campus.id
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['end_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        getMultipleServicesReservationPags(filters)(dispatch);
    }, [pageCurrent, pageSize, msScheduleId, filterState, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const pullCategoriesForFilters = async () => {
            const { payload } = await getMultipleServicesCategory({
              campus_id: user.campus.id,
            })(dispatch);
            const listMultipleServicesCategory = payload.listMultipleServicesCategory || [];
            const menuItemsServiceBookings: SecondMenuItemsType[] = listMultipleServicesCategory.map((item, i) => {
              return {
                label: item.name,
                activeTab: i === 0,
                children: item.multiple_service.map((ms, j) => {
                  if (j === 0) { setMsScheduleId(ms.id); }
                  return {
                    label: ms.name,
                    activeTab: j === 0,
                    callback() {
                      setMsScheduleId(ms.id);
                    }
                  };
                })
              }
            });
            addSeconMenuItems([...menuItemsServiceBookings])(dispatch);
          };
          pullCategoriesForFilters();
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type MultipleServicesReservationType = {
        status: string;
        people_total: number;
        start_date: string;
        first_name: string;
    };

    const { data, columns } = useTableMemo<MultipleServicesReservationType>({
      dataMemo: {
          factory: msReservation.map((multipleServicesReservation: any) => {
            return {
                status: multipleServicesReservation.status,
                people_total: multipleServicesReservation.people_total,
                start_date: multipleServicesReservation.start_date,
                first_name: multipleServicesReservation.user.first_name,
            };
        }),
          deps: [msReservation]
      },
      columnsMemo: {
          factory: [
            {
                Header: 'Socio',
                accessor: 'first_name', // accessor is the "key" in the data
            },
            {
                Header: 'Personas',
                accessor: 'people_total',
            },
            {
                Header: 'Entrada',
                accessor: 'start_date',
            },
            {
                Header: 'Estado',
                accessor: 'status',
            },
        ],
          deps: [],
      }
    });

    const handleCreateDownloadReport = async () => {
        const filters: MultipleServicesReservationFilters = {
            page_size: count,
            multiple_service_schedule__multiple_service_id: msScheduleId,
            status: filterState,
            start_date__gte: '',
            end_date__lte: '',
            multiple_service_schedule__multiple_service__multiple_service_category__campus_id: user.campus.id
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['end_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        const { payload } = await getMultipleServicesReservation(filters)(dispatch);
        const listMultipleServicesReservation = payload.listMultipleServicesReservation || [];
        const data_excel = listMultipleServicesReservation.map(item => ([
            item.user.first_name,
            item.people_total,
            new Date(item.start_date).toLocaleDateString(),
            new Date(item.start_date).toLocaleTimeString(),
            statusCellOptions[item.status].label,
        ]));
        const columns_titles = ['Socio', 'Núm Personas', 'Fecha Entrada', 'Hora Entrada', 'Estado'];
        // Code for excel download
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Reporte de servicios',
            Subject: 'Reporte de servicios',
            Author: 'Círculo Militar',
            CreatedDate: new Date(Date.now()),
        };
        wb.SheetNames.push('Reporte de servicios');
        const ws = XLSX.utils.json_to_sheet(data_excel);
        wb.Sheets['Reporte de servicios'] = ws;
        // add columns width
        ws['!cols'] = [
            { wch: 40 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
        ];
        // add columns title
        columns_titles.forEach((title, index) => {
            ws[`${String.fromCharCode(65 + index)}1`] = {
                v: title,
                t: 's',
                font: {
                    sz: 14,
                    bold: true,
                },
            };
        });


        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        const s2ab = (s: any) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_REPORTE_RESERVAS.xlsx`);
    }

    return (
        <>
            <div className="mb-8 flex flex-row items-center justify-end gap-4 print:hidden">
                {/* Button with Descargar reporte */}
                Descargar reporte
                <button
                    onClick={handleCreateDownloadReport}
                    className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>
            <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
                <div className="flex items-center">
                    <InputDateRange
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        className="h-11 font-bold"
                    />
                </div>
                <select
                    id="campusFrecuency"
                    name="campusFrecuency"
                    value={filterState}
                    onChange={(e) => setFilterState(e.target.value)}
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Estados (Todos)</option>
                    <option value="APPROVED">Aprobados</option>
                    <option value="FINISHED">Finalizados</option>
                    <option value="INCOMPLETED">Incompletos</option>
                </select>
            </div>
            <div className="overflow-hidden">
            <TableBody
                columns={columns} data={data} pageSize={pageSize} loading={loading}
                renderRows={row => (
                    // Apply the row props
                    <tr
                        className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                        {...row.getRowProps()}
                    >
                        {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                                if (cell.column.Header === 'Entrada') {
                                    const dateCell = new Date(
                                        (cell.render('Cell') as any).props.value,
                                    ).toLocaleDateString('en-GB', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    });
                                    return (
                                        <td
                                            className="flex flex-row items-center gap-2 px-6 py-4"
                                            {...cell.getCellProps()}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="inline-block h-5 w-5 text-gray-500 print:hidden"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            {dateCell}
                                        </td>
                                    );
                                }

                                if (cell.column.Header === 'Estado') {
                                    const statusCell = statusCellOptions[(cell.render('Cell') as any).props.value];
                                    return (
                                        <td
                                            className="px-6 py-4 text-left text-black"
                                            {...cell.getCellProps()}
                                        >
                                            <span className={`inline-flex items-center px-3 py-1 text-xs font-semibold leading-5 text-${statusCell.color}-500`}>
                                                {statusCell.label}
                                            </span>
                                        </td>
                                    );
                                }

                                // Apply the cell props
                                return (
                                    <td
                                        className="whitespace-nowrap px-6 py-3"
                                        {...cell.getCellProps()}
                                    >
                                        {
                                            // Render the cell contents
                                            cell.render('Cell')
                                        }
                                    </td>
                                );
                            })
                        }
                    </tr>
                )}
                // For footer
                count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
            />
            </div>
        </>
    );
};
