import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoHeader from '../../components/LogoHeader';
import useForm from '../../utils/hooks/useForm';
import Alert from '../../utils/alerts';
import { ButtonSpinner } from '../../components';
import { getListCampus, registerUser } from '../../redux/actions';
import { Campus } from '../../redux/interfaces';
import { InputPassword } from '../../components';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { validateDocument } from '../../utils/formValidation';

const errorNames: {
  [key: string]: string;
} = {
  email: 'Correo electrónico',
  password: 'Contraseña',
  name: 'Nombre y Apellido',
  document_number: 'Cédula',
  birthday: 'Fecha de nacimiento',
  cell_number: 'Número de celular',
  emergency_number: 'Número de emergencia',
  campus_frecuency: 'Círculo Militar',
  campus: 'Campus',
};

export function RegisterPage() {
  const navigate = useNavigate();
  const { campus } = useSelector((state: RootState) => state.register);
  const [isLoading, setIsLoading] = useState(false);
  // const [campusFrecuency, setCampusFrecuency] = useState(1);
  const dispatch = useDispatch();
  const {
    email,
    password,
    name,
    documentNumber,
    birthday,
    cellNumber,
    emergencyNumber,
    campusFrecuency,
    handleOnChangeInput,
  } = useForm({
    email: '',
    password: '',
    campusFrecuency: '',
    name: '',
    documentNumber: '',
    birthday: '',
    cellNumber: '',
    emergencyNumber: '',
  });

  useEffect(() => {
    getListCampus()(dispatch);
  }, []); // eslint-disable-line

  const handleRegister = async (e: any) => {
    e.preventDefault();
    if (!validateDocument(documentNumber, 'CI')) {
      return await Alert.error({
        title: 'Error al registrar',
        text: `Se encontraron los siguientes problemas:</br></br>
        <b>Cédula</b>: cédula inválida
      `,
      });
    }
    setIsLoading(true);
    const registerResponse = await registerUser({
      birthday,
      campusFrecuency: parseInt(campusFrecuency),
      cellNumber,
      documentNumber,
      email,
      emergencyNumber,
      name,
      password,
    })(dispatch);

    setIsLoading(false);

    if (registerResponse.payload.success) {
      Alert.success({
        title: 'Registro exitoso',
        text: 'Para iniciar sesión es necesario que un administrador confirme esta cuenta.',
      });
    } else {
      const errors = Object.entries(registerResponse.payload.error?.message!).map(
        ([key, value]) => `<b>${errorNames[key] ?? key}</b>: ${value}`,
      );

      Alert.error({
        title: 'Error al registrar',
        text: `Se encontraron los siguientes problemas:</br></br>
        ${errors.join('</br>')}
      `,
      });
    }
  };

  return (
    <>
      <div className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="w-full max-w-6xl rounded-[15px] bg-white p-12 shadow-lg">
          <button className="btn-gray p-2" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <LogoHeader />
          <form className="mt-8 space-y-6" onSubmit={handleRegister}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md">
              {/* Login Information */}
              <div className="mb-10 flex flex-col border-t-2 border-gray-200 pt-4 lg:flex-row">
                <div className="max-w-md sm:mb-6 lg:px-5">
                  <h1 className="mt-3 text-xl font-bold text-gray-700">Información básica</h1>
                  <p className="mt-3 text-gray-500">
                    Puedes iniciar sesión solo con tu usuario y contraseña. Se te pedirá el Círculo Militar que
                    administras con fines administrativos.
                  </p>
                </div>
                <div className="w-full">
                  <div>
                    <label htmlFor="email" className="ml-2 mt-3 block font-medium text-black">
                      Correo Electrónico
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        maxLength={255}
                        value={email}
                        onChange={handleOnChangeInput}
                        required
                        className="text-md relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                        placeholder="administrador@circulomilitar.com"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password" className="ml-2 mt-3 block font-medium text-black">
                      Contraseña
                    </label>
                    <InputPassword
                      id="password"
                      name="password"
                      maxLength={255}
                      autoComplete="current-password"
                      value={password}
                      minLength={8}
                      onChange={handleOnChangeInput}
                      required
                      placeholder="******************"
                    />
                  </div>
                  <div>
                    <label htmlFor="campusFrecuency" className="ml-2 mt-3 block font-medium text-black">
                      ¿Qué Círculo Militar administras?
                    </label>
                    <div className="mt-1">
                      <select
                        id="campusFrecuency"
                        name="campusFrecuency"
                        value={campusFrecuency}
                        onChange={handleOnChangeInput}
                        className="block w-full rounded border border-gray-200 bg-gray-50 py-3 px-2 leading-tight focus:border-gray-500 focus:bg-gray-200 focus:outline-none"
                        required
                      >
                        <option value="" disabled>
                          Selecciona un Círculo Militar
                        </option>
                        {campus &&
                          campus.map((campus: Campus) => (
                            <option key={campus.id} value={campus.id}>
                              {campus.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Personal Information */}
              <div className="flex flex-col border-t-2 border-gray-200 pt-4 lg:flex-row">
                <div className="max-w-md sm:mb-6 lg:px-5">
                  <h1 className="mt-3 text-xl font-bold text-gray-700">Información de perfil</h1>
                  <p className="mt-3 text-gray-500">
                    Esta información va ser utilizada para fines informativos. Guardamos cuidadosamente la información
                    que provees.
                  </p>
                </div>
                <div className="w-full">
                  <div>
                    <label htmlFor="name" className="ml-2 mt-3 block font-medium text-black">
                      Nombre y Apellido
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        maxLength={150}
                        value={name}
                        onChange={handleOnChangeInput}
                        required
                        className="text-md relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                        placeholder="Escribe tu nombre completo"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="documentNumber" className="ml-2 mt-3 block font-medium text-black">
                      Cédula
                    </label>
                    <div className="mt-1">
                      <input
                        id="documentNumber"
                        name="documentNumber"
                        type="number"
                        maxLength={10}
                        minLength={10}
                        required
                        className="text-md relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                        placeholder="Ingresa tu cédula"
                        value={documentNumber}
                        onChange={handleOnChangeInput}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="birthday" className="ml-2 mt-3 block font-medium text-black">
                      Fecha de Nacimiento
                    </label>
                    <div className="mt-1">
                      <input
                        id="birthday"
                        name="birthday"
                        type="date"
                        value={birthday}
                        max={new Date().toISOString().split('T')[0]}
                        onChange={handleOnChangeInput}
                        required
                        className="text-md relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                        placeholder="Ingrese su cédula"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="cellNumber" className="ml-2 mt-3 block font-medium text-black">
                      Número de Celular
                    </label>
                    <div className="mt-1">
                      <input
                        id="cellNumber"
                        name="cellNumber"
                        type="text"
                        maxLength={100}
                        value={cellNumber}
                        onChange={handleOnChangeInput}
                        required
                        className="text-md relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                        placeholder="Número personal"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="emergencyNumber" className="ml-2 mt-3 block font-medium text-black">
                      Número de Emergencia
                    </label>
                    <div className="mt-1">
                      <input
                        id="emergencyNumber"
                        name="emergencyNumber"
                        type="text"
                        maxLength={15}
                        value={emergencyNumber}
                        onChange={handleOnChangeInput}
                        required
                        className="text-md relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                        placeholder="Número Secundario o de un Familiar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <ButtonSpinner isLoading={isLoading} text="Registrarse" type="submit" color="black" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

RegisterPage.propTypes = {

}



