import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { Dispatch } from "react";
import { getFirstAndLastDayOfWeek } from "../utils/utils";

interface Props {
  dateRange: [Date | null, Date | null];
  setDateRange: Dispatch<React.SetStateAction<[Date | null, Date | null]>>,
  className?: string;
  selectByWeek?: boolean;
  [x: string]: any;
}

export const InputDateRange = ({ dateRange, setDateRange, className, selectByWeek, ...props }: Props) => {
  const [startDate, endDate] = dateRange;

  return (
    <>
      <DatePicker
        popperClassName="z-50"
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }: any) => (
          <div>
            <button
              aria-label="Mes anterior"
              className="react-datepicker__navigation react-datepicker__navigation--previous top-2"
              style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
              onClick={decreaseMonth}
            >
              <span
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              >
                {"<"}
              </span>
            </button>
            <span className="react-datepicker__current-month first-letter:capitalize">
              {monthDate.toLocaleString("es-ES", {
                  month: "long",
                  year: "numeric",
              }).replace(/\b\w/, (l: string) => l.toUpperCase())}
            </span>
            <button
              aria-label="Mes siguiente"
              className="react-datepicker__navigation react-datepicker__navigation--next top-2"
              style={customHeaderCount === 0 ? { visibility: "hidden" } : undefined}
              onClick={increaseMonth}
            >
              <span
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              >
                {">"}
              </span>
            </button>
          </div>
        )}
        className={`input-yellow pr-5 ${className ? className : ''}`}
        selected={startDate}
        dateFormat="dd/MM/yyyy"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={!!selectByWeek ? (update: [Date | null, Date | null]) => {
          const { first, last } = getFirstAndLastDayOfWeek(update[0] as Date);
          setDateRange([first, last]);
        } :
        (update: [Date | null, Date | null]) => setDateRange(update)}
        isClearable={true}
        locale={es}
        placeholderText="Rango de fecha"
        monthsShown={2}
        { ...props }
      />
    </>
  )
}
