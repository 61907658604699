import { MultipleServicesReservationAction, MultipleServicesReservationPayload } from "../interfaces";
import { MultipleServicesReservationTypes } from "../types";

const initialState: MultipleServicesReservationPayload = {
  loading: false,
  success: false,
  error: null,
  multipleServicesReservation: null,
  listMultipleServicesReservation: [],
  pagMultipleServicesReservation: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const multipleServicesReservation = (state = initialState, action: MultipleServicesReservationAction): MultipleServicesReservationPayload => {
  const { loading, success, error, multipleServicesReservation, listMultipleServicesReservation, pagMultipleServicesReservation } = action.payload || {};
  switch (action.type) {
    case MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATIONPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATIONPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATIONPAG_SUCCESS:
      return {
        ...state,
        pagMultipleServicesReservation,
        loading,
        success,
      };
    case MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATION_SUCCESS:
      return {
        ...state,
        listMultipleServicesReservation,
        loading,
        success,
      };
    case MultipleServicesReservationTypes.GETONLY_MULTIPLESERVICESRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesReservationTypes.GETONLY_MULTIPLESERVICESRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesReservationTypes.GETONLY_MULTIPLESERVICESRESERVATION_SUCCESS:
      return {
        ...state,
        multipleServicesReservation,
        loading,
        success,
      };
    case MultipleServicesReservationTypes.POST_MULTIPLESERVICESRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesReservationTypes.POST_MULTIPLESERVICESRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesReservationTypes.POST_MULTIPLESERVICESRESERVATION_SUCCESS:
      return {
        ...state,
        multipleServicesReservation,
        loading,
        success,
      };
    case MultipleServicesReservationTypes.PUT_MULTIPLESERVICESRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesReservationTypes.PUT_MULTIPLESERVICESRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesReservationTypes.PUT_MULTIPLESERVICESRESERVATION_SUCCESS:
      return {
        ...state,
        multipleServicesReservation,
        loading,
        success,
      };
    case MultipleServicesReservationTypes.DELETE_MULTIPLESERVICESRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesReservationTypes.DELETE_MULTIPLESERVICESRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesReservationTypes.DELETE_MULTIPLESERVICESRESERVATION_SUCCESS:
      return {
        ...state,
        multipleServicesReservation,
        loading,
        success,
      };
    default:
      return state;
  }
};

