import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import alerts from "../../utils/alerts";
import { Campus, HotelTable } from '../../redux/interfaces';
import useForm from '../../utils/hooks/useForm';
import { deleteHotel, getHotel, getListCampus, postHotel, putHotel } from '../../redux/actions';
import { RootState } from '../../redux/store';

export const HotelNewPage = () => {
    const dispatch = useDispatch();
    const { listHotel } = useSelector((state: RootState) => state.hotel);
    const { campus }: any = useSelector((state: RootState) => state.register);
    const [formAction, setFormAction] = useState<'update-form' | 'create-form'>('create-form');
    const {
        id,
        nameHotel,
        address,
        reservationCost,
        campusId,
        handleOnChangeInput,
        handleUpdateState,
    } = useForm<any>({
        id: -1,
        nameHotel: '',
        address: '',
        reservationCost: '',
        campusId: '',
    });

    const pullHotel = () => {
        getHotel()(dispatch);
        getListCampus()(dispatch);
    }

    useEffect(() => {
        pullHotel();
    }, []); // eslint-disable-line

    const handEditHotel = (hotel: HotelTable) => {
        handleUpdateState({
            id: hotel.id,
            nameHotel: hotel.name,
            address: hotel.address,
            reservationCost: hotel.reservation_cost,
            campusId: hotel.campus.id
        });
        setFormAction('update-form');
    };

    const handleSubmitHotel = async (e: FormEvent) => {
        // get name from submit button
        e.preventDefault();
        if (formAction === 'create-form') {
            handleCreateHotel();
        } else {
            handleUpdateHotel();
        }
    };

    const handleCreateHotel = async () => {
        const { payload } = await postHotel({
            name: nameHotel,
            address: address,
            reservation_cost: reservationCost,
            campus_id: campusId,
        })(dispatch);

        if (!payload.success && payload.error && payload.error.message) {
            return toast.error(payload.error.message);
        }

        toast.success(`"${nameHotel}" se creó correctamente`);
        resetToCreateForm();
    }

    const handleUpdateHotel = async () => {
        const { payload } = await putHotel({
            id: id,
            name: nameHotel,
            address: address,
            reservation_cost: reservationCost,
            campus_id: campusId,
        })(dispatch);

        if (!payload.success && payload.error && payload.error.message) {
            return toast.error(payload.error.message);
        }

        toast.success(`Datos de "${nameHotel}" actualizados correctamente`);
        resetToCreateForm();
    }

    const handleDeleteHotel = async (hotel: HotelTable) => {
        const userRes = await alerts.warning({
            title: 'Última oportunidad',
            text: `Está apunto de eliminar <b>"${hotel.name}"</b>. Una vez realizada esta acción no se podrá revertir.`
        });

        if (!userRes.isConfirmed) return;

        const { payload } = await deleteHotel(hotel.id)(dispatch);

        if (!payload.success && payload.error && payload.error.message) {
            return toast.error(payload.error.message);
        }

        toast.success('Hotel eliminado correctamente');
        resetToCreateForm();
    }

    const reloadForm = () => {
        // Reload all data
        pullHotel();
        // Reset Form
        handleUpdateState({
            id: -1,
            nameHotel: '',
            address: '',
            reservationCost: '',
            campusId: '',
        });
    };

    const resetToCreateForm = () => {
        setFormAction('create-form');
        reloadForm();
    }

    return (
        <div>
            <form onSubmit={handleSubmitHotel}>
                <div
                    onClick={() => resetToCreateForm()}
                    className={`mb-4 btn-black w-fit ${formAction === 'create-form' ? 'hidden' : 'block'}`}
                >
                    Nuevo Hotel
                <svg xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                </div>

                <h2 className="font-bold text-gray-500 mb-9">
                    Hoteles
                </h2>

                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 ">
                        <input
                            id="nameHotel"
                            name="nameHotel"
                            type="text"
                            maxLength={150}
                            value={nameHotel}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Nombre del Hotel"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="address"
                            name="address"
                            type="text"
                            maxLength={150}
                            value={address}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Dirección"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="reservationCost"
                            name="reservationCost"
                            type="number"
                            value={reservationCost}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Costo de reservación"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <select
                            id="campusId"
                            name='campusId'
                            value={campusId}
                            onChange={handleOnChangeInput}
                            className="block w-full bg-gray-50 border border-gray-200 py-3 px-2 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                            required
                        >
                            <option value="" disabled>Seleccione un campus</option>
                            {
                                campus && campus.map((c: Campus) => (
                                    <option key={`campus-${c.id}`} value={c.id}>
                                        {c.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-span-2 flex justify-end">
                        <div>
                            <button
                                name={formAction}
                                type="submit"
                                className={`my-8 ${formAction === 'create-form' ? 'btn-yellow' : 'btn-black'}`}
                            >
                                <h1>{formAction === 'create-form' ? 'Crear Hotel' : 'Actualizar Hotel'}</h1>
                            </button>
                        </div>
                    </div>
                </div>
            </form>


            <h2 className="font-bold text-gray-500 mb-4">
                Hoteles
            </h2>

            <div className="pb-8 divide-gray-500/25">
                <div className="  divide-gray-500/25 mb-8">
                    {listHotel && listHotel.map((hotel, i) => (
                        <div
                            key={`hotel-${i}`}
                            className="flex flex-row items-center justify-between odd:bg-gray-50 even:bg-white even:hover:bg-gray-50"
                        >
                            <span className="basis-3/5 px-4 font-bold text-black">
                                {/* {multipleServicesCategory.name} */}
                                {hotel.name}
                            </span>
                            <span className="basis-3/5 px-4 font-bold text-black">
                                {/* {multipleServicesCategory.name} */}
                                {hotel.address}
                            </span>
                            <span className="mr-6 flex flex-row gap-1">
                                <button
                                    onClick={() => handEditHotel(hotel)}
                                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-700"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                </button>
                                <button
                                    onClick={() => handleDeleteHotel(hotel)}
                                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-700"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
