import { MultipleServicesImagesProcess } from "../api";
import { MultipleServicesImagesAction, MultipleServicesImagesFilters } from "../interfaces";
import { MultipleServicesImagesTypes } from "../types";

// apply the reducer
export const getMultipleServicesImages = (filters?: MultipleServicesImagesFilters) => async (dispatch: (state: MultipleServicesImagesAction) => MultipleServicesImagesAction) => {
  dispatch({
    type: MultipleServicesImagesTypes.GET_MULTIPLESERVICESIMAGES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesImagesProcess = new MultipleServicesImagesProcess();
    // dont'n import the result of the process, only that dont't have error
    const multipleServicesImagesPag = await multipleServicesImagesProcess.getMultipleServicesImages({
      ...filters,
      data_all: 'YES',
    });
    const multipleServicesImages = multipleServicesImagesPag.results;
    return dispatch({
      type: MultipleServicesImagesTypes.GET_MULTIPLESERVICESIMAGES_SUCCESS,
      payload: {
        listMultipleServicesImages: multipleServicesImages,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesImagesTypes.GET_MULTIPLESERVICESIMAGES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postMultipleServicesImages = (msImages: FormData) => async (dispatch: (state: MultipleServicesImagesAction) => MultipleServicesImagesAction) => {
  dispatch({
    type: MultipleServicesImagesTypes.POST_MULTIPLESERVICESIMAGES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesImagesProcess = new MultipleServicesImagesProcess();
    // dont'n import the result of the process, only that dont't have error
    const msImagesRes = await multipleServicesImagesProcess.postMultipleServicesImages(msImages);
    return dispatch({
      type: MultipleServicesImagesTypes.POST_MULTIPLESERVICESIMAGES_SUCCESS,
      payload: {
        multipleServicesImages: msImagesRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar la lista de reservaciones. Por favor, intenta más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putMultipleServicesImages = (msImages: FormData) => async (dispatch: (state: MultipleServicesImagesAction) => MultipleServicesImagesAction) => {
  dispatch({
    type: MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesImagesProcess = new MultipleServicesImagesProcess();
    // dont'n import the result of the process, only that dont't have error
    const msImagesRes = await multipleServicesImagesProcess.putMultipleServicesImages(msImages);
    return dispatch({
      type: MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_SUCCESS,
      payload: {
        multipleServicesImages: msImagesRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteMultipleServicesImages = (id_images: number) => async (dispatch: (state: MultipleServicesImagesAction) => MultipleServicesImagesAction) => {
  dispatch({
    type: MultipleServicesImagesTypes.DELETE_MULTIPLESERVICESIMAGES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesImagesProcess = new MultipleServicesImagesProcess();
    // dont'n import the result of the process, only that dont't have error
    await multipleServicesImagesProcess.deleteMultipleServicesImages(id_images);
    return dispatch({
      type: MultipleServicesImagesTypes.DELETE_MULTIPLESERVICESIMAGES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar la lista de reservaciones. Por favor, intenta más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};