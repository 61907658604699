import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HolidaysFilters, HolidaysGet, HolidaysPost, HolidaysPut, HolidaysTable } from "../../interfaces";

export class HolidaysRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHolidays(filters?: HolidaysFilters): Promise<AxiosResponse<HolidaysGet>> {
    return this.lmBootApi.get<HolidaysGet>("/holidays/", { params: filters });
  }

  getHolidaysById(multiple_service_id: number): Promise<AxiosResponse<HolidaysGet>> {
    return this.lmBootApi.get<HolidaysGet>("/holidays/", { params: { multiple_service_id } });
  }

  postHolidays(msSchedule: HolidaysPost): Promise<AxiosResponse<HolidaysTable>> {
    return this.lmBootApi.post<HolidaysTable>("/holidays/", msSchedule);
  }

  putHolidays(msSchedule: HolidaysPut): Promise<AxiosResponse<HolidaysTable>> {
    return this.lmBootApi.put<HolidaysTable>("/holidays/", msSchedule);
  }

  // dont response data
  deleteHolidays(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/holidays/${id}/`);
  }
}
