import { HotelReservationRepository } from "../repositories";
import { HotelReservationFilters, HotelReservationGet, HotelReservationPost, HotelReservationPut, HotelReservationTable } from "../../interfaces";

export class HotelReservationProcess {
  private multipleServiceReservationRepository: HotelReservationRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelReservationRepository();
  }

  async getHotelReservation(filters?: HotelReservationFilters): Promise<HotelReservationGet> {
    const response = await this.multipleServiceReservationRepository.getHotelReservation(filters);
    return response.data;
  }

  async postHotelReservation(hotelReservation: HotelReservationPost): Promise<HotelReservationTable> {
    const response = await this.multipleServiceReservationRepository.postHotelReservation(hotelReservation);
    return response.data;
  }

  async putHotelReservation(hotelReservation: HotelReservationPut): Promise<HotelReservationTable> {
    const response = await this.multipleServiceReservationRepository.putHotelReservation(hotelReservation);
    return response.data;
  }

  // dont response data
  async deleteHotelReservation(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotelReservation(id);
    return response.data;
  }
}
