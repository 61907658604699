import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelOccupancyPaymentFilters, HotelOccupancyPaymentGet, HotelOccupancyPaymentPost, HotelOccupancyPaymentPut, HotelOccupancyPaymentTable } from "../../interfaces";

export class HotelOccupancyPaymentRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotelOccupancyPayment(filters?: HotelOccupancyPaymentFilters): Promise<AxiosResponse<HotelOccupancyPaymentGet>> {
    return this.lmBootApi.get<HotelOccupancyPaymentGet>("/hotel-occupancy-payments/", { params: filters });
  }

  postHotelOccupancyPayment(hotelOccupancyPayment: HotelOccupancyPaymentPost): Promise<AxiosResponse<HotelOccupancyPaymentTable>> {
    return this.lmBootApi.post<HotelOccupancyPaymentTable>("/hotel-occupancy-payments/", hotelOccupancyPayment);
  }

  putHotelOccupancyPayment(hotelOccupancyPayment: HotelOccupancyPaymentPut): Promise<AxiosResponse<HotelOccupancyPaymentTable>> {
    return this.lmBootApi.put<HotelOccupancyPaymentTable>("/hotel-occupancy-payments/", hotelOccupancyPayment);
  }

  // dont response data
  deleteHotelOccupancyPayment(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/hotel-occupancy-payments/${id}/`);
  }
}
