
import { RestaurantSchedulesRepository } from "../repositories";
import { RestaurantSchedulesFilters, RestaurantSchedulesGet, RestaurantSchedulesPost, RestaurantSchedulesPut, RestaurantSchedulesTable } from "../../interfaces";

export class RestaurantSchedulesProcess {
  private restaurantSchedulesRepository: RestaurantSchedulesRepository;

  constructor() {
    this.restaurantSchedulesRepository = new RestaurantSchedulesRepository();
  }

  async getRestaurantSchedules(filters?: RestaurantSchedulesFilters): Promise<RestaurantSchedulesGet> {
    const response = await this.restaurantSchedulesRepository.getRestaurantSchedules(filters);
    return response.data;
  }

  async postRestaurantSchedules(restaurantSchedules: RestaurantSchedulesPost): Promise<RestaurantSchedulesTable> {
    const response = await this.restaurantSchedulesRepository.postRestaurantSchedules(restaurantSchedules);
    return response.data;
  }

  async putRestaurantSchedules(restaurantSchedules: RestaurantSchedulesPut): Promise<RestaurantSchedulesTable> {
    const response = await this.restaurantSchedulesRepository.putRestaurantSchedules(restaurantSchedules);
    return response.data;
  }

  // dont response data
  async deleteRestaurantSchedules(id: number): Promise<any> {
    const response = await this.restaurantSchedulesRepository.deleteRestaurantSchedules(id);
    return response.data;
  }
}
