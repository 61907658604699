import { Form, Formik, FormikErrors } from "formik";
import { getParameters, postParameters, putParameters } from "../../redux/actions";
import { ParametersTable } from "../../redux/interfaces";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { useEffect, useState } from "react";

export const CampusTermsAndConditionsPage = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState<any>('');
  const [currentParameters, setCurrentParameters] = useState<ParametersTable>();

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await getParameters({
        code: 'terms_and_conditions'
      })(dispatch);

      const { success } = payload;
      if (!success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      if (payload.listParameters && payload.listParameters?.length > 0) {
        const parameterTermsAndConditions = payload.listParameters.find(x => x.code === 'terms_and_conditions');
        if (!!parameterTermsAndConditions) {
          setCurrentParameters(parameterTermsAndConditions);
          setDescription(parameterTermsAndConditions.description);
        }
      }
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <Formik
      initialValues={{
        description: '',
      }}
      validate={() => {
        const errors: FormikErrors<any> = {};
        if (!description) {
          errors.description = "Llene el campo por favor";
        }
        return errors;
      }}
      onSubmit={async () => {
        if (!!currentParameters) {
          // this is for put
          const { payload } = await putParameters({
            id: currentParameters.id,
            code: 'terms_and_conditions',
            description,
          })(dispatch);

          const { success } = payload;
          if (!success) {
            return toast.error(payload.error?.message, { type: 'error' });
          }
        } else {
          // this is for put
          const { payload } = await postParameters({
            code: 'privacy_policies',
            description,
          })(dispatch);

          const { success } = payload;
          if (!success) {
            return toast.error(payload.error?.message, { type: 'error' });
          }

        }
        toast.success('Términos y condiciones actualizados.');
      }}
    >
      {({ errors }) => (
        <>
          <h1 className="text-gray-500 text-lg font-bold mb-6">Términos y condiciones</h1>
          <Form
            className="flex flex-col gap-4"
          >
            <MDEditor
              id="description"
              value={description}
              onChange={setDescription}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
              height={500}
            />
            { errors.description && <span className="text-rose-500">{errors.description}</span> }
            <div className="flex flex-row justify-end">
              <button
                type="submit"
                className="btn-yellow w-32"
              >
                Guardar
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}