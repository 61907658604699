
import { CampusContributionAction, CampusContributionPayload } from "../interfaces";
import { CampusContributionTypes } from "../types";

const initialState: CampusContributionPayload = {
  loading: false,
  success: false,
  error: null,
  campusContribution: null,
  listCampusContribution: [],
  pagCampusContribution: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const campusContribution = (state = initialState, action: CampusContributionAction): CampusContributionPayload => {
  const { loading, success, error, campusContribution, listCampusContribution, pagCampusContribution } = action.payload || {};
  switch (action.type) {
    case CampusContributionTypes.GET_CAMPUSCONTRIBUTIONPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusContributionTypes.GET_CAMPUSCONTRIBUTIONPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusContributionTypes.GET_CAMPUSCONTRIBUTIONPAG_SUCCESS:
      return {
        ...state,
        pagCampusContribution,
        loading,
        success,
      };
    case CampusContributionTypes.GET_CAMPUSCONTRIBUTION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusContributionTypes.GET_CAMPUSCONTRIBUTION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusContributionTypes.GET_CAMPUSCONTRIBUTION_SUCCESS:
      return {
        ...state,
        listCampusContribution,
        loading,
        success,
      };
    case CampusContributionTypes.GETONLY_CAMPUSCONTRIBUTION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusContributionTypes.GETONLY_CAMPUSCONTRIBUTION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusContributionTypes.GETONLY_CAMPUSCONTRIBUTION_SUCCESS:
      return {
        ...state,
        campusContribution,
        loading,
        success,
      };
    case CampusContributionTypes.POST_CAMPUSCONTRIBUTION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusContributionTypes.POST_CAMPUSCONTRIBUTION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusContributionTypes.POST_CAMPUSCONTRIBUTION_SUCCESS:
      return {
        ...state,
        campusContribution,
        loading,
        success,
      };
    case CampusContributionTypes.PUT_CAMPUSCONTRIBUTION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusContributionTypes.PUT_CAMPUSCONTRIBUTION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusContributionTypes.PUT_CAMPUSCONTRIBUTION_SUCCESS:
      return {
        ...state,
        campusContribution,
        loading,
        success,
      };
    case CampusContributionTypes.DELETE_CAMPUSCONTRIBUTION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusContributionTypes.DELETE_CAMPUSCONTRIBUTION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusContributionTypes.DELETE_CAMPUSCONTRIBUTION_SUCCESS:
      return {
        ...state,
        campusContribution,
        loading,
        success,
      };
    default:
      return state;
  }
};

