
export enum SuggestionsTypes {
  GET_SUGGESTIONSPAG_LOADING = "GET_SUGGESTIONSPAG_LOADING",
  GET_SUGGESTIONSPAG_SUCCESS = "GET_SUGGESTIONSPAG_SUCCESS",
  GET_SUGGESTIONSPAG_FAILURE = "GET_SUGGESTIONSPAG_FAILURE",
  GET_SUGGESTIONS_LOADING = "GET_SUGGESTIONS_LOADING",
  GET_SUGGESTIONS_SUCCESS = "GET_SUGGESTIONS_SUCCESS",
  GET_SUGGESTIONS_FAILURE = "GET_SUGGESTIONS_FAILURE",
  GETONLY_SUGGESTIONS_LOADING = "GETONLY_SUGGESTIONS_LOADING",
  GETONLY_SUGGESTIONS_SUCCESS = "GETONLY_SUGGESTIONS_SUCCESS",
  GETONLY_SUGGESTIONS_FAILURE = "GETONLY_SUGGESTIONS_FAILURE",
  POST_SUGGESTIONS_LOADING = "POST_SUGGESTIONS_LOADING",
  POST_SUGGESTIONS_SUCCESS = "POST_SUGGESTIONS_SUCCESS",
  POST_SUGGESTIONS_FAILURE = "POST_SUGGESTIONS_FAILURE",
  PUT_SUGGESTIONS_LOADING = "PUT_SUGGESTIONS_LOADING",
  PUT_SUGGESTIONS_SUCCESS = "PUT_SUGGESTIONS_SUCCESS",
  PUT_SUGGESTIONS_FAILURE = "PUT_SUGGESTIONS_FAILURE",
  DELETE_SUGGESTIONS_LOADING = "DELETE_SUGGESTIONS_LOADING",
  DELETE_SUGGESTIONS_SUCCESS = "DELETE_SUGGESTIONS_SUCCESS",
  DELETE_SUGGESTIONS_FAILURE = "DELETE_SUGGESTIONS_FAILURE",
}