
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { RestaurantCategoriesFilters, RestaurantCategoriesGet, RestaurantCategoriesPost, RestaurantCategoriesPut, RestaurantCategoriesTable } from "../../interfaces";

export class RestaurantCategoriesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getRestaurantCategories(filters?: RestaurantCategoriesFilters): Promise<AxiosResponse<RestaurantCategoriesGet>> {
    return this.lmBootApi.get<RestaurantCategoriesGet>("/restaurant-categories/", { params: filters });
  }

  postRestaurantCategories(restaurantCategories: RestaurantCategoriesPost): Promise<AxiosResponse<RestaurantCategoriesTable>> {
    return this.lmBootApi.post<RestaurantCategoriesTable>("/restaurant-categories/", restaurantCategories);
  }

  putRestaurantCategories(restaurantCategories: RestaurantCategoriesPut): Promise<AxiosResponse<RestaurantCategoriesTable>> {
    return this.lmBootApi.put<RestaurantCategoriesTable>("/restaurant-categories/", restaurantCategories);
  }

  // dont response data
  deleteRestaurantCategories(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/restaurant-categories/${id}/`);
  }
}

