import { HotelOccupancyPaymentProcess } from "../api";
import { HotelOccupancyPaymentAction, HotelOccupancyPaymentFilters, HotelOccupancyPaymentPost, HotelOccupancyPaymentPut } from "../interfaces";
import { HotelOccupancyPaymentTypes } from "../types";

// apply the reducer
export const getHotelOccupancyPaymentPags = (filters?: HotelOccupancyPaymentFilters) => async (dispatch: (state: HotelOccupancyPaymentAction) => HotelOccupancyPaymentAction) => {
  dispatch({
    type: HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENTPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelOccupancyPaymentProcess = new HotelOccupancyPaymentProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelOccupancyPaymentPag = await hotelOccupancyPaymentProcess.getHotelOccupancyPayment(filters);
    return dispatch({
      type: HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENTPAG_SUCCESS,
      payload: {
        pagHotelOccupancyPayment: hotelOccupancyPaymentPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENTPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotelOccupancyPayment = (filters?: HotelOccupancyPaymentFilters) => async (dispatch: (state: HotelOccupancyPaymentAction) => HotelOccupancyPaymentAction) => {
  dispatch({
    type: HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelOccupancyPaymentProcess = new HotelOccupancyPaymentProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelOccupancyPaymentPag = await hotelOccupancyPaymentProcess.getHotelOccupancyPayment({
      ...filters,
      data_all: 'YES',
    });
    const hotelOccupancyPayment = hotelOccupancyPaymentPag.results;
    return dispatch({
      type: HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENT_SUCCESS,
      payload: {
        listHotelOccupancyPayment: hotelOccupancyPayment,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotelOccupancyPayment = (hotelOccupancyPayment: HotelOccupancyPaymentPost) => async (dispatch: (state: HotelOccupancyPaymentAction) => HotelOccupancyPaymentAction) => {
  dispatch({
    type: HotelOccupancyPaymentTypes.POST_HOTELOCCUPANCYPAYMENT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelOccupancyPaymentProcess = new HotelOccupancyPaymentProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelOccupancyPaymentProcess.postHotelOccupancyPayment(hotelOccupancyPayment);
    return dispatch({
      type: HotelOccupancyPaymentTypes.POST_HOTELOCCUPANCYPAYMENT_SUCCESS,
      payload: {
        hotelOccupancyPayment: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelOccupancyPaymentTypes.POST_HOTELOCCUPANCYPAYMENT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotelOccupancyPayment = (hotelOccupancyPayment: HotelOccupancyPaymentPut) => async (dispatch: (state: HotelOccupancyPaymentAction) => HotelOccupancyPaymentAction) => {
  dispatch({
    type: HotelOccupancyPaymentTypes.PUT_HOTELOCCUPANCYPAYMENT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelOccupancyPaymentProcess = new HotelOccupancyPaymentProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelOccupancyPaymentProcess.putHotelOccupancyPayment(hotelOccupancyPayment);
    return dispatch({
      type: HotelOccupancyPaymentTypes.PUT_HOTELOCCUPANCYPAYMENT_SUCCESS,
      payload: {
        hotelOccupancyPayment: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelOccupancyPaymentTypes.PUT_HOTELOCCUPANCYPAYMENT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotelOccupancyPayment = (id: number) => async (dispatch: (state: HotelOccupancyPaymentAction) => HotelOccupancyPaymentAction) => {
  dispatch({
    type: HotelOccupancyPaymentTypes.DELETE_HOTELOCCUPANCYPAYMENT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelOccupancyPaymentProcess = new HotelOccupancyPaymentProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelOccupancyPaymentProcess.deleteHotelOccupancyPayment(id);
    return dispatch({
      type: HotelOccupancyPaymentTypes.DELETE_HOTELOCCUPANCYPAYMENT_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelOccupancyPaymentTypes.DELETE_HOTELOCCUPANCYPAYMENT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};