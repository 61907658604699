import { HotelBedroomAction, HotelBedroomPayload } from "../interfaces";
import { HotelBedroomTypes } from "../types";

const initialState: HotelBedroomPayload = {
  loading: false,
  success: false,
  error: null,
  hotelBedroom: null,
  listHotelBedroom: [],
  pagHotelBedroom: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotelBedroom = (state = initialState, action: HotelBedroomAction): HotelBedroomPayload => {
  const { loading, success, error, hotelBedroom, listHotelBedroom, pagHotelBedroom } = action.payload || {};
  switch (action.type) {
    case HotelBedroomTypes.GET_HOTELBEDROOMPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBedroomTypes.GET_HOTELBEDROOMPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBedroomTypes.GET_HOTELBEDROOMPAG_SUCCESS:
      return {
        ...state,
        pagHotelBedroom,
        loading,
        success,
      };
    case HotelBedroomTypes.GET_HOTELBEDROOM_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBedroomTypes.GET_HOTELBEDROOM_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBedroomTypes.GET_HOTELBEDROOM_SUCCESS:
      return {
        ...state,
        listHotelBedroom,
        loading,
        success,
      };
    case HotelBedroomTypes.GETONLY_HOTELBEDROOM_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBedroomTypes.GETONLY_HOTELBEDROOM_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBedroomTypes.GETONLY_HOTELBEDROOM_SUCCESS:
      return {
        ...state,
        hotelBedroom,
        loading,
        success,
      };
    case HotelBedroomTypes.POST_HOTELBEDROOM_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBedroomTypes.POST_HOTELBEDROOM_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBedroomTypes.POST_HOTELBEDROOM_SUCCESS:
      return {
        ...state,
        hotelBedroom,
        loading,
        success,
      };
    case HotelBedroomTypes.PUT_HOTELBEDROOM_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBedroomTypes.PUT_HOTELBEDROOM_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBedroomTypes.PUT_HOTELBEDROOM_SUCCESS:
      return {
        ...state,
        hotelBedroom,
        loading,
        success,
      };
    case HotelBedroomTypes.DELETE_HOTELBEDROOM_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBedroomTypes.DELETE_HOTELBEDROOM_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBedroomTypes.DELETE_HOTELBEDROOM_SUCCESS:
      return {
        ...state,
        hotelBedroom,
        loading,
        success,
      };
    default:
      return state;
  }
};

