
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { EventSaloonsFilters, EventSaloonsGet, EventSaloonsPost, EventSaloonsPut, EventSaloonsTable } from "../../interfaces";

export class EventSaloonsRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getEventSaloons(filters?: EventSaloonsFilters): Promise<AxiosResponse<EventSaloonsGet>> {
    return this.lmBootApi.get<EventSaloonsGet>("/event-salons/", { params: filters });
  }

  postEventSaloons(eventSaloons: EventSaloonsPost): Promise<AxiosResponse<EventSaloonsTable>> {
    return this.lmBootApi.post<EventSaloonsTable>("/event-salons/", eventSaloons);
  }

  putEventSaloons(eventSaloons: EventSaloonsPut): Promise<AxiosResponse<EventSaloonsTable>> {
    return this.lmBootApi.put<EventSaloonsTable>("/event-salons/", eventSaloons);
  }

  // dont response data
  deleteEventSaloons(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/event-salons/${id}/`);
  }
}

