import { HotelNewPage } from "../../pages/hotel";
import { UsersPage } from "../../pages/users";
import { UsersReportPage } from "../../pages/users/UsersReportPage";
import { UserRole } from "../../utils/enums/userRoles.enum";

export const usersRoutes = [
    // USERS MODULE
    {
        path: '/users',
        to: '/users',
        name: 'Usuarios',
        secondMenuShow: false,
        showMenu: true,
        roles: [UserRole.SUPER_ADMIN],
        Component: UsersPage,
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
            </svg>
        )
    },
    {
        path: '/users/report',
        to: '/users/report',
        name: 'Reporte de usuarios',
        secondMenuShow: false,
        showMenu: true,
        roles: [UserRole.SUPER_ADMIN],
        Component: UsersReportPage,
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z" clipRule="evenodd" />
            </svg>
        )
    },
    {
        path: '/hotel/new',
        to: '/hotel/new',
        name: 'Hoteles',
        secondMenuShow: false,
        showMenu: true,
        roles: [UserRole.SUPER_ADMIN],
        Component: HotelNewPage,
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
            </svg>
        )
    },
]