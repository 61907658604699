import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { MultipleServicesImagesFilters, MultipleServicesImagesGet, MultipleServicesImagesTable } from "../../interfaces";

export class MultipleServicesImagesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getMultipleServicesImages(filters?: MultipleServicesImagesFilters): Promise<AxiosResponse<MultipleServicesImagesGet>> {
    return this.lmBootApi.get<MultipleServicesImagesGet>("/multiple-service-images/", { params: filters });
  }

  postMultipleServicesImages(msImages: FormData): Promise<AxiosResponse<MultipleServicesImagesTable>> {
    return this.lmBootApi.post<MultipleServicesImagesTable>("/multiple-service-images/", msImages);
  }

  putMultipleServicesImages(msImages: FormData): Promise<AxiosResponse<MultipleServicesImagesTable>> {
    return this.lmBootApi.put<MultipleServicesImagesTable>("/multiple-service-images/", msImages);
  }

  // dont response data
  deleteMultipleServicesImages(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/multiple-service-images/${id}/`);
  }
}
