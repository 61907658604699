import { HotelBankAccountAction, HotelBankAccountPayload } from "../interfaces";
import { HotelBankAccountTypes } from "../types";

const initialState: HotelBankAccountPayload = {
  loading: false,
  success: false,
  error: null,
  hotelBankAccount: null,
  listHotelBankAccount: [],
  pagHotelBankAccount: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotelBankAccount = (state = initialState, action: HotelBankAccountAction): HotelBankAccountPayload => {
  const { loading, success, error, hotelBankAccount, listHotelBankAccount, pagHotelBankAccount } = action.payload || {};
  switch (action.type) {
    case HotelBankAccountTypes.GET_HOTELBANKACCOUNTPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBankAccountTypes.GET_HOTELBANKACCOUNTPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBankAccountTypes.GET_HOTELBANKACCOUNTPAG_SUCCESS:
      return {
        ...state,
        pagHotelBankAccount,
        loading,
        success,
      };
    case HotelBankAccountTypes.GET_HOTELBANKACCOUNT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBankAccountTypes.GET_HOTELBANKACCOUNT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBankAccountTypes.GET_HOTELBANKACCOUNT_SUCCESS:
      return {
        ...state,
        listHotelBankAccount,
        loading,
        success,
      };
    case HotelBankAccountTypes.GETONLY_HOTELBANKACCOUNT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBankAccountTypes.GETONLY_HOTELBANKACCOUNT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBankAccountTypes.GETONLY_HOTELBANKACCOUNT_SUCCESS:
      return {
        ...state,
        hotelBankAccount,
        loading,
        success,
      };
    case HotelBankAccountTypes.POST_HOTELBANKACCOUNT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBankAccountTypes.POST_HOTELBANKACCOUNT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBankAccountTypes.POST_HOTELBANKACCOUNT_SUCCESS:
      return {
        ...state,
        hotelBankAccount,
        loading,
        success,
      };
    case HotelBankAccountTypes.PUT_HOTELBANKACCOUNT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBankAccountTypes.PUT_HOTELBANKACCOUNT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBankAccountTypes.PUT_HOTELBANKACCOUNT_SUCCESS:
      return {
        ...state,
        hotelBankAccount,
        loading,
        success,
      };
    case HotelBankAccountTypes.DELETE_HOTELBANKACCOUNT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelBankAccountTypes.DELETE_HOTELBANKACCOUNT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelBankAccountTypes.DELETE_HOTELBANKACCOUNT_SUCCESS:
      return {
        ...state,
        hotelBankAccount,
        loading,
        success,
      };
    default:
      return state;
  }
};

