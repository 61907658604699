import { FormikErrors, useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getEventSaloons, getEventTypes, getHotelBankAccount, getUser, postEventReservations, postEventWireTransfers } from "../../redux/actions";
import { EventReservationsTable, EventSaloonsTable, User } from "../../redux/interfaces";
import { RootState } from '../../redux/store';

type VoucherType = {
    id?: number;
    receipt: string;
    amount?: number;
    hotel_reservation_id?: number;
    file?: File
}

export const EventReservationNewPage = () => {
    const dispatch = useDispatch();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    // const { listEventSaloons } = useSelector((state: RootState) => state.eventSaloons);
    const { listEventTypes } = useSelector((state: RootState) => state.eventTypes);
    const { listHotelBankAccount } = useSelector((state: RootState) => state.hotelBankAccount);
    const [ voucherFile, setVoucherFile ] = useState<VoucherType[]>([]);
    const [ memberUser, setMemberUser ] = useState<User | null>(null);
    const [ voucherName, setVoucherName ] = useState('');
    const [ eventSaloons, setEventSaloons ] = useState<EventSaloonsTable[]>([]);

    const {
        values: {
            documentNumber,
            hasMember,
            totalPeople,
            eventPackage,
            eventTypeId,
            paymentMethod,
            startHour,
            endHour,
            totalCost,
            startDate,
            eventSalonId,
            eventPackages
        },
        setFieldValue,
        getFieldProps,
        handleSubmit,
        errors,
        touched
        } = useFormik({
        initialValues: {
            nameMember: '',
            documentNumber: '',
            hasMember: false,
            eventTypeId: '',
            paymentMethod: '',
            bankAccount: '',
            startHour: '',
            endHour: '',
            description: '',
            totalCost: '',
            startDate: '',
            amountPaid: '',
            eventSalonId: '',
            eventPackages: [],
            eventPackage: '',
            totalPeople: '',
            // errors
            voucherName: '',
        },
        validate: values => {
            const errors: FormikErrors<any> = {};
            if (values.paymentMethod === 'WIRE_TRANSFER' && !voucherName) {
                errors.voucherName = 'Comprobante es requerido';
            }
            return errors;
        },
        onSubmit: async (values, formikBag) => {
            if (memberUser === null) {
                return toast.error('El socio no existe.');
            };

            const { payload } = await postEventReservations({
                description: values.description,
                event_type_id: parseInt(values.eventTypeId),
                start_date: new Date(`${values.startDate}T${values.startHour}`).toISOString(),
                end_date: new Date(`${values.startDate}T${values.endHour}`).toISOString(),
                event_coordinator_id: 1,
                event_package: values.eventPackage,
                event_salon_id: parseInt(values.eventSalonId),
                payment_method: values.paymentMethod,
                status: 'PENDING',
                total_people: parseInt(values.totalPeople),
                total_price: parseFloat(totalCost),
                user_id: memberUser.id
            })(dispatch);
            if (!payload.success) {
                toast.error(payload.error?.message, { type: 'error' });
                return;
            }
            toast.success('Reservación creada con éxito.');

            if (voucherFile.length === 0) return formikBag.resetForm();

            toast.info('Subiendo comprobante...');
            // Upload images
            const voucherToPost = new FormData();
            const eventReservation = payload.eventReservations as EventReservationsTable;

            voucherToPost.append('amount', values.amountPaid);
            voucherToPost.append('event_reservation_id', eventReservation.id.toString());
            voucherToPost.append('bank_data_id', values.bankAccount);
            for (const voucher of voucherFile) {
                if (!voucher.id && voucher.file){
                    voucherToPost.append('receipt', voucher.file);
                }
            }

            const allPromiseRequest = [];
            voucherToPost.getAll('receipt').length > 0 && allPromiseRequest.push(postEventWireTransfers(voucherToPost)(dispatch));

            const responses = await Promise.all(allPromiseRequest);
            const allSuccess = responses.every(response => response.payload.success);

            if (!allSuccess) {
            return toast.error(
                '¡Oops!, tuvimos un problema al subir el comprobante. Por favor vuelva a intentarlo más tarde.',
                { type: 'error' }
            );
            }
            toast.success('Comprobante subido con éxito.');
            formikBag.resetForm();
        }
    });

    // const reloadForm = () => {
    //     // Reload all data
    //     pullFormData();
    //     // Reset Form
    //     setVoucherFile([]);
    //     setMemberUser(null);
    //     // setValues({
    //     //     nameMember: '',
    //     //     documentNumber: '',
    //     //     hotelBedroomId: '',
    //     //     paymentMethod: '',
    //     //     bankAccount: '',
    //     //     startHour: '',
    //     //     endHour: '',
    //     //     description: '',
    //     //     totalCost: '',
    //     //     startDate: '',
    //     //     endDate: '',
    //     //     membersNumber: '',
    //     //     familyNumber: '',
    //     //     guessNumber: '',
    //     //     amountPaid: '',
    //     //     eventSalonId: '',
    //     // });
    //     setVoucherName('');
    // };
    const pullFormData = async() => {
        getHotelBankAccount({
            campus_id: user.campus.id
        })(dispatch);
    };
    useEffect(() => {
        pullFormData();
        getEventTypes({
            campus_id: user.campus.id
        })(dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const pullMember = async () => {
            if (documentNumber.toString().length < 10) return;
            const { payload } = await getUser({
                document_number: documentNumber
            })(dispatch);
            if (payload.member.results.length > 0) {
                setFieldValue('nameMember', payload.member.results[0].first_name);
                setFieldValue('hasMember', true);
                setMemberUser(payload.member.results[0]);
            } else {
                setFieldValue('nameMember', 'No se encontró el socio.');
                setFieldValue('hasMember', false);
                setMemberUser(null);
            }
        }
        pullMember();
    }, [documentNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const pullEventSaloons = async () => {
            const { payload } = await getEventSaloons({
                campus_id: user.campus.id,
            })(dispatch);
            const listEventSaloons = payload.listEventSaloons || [];
            setEventSaloons(listEventSaloons.filter(saloon => saloon.capacity >= parseInt(totalPeople)));
        }
        pullEventSaloons();
    }, [totalPeople]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!startHour) return;
        setFieldValue('endHour', startHour ?
        new Date(
                new Date()
                .setHours(parseInt(startHour.split(':')[0]), parseInt(startHour.split(':')[1]), 0)
                + (6 * 60 * 60 * 1000) - (1 * 60 * 1000)
            ).toTimeString().split(' ')[0] : '');
    }, [startHour]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!startDate || !startHour || !endHour) return;
        
        const fetchEventSaloons = async () => {
          const start = new Date(`${startDate}T${startHour}`).toISOString();
          const end = new Date(`${startDate}T${endHour}`).toISOString();
          const { payload } = await getEventSaloons({
            start_date__gte: start,
            start_date__lte: end,
            campus_id: user.campus.id
          })(dispatch);
          if (!payload.success) {
            return toast.error(payload.error?.message, { type: 'error' });
          }
        };
        fetchEventSaloons();
    }, [startDate, startHour, endHour]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!eventTypeId) return;
      setFieldValue('eventPackages', listEventTypes?.filter(eventType => eventType.id === parseInt(eventTypeId))[0].event_package.split(','));
    }, [eventTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCatchVoucher = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        setVoucherName(e.target.files[0].name);
        const voucherList: VoucherType[] = Object.values(e.target.files || {})
            .map(file => ({ receipt: URL.createObjectURL(file), file }));
        setVoucherFile((prevState) => [...prevState, ...voucherList]);
    }

    return (
        <div>
            <form className="mx-auto max-w-3xl flex flex-col gap-2.5" onSubmit={handleSubmit}>
                <h2 className="text-gray-500 font-bold">Subir Servicio Manualmente</h2>
                <h2 className="text-gray-500">Socio</h2>
                <input
                    id="documentNumber"
                    type="text"
                    {...getFieldProps('documentNumber')}
                    min={0}
                    maxLength={10}
                    required
                    className="input-yellow"
                    placeholder="Ingrese el Numero de Cédula"
                />
                <input
                    id="nameMember"
                    type="text"
                    {...getFieldProps('nameMember')}
                    min={0}
                    required
                    className="input-yellow"
                    placeholder="Nombre del Socio"
                    disabled
                />

                <div className=" w-full mt-6 border border-gray-200"></div>
                <h2 className="text-gray-500">Tipo de evento</h2>
                <select
                    id="eventTypeId"
                    {...getFieldProps('eventTypeId')}
                    className="select-yellow"
                    disabled={!hasMember}
                    required
                >
                    <option value="" disabled>Seleccione un tipo de evento</option>
                    {
                        listEventTypes && listEventTypes.map((eventTypes) => (
                            <option key={`bedroom-${eventTypes.id}`} value={eventTypes.id}>{eventTypes.name}</option>
                        ))
                    }
                </select>

                <div className=" w-full mt-6 border border-gray-200"></div>
                <h2 className="text-gray-500">Fecha de reserva</h2>
                <div className="flex flex-row gap-4 items-center">
                    {/* Fecha de ingreso */}
                    <input
                        id="startDate"
                        type="date"
                        min={new Date().toISOString().split('T')[0]}
                        {...getFieldProps('startDate')}
                        required
                        className="basis-1/2 input-yellow"
                        placeholder="Fecha de Entrada"
                        disabled={!eventTypeId}
                    />
                    {/* Hora de ingreso */}
                    <input
                        type="time"
                        id="startHour"
                        {...getFieldProps('startHour')}
                        placeholder="Hora Entrada"
                        className="basis-1/2 input-yellow"
                        disabled={!eventTypeId}
                    />
                    {/* Hora de salida */}
                    <input
                        type="time"
                        id="endHour"
                        {...getFieldProps('endHour')}
                        required
                        placeholder="Hora Salida"
                        className="basis-1/2 input-yellow"
                        disabled={true}
                    />
                </div>

                <div className=" w-full mt-6 border border-gray-200"></div>
                <h2 className="text-gray-500">Tipo de paquete</h2>
                <select
                    id="eventPackage"
                    {...getFieldProps('eventPackage')}
                    required
                    disabled={!startDate || !startHour || !endHour}
                    className="select-yellow"
                >
                    <option value="" disabled>Seleccione un paquete</option>
                    {
                        eventPackages && eventPackages.map((pack: string, i) => (
                            <option key={`package-${i}`} value={pack}>{pack}</option>
                        ))
                    }
                </select>
                <input
                    id="totalPeople"
                    type="number"
                    min={0}
                    {...getFieldProps('totalPeople')}
                    required
                    className="input-yellow"
                    placeholder="Número de personas"
                    disabled={!startDate || !startHour || !endHour}
                />
                { touched.totalPeople && errors.totalPeople && <span className="text-rose-500">{errors.totalPeople}</span> }

                <div className=" w-full mt-6 border border-gray-200"></div>
                <h2 className="text-gray-500">Salón</h2>
                <select
                    id="eventSalonId"
                    {...getFieldProps('eventSalonId')}
                    onChange={(e) => {
                        setFieldValue('eventSalonId', e.target.value);
                        const currentSaloon = eventSaloons.filter(saloon => saloon.id === parseInt(e.target.value))[0];
                        setFieldValue('totalCost', currentSaloon.reservation_cost);
                    }}
                    required
                    disabled={!totalPeople || !eventPackage}
                    className="select-yellow"
                >
                    <option value="" disabled>Seleccione un salón</option>
                    {
                        eventSaloons && eventSaloons.map((saloon) => (
                            <option key={`saloon-${saloon.id}`} value={saloon.id}>{saloon.name}</option>
                        ))
                    }
                </select>


                <div className="mt-6 w-full border border-gray-200"></div>
                <h2 className="text-gray-500">Método de pago</h2>
                <div className="mt-4 grid grid-cols-3 gap-4">
                    <div className="col-span-2">
                        <select
                            id="paymentMethod"
                            {...getFieldProps('paymentMethod')}
                            className="w-full select-yellow"
                            disabled={!eventSalonId}
                        >
                            <option value="" disabled>Seleccione un método de pago</option>
                            <option value="WIRE_TRANSFER">Transferencia</option>
                            <option value="CASH">Efectivo</option>
                            <option value="CREDIT_CARD_WEB">Tarjeta</option>
                        </select>
                    </div>
                    <input
                        id="totalCost"
                        type="number"
                        min={0}
                        {...getFieldProps('totalCost')}
                        required
                        className="basis-1/3 input-yellow"
                        placeholder="Monto a Pagar"
                        disabled={!eventSalonId}
                    />
                </div>

                <div className={`${paymentMethod === 'WIRE_TRANSFER' ? 'grid' : 'hidden'}`}>
                  <div className={`mt-4 grid grid-cols-3 gap-4 ${paymentMethod === 'WIRE_TRANSFER' ? 'grid' : 'hidden'}`}>
                      <div className="col-span-2">
                          <select
                              id="bankAccount"
                              {...getFieldProps('bankAccount')}
                              required={paymentMethod === 'WIRE_TRANSFER'}
                              className="select-yellow w-full"
                          >
                              <option value="" disabled>Seleccione una cuenta bancaria</option>
                              {
                                  listHotelBankAccount && listHotelBankAccount.map((bankData) => (
                                      <option key={`bankData-${bankData.id}`} value={bankData.id}>{bankData.name_bank}</option>
                                  ))
                              }
                          </select>
                      </div>
                      <input
                          id="amountPaid"
                          type="number"
                          min={0}
                          {...getFieldProps('amountPaid')}
                          required={paymentMethod === 'WIRE_TRANSFER'}
                          className="basis-1/3 input-yellow"
                          placeholder="Monto pagado"
                      />
                  </div>
                  <div className="py-4 static flex flex-row justify-end">
                      <label htmlFor="file" className="my-2 text-black">
                          {voucherName === '' ? 'Subir comprobante': voucherName}
                      </label>
                      <input multiple type="file" name="file" id="file" accept="image/png, image/jpeg" onChange={handleCatchVoucher} required={paymentMethod === 'WIRE_TRANSFER'} className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10" />
                      <label htmlFor="file" className='justify-end'>
                          <div className="ml-6 p-2 border border-transparent text-sm font-bold rounded-md text-black bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                              </svg>
                          </div>
                      </label>
                  </div>
                  <div className="py-4 static flex flex-row justify-end">
                    { errors.voucherName && <span className="text-rose-500">{errors.voucherName}</span> }
                  </div>
                </div>

                <div className="mt-6 w-full border border-gray-200"></div>
                <h2 className="text-gray-500">Nota</h2>
                <textarea
                    id="description"
                    {...getFieldProps('description')}
                    className="w-full min-h-[6rem] input-yellow"
                    placeholder="Campo de Nota"
                />

                <div className="flex justify-end mb-8">
                    <button
                        type="submit"
                        className=" mb-8 w-40 flex justify-center py-3 px-4 mt-6 border border-transparent text-sm font-bold rounded-md text-black bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                        Reservar
                    </button>
                </div>

            </form>
        </div>
    )
}
