import { MultipleServicesScheduleProcess } from "../api";
import { MultipleServicesScheduleAction, MultipleServicesScheduleFilters, MultipleServicesSchedulePost, MultipleServicesSchedulePut } from "../interfaces";
import { MultipleServicesScheduleTypes } from "../types";

// apply the reducer
export const getMultipleServicesSchedule = (filters?: MultipleServicesScheduleFilters) => async (dispatch: (state: MultipleServicesScheduleAction) => MultipleServicesScheduleAction) => {
  dispatch({
    type: MultipleServicesScheduleTypes.GET_MULTIPLESERVICESSCHEDULE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesScheduleProcess = new MultipleServicesScheduleProcess();
    // dont'n import the result of the process, only that dont't have error
    const multipleServicesSchedulePag = await multipleServicesScheduleProcess.getMultipleServicesSchedule({
      ...filters,
      data_all: 'YES',
    });
    const multipleServicesSchedule = multipleServicesSchedulePag.results;
    return dispatch({
      type: MultipleServicesScheduleTypes.GET_MULTIPLESERVICESSCHEDULE_SUCCESS,
      payload: {
        listMultipleServicesSchedule: multipleServicesSchedule,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesScheduleTypes.GET_MULTIPLESERVICESSCHEDULE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postMultipleServicesSchedule = (msSchedule: MultipleServicesSchedulePost) => async (dispatch: (state: MultipleServicesScheduleAction) => MultipleServicesScheduleAction) => {
  dispatch({
    type: MultipleServicesScheduleTypes.POST_MULTIPLESERVICESSCHEDULE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesScheduleProcess = new MultipleServicesScheduleProcess();
    // dont'n import the result of the process, only that dont't have error
    const msScheduleRes = await multipleServicesScheduleProcess.postMultipleServicesSchedule(msSchedule);
    return dispatch({
      type: MultipleServicesScheduleTypes.POST_MULTIPLESERVICESSCHEDULE_SUCCESS,
      payload: {
        multipleServicesSchedule: msScheduleRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesScheduleTypes.POST_MULTIPLESERVICESSCHEDULE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putMultipleServicesSchedule = (msSchedule: MultipleServicesSchedulePut) => async (dispatch: (state: MultipleServicesScheduleAction) => MultipleServicesScheduleAction) => {
  dispatch({
    type: MultipleServicesScheduleTypes.PUT_MULTIPLESERVICESSCHEDULE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesScheduleProcess = new MultipleServicesScheduleProcess();
    // dont'n import the result of the process, only that dont't have error
    const msScheduleRes = await multipleServicesScheduleProcess.putMultipleServicesSchedule(msSchedule);
    return dispatch({
      type: MultipleServicesScheduleTypes.PUT_MULTIPLESERVICESSCHEDULE_SUCCESS,
      payload: {
        multipleServicesSchedule: msScheduleRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesScheduleTypes.PUT_MULTIPLESERVICESSCHEDULE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteMultipleServicesSchedule = (id_schedule: number) => async (dispatch: (state: MultipleServicesScheduleAction) => MultipleServicesScheduleAction) => {
  dispatch({
    type: MultipleServicesScheduleTypes.DELETE_MULTIPLESERVICESSCHEDULE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesScheduleProcess = new MultipleServicesScheduleProcess();
    // dont'n import the result of the process, only that dont't have error
    await multipleServicesScheduleProcess.deleteMultipleServicesSchedule(id_schedule);
    return dispatch({
      type: MultipleServicesScheduleTypes.DELETE_MULTIPLESERVICESSCHEDULE_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesScheduleTypes.DELETE_MULTIPLESERVICESSCHEDULE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};