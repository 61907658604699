
import { EventSaloonsProcess } from "../api";
import { EventSaloonsAction, EventSaloonsFilters, EventSaloonsPost, EventSaloonsPut,  } from "../interfaces";
import { EventSaloonsTypes } from "../types";

export const getEventSaloonsPags = (filters?: EventSaloonsFilters) => async (dispatch: (state: EventSaloonsAction) => EventSaloonsAction) => {
  dispatch({
    type: EventSaloonsTypes.GET_EVENTSALOONSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventSaloonsProcess = new EventSaloonsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventSaloonsPag = await eventSaloonsProcess.getEventSaloons(filters);
    return dispatch({
      type: EventSaloonsTypes.GET_EVENTSALOONSPAG_SUCCESS,
      payload: {
        pagEventSaloons: eventSaloonsPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventSaloonsTypes.GET_EVENTSALOONSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getEventSaloons = (filters?: EventSaloonsFilters) => async (dispatch: (state: EventSaloonsAction) => EventSaloonsAction) => {
  dispatch({
    type: EventSaloonsTypes.GET_EVENTSALOONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventSaloonsProcess = new EventSaloonsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventSaloonsPag = await eventSaloonsProcess.getEventSaloons({
      ...filters,
      data_all: 'YES',
    });
    const eventSaloons = eventSaloonsPag.results;
    return dispatch({
      type: EventSaloonsTypes.GET_EVENTSALOONS_SUCCESS,
      payload: {
        listEventSaloons: eventSaloons,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventSaloonsTypes.GET_EVENTSALOONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postEventSaloons = (eventSaloons: EventSaloonsPost) => async (dispatch: (state: EventSaloonsAction) => EventSaloonsAction) => {
  dispatch({
    type: EventSaloonsTypes.POST_EVENTSALOONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventSaloonsProcess = new EventSaloonsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventSaloonsTable = await eventSaloonsProcess.postEventSaloons(eventSaloons);
    return dispatch({
      type: EventSaloonsTypes.POST_EVENTSALOONS_SUCCESS,
      payload: {
        eventSaloons: eventSaloonsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventSaloonsTypes.POST_EVENTSALOONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putEventSaloons = (eventSaloons: EventSaloonsPut) => async (dispatch: (state: EventSaloonsAction) => EventSaloonsAction) => {
  dispatch({
    type: EventSaloonsTypes.PUT_EVENTSALOONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventSaloonsProcess = new EventSaloonsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventSaloonsTable = await eventSaloonsProcess.putEventSaloons(eventSaloons);
    return dispatch({
      type: EventSaloonsTypes.PUT_EVENTSALOONS_SUCCESS,
      payload: {
        eventSaloons: eventSaloonsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventSaloonsTypes.PUT_EVENTSALOONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteEventSaloons = (id: number) => async (dispatch: (state: EventSaloonsAction) => EventSaloonsAction) => {
  dispatch({
    type: EventSaloonsTypes.DELETE_EVENTSALOONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventSaloonsProcess = new EventSaloonsProcess();
    // dont'n import the result of the process, only that dont't have error
    await eventSaloonsProcess.deleteEventSaloons(id);
    return dispatch({
      type: EventSaloonsTypes.DELETE_EVENTSALOONS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventSaloonsTypes.DELETE_EVENTSALOONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

