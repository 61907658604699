import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "../../components";
import { getCurrentUser, putUser, putUserImage } from "../../redux/actions";
import { User } from "../../redux/interfaces";
import useForm from "../../utils/hooks/useForm";
import noUserImg from '../../assets/images/undraw_handcrafts_cat.png';

export function ProfilePage() {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.user.user);
  // modal
  const [isOpen, setIsOpen] = useState(false);
  const {
    id,
    username,
    firstName,
    emergencyNumber,
    campusId,
    groupId,
    isActive,
    handleOnChangeInput,
    handleUpdateState
  } = useForm({
    id: -1,
    username: '',
    firstName: '',
    email: '',
    emergencyNumber: '',
    campusId: '',
    groupId: '',
    isActive: false,
  });

  const handleOpenEdit = async (user: User) => {
    handleUpdateState({
      id: user.id,
      firstName: user.first_name,
      username: user.username,
      email: user.email,
      emergencyNumber: user.emergency_number,
      campusId: user.campus.id,
      groupId: user.groups[0].id,
      isActive: user.is_active,
    });
    setIsOpen(true);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { payload } = await putUser({
      id: id,
      is_active: isActive,
      campus: parseInt(campusId),
      email: username,
      first_name: firstName,
      emergency_number: emergencyNumber,
      groups: [ parseInt(groupId)],
      username: username,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    setIsOpen(false);
    toast.success('Datos actualizados con éxito.');
    getCurrentUser()(dispatch);
  }

  const handleUpdatePhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    toast.info('Subiendo tu nueva foto.');
    if (!e.target.files || e.target.files.length === 0) return;
    const photo = e.target.files[0];
    const imagesToPost = new FormData();
    imagesToPost.append('photo_url', photo);
    const { payload } = await putUserImage(userData.id, imagesToPost)(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Foto de perfil actualizada con éxito.');
    getCurrentUser()(dispatch);
  }

  return (
    <>
      {/* Same as */}
      <div className='flex lg:flex-row lg:gap-0 flex-col gap-10 justify-around mt-10'>
        <div className='flex flex-row divide-x-2 items-center divide-gray-500 gap-3'>
          <div className='relative h-[142px] max-h-[142px] rounded-full group'>
            {
            !userData.photo_url ?
                <img className={`block h-[142px] max-h-[142px] w-[142px] w-max-[142px] aspect-square rounded-full object-cover`} src={noUserImg} alt="Profile" />
              : <img
                  className="block h-[142px] max-h-[142px] w-[142px] w-max-[142px] aspect-square rounded-full object-cover"
                  src={userData.photo_url}
                  alt=""
                />
            }
            <label
              htmlFor="photo"
              className="group-hover:bg-black/50 group-active:bg-black/70 absolute aspect-square rounded-full inset-0 grid place-items-center text-white cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="hidden group-hover:block h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </label>
            <input type="file" name="photo" id="photo" accept="image/png, image/jpeg" onChange={handleUpdatePhoto} className="h-0 w-0" />
          </div>
          <div className='flex flex-col justify-center p-6 gap-3'>
            <h2 className='font-bold text-lg'>{userData.first_name}</h2>
            <button onClick={() => window.print()} className='btn-yellow print:hidden'>Descargar Detalle</button>
          </div>
        </div>
        <div className='border-2 rounded-xl'>
          <h2 className="font-bold text-lg px-10 pt-4 flex flex-row justify-between">
            <span>Datos del usuario</span>
            <button
              onClick={() => handleOpenEdit(userData)}
              className="p-2 rounded-lg hover:bg-gray-100 active:bg-gray-200 active:ring-2 active:ring-gray-200 active:ring-offset-1"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
            </button>
          </h2>
          <table className="mt-4 w-full">
            <tbody>
              <tr className='mt-4 py-1 border-b-2 border-gray-200'>
                <td className="font-semibold pl-10">Usuario desde:</td>
                <td className="px-5 py-1 text-gray-700">
                  {
                    new Date(userData.date_joined).toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                    })
                  }
                </td>
              </tr>
              <tr className="py-1 border-b-2 border-gray-200">
                <td className="font-semibold pl-10">Correo electrónico:</td>
                <td className="px-5 py-1 text-gray-700">{userData.email}</td>
              </tr>
              <tr className="py-1 border-b-2 border-gray-200">
                <td className="font-semibold pl-10">Teléfono:</td>
                <td className="px-5 py-1 text-gray-700">{userData.phone}</td>
              </tr>
              <tr className="py-1 border-b-2 border-gray-200">
                <td className="font-semibold pl-10">Teléfono de emergencia:</td>
                <td className="px-5 py-1 text-gray-700">{userData.emergency_number}</td>
              </tr>
              <tr className="py-1 border-b-2 border-gray-200">
                <td className="font-semibold pl-10">Fecha de Nacimiento:</td>
                <td className="px-5 py-1 text-gray-700">{
                  new Date(userData.date_birth).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  })
                }</td>
              </tr>
              <tr className="py-1 border-b-2 border-gray-200">
                <td className="font-semibold pl-10">Cédula:</td>
                <td className="px-5 py-1 text-gray-700">{userData.document_number}</td>
              </tr>
              <tr className="px-10">
                <td className="font-semibold pl-10">Sede:</td>
                <td className="px-5 py-1 text-gray-700">{userData.campus.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Editar usuario"
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4 mt-6">
            <div className="flex flex-col gap-1">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Correo</label>
              <input
                  id="username"
                  name="username"
                  type="email"
                  maxLength={150}
                  value={username}
                  onChange={handleOnChangeInput}
                  required
                  className="input-yellow w-full"
                  placeholder="Correo"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="emergencyNumber" className="block text-sm font-medium text-gray-700">Número de emergencia</label>
              <input
                  id="emergencyNumber"
                  name="emergencyNumber"
                  type="text"
                  maxLength={150}
                  value={emergencyNumber}
                  onChange={handleOnChangeInput}
                  required
                  className="input-yellow w-full"
                  placeholder="Número de emergencia"
              />
            </div>
            <div className="flex justify-end">
              <button
                  type="submit"
                  className="btn-yellow w-full"
              >
                  <h1>Actualizar</h1>
              </button>
            </div>
        </div>
    </form>
    </Modal>
    </>
  )
}