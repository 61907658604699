import { HotelImageProcess } from "../api";
import { HotelImageAction, HotelImageFilters } from "../interfaces";
import { HotelImageTypes } from "../types";

// apply the reducer
export const getHotelImagePags = (filters?: HotelImageFilters) => async (dispatch: (state: HotelImageAction) => HotelImageAction) => {
  dispatch({
    type: HotelImageTypes.GET_HOTELIMAGEPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelImageProcess = new HotelImageProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelImagePag = await hotelImageProcess.getHotelImage(filters);
    return dispatch({
      type: HotelImageTypes.GET_HOTELIMAGEPAG_SUCCESS,
      payload: {
        pagHotelImage: hotelImagePag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelImageTypes.GET_HOTELIMAGEPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotelImage = (filters?: HotelImageFilters) => async (dispatch: (state: HotelImageAction) => HotelImageAction) => {
  dispatch({
    type: HotelImageTypes.GET_HOTELIMAGE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelImageProcess = new HotelImageProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelImagePag = await hotelImageProcess.getHotelImage({
      ...filters,
      data_all: 'YES',
    });
    const hotelImage = hotelImagePag.results;
    return dispatch({
      type: HotelImageTypes.GET_HOTELIMAGE_SUCCESS,
      payload: {
        listHotelImage: hotelImage,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelImageTypes.GET_HOTELIMAGE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotelImage = (hotelImage: FormData) => async (dispatch: (state: HotelImageAction) => HotelImageAction) => {
  dispatch({
    type: HotelImageTypes.POST_HOTELIMAGE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelImageProcess = new HotelImageProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelImageProcess.postHotelImage(hotelImage);
    return dispatch({
      type: HotelImageTypes.POST_HOTELIMAGE_SUCCESS,
      payload: {
        hotelImage: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelImageTypes.POST_HOTELIMAGE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotelImage = (hotelImage: FormData) => async (dispatch: (state: HotelImageAction) => HotelImageAction) => {
  dispatch({
    type: HotelImageTypes.PUT_HOTELIMAGE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelImageProcess = new HotelImageProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelImageProcess.putHotelImage(hotelImage);
    return dispatch({
      type: HotelImageTypes.PUT_HOTELIMAGE_SUCCESS,
      payload: {
        hotelImage: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelImageTypes.PUT_HOTELIMAGE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotelImage = (id: number) => async (dispatch: (state: HotelImageAction) => HotelImageAction) => {
  dispatch({
    type: HotelImageTypes.DELETE_HOTELIMAGE_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelImageProcess = new HotelImageProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelImageProcess.deleteHotelImage(id);
    return dispatch({
      type: HotelImageTypes.DELETE_HOTELIMAGE_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelImageTypes.DELETE_HOTELIMAGE_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};