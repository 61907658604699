import { FormikErrors, useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Chips, MiniCarouselImg, PDFViewer } from '../../components'
import { getEventSaloons, postEventTypes } from '../../redux/actions';
import { User } from '../../redux/interfaces';
import { RootState } from '../../redux/store';
import { testSizeImg } from '../../utils/utils';

interface ImageType {
  id?: number;
  url: string;
  hotel_bedroom?: number;
  file?: File
}

export const EventTypeNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [chipsSaloons, setChipsSaloons] = useState<{ id?: number, name: string }[]>([]);
  const [chipsPackages, setChipsPackages] = useState<{ name: string }[]>([]);
  const { listEventSaloons } = useSelector((state: RootState) => state.eventSaloons);
  const [images, setImages] = useState<ImageType[]>([]);
  const [imagesCover, setImagesCover] = useState<ImageType[]>([]);
  const [pdfs, setPdfs] = useState<ImageType[]>([]);
  const { values, setFieldValue, getFieldProps, errors, setFieldError, touched, handleSubmit } = useFormik({
    initialValues: {
      saloon_id: '',
      package: '',
      nameEvent: '',
      description: '',
      images: '',
      imagesCover: '',
      pdfs: '',
      chipsPackages: '',
      chipSaloons: '',
    },
    validate: ({ nameEvent, description }) => {
      const errors: FormikErrors<any> = {};
      if (!nameEvent) {
        errors.nameEvent = 'El nombre del evento es requerido';
      }
      if (!description) {
        errors.description = 'La descripción es requerida';
      }
      if (images.length === 0) {
        errors.images = 'La imagen es requerida';
      }
      if (imagesCover.length === 0) {
        errors.imagesCover = 'La imagen de portada es requerida';
      }
      if (pdfs.length === 0) {
        errors.pdfs = 'El pdf es requerido';
      }
      if (chipsSaloons.length === 0) {
        errors.chipSaloons = 'Por favor, asigne al menos un salón';
      }
      if (chipsPackages.length === 0) {
        errors.chipsPackages = 'Por favor, registre al menos un paquete';
      }
      return errors;
    },
    onSubmit: async (values, actions) => {
      // The real validation is in other place, this' only for avoid warnings
      if (!imagesCover[0].file || !images[0].file || !pdfs[0].file ) return;

      toast.info('Creando el evento...');

      const formData = new FormData();
      formData.append('name', values.nameEvent);
      formData.append('description', values.description);
      formData.append('cover_image', imagesCover[0].file);
      formData.append('event_image', images[0].file);
      formData.append('event_pdf', pdfs[0].file);
      formData.append('event_package', chipsPackages.map(p => p.name).join(','));
      formData.append('campus_id', user.campus.id.toString());
      for (const saloon of chipsSaloons) {
        const saloonId = saloon.id ? saloon.id.toString() : 'salons_error';
        formData.append('salons', saloonId);
      }

      const { payload } = await postEventTypes(formData)(dispatch);

      const { success } = payload;
      if (!success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      toast.success('Evento creado correctamente');
      actions.resetForm();
      setImages([]);
      setImagesCover([]);
      setPdfs([]);
      setChipsSaloons([]);
      setChipsPackages([]);
      // Maybe, must be return to the list
      navigate(-1);
    }
  });

  const fetchData = () => {
    getEventSaloons({
      campus_id: user.campus.id
    })(dispatch);
  }

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const handleCatchImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', { type: 'error' });
    }

    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
    setImages((prevState) => [...prevState, ...imagesList]);
  }

  const handleCatchImagesCover = (e: ChangeEvent<HTMLInputElement>) => {
    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
    setImagesCover((prevState) => [...prevState, ...imagesList]);
  }

  const handleCatchPDFs = (e: ChangeEvent<HTMLInputElement>) => {
    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
      setPdfs((prevState) => [...prevState, ...imagesList]);
  }

  return (
    <>
      <button className="btn-gray p-2 mb-6" onClick={() => navigate(-1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      <h1 className="font-bold text-gray-500 mb-4">Agregar un tipo de evento</h1>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit}
        noValidate
      >
        <input
          type="text" id="nameEvent"
          className="input-yellow"
          placeholder="Nombre del evento"
          required
          {...getFieldProps('nameEvent')}
        />
        { touched.nameEvent && errors.nameEvent && <span className="text-rose-500">{errors.nameEvent}</span> }
        <textarea
          id="description"
          className="input-yellow h-28"
          placeholder="Descripción del evento"
          required
          {...getFieldProps('description')}
        ></textarea>
        { touched.description && errors.description && <span className="text-rose-500">{errors.description}</span> }
        <div className="divider"></div>


        <h2 className="font-bold text-gray-500">Multimedia</h2>
        <div className="grid grid-cols-[170px_170px_minmax(0,1fr)_minmax(170px,1fr)_minmax(0,1fr)] gap-4">
          <div>
            <input
              type="file"
              name="images"
              id="images"
              accept="image/png, image/jpeg"
              onChange={handleCatchImagesCover}
              className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
              disabled={imagesCover.length >= 1}
            />
            <label htmlFor="images" className="btn-yellow">Subir portada</label>
          </div>
          <div>
            <input
              type="file"
              name="imagesCover"
              id="imagesCover"
              accept="image/png, image/jpeg"
              onChange={handleCatchImages}
              className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
              disabled={images.length >= 1}
            />
            <label htmlFor="imagesCover" className="btn-yellow">Subir imagen</label>
          </div>
          <div className="col-start-4 w-44">
            <input
              type="file"
              name="pdfs"
              id="pdfs"
              accept="application/pdf"
              onChange={handleCatchPDFs}
              className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
              disabled={pdfs.length >= 1}
            />
            <label htmlFor="pdfs" className="btn-yellow">Subir PDF</label>
          </div>
        </div>
        <div className="grid grid-cols-[170px_170px_minmax(0,1fr)_minmax(170px,1fr)_minmax(0,1fr)] gap-4">
          <div className="flex flex-col gap-4">
            <span className="text-gray-500 font-bold">Portada</span>
            <MiniCarouselImg blankMessage="No hay portada" images={imagesCover} onRemoveImage={(image) => setImagesCover(imagesCover.filter((imageItem) => imageItem !== image))} />
          </div>
          <div className="flex flex-col gap-4">
            <span className="text-gray-500 font-bold">Imagen</span>
            <MiniCarouselImg blankMessage="No hay imagen" images={images} onRemoveImage={(image) => setImages(images.filter((imageItem) => imageItem !== image))} />
          </div>
          <div className="flex flex-col gap-4 col-start-4">
            <span className="text-gray-500 font-bold">PDF</span>
            {
              pdfs.length > 0 ? pdfs.map((pdf) => (
                <PDFViewer key={pdf.url} pdf={{
                  url: pdf.url,
                  file: pdf.file,
                  title: pdf.file?.name || 'PDF',
                }} onRemove={() => setPdfs( pdfs.filter(p => p !== pdf) ) } />
              )) : <span className="text-gray-500">No hay PDF</span>
            }
          </div>
        </div>
        { errors.imagesCover && <span className="text-rose-500">{errors.imagesCover}</span> }
        { errors.images && <span className="text-rose-500">{errors.images}</span> }
        { errors.pdfs && <span className="text-rose-500">{errors.pdfs}</span> }


        <div className="flex flex-col gap-3 mb-4">
          <h2 className="font-bold text-gray-500">Salones asignados</h2>
          <div className="flex flex-row gap-2">
            <select
              className="select-yellow grow"
              {...getFieldProps('saloon_id')}
            >
              <option value="" disabled>Seleccione una opcion</option>
              {listEventSaloons && listEventSaloons.map((saloon, index) => (
                <option key={`salon-${index}`} value={saloon.id}>{saloon.name}</option>
              ))}
            </select>
            <button
              type="button"
              className="btn-yellow w-36"
              disabled={!values.saloon_id}
              onClick={() => {
                const saloon = listEventSaloons?.filter(saloon => saloon.id === parseInt(values.saloon_id))[0] || null;
                if (!saloon) return toast.error('Tuvimos un problema al agregar, intentelo nuevamente');
                setChipsSaloons([...chipsSaloons, {id: saloon.id, name: saloon.name}])
                setFieldValue('saloon_id', '');
              }}
            >
              Agregar
            </button>
          </div>
          { errors.chipSaloons && <span className="text-rose-500">{errors.chipSaloons}</span> }
          <Chips chips={chipsSaloons} setChips={setChipsSaloons} />
        </div>
        <div className="flex flex-col gap-3">
          <h2 className="font-bold text-gray-500">Paquetes</h2>
          <div className="flex flex-row gap-2">
            <input
              type="text"
              className="input-yellow grow"
              {...getFieldProps('package')}
            />
            <button
              type="button"
              className="btn-yellow w-36"
              disabled={!values.package}
              onClick={() => {
                if (values.package.includes(',')) return setFieldError('chipsPackages', 'No se puede usar coma como separador');
                setChipsPackages([...chipsPackages, {name: values.package}]);
                setFieldValue('package', '');
              }}
            >
              Agregar
            </button>
          </div>
          { errors.chipsPackages && <span className="text-rose-500">{errors.chipsPackages}</span> }
          <Chips chips={chipsPackages} setChips={setChipsPackages} />
        </div>
        <div className="flex flex-row justify-end mb-10">
          <button
            type="submit"
            className="btn-yellow w-36"
          >
            Guardar
          </button>
        </div>
      </form>
    </>
  )
}
