import { HotelBankingParametersPage, HotelBedroomNewPage, HotelBedroomPriceEditPage, HotelHistoryServicePage, HotelReservationNewPage, HotelReservePendingPage, HotelSchedulePage, HotelScheduleTablePage } from "../../pages/hotel";
import { UserRole } from "../../utils/enums/userRoles.enum";

export const hotelsRoutes = [
  // HOTELS MODULE
  {
    path: '/hotel/reservation/pending',
    to: '/hotel/reservation/pending',
    name: 'Reservas Pendientes',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.HOTEL_ADMIN],
    Component: HotelReservePendingPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
        </svg>
    )
  },
  {
      path: '/hotel/schedule',
      to: '/hotel/schedule',
      name: 'Agenda',
      secondMenuShow: false,
      showMenu: true,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelSchedulePage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
          </svg>
      )
  },
  {
      path: '/hotel/schedule/table',
      to: '/hotel/schedule/table',
      name: 'Agenda Tabla',
      secondMenuShow: false,
      showMenu: false,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelScheduleTablePage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
          </svg>
      )
  },
  {
      path: '/hotel/reservation/new',
      to: '/hotel/reservation/new',
      name: 'Reserva Manual',
      secondMenuShow: false,
      showMenu: true,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelReservationNewPage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
          </svg>
      )
  },
  {
      path: '/hotel/bedroom/new',
      to: '/hotel/bedroom/new',
      name: 'Agregar habitación',
      secondMenuShow: false,
      showMenu: true,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelBedroomNewPage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
          </svg>
      )
  },
  {
      path: '/hotel/bedroom/prices/edit',
      to: '/hotel/bedroom/prices/edit',
      name: 'Editar precio habitación',
      secondMenuShow: false,
      showMenu: false,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelBedroomPriceEditPage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
          </svg>
      )
  },
  {
      path: '/hotel/service/history',
      to: '/hotel/service/history',
      name: 'Historial de Servicios',
      secondMenuShow: false,
      showMenu: true,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelHistoryServicePage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
          </svg>
      )
  },
  {
      path: '/hotel/bank/account',
      to: '/hotel/bank/account',
      name: 'Parámetros Bancarios',
      secondMenuShow: false,
      showMenu: true,
      roles: [UserRole.HOTEL_ADMIN],
      Component: HotelBankingParametersPage,
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
          </svg>
      )
  },
]