import {useEffect, useState} from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { getCampusSurveyAsk, postCampusSurvey, deleteCampusSurveyAsk } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CampusSurveyAskTable, CampusSurveyTable, CampusSurveyAsk } from '../../redux/interfaces';
import { generateRandomId } from '../../utils/utils';
import { toast } from 'react-toastify';
import imgSearching from "../../assets/images/undraw_house_searching.svg";
import alerts from '../../utils/alerts';

interface IValidations {
  type: string;
}

interface ICheckbox {
  id: string;
  type: 'checkbox' | 'radio';
  name: string;
  label: string;
  value: string;
  validations?: IValidations[];
  children: Array<IInput | ICheckbox>;
  // extras
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  meta?: any;
}

interface IInput {
  id: string;
  type: 'input' | 'email';
  name: string;
  label: string;
  placeholder: string;
  value: string;
  validations?: IValidations[];
  children: Array<IInput | ICheckbox>;
  // extras
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  meta?: any;
}

interface IOption {
  id: string;
  label: string;
  order: number;
  required: boolean;
  type: string;
  meta?: any;
}

interface ICard {
  id: string;
  ask: string;
  placeholder: string;
  required: boolean;
  type: string;
  order: number;
  options: IOption[];
  meta?: any;
}

export const CampusSurveysEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { survey } = state as { survey: CampusSurveyTable };
  const [ surveyData, setSurveyData ] = useState<CampusSurveyTable>(survey);
  const [ asksData, setAsksData ] = useState<CampusSurveyAskTable[]>([]);
  const [ deleteOptions, setDeleteOptions ] = useState<number[]>([]);
  const [ deleteCards, setDeleteCards ] = useState<number[]>([]);

  const [card, setCard] = useState<ICard[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const fetchData = async () => {
    const { payload } = await getCampusSurveyAsk({
      survey_id: survey.id,
    })(dispatch);

    const listCampusSurveyAsk = payload.listCampusSurveyAsk as CampusSurveyAskTable[];
    setAsksData([...listCampusSurveyAsk]);
  }

  useEffect(() => {
    if (survey) {
      fetchData();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!asksData && Object.keys(asksData).length === 0) return;
    setCard(state => {
      return [
        ...state,
        ...asksData.map((ask, i) => ({
          id: generateRandomId(`${i}_card`),
          ask: ask.ask,
          placeholder: ask.placeholder,
          required: ask.required,
          type: ask.type,
          order: ask.order,
          options: ask.survey_ask_options.map((options, j) => ({
            id: generateRandomId(`${i}_${j}_option`),
            label: options.value,
            order: options.order,
            required: ask.required,
            type: options.type,
            meta: {
              id: options.id,
            }
          })),
          meta: {
            id: ask.id,
          }
        } as ICard))
      ];
    });
  }, [asksData]); // eslint-disable-line

  const handleDragDropCard = (event: any) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setCard((container) => {
        const oldIndex = container.findIndex(c => c.id === active.id);
        const newIndex = container.findIndex(c => c.id === over.id);

        return arrayMove(container, oldIndex, newIndex);
      });
    }
  }

  const handleSubmit = async () => {
    const aux = {
      id: surveyData?.id || null,
      title: surveyData.title,
      description: surveyData.description,
      status: surveyData.status,
      survey_asks: card.map((card, i) => ({
        id: card?.meta?.id || null,
        ask: card.ask,
        placeholder: card.placeholder,
        type: card.type,
        required: card.required,
        order: i,
        survey_ask_options: card.options.map((option, j) => ({
          id: option?.meta?.id || null,
          value: option.label,
          type: option.type,
          required: card.required,
          order: j,
        }))
      } as CampusSurveyAsk)),
      is_editable: surveyData.is_editable,
      campus_id: surveyData.campus.id,
      user_id: surveyData.user.id,
    };
    const { payload } = await postCampusSurvey(aux)(dispatch);

    if (!payload.success) {
      toast.error(payload.error?.message, { type: 'error' });
      return;
    }

    const { payload: { success } } = await deleteCampusSurveyAsk({
      survey_ask_ids: deleteCards,
      survey_ask_option_ids: deleteOptions
    })(dispatch);

    if (!success) {
      toast.error(payload.error?.message, { type: 'error' });
      return;
    }

    toast.success('Encuesta actualizada con éxito.');
    navigate(-1);
  }

  return (
    <>
      <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      <h2 className="font-bold text-xl mt-6 pb-6 text-gray-500">Encuesta</h2>
      <div className="flex flex-col gap-4">
        <input
          type="text"
          className="input-yellow w-full"
          placeholder="Título de la encuesta"
          value={surveyData.title}
          onChange={(e) => setSurveyData({...surveyData, title: e.target.value})}
        />
        <input
          type="text"
          className="input-yellow w-full"
          placeholder="Descripción de la encuesta"
          value={surveyData.description}
          onChange={(e) => setSurveyData({...surveyData, description: e.target.value})}
        />
      </div>
      <h2 className="font-bold text-xl mt-6 pb-6">Preguntas</h2>
      {
        card.length > 0 ? (
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragDropCard}
              >
              <SortableContext
                items={card}
                strategy={verticalListSortingStrategy}
              >
                <div className="flex flex-col gap-4">
                  {
                    card.map((container, i) => (
                      <SortableCard
                        key={`${i}_sortable_card`}
                        container={container}
                        setContainers={setCard}
                        setDeleteOptions={setDeleteOptions}
                        setDeleteCards={setDeleteCards}
                      />
                    ))
                  }
                </div>
              </SortableContext>
            </DndContext>
            <div className="flex flex-row justify-end gap-4 mt-6 pb-10">
              <button
                // add new card or ask
                onClick={() => {
                  setCard(state => {
                    return [
                      ...state,
                      {
                        id: generateRandomId('new_card'),
                        ask: '',
                        placeholder: 'Escribe tu respuesta',
                        required: false,
                        type: 'input',
                        order: 1,
                        options: []
                      } as ICard
                    ];
                  });
                }}
                className="btn-gray"
              >
                Añadir otra pregunta
              </button>
              <button
                // submit event
                onClick={handleSubmit}
                className="btn-yellow"
              >
                Guardar
              </button>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center items-center gap-2">
            <img src={imgSearching} alt="404" className="w-64" />
            <p className="text-gray-600 text-center font-bold text-2xl">Estamos buscando sus datos...</p>
            <p className="text-gray-600 text-center">Espere un momento por favor</p>
          </div>
        )
      }
    </>
  );
}

interface SortableCardProps {
  container: ICard;
  setContainers: React.Dispatch<React.SetStateAction<ICard[]>>;
  setDeleteOptions: React.Dispatch<React.SetStateAction<number[]>>;
  setDeleteCards: React.Dispatch<React.SetStateAction<number[]>>;
}

function SortableCard({
  container,
  setContainers,
  setDeleteOptions,
  setDeleteCards
}: SortableCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: container.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragDropOption = (event: any) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setContainers((state) => {
        return state.map(c => {
          if (c.id === container.id) {
            const oldIndex = container.options.findIndex(option => option.id === active.id);
            const newIndex = container.options.findIndex(option => option.id === over.id);

            return {
              ...container,
              options: arrayMove(container.options, oldIndex, newIndex)
            }
          }

          return c;
        });
      });
    }
  }

  return (
    <div  style={style} className="bg-white">
      <div className="rounded-lg border border-gray-600 pl-6 pr-6 pb-6 pt-2 flex flex-col gap-4">
        <div className="flex flex-row justify-center">
          <div ref={setNodeRef} {...attributes} {...listeners}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 relative -top-3" viewBox="0 0 20 20" fill="currentColor">
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <input
            type="text"
            className="input-yellow basis-3/5"
            placeholder={container.placeholder}
            value={container.ask}
            onChange={e => {
              // update ask input
              setContainers(state => {
                return state.map(c => {
                  if (c.id === container.id) {
                    return {...c, ask: e.target.value};
                  }
                  return c;
                });
              })
            }}
          />
          <select
            className="input-yellow bg-yellow-500 font-bold basis-1/5"
            value={!!container.required ? 'true' : 'false'}
            onChange={e => {
              setContainers(state => {
                return state.map(c => {
                  if (c.id === container.id) {
                    return {...c, required: e.target.value === 'true'};
                  }
                  return c;
                });
              })
            }}
          >
            <option value="true">Obligatoria</option>
            <option value="false">Opcional</option>
          </select>
          <select
            className="input-yellow bg-yellow-500 font-bold basis-1/5"
            name=""
            id=""
            value={
              container.options.length > 0 ?
              container.options[0].type === 'radio' ? 'radio' : 'checkbox' :
              'input'
            }
            onChange={async (e) => {
              if (e.target.value === 'input') {
                const res = await alerts.warning({
                  title: '¡Piénselo otra vez!',
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Aceptar',
                  text: 'Se borrarán todas las opciones existentes. ¿Desea continuar?',
                });
                if (!res.isConfirmed) return;
                else e.target.value = 'input';
              }
              setContainers(state => {
                return state.map(c => {
                  if (c.id === container.id) {
                    if (e.target.value === 'input') {
                      if (c.options.length > 0) {
                        const aux = c.options.filter(option => !!option.meta?.id);
                        setDeleteOptions(state => [...state, ...aux.map(option => option.meta.id)]);
                      }
                      return {...c, options: []};
                    } else if (e.target.value === 'radio') {
                      return {
                        ...c,
                        options: c.options.length > 0 ? c.options.map(option => ({
                          ...option,
                          type: 'radio'
                        })) :
                        [{
                          id: generateRandomId('new_option'),
                          label: '',
                          order: 1,
                          type: 'radio'
                        } as IOption]
                      };
                    } else {
                      return {
                        ...c,
                        options: c.options.length > 0 ? c.options.map(option => ({
                          ...option,
                          type: 'checkbox'
                        })) :
                        [{
                          id: generateRandomId('new_option'),
                          label: '',
                          order: 1,
                          type: 'checkbox'
                        } as IOption]
                      };
                    }
                  }
                  return c;
                });
              })
            }}
          >
            <option value="radio">Única elección</option>
            <option value="checkbox">Elección múltiple</option>
            <option value="input">Campo de texto</option>
          </select>
        </div>
        <div className="flex flex-col gap-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragDropOption}
        >
            <SortableContext
              items={container.options}
              strategy={verticalListSortingStrategy}
            >
              {
                container.options.length > 0 && container.options.map((option, i) => (
                  <SortableOption
                    key={generateRandomId(`${i}_sortable_option`)}
                    item={option}
                    setContainers={setContainers}
                    setDeleteOptions={setDeleteOptions}
                    setDeleteCards={setDeleteCards}
                  />
                ))
              }
              {
                container.options.length > 0 && <div className="ml-9">
                  <label htmlFor="" className="flex flex-row gap-4 items-center">
                    <input type={container.options[0].type} disabled />
                    <button
                      onClick={() => {
                        // add new item into options array
                        setContainers(state => {
                          return state.map(c => {
                            if (c.id === container.id) {
                              return {
                                ...c,
                                options: [
                                  ...c.options,
                                  {
                                    id: generateRandomId('new_option'),
                                    label: '',
                                    type: c.options.length > 0 ? c.options[0].type : 'radio',
                                    order: 1,
                                    required: c.required
                                  } as IOption
                                ]
                              };
                            }
                            return c;
                          });
                        })
                      }}
                      className="underline text-gray-500 hover:text-gray-700 active:text-black"
                    >Añadir otra opción</button>
                  </label>
                </div>
              }
          </SortableContext>
        </DndContext>
        </div>
        <div className="flex flex-row justify-end">
          <button
            onClick={async () => {
              const res = await alerts.warning({
                title: '¡Piénselo otra vez!',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Aceptar',
                text: 'Está apunto de borrar la pregunta y todas sus opciones. ¿Desea continuar?',
              });
              if (!res.isConfirmed) return;
              setContainers(state => state.filter(c => {
                if (c.meta?.id && (c.id === container.id)) {
                  setDeleteCards(state => [...state, c.meta.id]);
                  if (c.options.length > 0) {
                    const aux = c.options.filter(option => !!option.meta?.id);
                    setDeleteOptions(state => [...state, ...aux.map(option => option.meta.id)]);
                  }
                }
                return c.id !== container.id;
              }));
            }}
            className="btn-gray gap-2"
          >
            Descartar
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

interface SortableOptionProps {
  item: IOption;
  setContainers: React.Dispatch<React.SetStateAction<ICard[]>>;
  setDeleteOptions: React.Dispatch<React.SetStateAction<number[]>>;
  setDeleteCards: React.Dispatch<React.SetStateAction<number[]>>;
}

function SortableOption({
  item,
  setContainers,
  setDeleteOptions,
  setDeleteCards
}: SortableOptionProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: item.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [inputASk, setInputASk] = useState(item.label);

  return (
    <div style={style}>
      <label htmlFor="" className="flex flex-row gap-4 items-center">
        <div ref={setNodeRef} {...attributes} {...listeners} className="rotate-90">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 relative top-1.5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 relative -top-1.5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
        </div>
        {
          item.type === 'radio' && (
            <input type="radio" name={item.id} disabled />
          )
        }
        {
          item.type === 'checkbox' && (
            <input type="checkbox" name={item.id} disabled />
          )
        }
        <input
          type="text"
          className="input-yellow w-full"
          value={inputASk}
          onChange={(e) => setInputASk(e.target.value)}
          onBlur={(e) => {
            setContainers(state => {
              return state.map((c) => {
                return {
                  ...c,
                  options: c.options.map((option) => {
                    if (option.id === item.id) {
                      return {
                        ...option,
                        label: e.target.value
                      };
                    }
                    return option;
                  })
                }
              });
            });
          }}
        />
        <button
          // delete item from options array
          onClick={() => {
            setContainers(state => {
              return state.map((c) => {
                return {
                  ...c,
                  options: c.options.filter((option) => {
                    if (option?.meta?.id && (option.id === item.id)) {
                      setDeleteOptions(state => [...state, option.meta.id]);
                    }
                    return option.id !== item.id;
                  })
                }
              });
            });
          }}
          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </label>
    </div>
  );
}