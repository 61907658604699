
import { EventTypesRepository } from "../repositories";
import { EventTypesFilters, EventTypesGet, EventTypesTable } from "../../interfaces";

export class EventTypesProcess {
  private eventTypesRepository: EventTypesRepository;

  constructor() {
    this.eventTypesRepository = new EventTypesRepository();
  }

  async getEventTypes(filters?: EventTypesFilters): Promise<EventTypesGet> {
    const response = await this.eventTypesRepository.getEventTypes(filters);
    return response.data;
  }

  async postEventTypes(eventTypes: FormData): Promise<EventTypesTable> {
    const response = await this.eventTypesRepository.postEventTypes(eventTypes);
    return response.data;
  }

  async putEventTypes(eventTypes: FormData): Promise<EventTypesTable> {
    const response = await this.eventTypesRepository.putEventTypes(eventTypes);
    return response.data;
  }

  // dont response data
  async deleteEventTypes(id: number): Promise<any> {
    const response = await this.eventTypesRepository.deleteEventTypes(id);
    return response.data;
  }
}
