
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { CampusNewsFilters, CampusNewsGet, CampusNewsTable } from "../../interfaces";

export class CampusNewsRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCampusNews(filters?: CampusNewsFilters): Promise<AxiosResponse<CampusNewsGet>> {
    return this.lmBootApi.get<CampusNewsGet>("/news/", { params: filters });
  }

  postCampusNews(campusNews: FormData): Promise<AxiosResponse<CampusNewsTable>> {
    return this.lmBootApi.post<CampusNewsTable>("/news/", campusNews);
  }

  putCampusNews(campusNews: FormData): Promise<AxiosResponse<CampusNewsTable>> {
    return this.lmBootApi.put<CampusNewsTable>("/news/", campusNews);
  }

  // dont response data
  deleteCampusNews(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/news/${id}/`);
  }
}

