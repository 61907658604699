import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RestaurantLunchesFilters, RestaurantLunchesTable, RestaurantPut, RestaurantTable } from "../../redux/interfaces";
import { getAllDaysInARange, getFirstAndLastDayOfWeek } from "../../utils/utils";
import { FormInput, InputDateRange, Modal } from "../../components";
import { deleteRestaurantLunches, getRestaurantLunches, putRestaurant } from "../../redux/actions";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import alerts from "../../utils/alerts";

interface DataStructure {
  [key: string]: {
    day: string;
    label: string;
    date: string;
    meals: RestaurantLunchesTable[];
  }
}

export const RestaurantMenuWeekPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { restaurant } = state as { restaurant: RestaurantTable };
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([getFirstAndLastDayOfWeek().first, getFirstAndLastDayOfWeek().last]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ dataStructute, setDataStructute ] = useState<DataStructure>({});

  useEffect(() => {
    // Begins in Monday and ends in Sunday
    const [ first, last ] = dateRange as Date[];
    // Arrays of dates
    const allDaysWeek = getAllDaysInARange(first, last);
    const sunday = allDaysWeek[6].setHours(23, 59, 59, 999);
    const weekDays = [...allDaysWeek.filter(day => day.getDay() > 0), new Date(sunday)];
    // Array of days
    const weekDaysStrings = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];

    const allDays: DataStructure = {};
    for (const day of weekDays) {
      allDays[weekDaysStrings[day.getDay()]] = {
        day: weekDaysStrings[day.getDay()],
        label: day.toLocaleDateString("es-ES", { weekday: "long", month: "long", day: "numeric" }),
        date: day.toISOString(),
        meals: [],
      };
    }

    // Fetch data
    const fetchData = async () => {
      const filters: RestaurantLunchesFilters = {
        restaurant_id: restaurant.id,
        date__gte: '',
        date__lte: '',
      }
      if (first && last) {
          filters['date__gte'] = new Date(first.setHours(0, 0, 0, 0)).toISOString();
          filters['date__lte'] = new Date(last.setHours(23, 59, 59, 999)).toISOString();
      }
      const { payload } = await getRestaurantLunches(filters)(dispatch);
      if (!payload.success) {
        setDataStructute({...allDays});
        return toast.error(payload.error?.message);
      }

      const listRestaurantLunches = payload.listRestaurantLunches as RestaurantLunchesTable[];
      for (const day of weekDays) {
        allDays[weekDaysStrings[day.getDay()]].meals = listRestaurantLunches.filter(lunch => lunch.day === weekDaysStrings[day.getDay()]);
      }
      setDataStructute({...allDays});
    }
    fetchData();
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenModal = () => {
    setIsOpenModal(true);
  }

  const handleDeleteMeal = async (lunch: RestaurantLunchesTable) => {
    const { isConfirmed } = await alerts.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${lunch.product_name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteRestaurantLunches(lunch.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success(`${lunch.product_name} eliminado con éxito.`);
        setDataStructute({
          ...dataStructute,
          [lunch.day]: {
            ...dataStructute[lunch.day],
            meals: dataStructute[lunch.day].meals.filter(meal => meal.id !== lunch.id),
          },
        });
      } else {
        toast.error(payload.error?.message, { type: 'error' });
      }
    }
  }

  return (
    <>
      <div
        className="flex flex-row justify-between"
      >
        <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        <div className="flex flex-row gap-2 h-11">
          <InputDateRange
              dateRange={dateRange}
              setDateRange={setDateRange}
              className="h-11 font-bold pr-0"
              selectByWeek={true}
              isClearable={false}
          />
          <button
            onClick={handleOpenModal}
            className="btn-yellow"
          >
            Precios
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="font-bold text-gray-500 text-lg">Almuerzos de la semana ({restaurant.name})</h2>
        <div>
          {
            dataStructute && Object.entries(dataStructute).map(([key, dayOfWeek]) => {
              return (
                <>
                  <div key={`dayStructure-${key}`} className="">
                    <h1 className="grid grid-cols-[minmax(0,1fr)_120px] font-bold text-black mb-4">
                        <span className="basis-[73%] block first-letter:capitalize">{dayOfWeek.label}</span>
                        {/* <span>Acciones</span> */}
                    </h1>
                    {(dayOfWeek.meals &&
                      dayOfWeek.meals.length > 0) ?
                      dayOfWeek.meals.map((meal, i) => (
                        <div
                          key={`form-${i}`}
                          className="grid grid-cols-[minmax(0,1fr)_120px] items-center justify-between text-gray-500 even:bg-gray-50 odd:bg-white odd:hover:bg-gray-50"
                        >
                          <span className="px-4 font-bold">{meal.product_name}</span>
                          <span className="mr-6 flex flex-row gap-1">
                            <button
                              onClick={() =>
                                navigate('/restaurant/menu/week/edit', {
                                  state: {
                                    restaurant,
                                    dayOfWeek,
                                    meal,
                                  },
                                })
                              }
                              className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-gray-700"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                            </button>
                            <button
                              onClick={() => handleDeleteMeal(meal)}
                              className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-gray-700"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </span>
                        </div>
                      )) : (
                        <div className="flex flex-row gap-2 items-center">
                          <button
                            onClick={() =>
                              navigate('/restaurant/menu/week/new', {
                                state: {
                                  restaurant,
                                  dayOfWeek
                                },
                              })
                            }
                            className="btn-yellow text-white p-1.5 rounded-full"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                          </button>
                          Añadir a Almuerzo del día
                        </div>
                      )}
                  </div>
                  {
                    (dayOfWeek.meals && dayOfWeek.meals.length > 0) && (
                      <div className="flex flex-row gap-2 items-center mt-6">
                        <button
                          onClick={() =>
                            navigate('/restaurant/menu/week/new', {
                              state: {
                                restaurant,
                                dayOfWeek
                              },
                            })
                          }
                          className="btn-gray text-black p-1.5 rounded-full"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                          </svg>
                        </button>
                        Añadir otra opción
                      </div>
                    )
                  }
                  <div className="divider my-14"></div>
                </>
              )
            })
          }
        </div>
      </div>
      <Modal title="Precios" isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)}>
        <Formik
          initialValues={{
            memberPrice: restaurant.member_price || '',
            specialGuestPrice: restaurant.special_guest_price || '',
            sponsorGuestPrice: restaurant.sponsor_guest_price || '',
            casualGuestPrice: restaurant.casual_guest_price || '',
          }}
          validationSchema={
            Yup.object().shape({
              memberPrice: Yup.number().required('El precio de Socio y sus dependientes es requerido'),
              specialGuestPrice: Yup.number().required('El precio de los Invitados especiales es requerido'),
              sponsorGuestPrice: Yup.number().required('El precio de los Invitados con patrocinio es requerido'),
              casualGuestPrice: Yup.number().required('El precio de los Invitados ocasionales es requerido'),
            })
          }
          onSubmit={async (values) => {
            const formData = new FormData();
            const formDataFields: RestaurantPut = {
              id: restaurant.id,
              name: restaurant.name,
              description: restaurant.description,
              phone: restaurant.phone,
              cover_image: restaurant.cover_image,
              casual_guest_price: !!parseFloat(values.casualGuestPrice) ? parseFloat(values.casualGuestPrice) : 0,
              member_price: !!parseFloat(values.memberPrice) ? parseFloat(values.memberPrice) : 0,
              special_guest_price: !!parseFloat(values.specialGuestPrice) ? parseFloat(values.specialGuestPrice) : 0,
              sponsor_guest_price: !!parseFloat(values.sponsorGuestPrice) ? parseFloat(values.sponsorGuestPrice) : 0,
              campus_id: restaurant.campus.id,
            };

            Object.entries(formDataFields).forEach(([key, value]) => {
              formData.append(key, value);
            });

            const { payload } = await putRestaurant(formData)(dispatch);
            if (!payload.success) {
              return toast.error(payload.error?.message, { type: 'error' });
            }
            toast.success(`Precios actualizados con éxito.`);
            // This only for the user to see the changes
            restaurant.member_price = values.memberPrice;
            restaurant.special_guest_price = values.specialGuestPrice;
            restaurant.sponsor_guest_price = values.sponsorGuestPrice;
            restaurant.casual_guest_price = values.casualGuestPrice;
            setIsOpenModal(false);
          }}
          // onReset={handleReset}
        >
          {() => (
            <>
              <Form className="mt-4 flex flex-col gap-4">
                <FormInput
                  type="number"
                  name="memberPrice"
                  placeholder="Socio y sus dependientes"
                  label="Socio y sus dependientes"
                  required
                />
                <FormInput
                  type="number"
                  name="specialGuestPrice"
                  placeholder="Invitados especiales"
                  label="Invitados especiales"
                  required
                />
                <FormInput
                  type="number"
                  name="sponsorGuestPrice"
                  placeholder="Invitados con patrocinio"
                  label="Invitados con patrocinio"
                  required
                />
                <FormInput
                  type="number"
                  name="casualGuestPrice"
                  placeholder="Invitados ocasionales"
                  label="Invitados ocasionales"
                  required
                />
                <button
                  type="submit"
                  className="h-11 btn-yellow"
                >
                  Actualizar
                </button>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}