
export enum RestaurantLunchesTypes {
  GET_RESTAURANTLUNCHESPAG_LOADING = "GET_RESTAURANTLUNCHESPAG_LOADING",
  GET_RESTAURANTLUNCHESPAG_SUCCESS = "GET_RESTAURANTLUNCHESPAG_SUCCESS",
  GET_RESTAURANTLUNCHESPAG_FAILURE = "GET_RESTAURANTLUNCHESPAG_FAILURE",
  GET_RESTAURANTLUNCHES_LOADING = "GET_RESTAURANTLUNCHES_LOADING",
  GET_RESTAURANTLUNCHES_SUCCESS = "GET_RESTAURANTLUNCHES_SUCCESS",
  GET_RESTAURANTLUNCHES_FAILURE = "GET_RESTAURANTLUNCHES_FAILURE",
  GETONLY_RESTAURANTLUNCHES_LOADING = "GETONLY_RESTAURANTLUNCHES_LOADING",
  GETONLY_RESTAURANTLUNCHES_SUCCESS = "GETONLY_RESTAURANTLUNCHES_SUCCESS",
  GETONLY_RESTAURANTLUNCHES_FAILURE = "GETONLY_RESTAURANTLUNCHES_FAILURE",
  POST_RESTAURANTLUNCHES_LOADING = "POST_RESTAURANTLUNCHES_LOADING",
  POST_RESTAURANTLUNCHES_SUCCESS = "POST_RESTAURANTLUNCHES_SUCCESS",
  POST_RESTAURANTLUNCHES_FAILURE = "POST_RESTAURANTLUNCHES_FAILURE",
  PUT_RESTAURANTLUNCHES_LOADING = "PUT_RESTAURANTLUNCHES_LOADING",
  PUT_RESTAURANTLUNCHES_SUCCESS = "PUT_RESTAURANTLUNCHES_SUCCESS",
  PUT_RESTAURANTLUNCHES_FAILURE = "PUT_RESTAURANTLUNCHES_FAILURE",
  DELETE_RESTAURANTLUNCHES_LOADING = "DELETE_RESTAURANTLUNCHES_LOADING",
  DELETE_RESTAURANTLUNCHES_SUCCESS = "DELETE_RESTAURANTLUNCHES_SUCCESS",
  DELETE_RESTAURANTLUNCHES_FAILURE = "DELETE_RESTAURANTLUNCHES_FAILURE",
}