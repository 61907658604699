
import { SuggestionsAction, SuggestionsPayload } from "../interfaces";
import { SuggestionsTypes } from "../types";

const initialState: SuggestionsPayload = {
  loading: false,
  success: false,
  error: null,
  suggestions: null,
  listSuggestions: [],
  pagSuggestions: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const suggestions = (state = initialState, action: SuggestionsAction): SuggestionsPayload => {
  const { loading, success, error, suggestions, listSuggestions, pagSuggestions } = action.payload || {};
  switch (action.type) {
    case SuggestionsTypes.GET_SUGGESTIONSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case SuggestionsTypes.GET_SUGGESTIONSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case SuggestionsTypes.GET_SUGGESTIONSPAG_SUCCESS:
      return {
        ...state,
        pagSuggestions,
        loading,
        success,
      };
    case SuggestionsTypes.GET_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case SuggestionsTypes.GET_SUGGESTIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case SuggestionsTypes.GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        listSuggestions,
        loading,
        success,
      };
    case SuggestionsTypes.GETONLY_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case SuggestionsTypes.GETONLY_SUGGESTIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case SuggestionsTypes.GETONLY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions,
        loading,
        success,
      };
    case SuggestionsTypes.POST_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case SuggestionsTypes.POST_SUGGESTIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case SuggestionsTypes.POST_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions,
        loading,
        success,
      };
    case SuggestionsTypes.PUT_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case SuggestionsTypes.PUT_SUGGESTIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case SuggestionsTypes.PUT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions,
        loading,
        success,
      };
    case SuggestionsTypes.DELETE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case SuggestionsTypes.DELETE_SUGGESTIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case SuggestionsTypes.DELETE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions,
        loading,
        success,
      };
    default:
      return state;
  }
};

