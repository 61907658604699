import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { MultipleServicesReservationFilters, MultipleServicesReservationGet, MultipleServicesReservationPost, MultipleServicesReservationPut, MultipleServicesReservationTable } from "../../interfaces";

export class MultipleServicesReservationRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getMultipleServicesReservation(filters?: MultipleServicesReservationFilters): Promise<AxiosResponse<MultipleServicesReservationGet>> {
    return this.lmBootApi.get<MultipleServicesReservationGet>("/multiple-service-reservations/", { params: filters });
  }

  getMultipleServicesReservationById(multiple_service_id: number): Promise<AxiosResponse<MultipleServicesReservationGet>> {
    return this.lmBootApi.get<MultipleServicesReservationGet>("/multiple-service-reservations/", { params: { multiple_service_id } });
  }

  postMultipleServicesReservation(msReservation: MultipleServicesReservationPost): Promise<AxiosResponse<MultipleServicesReservationTable>> {
    return this.lmBootApi.post<MultipleServicesReservationTable>("/multiple-service-reservations/", msReservation);
  }

  putMultipleServicesReservation(msReservation: MultipleServicesReservationPut): Promise<AxiosResponse<MultipleServicesReservationTable>> {
    return this.lmBootApi.put<MultipleServicesReservationTable>("/multiple-service-reservations/", msReservation);
  }

  // dont response data
  deleteMultipleServicesReservation(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/multiple-service-reservations/${id}/`);
  }
}
