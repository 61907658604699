
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { RestaurantSchedulesFilters, RestaurantSchedulesGet, RestaurantSchedulesPost, RestaurantSchedulesPut, RestaurantSchedulesTable } from "../../interfaces";

export class RestaurantSchedulesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getRestaurantSchedules(filters?: RestaurantSchedulesFilters): Promise<AxiosResponse<RestaurantSchedulesGet>> {
    return this.lmBootApi.get<RestaurantSchedulesGet>("/restaurant-schedules/", { params: filters });
  }

  postRestaurantSchedules(restaurantSchedules: RestaurantSchedulesPost): Promise<AxiosResponse<RestaurantSchedulesTable>> {
    return this.lmBootApi.post<RestaurantSchedulesTable>("/restaurant-schedules/", restaurantSchedules);
  }

  putRestaurantSchedules(restaurantSchedules: RestaurantSchedulesPut): Promise<AxiosResponse<RestaurantSchedulesTable>> {
    return this.lmBootApi.put<RestaurantSchedulesTable>("/restaurant-schedules/", restaurantSchedules);
  }

  // dont response data
  deleteRestaurantSchedules(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/restaurant-schedules/${id}/`);
  }
}

