
import { RestaurantCategoriesAction, RestaurantCategoriesPayload } from "../interfaces";
import { RestaurantCategoriesTypes } from "../types";

const initialState: RestaurantCategoriesPayload = {
  loading: false,
  success: false,
  error: null,
  restaurantCategories: null,
  listRestaurantCategories: [],
  pagRestaurantCategories: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const restaurantCategories = (state = initialState, action: RestaurantCategoriesAction): RestaurantCategoriesPayload => {
  const { loading, success, error, restaurantCategories, listRestaurantCategories, pagRestaurantCategories } = action.payload || {};
  switch (action.type) {
    case RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIESPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIESPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIESPAG_SUCCESS:
      return {
        ...state,
        pagRestaurantCategories,
        loading,
        success,
      };
    case RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIES_SUCCESS:
      return {
        ...state,
        listRestaurantCategories,
        loading,
        success,
      };
    case RestaurantCategoriesTypes.GETONLY_RESTAURANTCATEGORIES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantCategoriesTypes.GETONLY_RESTAURANTCATEGORIES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantCategoriesTypes.GETONLY_RESTAURANTCATEGORIES_SUCCESS:
      return {
        ...state,
        restaurantCategories,
        loading,
        success,
      };
    case RestaurantCategoriesTypes.POST_RESTAURANTCATEGORIES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantCategoriesTypes.POST_RESTAURANTCATEGORIES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantCategoriesTypes.POST_RESTAURANTCATEGORIES_SUCCESS:
      return {
        ...state,
        restaurantCategories,
        loading,
        success,
      };
    case RestaurantCategoriesTypes.PUT_RESTAURANTCATEGORIES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantCategoriesTypes.PUT_RESTAURANTCATEGORIES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantCategoriesTypes.PUT_RESTAURANTCATEGORIES_SUCCESS:
      return {
        ...state,
        restaurantCategories,
        loading,
        success,
      };
    case RestaurantCategoriesTypes.DELETE_RESTAURANTCATEGORIES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantCategoriesTypes.DELETE_RESTAURANTCATEGORIES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantCategoriesTypes.DELETE_RESTAURANTCATEGORIES_SUCCESS:
      return {
        ...state,
        restaurantCategories,
        loading,
        success,
      };
    default:
      return state;
  }
};

