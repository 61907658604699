
import { EventWireTransfersRepository } from "../repositories";
import { EventWireTransfersFilters, EventWireTransfersGet, EventWireTransfersTable } from "../../interfaces";

export class EventWireTransfersProcess {
  private eventWireTransfersRepository: EventWireTransfersRepository;

  constructor() {
    this.eventWireTransfersRepository = new EventWireTransfersRepository();
  }

  async getEventWireTransfers(filters?: EventWireTransfersFilters): Promise<EventWireTransfersGet> {
    const response = await this.eventWireTransfersRepository.getEventWireTransfers(filters);
    return response.data;
  }

  async postEventWireTransfers(eventWireTransfers: FormData): Promise<EventWireTransfersTable> {
    const response = await this.eventWireTransfersRepository.postEventWireTransfers(eventWireTransfers);
    return response.data;
  }

  async putEventWireTransfers(eventWireTransfers: FormData): Promise<EventWireTransfersTable> {
    const response = await this.eventWireTransfersRepository.putEventWireTransfers(eventWireTransfers);
    return response.data;
  }

  // dont response data
  async deleteEventWireTransfers(id: number): Promise<any> {
    const response = await this.eventWireTransfersRepository.deleteEventWireTransfers(id);
    return response.data;
  }
}
