
import { RestaurantProcess } from "../api";
import { RestaurantAction, RestaurantFilters } from "../interfaces";
import { RestaurantTypes } from "../types";

export const getRestaurantPags = (filters?: RestaurantFilters) => async (dispatch: (state: RestaurantAction) => RestaurantAction) => {
  dispatch({
    type: RestaurantTypes.GET_RESTAURANTPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantProcess = new RestaurantProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantPag = await restaurantProcess.getRestaurant(filters);
    return dispatch({
      type: RestaurantTypes.GET_RESTAURANTPAG_SUCCESS,
      payload: {
        pagRestaurant: restaurantPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantTypes.GET_RESTAURANTPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getRestaurant = (filters?: RestaurantFilters) => async (dispatch: (state: RestaurantAction) => RestaurantAction) => {
  dispatch({
    type: RestaurantTypes.GET_RESTAURANT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantProcess = new RestaurantProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantPag = await restaurantProcess.getRestaurant({
      ...filters,
      data_all: 'YES',
    });
    const restaurant = restaurantPag.results;
    return dispatch({
      type: RestaurantTypes.GET_RESTAURANT_SUCCESS,
      payload: {
        listRestaurant: restaurant,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantTypes.GET_RESTAURANT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postRestaurant = (restaurant: FormData) => async (dispatch: (state: RestaurantAction) => RestaurantAction) => {
  dispatch({
    type: RestaurantTypes.POST_RESTAURANT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantProcess = new RestaurantProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantTable = await restaurantProcess.postRestaurant(restaurant);
    return dispatch({
      type: RestaurantTypes.POST_RESTAURANT_SUCCESS,
      payload: {
        restaurant: restaurantTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantTypes.POST_RESTAURANT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putRestaurant = (restaurant: FormData) => async (dispatch: (state: RestaurantAction) => RestaurantAction) => {
  dispatch({
    type: RestaurantTypes.PUT_RESTAURANT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantProcess = new RestaurantProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantTable = await restaurantProcess.putRestaurant(restaurant);
    return dispatch({
      type: RestaurantTypes.PUT_RESTAURANT_SUCCESS,
      payload: {
        restaurant: restaurantTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantTypes.PUT_RESTAURANT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteRestaurant = (id: number) => async (dispatch: (state: RestaurantAction) => RestaurantAction) => {
  dispatch({
    type: RestaurantTypes.DELETE_RESTAURANT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantProcess = new RestaurantProcess();
    // dont'n import the result of the process, only that dont't have error
    await restaurantProcess.deleteRestaurant(id);
    return dispatch({
      type: RestaurantTypes.DELETE_RESTAURANT_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantTypes.DELETE_RESTAURANT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

