import { ChangeEvent, useState } from "react";

const useForm = <T extends Object>(
    formValues: T,
) => {
    const [state, setState] = useState(formValues);

    const handleOnChangeInput = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = target;
        const checked = target instanceof HTMLInputElement && target.type === "radio" ? value === 'true' : null;
        setState({
            ...state,
            [name]: checked === null ? value : checked,
        });
    };

    const handleUpdateState = (newState: any) => {
        setState({
            ...state,
            ...newState,
        });
    };

    const handleUpdateField = (field: keyof T, value: any) => {
        setState({
            ...state,
            [field]: value,
        });
    };

    return {
        formValues: state,
        handleOnChangeInput,
        handleUpdateState,
        handleUpdateField,
        ...state,
    };
};

export default useForm;
