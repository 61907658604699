import { useFormik } from "formik";
import * as Yup from "yup";
import { getCampusContribution, postCampusContribution, putCampusContribution } from "../../redux/actions";
import { User } from "../../redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { useEffect } from "react";

export const CampusContributionsPage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listCampusContribution } = useSelector((state: RootState) => state.campusContribution);

  const {
    getFieldProps,
    handleSubmit,
    touched,
    errors,
    setValues
  } = useFormik({
    initialValues: {
      nameConsultant: listCampusContribution?.[0]?.name || '',
      phone: listCampusContribution?.[0]?.phone || '',
      firstTime: listCampusContribution?.length === 0 ? true : false,
    },
    validationSchema: Yup.object({
      nameConsultant: Yup.string().required('El nombre del asesor es requerido'),
      phone: Yup.string().required('El teléfono es requerido'),
    }),
    onSubmit: async (values) => {
      if (values.firstTime) {
        const { payload } = await postCampusContribution({
          name: values.nameConsultant,
          phone: values.phone,
          campus_id: user.campus.id,
        })(dispatch);

        const { success } = payload;
        if (!success) {
          return toast.error(payload.error?.message, { type: 'error' });
        }
      } else {
        const { payload } = await putCampusContribution({
          id: listCampusContribution?.[0]?.id || -1,
          name: values.nameConsultant,
          phone: values.phone,
          campus_id: user.campus.id,
        })(dispatch);

        const { success } = payload;
        if (!success) {
          return toast.error(payload.error?.message, { type: 'error' });
        }
      }
      toast.success('Datos del asesor actualizados.');
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await getCampusContribution({
        campus_id: user.campus.id,
      })(dispatch);

      const { success } = payload;
      if (!success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      setValues({
        nameConsultant: payload.listCampusContribution?.[0]?.name || '',
        firstTime: payload.listCampusContribution?.length === 0 ? true : false,
        phone: payload.listCampusContribution?.[0]?.phone || '',
      })
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h1 className="text-gray-500 text-lg font-bold mb-6">Aportaciones</h1>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col">
          <input
            id="phone"
            type="text"
            maxLength={20}
            {...getFieldProps('phone')}
            required
            className="input-yellow"
            placeholder="Número de Whatsapp para contacto con asesor"
          />
          <span className="text-rose-500">{ touched.phone && errors.phone && errors.phone}</span>
        </div>
        <div className="flex flex-col">
          <input
            id="nameConsultant"
            type="text"
            maxLength={20}
            {...getFieldProps('nameConsultant')}
            required
            className="input-yellow"
            placeholder="Nombre del Asesor"
          />
          <span className="text-rose-500">{ touched.nameConsultant && errors.nameConsultant && errors.nameConsultant}</span>
        </div>
        <div className="flex flex-row justify-end">
          <button
            type="submit"
            className="btn-yellow w-32"
          >
            Guardar
          </button>
        </div>
      </form>
    </>
  )
}