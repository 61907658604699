import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getHotel,
  getHotelBankAccount,
  getHotelBedroom,
  getUser,
  postHotelReservation,
  postHotelWireTransfers,
} from '../../redux/actions';
import { HotelBedroomTable, HotelReservationTable, User } from '../../redux/interfaces';
import { RootState } from '../../redux/store';
import useForm from '../../utils/hooks/useForm';
import { rolsCampusAdmin } from '../../utils/rolsCampusAdmin';
import { testSizeImg } from '../../utils/utils';

type VoucherType = {
  id?: number;
  receipt: string;
  amount?: number;
  hotel_reservation_id?: number;
  file?: File;
};

interface ReservationData {
  peopleTotal: {
    value: number;
  };
  calculeTotalCost: number;
  checkinOutHoursPrice: number;
  reservationCost: number;
  peopleSection: boolean;
  datesSection: boolean;
  paymentSection: boolean;
  submitSection: boolean;
}

export const HotelReservationNewPage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listHotel } = useSelector((state: RootState) => state.hotel);
  const { listHotelBedroom } = useSelector((state: RootState) => state.hotelBedroom);
  const { listHotelBankAccount } = useSelector((state: RootState) => state.hotelBankAccount);
  const [voucherFile, setVoucherFile] = useState<VoucherType[]>([]);
  const [memberUser, setMemberUser] = useState<User | null>(null);
  const [voucherName, setVoucherName] = useState('');
  const [selectedBedroom, setSelectedBedroom] = useState<HotelBedroomTable | null>(null);
  // const [peopleTotal, setPeopleTotal] = useState<number | null>(null);
  const [reservationData, setReservationData] = useState<ReservationData>({
    peopleTotal: {
      value: 0,
    },
    calculeTotalCost: 0,
    checkinOutHoursPrice: 0,
    reservationCost: 0,
    peopleSection: false,
    datesSection: false,
    paymentSection: false,
    submitSection: false,
  });

  const {
    documentNumber,
    nameMember,
    hotelBedroomId,
    paymentMethod,
    totalCost,
    description,
    startDate,
    endDate,
    checkoutTime,
    membersNumber,
    familyNumber,
    checkinTime,
    bankAccount,
    amountPaid,
    guessNumber,
    handleOnChangeInput,
    handleUpdateState,
  } = useForm({
    nameMember: '',
    documentNumber: '',
    hotelBedroomId: '',
    paymentMethod: '',
    bankAccount: '',
    checkinTime: '14:00',
    checkoutTime: '11:59',
    description: '',
    totalCost: '',
    startDate: '',
    endDate: '',
    membersNumber: '',
    familyNumber: '',
    guessNumber: '',
    amountPaid: '',
  });

  const reloadForm = () => {
    // Reload all data
    pullFormData();
    // Reset Form
    setVoucherFile([]);
    setMemberUser(null);
    handleUpdateState({
      nameMember: '',
      documentNumber: '',
      hotelBedroomId: '',
      paymentMethod: '',
      bankAccount: '',
      checkinTime: '14:00:00',
      checkoutTime: '11:59:00',
      description: '',
      totalCost: '',
      startDate: '',
      endDate: '',
      membersNumber: '',
      familyNumber: '',
      guessNumber: '',
      amountPaid: '',
    });
    setVoucherName('');
    setReservationData({
      ...reservationData,
      calculeTotalCost: 0,
    });
    setSelectedBedroom(null);
  };
  const pullFormData = async () => {
    getHotel({
      campus_id: user.campus.id,
    })(dispatch);
    getHotelBankAccount({
      campus_id: user.campus.id,
    })(dispatch);
  };
  useEffect(() => {
    pullFormData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!listHotel || listHotel.length === 0) return;
    getHotelBedroom({
      hotel_id: listHotel[0].id,
      campus_id: user.campus.id,
    })(dispatch);
  }, [listHotel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pullMember = async () => {
      if (documentNumber.toString().length < 10) {
        handleUpdateState({
          nameMember: '',
        });
        setMemberUser(null);
        return;
      }
      const { payload } = await getUser({
        document_number: documentNumber,
      })(dispatch);
      if (payload.member.results.length > 0) {
        handleUpdateState({
          nameMember: payload.member.results[0].first_name,
        });
        setMemberUser(payload.member.results[0]);
      } else {
        handleUpdateState({
          nameMember: 'No se encontró el socio.',
        });
        setMemberUser(null);
      }
    };
    pullMember();
  }, [documentNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!selectedBedroom && !!memberUser) {
      setReservationData({
        ...reservationData,
        peopleSection: true,
      });
    } else {
      setReservationData({
        ...reservationData,
        peopleSection: false,
      });
    }
  }, [selectedBedroom, memberUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const maxPeople = +membersNumber + +familyNumber + +guessNumber;
    if (!!selectedBedroom && !!memberUser && maxPeople > 0) {
      if (maxPeople > selectedBedroom.people_total) {
        toast.info(`El número máximo de personas debe ser igual o inferior a ${selectedBedroom?.people_total}.`);
        return;
      }
      setReservationData({
        ...reservationData,
        datesSection: true,
        peopleTotal: {
          value: maxPeople,
        },
      });
    } else {
      setReservationData({
        ...reservationData,
        datesSection: false,
        peopleTotal: {
          value: 0,
        },
      });
    }
  }, [membersNumber, familyNumber, guessNumber, selectedBedroom]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!startDate && !!endDate) {
      const { peopleTotal } = reservationData;

      const totalCost = onCalculateTotal(peopleTotal.value);
      const diff = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      let calculeTotalCost = diffDays * totalCost;

      const checkinOutHoursPrice = checkinTime !== '14:00' ? totalCost / 2 : 0;
      calculeTotalCost = calculeTotalCost + checkinOutHoursPrice;
      // const reservationCost = (parseFloat(listHotel![0].reservation_cost) / 100) * calculeTotalCost;

      setReservationData({
        ...reservationData,
        paymentSection: true,
        calculeTotalCost: calculeTotalCost,
        reservationCost: 0,
      });
    }
  }, [startDate, endDate, checkinTime, reservationData.peopleTotal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!reservationData.paymentSection && !!paymentMethod && !!reservationData.calculeTotalCost) {
      if (paymentMethod === 'WIRE_TRANSFER') {
        if (!!bankAccount && !!amountPaid && voucherFile.length > 0) {
          setReservationData({
            ...reservationData,
            submitSection: true,
          });
        } else {
          setReservationData({
            ...reservationData,
            submitSection: false,
          });
        }
        return;
      }
      setReservationData({
        ...reservationData,
        submitSection: true,
      });
    }
  }, [reservationData.paymentSection, paymentMethod, totalCost, bankAccount, amountPaid, voucherFile]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleForm = async (e: FormEvent) => {
    e.preventDefault();
    if (memberUser === null) return toast.error('El socio no existe.');
    if (!listHotelBedroom || listHotelBedroom.length === 0) return toast.error('No hay habitaciones disponibles.');

    // const peopleTotal = +membersNumber + +familyNumber + +guessNumber;
    // if (peopleTotal > selectedBedroom!.people_total) {
    //     return toast.info(`El número máximo de personas debe ser igual o inferior a ${selectedBedroom?.people_total}.`);
    // }

    // const totalCost = onCalculateTotal(peopleTotal);
    // const diff = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
    // const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    // let calculeTotalCost = diffDays * totalCost;

    // const checkinOutHoursPrice = checkinTime !== '12:30:00' ? (calculeTotalCost / 2) * 0.1 : 0;
    // calculeTotalCost = calculeTotalCost + checkinOutHoursPrice;
    // const reservationCost = (parseFloat(listHotel![0].reservation_cost) / 100) * calculeTotalCost;

    const { payload } = await postHotelReservation({
      description: description,
      start_date: new Date(`${startDate}T${checkinTime}`).toISOString(),
      end_date: new Date(`${endDate}T${checkoutTime}`).toISOString(),
      family_number: parseInt(familyNumber),
      guest_number: parseInt(guessNumber),
      member_number: parseInt(membersNumber),
      people_total: reservationData.peopleTotal.value,
      payment_method: paymentMethod,
      status: 'PENDING',
      check_in_out_hours: reservationData.checkinOutHoursPrice,
      hotel_cost: reservationData.calculeTotalCost,
      reservation_cost: reservationData.reservationCost,
      total_cost: reservationData.calculeTotalCost,
      hotel_bedroom_id: parseInt(hotelBedroomId),
      user_id: memberUser.id,
    })(dispatch);
    if (!payload.success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success('Reservación creada con éxito.');

    if (voucherFile.length === 0) {
      return reloadForm();
    }

    toast.info('Subiendo comprobante...');
    // Upload images
    const voucherToPost = new FormData();
    const hotelReservation = payload.hotelReservation as HotelReservationTable;

    voucherToPost.append('amount', amountPaid);
    voucherToPost.append('hotel_reservation_id', hotelReservation.id.toString());
    voucherToPost.append('bank_data_id', bankAccount);
    for (const voucher of voucherFile) {
      if (!voucher.id && voucher.file) {
        voucherToPost.append('receipt', voucher.file);
      }
    }

    const allPromiseRequest = [];
    voucherToPost.getAll('receipt').length > 0 &&
      allPromiseRequest.push(postHotelWireTransfers(voucherToPost)(dispatch));

    const responses = await Promise.all(allPromiseRequest);
    const allSuccess = responses.every((response) => response.payload.success);

    if (!allSuccess) {
      return toast.error(
        '¡Oops!, tuvimos un problema al subir el comprobante. Por favor vuelva a intentarlo más tarde.',
        { type: 'error' },
      );
    }
    toast.success('Comprobante subido con éxito.');
    reloadForm();
  };

  const onCalculateTotal = (peopleTotal: number) => {
    if (!selectedBedroom) return 0;
    if (selectedBedroom.is_pay_bedroom) {
      switch (memberUser?.groups[0].name) {
        case 'member_user':
          return selectedBedroom.price_member;
        case 'family_user':
          return selectedBedroom.price_family;
        case 'guest_user':
          return selectedBedroom.price_guest;
        default:
          return selectedBedroom.price_member;
      }
    } else {
      switch (memberUser?.groups[0].name) {
        case 'member_user':
          const occupancyPaymentMember = selectedBedroom.occupancy_payments.find(
            (item) => item.member_type === 'member_user' && item.people_total === peopleTotal,
          );
          return occupancyPaymentMember ? occupancyPaymentMember.price : 0;
        case 'family_user':
          const occupancyPaymentFamily = selectedBedroom.occupancy_payments.find(
            (item) => item.member_type === 'family_user' && item.people_total === peopleTotal,
          );
          return occupancyPaymentFamily ? occupancyPaymentFamily.price : 0;
        case 'guest_user':
          const occupancyPaymentGuest = selectedBedroom.occupancy_payments.find(
            (item) => item.member_type === 'guest_user' && item.people_total === peopleTotal,
          );
          return occupancyPaymentGuest ? occupancyPaymentGuest.price : 0;
        default:
          return 0;
      }
    }
  };

  const handleCatchVoucher = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', {
        type: 'error',
      });
    }
    setVoucherName(e.target.files[0].name);
    const voucherList: VoucherType[] = Object.values(e.target.files || {}).map((file) => ({
      receipt: URL.createObjectURL(file),
      file,
    }));
    setVoucherFile((prevState) => [...prevState, ...voucherList]);
  };

  const handleOnChangeLimitPeople = (e: ChangeEvent<HTMLInputElement>, inputName: string) => {
    let maxPeople = 0;
    if (inputName === 'membersNumber') {
      maxPeople = +e.target.value + +familyNumber + +guessNumber;
    }
    if (inputName === 'familyNumber') {
      maxPeople = +membersNumber + +e.target.value + +guessNumber;
    }
    if (inputName === 'guessNumber') {
      maxPeople = +membersNumber + +familyNumber + +e.target.value;
    }
    if (!selectedBedroom) {
      return toast.error('Seleccione una habitación primero.');
    }
    if (maxPeople > selectedBedroom.people_total) {
      return toast.info(`El número máximo de personas debe ser igual o inferior a ${selectedBedroom?.people_total}.`);
    }
    // memberUser
    if (inputName === 'membersNumber') {
      handleUpdateState({
        membersNumber: e.target.value,
      });
    }
    // familyNumber
    if (inputName === 'familyNumber') {
      handleUpdateState({
        familyNumber: e.target.value,
      });
    }
    // guessNumber
    if (inputName === 'guessNumber') {
      handleUpdateState({
        guessNumber: e.target.value,
      });
    }
  };

  return (
    <div>
      <form className="mx-auto flex max-w-3xl flex-col gap-2.5" onSubmit={handleForm}>
        <h2 className="font-bold text-gray-500">Subir Servicio Manualmente</h2>

        <select
          id="hotelBedroomId"
          name="hotelBedroomId"
          value={hotelBedroomId}
          onChange={(e) => {
            setSelectedBedroom({
              ...listHotelBedroom!.filter((bedroom) => bedroom.id === parseInt(e.target.value))[0],
            });
            handleUpdateState({
              hotelBedroomId: e.target.value,
            });
          }}
          required
          className="select-yellow w-full"
        >
          <option value="" disabled>
            Seleccione una habitación
          </option>
          {listHotelBedroom &&
            listHotelBedroom.map((bedroom) => (
              <option key={`bedroom-${bedroom.id}`} value={bedroom.id}>
                {bedroom.name}
              </option>
            ))}
        </select>
        <input
          id="documentNumber"
          name="documentNumber"
          type="number"
          value={documentNumber}
          onChange={handleOnChangeInput}
          min={0}
          maxLength={10}
          required
          className="input-yellow w-full"
          placeholder="Ingrese el Numero de Cédula"
        />
        <input
          id="nameMember"
          name="nameMember"
          type="text"
          value={nameMember}
          onChange={handleOnChangeInput}
          min={0}
          required
          disabled
          className="input-yellow mt-2 w-full"
          placeholder="Nombre del Socio"
        />
        {!!memberUser?.first_name && (
          <span className="text-gray-500">
            Rol: {rolsCampusAdmin.find((rol) => rol.value === memberUser?.groups[0].name)?.label}
          </span>
        )}

        <div className=" mt-6 w-full border border-gray-200"></div>

        <div className="mt-4 flex flex-row items-center gap-4">
          <input
            id="membersNumber"
            name="membersNumber"
            type="number"
            value={membersNumber}
            onChange={(e) => handleOnChangeLimitPeople(e, 'membersNumber')}
            min={0}
            disabled={!reservationData.peopleSection}
            required
            className="input-yellow basis-1/3"
            placeholder="# Socios"
          />
          <input
            id="familyNumber"
            name="familyNumber"
            type="number"
            value={familyNumber}
            onChange={(e) => handleOnChangeLimitPeople(e, 'familyNumber')}
            min={0}
            disabled={!reservationData.peopleSection}
            required
            className="input-yellow basis-1/3"
            placeholder="# Familiares"
          />
          <input
            id="guessNumber"
            name="guessNumber"
            type="number"
            value={guessNumber}
            min={0}
            onChange={(e) => handleOnChangeLimitPeople(e, 'guessNumber')}
            disabled={!reservationData.peopleSection}
            required
            className="input-yellow basis-1/3"
            placeholder="# Invitados"
          />
        </div>
        {!!selectedBedroom && (
          <span className="text-gray-500">Número máximo de personas: {selectedBedroom.people_total}</span>
        )}

        <div className=" mt-6 w-full border border-gray-200"></div>

        <div className="mt-4 flex flex-row items-center gap-4">
          {/* Fecha de ingreso */}
          <input
            id="startDate"
            name="startDate"
            type="date"
            min={new Date().toISOString().split('T')[0]}
            value={startDate}
            onChange={handleOnChangeInput}
            disabled={!reservationData.datesSection}
            required
            className="input-yellow basis-1/2"
            placeholder="Fecha de Entrada"
          />

          <input
            type="time"
            id="checkinTime"
            name="checkinTime"
            value={checkinTime}
            onChange={handleOnChangeInput}
            disabled={!reservationData.datesSection}
            placeholder="Hora Entrada"
            className="input-yellow basis-1/2"
          />
        </div>
        <div className="flex flex-row items-center gap-4">
          {/* Fecha de salida */}
          <input
            id="endDate"
            name="endDate"
            type="date"
            min={startDate}
            value={endDate}
            onChange={handleOnChangeInput}
            disabled={!reservationData.datesSection}
            required
            className="input-yellow basis-1/2"
            placeholder="Fecha de Salida"
          />
          <input
            type="time"
            id="checkoutTime"
            name="checkoutTime"
            value={checkoutTime}
            onChange={handleOnChangeInput}
            disabled={!reservationData.datesSection}
            required
            readOnly
            placeholder="Hora Salida"
            className="input-yellow mt-2 block h-11 basis-1/2 "
          />
        </div>

        <div className="mt-6 w-full border border-gray-200"></div>

        <div className="mt-4 grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <select
              id="paymentMethod"
              name="paymentMethod"
              value={paymentMethod}
              onChange={handleOnChangeInput}
              disabled={!reservationData.paymentSection}
              className="select-yellow w-full"
            >
              <option value="" disabled>
                Seleccione un método de pago
              </option>
              <option value="WIRE_TRANSFER">Transferencia</option>
              <option value="CASH">Efectivo</option>
              <option value="CREDIT_CARD_WEB">Tarjeta</option>
            </select>
          </div>
          <input
            id="totalCost"
            name="totalCost"
            type="number"
            value={reservationData.calculeTotalCost}
            min={0}
            step={0.01}
            onChange={(e) => setReservationData({ ...reservationData, calculeTotalCost: +e.target.value })}
            disabled={!reservationData.paymentSection}
            required
            className="input-yellow basis-1/3"
            placeholder="Monto a Pagar"
          />
        </div>
        <div className={`${paymentMethod === 'WIRE_TRANSFER' ? 'block' : 'hidden'}`}>
          <div className="mt-2 grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <select
                id="bankAccount"
                name="bankAccount"
                value={bankAccount}
                onChange={handleOnChangeInput}
                required={paymentMethod === 'WIRE_TRANSFER'}
                disabled={!reservationData.paymentSection}
                className="input-yellow block w-full"
              >
                <option value="" disabled>
                  Seleccione una cuenta bancaria
                </option>
                {listHotelBankAccount &&
                  listHotelBankAccount.map((bankData) => (
                    <option key={`bankData-${bankData.id}`} value={bankData.id}>
                      {bankData.name_bank}
                    </option>
                  ))}
              </select>
            </div>
            <input
              id="amountPaid"
              name="amountPaid"
              type="number"
              value={amountPaid}
              min={0}
              max={totalCost}
              step={0.01}
              onChange={handleOnChangeInput}
              disabled={!reservationData.paymentSection}
              required={paymentMethod === 'WIRE_TRANSFER'}
              className="input-yellow basis-1/3"
              placeholder="Monto pagado"
            />
          </div>

          <div className="static flex flex-row justify-end py-4">
            <label htmlFor="file" className="my-2 text-black">
              {voucherName === '' ? 'Subir comprobante' : voucherName}
            </label>
            <input
              multiple
              type="file"
              name="file"
              id="file"
              accept="image/png, image/jpeg"
              onChange={handleCatchVoucher}
              className="absolute -z-10 h-0 w-0 overflow-hidden opacity-0"
            />
            <label htmlFor="file" className="justify-end">
              <div className="ml-6 rounded-md border border-transparent bg-yellow-500 p-2 text-sm font-bold text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
              </div>
            </label>
          </div>
        </div>

        <textarea
          id="description"
          name="description"
          value={description}
          onChange={handleOnChangeInput}
          className="relative mt-2 block h-24 w-full appearance-none rounded-lg border-[1px] border-gray-300  bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
          placeholder="Campo de Nota"
        />

        <div className="mb-8 flex justify-end">
          <button type="submit" className=" btn-yellow mb-8 w-40" disabled={!reservationData.submitSection}>
            Reservar
          </button>
        </div>
      </form>
    </div>
  );
};
