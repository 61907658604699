import { HotelBankAccountProcess } from "../api";
import { HotelBankAccountAction, HotelBankAccountFilters, HotelBankAccountPost, HotelBankAccountPut } from "../interfaces";
import { HotelBankAccountTypes } from "../types";

// apply the reducer
export const getHotelBankAccountPags = (filters?: HotelBankAccountFilters) => async (dispatch: (state: HotelBankAccountAction) => HotelBankAccountAction) => {
  dispatch({
    type: HotelBankAccountTypes.GET_HOTELBANKACCOUNTPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBankAccountProcess = new HotelBankAccountProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelBankAccountPag = await hotelBankAccountProcess.getHotelBankAccount(filters);
    return dispatch({
      type: HotelBankAccountTypes.GET_HOTELBANKACCOUNTPAG_SUCCESS,
      payload: {
        pagHotelBankAccount: hotelBankAccountPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBankAccountTypes.GET_HOTELBANKACCOUNTPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotelBankAccount = (filters?: HotelBankAccountFilters) => async (dispatch: (state: HotelBankAccountAction) => HotelBankAccountAction) => {
  dispatch({
    type: HotelBankAccountTypes.GET_HOTELBANKACCOUNT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBankAccountProcess = new HotelBankAccountProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelBankAccountPag = await hotelBankAccountProcess.getHotelBankAccount({
      ...filters,
      data_all: 'YES',
    });
    const hotelBankAccount = hotelBankAccountPag.results;
    return dispatch({
      type: HotelBankAccountTypes.GET_HOTELBANKACCOUNT_SUCCESS,
      payload: {
        listHotelBankAccount: hotelBankAccount,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBankAccountTypes.GET_HOTELBANKACCOUNT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotelBankAccount = (hotelBankAccount: HotelBankAccountPost) => async (dispatch: (state: HotelBankAccountAction) => HotelBankAccountAction) => {
  dispatch({
    type: HotelBankAccountTypes.POST_HOTELBANKACCOUNT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBankAccountProcess = new HotelBankAccountProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelBankAccountProcess.postHotelBankAccount(hotelBankAccount);
    return dispatch({
      type: HotelBankAccountTypes.POST_HOTELBANKACCOUNT_SUCCESS,
      payload: {
        hotelBankAccount: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBankAccountTypes.POST_HOTELBANKACCOUNT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotelBankAccount = (hotelBankAccount: HotelBankAccountPut) => async (dispatch: (state: HotelBankAccountAction) => HotelBankAccountAction) => {
  dispatch({
    type: HotelBankAccountTypes.PUT_HOTELBANKACCOUNT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBankAccountProcess = new HotelBankAccountProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelBankAccountProcess.putHotelBankAccount(hotelBankAccount);
    return dispatch({
      type: HotelBankAccountTypes.PUT_HOTELBANKACCOUNT_SUCCESS,
      payload: {
        hotelBankAccount: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBankAccountTypes.PUT_HOTELBANKACCOUNT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotelBankAccount = (id: number) => async (dispatch: (state: HotelBankAccountAction) => HotelBankAccountAction) => {
  dispatch({
    type: HotelBankAccountTypes.DELETE_HOTELBANKACCOUNT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBankAccountProcess = new HotelBankAccountProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelBankAccountProcess.deleteHotelBankAccount(id);
    return dispatch({
      type: HotelBankAccountTypes.DELETE_HOTELBANKACCOUNT_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBankAccountTypes.DELETE_HOTELBANKACCOUNT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};