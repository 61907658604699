import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { MultipleServicesScheduleFilters, MultipleServicesScheduleGet, MultipleServicesSchedulePost, MultipleServicesSchedulePut, MultipleServicesScheduleTable } from "../../interfaces";

export class MultipleServicesScheduleRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getMultipleServicesSchedule(filters?: MultipleServicesScheduleFilters): Promise<AxiosResponse<MultipleServicesScheduleGet>> {
    return this.lmBootApi.get<MultipleServicesScheduleGet>("/multiple-service-schedules/", { params: filters });
  }

  getMultipleServicesScheduleById(multiple_service_id: number): Promise<AxiosResponse<MultipleServicesScheduleGet>> {
    return this.lmBootApi.get<MultipleServicesScheduleGet>("/multiple-service-schedules/", { params: { multiple_service_id } });
  }

  postMultipleServicesSchedule(msSchedule: MultipleServicesSchedulePost): Promise<AxiosResponse<MultipleServicesScheduleTable>> {
    return this.lmBootApi.post<MultipleServicesScheduleTable>("/multiple-service-schedules/", msSchedule);
  }

  putMultipleServicesSchedule(msSchedule: MultipleServicesSchedulePut): Promise<AxiosResponse<MultipleServicesScheduleTable>> {
    return this.lmBootApi.put<MultipleServicesScheduleTable>("/multiple-service-schedules/", msSchedule);
  }

  // dont response data
  deleteMultipleServicesSchedule(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/multiple-service-schedules/${id}/`);
  }
}
