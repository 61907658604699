import { MenuAction, MenuPayload } from "../interfaces/menu.interface";
import { MenuTypes } from "../types/menu.types";

const initialState: MenuPayload = {
  secondMenuToggle: false,
  secondMenuShow: false,
  secondMenuItems: []
};

export const menu = (state = initialState, action: MenuAction) => {
  const { secondMenuToggle, secondMenuItems } = action.payload || {};
  switch (action.type) {
    case MenuTypes.ADD_MENU_ITEMS:
      return {
        ...state,
        secondMenuItems
      };
    case MenuTypes.SIDE_MENU_TOGGLE:
      return {
        ...state,
        secondMenuToggle,
      };
    case MenuTypes.SIDE_MENU_SHOW:
      return {
        ...state,
        secondMenuToggle: true,
      };
    case MenuTypes.SIDE_MENU_HIDDEN:
      return {
        ...state,
        secondMenuShow: false,
      };
    default:
      return state;
  }
};

