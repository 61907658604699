export const campusSurveysStatus = [
  {
    value: 'PUBLISHED',
    label: 'Publicado',
    color: 'green-500'
  },
  {
    value: 'SAVED',
    label: 'Guardado',
    color: 'rose-500'
  },
  {
    value: 'FINISHED',
    label: 'Finalizado',
    color: 'gray-500'
  },
]