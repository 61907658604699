
export enum EventCoordinatorsTypes {
  GET_EVENTCOORDINATORSPAG_LOADING = "GET_EVENTCOORDINATORSPAG_LOADING",
  GET_EVENTCOORDINATORSPAG_SUCCESS = "GET_EVENTCOORDINATORSPAG_SUCCESS",
  GET_EVENTCOORDINATORSPAG_FAILURE = "GET_EVENTCOORDINATORSPAG_FAILURE",
  GET_EVENTCOORDINATORS_LOADING = "GET_EVENTCOORDINATORS_LOADING",
  GET_EVENTCOORDINATORS_SUCCESS = "GET_EVENTCOORDINATORS_SUCCESS",
  GET_EVENTCOORDINATORS_FAILURE = "GET_EVENTCOORDINATORS_FAILURE",
  GETONLY_EVENTCOORDINATORS_LOADING = "GETONLY_EVENTCOORDINATORS_LOADING",
  GETONLY_EVENTCOORDINATORS_SUCCESS = "GETONLY_EVENTCOORDINATORS_SUCCESS",
  GETONLY_EVENTCOORDINATORS_FAILURE = "GETONLY_EVENTCOORDINATORS_FAILURE",
  POST_EVENTCOORDINATORS_LOADING = "POST_EVENTCOORDINATORS_LOADING",
  POST_EVENTCOORDINATORS_SUCCESS = "POST_EVENTCOORDINATORS_SUCCESS",
  POST_EVENTCOORDINATORS_FAILURE = "POST_EVENTCOORDINATORS_FAILURE",
  PUT_EVENTCOORDINATORS_LOADING = "PUT_EVENTCOORDINATORS_LOADING",
  PUT_EVENTCOORDINATORS_SUCCESS = "PUT_EVENTCOORDINATORS_SUCCESS",
  PUT_EVENTCOORDINATORS_FAILURE = "PUT_EVENTCOORDINATORS_FAILURE",
  DELETE_EVENTCOORDINATORS_LOADING = "DELETE_EVENTCOORDINATORS_LOADING",
  DELETE_EVENTCOORDINATORS_SUCCESS = "DELETE_EVENTCOORDINATORS_SUCCESS",
  DELETE_EVENTCOORDINATORS_FAILURE = "DELETE_EVENTCOORDINATORS_FAILURE",
}