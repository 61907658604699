
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { CampusSurveyAskDelete, CampusSurveyAskFilters, CampusSurveyAskGet, CampusSurveyAskPost, CampusSurveyAskPut, CampusSurveyAskTable } from "../../interfaces";

export class CampusSurveyAskRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCampusSurveyAsk(filters?: CampusSurveyAskFilters): Promise<AxiosResponse<CampusSurveyAskGet>> {
    return this.lmBootApi.get<CampusSurveyAskGet>("/survey-ask/", { params: filters });
  }

  postCampusSurveyAsk(campusSurveyAsk: CampusSurveyAskPost): Promise<AxiosResponse<CampusSurveyAskTable>> {
    return this.lmBootApi.post<CampusSurveyAskTable>("/survey-ask/", campusSurveyAsk);
  }

  putCampusSurveyAsk(campusSurveyAsk: CampusSurveyAskPut): Promise<AxiosResponse<CampusSurveyAskTable>> {
    return this.lmBootApi.put<CampusSurveyAskTable>("/survey-ask/", campusSurveyAsk);
  }

  // dont response data
  deleteCampusSurveyAsk(campusSurveyAsk: CampusSurveyAskDelete): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/survey-ask/`, { data: campusSurveyAsk });
  }
}

