import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getHolidays, getMultipleServicesCategory, getMultipleServicesSchedule, getUser, postMultipleServicesReservation } from "../../redux/actions";
import { User } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import useForm from "../../utils/hooks/useForm";

export const ServiceScheduleNewPage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listMultipleServicesCategory } = useSelector((state: RootState) => state.multipleServicesCategory);
  const { listMultipleServicesSchedule = [] } = useSelector((state: RootState) => state.multipleServicesSchedule);
  const { nameMember, numberMember, nameService, dateSchedule, dateScheduleId, members, family, guess, handleOnChangeInput, handleUpdateField, handleUpdateState } = useForm({
    nameMember: '',
    numberMember: '',
    nameService: 0,
    dateScheduleId: '',
    dateSchedule: new Date().toISOString().split('T')[0],
    members: '',
    family: '',
    guess: ''
  });
  const [ multipleServiceId, setMultipleServiceId ] = useState(-1);
  const [ memberUser, setMemberUser ] = useState<User | null>(null);

  useEffect(() => {
    const pullMultipleServices = async () => {
      getMultipleServicesCategory({
        campus_id: user.campus.id,
      })(dispatch);
    }
    pullMultipleServices();
    getHolidays()(dispatch);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // get number of week from dateSchedule
    // 0: monday, 1: tuesday, 2: wednesday, 3: thursday, 4: friday, 5: saturday, 6: sunday
    const daysByNumber: any = {
      0: 'lunes',
      1: 'martes',
      2: 'miercoles',
      3: 'jueves',
      4: 'viernes',
      5: 'sabado',
      6: 'domingo'
    };
    const numberWeek = new Date(dateSchedule).getDay();
    if (multipleServiceId === -1) return;
    getMultipleServicesSchedule({
      day__icontains: daysByNumber[numberWeek],
      multiple_service_id: multipleServiceId,
    })(dispatch);
  }, [dateSchedule, multipleServiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pullMember = async () => {
      if (numberMember.toString().length < 10) return;
      const { payload } = await getUser({
        document_number: numberMember
      })(dispatch);
      if (payload.member.results.length > 0) {
        handleUpdateState({
          nameMember: payload.member.results[0].first_name,
        });
        setMemberUser(payload.member.results[0]);
      } else {
        handleUpdateState({
          nameMember: 'No se encontró el socio.',
        });
        setMemberUser(null);
      }
    }
    pullMember();
  }, [numberMember]); // eslint-disable-line react-hooks/exhaustive-deps

  // Select first service from categorys in the select
  useEffect(() => {
    if (multipleServiceId === -1 && listMultipleServicesCategory &&
      listMultipleServicesCategory.length > 0) {
        let firstCategoryWData = false;
        for (const msCategory of listMultipleServicesCategory) {
          for (const ms of msCategory.multiple_service) {
            if (ms.id) {
              setMultipleServiceId(ms.id);
              handleUpdateField('nameService', ms.id);
              firstCategoryWData = true;
              break;
            }
          }
          if (firstCategoryWData) break;
        }
      }

  }, [listMultipleServicesCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMSSchedule = async (mService: ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(mService.target.value);
    setMultipleServiceId(id);
    handleUpdateState({
      nameService: id,
    });
  }

  const handleSubmitScheduleNew = async (e: FormEvent) => {
    e.preventDefault();
    const [ msScheduleId, schedules ] = dateScheduleId.split('?');
    const [ startDate, endDate ] = schedules.split('-');
    const peopleTotal = parseInt(members) + parseInt(family) + parseInt(guess);

    if (memberUser === null) return toast.error('El socio no existe.');

    const { payload } = await postMultipleServicesReservation({
      status: 'APPROVED',
      member_number: parseInt(members),
      family_number: parseInt(family),
      guest_number: parseInt(guess),
      people_total: peopleTotal,
      start_date: new Date(dateSchedule + 'T' + startDate).toISOString(),
      end_date: new Date(dateSchedule + 'T' + endDate).toISOString(),
      multiple_service_schedule_id: parseInt(msScheduleId),
      user_id: memberUser.id
    })(dispatch);

    if (payload.success) {
      toast.success('Reserva creada con éxito.');
    } else {
      toast.error(payload.error?.message);
    }
  }

  return (
    <div>
      <form className="mx-auto max-w-3xl" onSubmit={handleSubmitScheduleNew}>
        <h2 className="text-gray-500 font-bold">Añadir Reserva</h2>
        <h3 className="text-gray-500 text-sm font-bold mt-4">Fecha y hora</h3>
        <select
          id="nameService"
          name="nameService"
          value={nameService}
          onChange={getMSSchedule}
          className="w-full block bg-gray-50 border-[1px] border-gray-300 py-3 px-2 h-11 mt-2 rounded-lg leading-tight focus:outline-none focus:ring-yellow-500 focus:border-yellow-500"
        >
          {/* Select with optgroup y options */}
          {
            listMultipleServicesCategory && listMultipleServicesCategory.map((service) => (
              <optgroup key={service.id} label={service.name}>
                {
                  service.multiple_service.map((mService: any) => (
                    <option key={mService.id} value={mService.id}>{mService.name}</option>
                  ))
                }
              </optgroup>
            ))
          }
        </select>
        <div className="flex flex-row gap-4 items-center">
          <input
            id="dateSchedule"
            name="dateSchedule"
            type="date"
            min={new Date().toISOString().split('T')[0]}
            value={dateSchedule}
            onChange={handleOnChangeInput}
            required
            className="basis-1/2 appearance-none rounded-lg relative block px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 text-sm"
            placeholder="Elegir fecha"
          />
          <select
            id="dateScheduleId"
            name="dateScheduleId"
            value={dateScheduleId}
            onChange={handleOnChangeInput}
            required
            className="basis-1/2 block bg-gray-50 border-[1px] border-gray-300 py-3 px-2 h-11 mt-2 rounded-lg leading-tight focus:outline-none focus:ring-yellow-500 focus:border-yellow-500"
          >
            <option value="" disabled>{listMultipleServicesSchedule.length > 0 ? 'Seleccione un horario' : 'No hay horarios disponibles'}</option>
            {
              listMultipleServicesSchedule.length > 0 && listMultipleServicesSchedule.map((msSchedule) => (
                <option key={msSchedule.id} value={`${msSchedule.id}?${msSchedule.start_time}-${msSchedule.end_time}`}>{msSchedule.day}: {msSchedule.start_time} - {msSchedule.end_time}</option>
              ))
            }
          </select>
        </div>
        <h3 className="text-gray-500 text-sm font-bold mt-8">Detalles del servicio</h3>
        <input
          id="numberMember"
          name="numberMember"
          type="number"
          value={numberMember}
          onChange={handleOnChangeInput}
          min={0}
          maxLength={10}
          required
          className="w-full appearance-none rounded-lg relative block px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 text-sm"
          placeholder="Número de cédula del socio"
        />
        <input
          id="nameMember"
          name="nameMember"
          type="text"
          value={nameMember}
          onChange={handleOnChangeInput}
          min={0}
          required
          disabled
          className="w-full appearance-none rounded-lg relative block px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 disabled:text-gray-400 disabled:border-gray-200 focus:z-10 text-sm"
          placeholder="Nombre del socio"
        />
        <div className="flex flex-row gap-4 items-center">
          <input
            id="members"
            name="members"
            type="number"
            value={members}
            onChange={handleOnChangeInput}
            min={0}
            required
            className="basis-1/3 appearance-none rounded-lg px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 text-sm"
            placeholder="# Socios"
          />
          <input
            id="family"
            name="family"
            type="number"
            value={family}
            onChange={handleOnChangeInput}
            min={0}
            required
            className="basis-1/3 appearance-none rounded-lg px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 text-sm"
            placeholder="# Dependientes"
          />
          <input
            id="guess"
            name="guess"
            type="number"
            value={guess}
            min={0}
            onChange={handleOnChangeInput}
            required
            className="basis-1/3 appearance-none rounded-lg px-3 py-2 h-11 mt-2 bg-gray-50 text-gray-900 border-[1px] border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 text-sm"
            placeholder="# Invitados"
          />
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 mt-6 border border-transparent text-sm font-bold rounded-md text-black bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          Agregar
        </button>
      </form>
    </div>
  )
}
