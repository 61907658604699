
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { RestaurantFilters, RestaurantGet, RestaurantTable } from "../../interfaces";

export class RestaurantRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getRestaurant(filters?: RestaurantFilters): Promise<AxiosResponse<RestaurantGet>> {
    return this.lmBootApi.get<RestaurantGet>("/restaurants/", { params: filters });
  }

  postRestaurant(restaurant: FormData): Promise<AxiosResponse<RestaurantTable>> {
    return this.lmBootApi.post<RestaurantTable>("/restaurants/", restaurant);
  }

  putRestaurant(restaurant: FormData): Promise<AxiosResponse<RestaurantTable>> {
    return this.lmBootApi.put<RestaurantTable>("/restaurants/", restaurant);
  }

  // dont response data
  deleteRestaurant(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/restaurants/${id}/`);
  }
}

