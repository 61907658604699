import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, TableBody } from "../../components";
import { getListCampus, getListUsersPag, getUserGroup, putUser } from "../../redux/actions";
import { User, UserResponse } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import useForm from "../../utils/hooks/useForm";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { rolsCampusAdmin } from "../../utils/rolsCampusAdmin";
import { generateRandomId } from "../../utils/utils";

export const UsersPage = () => {
  // Fetch data from the server
  const dispatch = useDispatch();
  const { listUsersPag, loading } = useSelector((state: RootState) => state.user);
  const { listUserGroup } = useSelector((state: RootState) => state.userGroup);
  const { campus } = useSelector((state: RootState) => state.register);
  const {
    results: listUser,
    count,
    next,
    previous,
} = listUsersPag as UserResponse;
  // pagination
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize] = useState(10);
  // searching user reactive
  const [search, setSearch] = useState('');
  // modal
  const [isOpen, setIsOpen] = useState(false);
  const {
    id,
    username,
    firstName,
    emergencyNumber,
    campusId,
    groupId,
    isActive,
    handleOnChangeInput,
    handleUpdateState
  } = useForm({
    id: -1,
    username: '',
    firstName: '',
    email: '',
    emergencyNumber: '',
    campusId: '',
    groupId: '',
    isActive: false,
  });


  const fetchData = () => {
    const filters = {
        page: pageCurrent,
        page_size: pageSize,
        first_name__icontains: search,
    }
    getListUsersPag(filters)(dispatch);
  };
  
  useEffect(() => {
    fetchData();
    getUserGroup()(dispatch);
    getListCampus()(dispatch);
  }, [pageCurrent, pageSize, search]); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type UsersType = {
      name: string;
      email: string;
      is_active: User;
      campus: User;
      group: User;
      options: User;
  };

  const { data, columns } = useTableMemo<UsersType>({
    dataMemo: {
        factory: listUser.map((user) => {
            return {
                name: user.first_name,
                email: user.email,
                is_active: user,
                campus: user,
                group: user,
                options: user,
            };
        }),
        deps: [listUser]
    },
    columnsMemo: {
        factory: [
          {
              Header: 'Nombre',
              accessor: 'name', // accessor is the "key" in the data
          },
          {
              Header: 'Correo',
              accessor: 'email',
          },
          {
              Header: 'Activo',
              accessor: 'is_active',
          },
          {
              Header: 'Sucursal',
              accessor: 'campus',
          },
          {
              Header: 'Grupo',
              accessor: 'group',
          },
          {
              Header: 'Opciones',
              accessor: 'options',
          },
      ],
        deps: [],
    }
  });

  const handleChangeActive = async (e: ChangeEvent<HTMLSelectElement>, user: User) => {
    // get value
    const isActive = e.target.value;
    const { payload } = await putUser({
      id: user.id,
      is_active: isActive === "true" ? true : false,
      campus: user.campus.id,
      email: user.email,
      first_name: user.first_name,
      emergency_number: user.emergency_number,
      groups: user.groups.map((group) => group.id),
      username: user.email,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Estado del usuario actualizado con éxito.');
    fetchData();
  }

  const handleUpdateGroup = async (e: ChangeEvent<HTMLSelectElement>, user: User) => {
    const groupId = parseInt(e.target.value);
    const { payload } = await putUser({
      id: user.id,
      is_active: user.is_active,
      campus: user.campus.id,
      email: user.email,
      first_name: user.first_name,
      emergency_number: user.emergency_number,
      groups: [groupId],
      username: user.email,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Grupo del usuario actualizado con éxito.');
    fetchData();
  }

  const handleUpdateCampus = async (e: ChangeEvent<HTMLSelectElement>, user: User) => {
    const campusId = parseInt(e.target.value);
    const { payload } = await putUser({
      id: user.id,
      is_active: user.is_active,
      campus: campusId,
      email: user.email,
      first_name: user.first_name,
      emergency_number: user.emergency_number,
      groups: user.groups.map((group) => group.id),
      username: user.email,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Campus del usuario actualizado con éxito.');
    fetchData();
  }

  const handleOpenEdit = async (user: User) => {
    handleUpdateState({
      id: user.id,
      firstName: user.first_name,
      username: user.username,
      email: user.email,
      emergencyNumber: user.emergency_number,
      campusId: user.campus.id,
      groupId: user.groups[0].id,
      isActive: user.is_active,
    });
    setIsOpen(true);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { payload } = await putUser({
      id: id,
      is_active: isActive,
      campus: parseInt(campusId),
      email: username,
      first_name: firstName,
      emergency_number: emergencyNumber,
      groups: [ parseInt(groupId)],
      username: username,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    setIsOpen(false);
    toast.success('Usuario actualizado con éxito.');
    fetchData();
  }

  const listActiveCampus: { value: number; label: string; }[] = [];
  if (listUserGroup) {
    for (const rol of listUserGroup) {
      if (!rolsCampusAdmin.some(rolCampus => (rolCampus.value === rol.name))) continue;
      listActiveCampus.push({
        value: rol.id,
        label: rolsCampusAdmin.find(rolCampus => rolCampus.value === rol.name)!.label,
      });
    }
  }


  return (
    <>
      <div className="flex flex-row justify-between mb-6">
        <h1 className="font-bold text-2xl flex flex-row justify-between">
          Lista de usuarios
        </h1>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row items-center relative">
            <input
              type="text"
              className="input-yellow"
              placeholder="Buscar usuario"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <div
              className="absolute z-10 right-1.5 text-gray-500 hover:text-gray-600 active:text-gray-800 cursor-pointer"
              onClick={() => setSearch('')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <TableBody
            columns={columns} data={data} pageSize={pageSize} loading={loading}
            renderRows={row => (
                // Apply the row props
                <tr
                    className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                    {...row.getRowProps()}
                >
                    {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                            if (cell.column.Header === 'Activo') {
                                const user = (cell.render('Cell') as any).props.value;
                                return (
                                    <td
                                        className="flex flex-row items-center gap-4 px-6 py-4 first-letter:capitalize"
                                        {...cell.getCellProps()}
                                    >
                                        <select
                                          id="isActive"
                                          name="isActive"
                                          value={user.is_active}
                                          onChange={e => handleChangeActive(e, user)}
                                          required
                                          className="select-yellow"
                                        >
                                          <option value="true">
                                            Sí
                                          </option>
                                          <option value="false">
                                            No
                                          </option>
                                        </select>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Sucursal') {
                              const user = (cell.render('Cell') as any).props.value;
                                // two buttons with taildwind
                                return (
                                    <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                        <select
                                            id="newState"
                                            name="newState"
                                            value={user.campus.id}
                                            onChange={(e) => handleUpdateCampus(e, user)}
                                            className="select-yellow"
                                        >
                                            {
                                              campus && campus.map((c) => (
                                                    <option value={c.id}>{c.name}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Grupo') {
                              const user = (cell.render('Cell') as any).props.value;
                                // two buttons with taildwind
                                return (
                                    <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                        <select
                                            id="newState"
                                            name="newState"
                                            value={user.groups[0].id}
                                            onChange={(e) => handleUpdateGroup(e, user)}
                                            className="select-yellow"
                                        >
                                            {
                                              listActiveCampus && listActiveCampus.map((group, i) => (
                                                <option key={generateRandomId(`${i}_group`)} value={group.value}>
                                                  {group.label}
                                                </option>
                                              ))
                                            }
                                        </select>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Opciones') {
                              const user = (cell.render('Cell') as any).props.value;
                                // two buttons with taildwind
                                return (
                                    <td className="px-6 py-4 text-center flex flex-row justify-center" {...cell.getCellProps()}>
                                        <button className="btn-yellow p-2" onClick={() => handleOpenEdit(user)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                          </svg>
                                        </button>
                                    </td>
                                );
                            }

                            // Apply the cell props
                            return (
                                <td
                                    className="whitespace-nowrap px-6 py-3"
                                    {...cell.getCellProps()}
                                >
                                    {
                                        // Render the cell contents
                                        cell.render('Cell')
                                    }
                                </td>
                            );
                        })
                    }
                </tr>
            )}
            // For footer
            count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
            // Blank message
            blankMessage="No hay usuarios disponibles"
        />
    </div>
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Editar usuario"
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4 mt-6">
            <div className="flex flex-col gap-1">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Usuario</label>
              <input
                  id="username"
                  name="username"
                  type="email"
                  maxLength={150}
                  value={username}
                  onChange={handleOnChangeInput}
                  required
                  className="input-yellow w-full"
                  placeholder="Correo"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="isActive" className="block text-sm font-medium text-gray-700">Está activo</label>
              {/* radio buttons for isActive */}
              <div className="flex flex-row gap-4">
                <div className="flex flex-row items-center gap-2">
                  <input
                    id="isActive"
                    name="isActive"
                    type="radio"
                    value="true"
                    checked={isActive}
                    onChange={handleOnChangeInput}
                    required
                    className="checked:bg-yellow-500 checked:ring-yellow-500 focus:ring-yellow-500"
                  />
                  <label htmlFor="isActive" className="block text-sm font-medium text-gray-700">Sí</label>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <input
                    id="isActive"
                    name="isActive"
                    type="radio"
                    value="false"
                    checked={!isActive}
                    onChange={handleOnChangeInput}
                    required
                    className="checked:bg-yellow-500 checked:ring-yellow-500 focus:ring-yellow-500"
                  />
                  <label htmlFor="isActive" className="block text-sm font-medium text-gray-700">No</label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="emergencyNumber" className="block text-sm font-medium text-gray-700">Número de emergencia</label>
              <input
                  id="emergencyNumber"
                  name="emergencyNumber"
                  type="text"
                  maxLength={150}
                  value={emergencyNumber}
                  onChange={handleOnChangeInput}
                  required
                  className="input-yellow w-full"
                  placeholder="Número de emergencia"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="groupId" className="block text-sm font-medium text-gray-700">Grupo</label>
              <select
                  id="groupId"
                  name="groupId"
                  value={groupId}
                  onChange={handleOnChangeInput}
                  className="input-yellow w-full"
              >
                  {
                    listActiveCampus && listActiveCampus.map((group, i) => (
                      <option key={generateRandomId(`${i}_group`)} value={group.value}>
                        {group.label}
                      </option>
                    ))
                  }
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="campusId" className="block text-sm font-medium text-gray-700">Campus</label>
              <select
                  id="campusId"
                  name="campusId"
                  value={campusId}
                  onChange={handleOnChangeInput}
                  className="input-yellow w-full"
              >
                  {
                    campus && campus.map((c) => (
                          <option value={c.id}>{c.name}</option>
                      ))
                  }
              </select>
            </div>
            <div className="flex justify-end">
              <button
                  type="submit"
                  className="btn-yellow w-full"
              >
                  <h1>Actualizar</h1>
              </button>
            </div>
        </div>
    </form>
    </Modal>
    </>
  )
}
