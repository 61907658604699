import { AuthTypes } from "../types/auth.types";
import { AuthProcess } from "../api/process/auth.process";
import * as localStorage from "../../utils/localStorage";
import { GenericAction } from "../interfaces";

export const loginUser =
    (username: string, password: string) => async (dispatch: (state: GenericAction) => GenericAction) => {
        dispatch({
            type: AuthTypes.LOGIN_USER_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const authProcess = new AuthProcess();
            const response = await authProcess.loginUserWithEmailAndPassword(username, password);
            localStorage.saveObject("access_token", response.access);
            localStorage.saveObject("refresh_token", response.refresh);
            return dispatch({
                type: AuthTypes.LOGIN_USER_SUCCESS,
                payload: {
                    isLoggedIn: true,
                    loading: false,
                    success: true,
                },
            });
        } catch (error: any) {
            localStorage.clear();
            const { message, detail } = error.response.data || {};
            return dispatch({
                type: AuthTypes.LOGIN_USER_FAILURE,
                payload: {
                    error: {
                        message:
                        message || detail || 'Tuvimos un problema al cargar sus datos. Por favor, inténtelo más tarde.',
                        code: error?.code,
                    },
                    loading: false,
                    success: false,
                },
            });
        }
};
export const getUserAuth = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: AuthTypes.GET_USER_AUTH_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const token = localStorage.loadObject("access_token");
        if (token) {
            return dispatch({
                type: AuthTypes.GET_USER_AUTH_SUCCESS,
                payload: {
                    isLoggedIn: true,
                    loading: false,
                    success: true,
                },
            });
        } else {
            return dispatch({
                type: AuthTypes.GET_USER_AUTH_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    } catch (error: any) {
        localStorage.clear();
        const { message, detail } = error.response.data || {};
        return dispatch({
            type: AuthTypes.GET_USER_AUTH_FAILURE,
            payload: {
                error: {
                    message:
                    message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
                    code: error?.code,
                },
                loading: false,
                success: false,
            },
        });
    }
};
export const logoutUser = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: AuthTypes.LOGOUT_USER_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        localStorage.clear();
        return dispatch({
            type: AuthTypes.LOGOUT_USER_SUCCESS,
            payload: {
                isLoggedIn: false,
                loading: false,
                success: true,
            },
        });
    } catch (error: any) {
        const { message, detail } = error.response.data || {};
        return dispatch({
            type: AuthTypes.LOGOUT_USER_FAILURE,
            payload: {
                error: {
                    message:
                        message || detail || 'Houston, tenemos un problema. Por favor, inténtelo más tarde.',
                    code: error?.code,
                },
                loading: false,
                success: false,
            },
        });
    }
};
export const passwordResetConfirm = (password: string, token: string) => async (dispatch: (state: any) => any) => {
    dispatch({
        type: AuthTypes.PASSWORDRESETCONFIRM_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const authProcess = new AuthProcess();
        const response = await authProcess.passwordResetConfirm(token, password);
        return dispatch({
            type: AuthTypes.PASSWORDRESETCONFIRM_SUCCESS,
            payload: {
                response: response,
                loading: false,
                successChangePassword: true,
            },
        });
    } catch (error: any) {
        localStorage.clear();
        const { message, detail, password } = error.response.data || {};
        return dispatch({
            type: AuthTypes.PASSWORDRESETCONFIRM_FAILURE,
            payload: {
                error: {
                    message:
                    password || message || detail || 'Tuvimos un problema al cargar sus datos. Por favor, inténtelo más tarde.',
                    code: error?.code,
                },
                loading: false,
                success: false,
            },
        });
    }
};
export const passwordReset = (email: string) => async (dispatch: (state: any) => any) => {
    dispatch({
        type: AuthTypes.PASSWORDRESET_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const authProcess = new AuthProcess();
        const response = await authProcess.passwordReset(email);
        return dispatch({
            type: AuthTypes.PASSWORDRESET_SUCCESS,
            payload: {
                response: response,
                loading: false,
                successResetPassword: true,
            },
        });
    } catch (error: any) {
        localStorage.clear();
        const { message, detail, password, email } = error.response.data || {};
        return dispatch({
            type: AuthTypes.PASSWORDRESET_FAILURE,
            payload: {
                error: {
                    message:
                    email || password || message || detail || 'Tuvimos un problema al cargar sus datos. Por favor, inténtelo más tarde.',
                    code: error?.code,
                },
                loading: false,
                success: false,
            },
        });
    }
};