
import { EventReservationsProcess } from "../api";
import { EventReservationsAction, EventReservationsFilters, EventReservationsPost, EventReservationsPut,  } from "../interfaces";
import { EventReservationsTypes } from "../types";

export const getEventReservationsPags = (filters?: EventReservationsFilters) => async (dispatch: (state: EventReservationsAction) => EventReservationsAction) => {
  dispatch({
    type: EventReservationsTypes.GET_EVENTRESERVATIONSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventReservationsProcess = new EventReservationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventReservationsPag = await eventReservationsProcess.getEventReservations(filters);
    return dispatch({
      type: EventReservationsTypes.GET_EVENTRESERVATIONSPAG_SUCCESS,
      payload: {
        pagEventReservations: eventReservationsPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventReservationsTypes.GET_EVENTRESERVATIONSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getEventReservations = (filters?: EventReservationsFilters) => async (dispatch: (state: EventReservationsAction) => EventReservationsAction) => {
  dispatch({
    type: EventReservationsTypes.GET_EVENTRESERVATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventReservationsProcess = new EventReservationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventReservationsPag = await eventReservationsProcess.getEventReservations({
      ...filters,
      data_all: 'YES',
    });
    const eventReservations = eventReservationsPag.results;
    return dispatch({
      type: EventReservationsTypes.GET_EVENTRESERVATIONS_SUCCESS,
      payload: {
        listEventReservations: eventReservations,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventReservationsTypes.GET_EVENTRESERVATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postEventReservations = (eventReservations: EventReservationsPost) => async (dispatch: (state: EventReservationsAction) => EventReservationsAction) => {
  dispatch({
    type: EventReservationsTypes.POST_EVENTRESERVATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventReservationsProcess = new EventReservationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventReservationsTable = await eventReservationsProcess.postEventReservations(eventReservations);
    return dispatch({
      type: EventReservationsTypes.POST_EVENTRESERVATIONS_SUCCESS,
      payload: {
        eventReservations: eventReservationsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventReservationsTypes.POST_EVENTRESERVATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putEventReservations = (eventReservations: EventReservationsPut) => async (dispatch: (state: EventReservationsAction) => EventReservationsAction) => {
  dispatch({
    type: EventReservationsTypes.PUT_EVENTRESERVATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventReservationsProcess = new EventReservationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventReservationsTable = await eventReservationsProcess.putEventReservations(eventReservations);
    return dispatch({
      type: EventReservationsTypes.PUT_EVENTRESERVATIONS_SUCCESS,
      payload: {
        eventReservations: eventReservationsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventReservationsTypes.PUT_EVENTRESERVATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteEventReservations = (id: number) => async (dispatch: (state: EventReservationsAction) => EventReservationsAction) => {
  dispatch({
    type: EventReservationsTypes.DELETE_EVENTRESERVATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventReservationsProcess = new EventReservationsProcess();
    // dont'n import the result of the process, only that dont't have error
    await eventReservationsProcess.deleteEventReservations(id);
    return dispatch({
      type: EventReservationsTypes.DELETE_EVENTRESERVATIONS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventReservationsTypes.DELETE_EVENTRESERVATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

