import { HotelWireTransfersProcess } from "../api";
import { HotelWireTransfersAction, HotelWireTransfersFilters } from "../interfaces";
import { HotelWireTransfersTypes } from "../types";

// apply the reducer
export const getHotelWireTransfersPags = (filters?: HotelWireTransfersFilters) => async (dispatch: (state: HotelWireTransfersAction) => HotelWireTransfersAction) => {
  dispatch({
    type: HotelWireTransfersTypes.GET_HOTELWIRETRANSFERSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelWireTransfersProcess = new HotelWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelWireTransfersPag = await hotelWireTransfersProcess.getHotelWireTransfers(filters);
    return dispatch({
      type: HotelWireTransfersTypes.GET_HOTELWIRETRANSFERSPAG_SUCCESS,
      payload: {
        pagHotelWireTransfers: hotelWireTransfersPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelWireTransfersTypes.GET_HOTELWIRETRANSFERSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotelWireTransfers = (filters?: HotelWireTransfersFilters) => async (dispatch: (state: HotelWireTransfersAction) => HotelWireTransfersAction) => {
  dispatch({
    type: HotelWireTransfersTypes.GET_HOTELWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelWireTransfersProcess = new HotelWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelWireTransfersPag = await hotelWireTransfersProcess.getHotelWireTransfers({
      ...filters,
      data_all: 'YES',
    });
    const hotelWireTransfers = hotelWireTransfersPag.results;
    return dispatch({
      type: HotelWireTransfersTypes.GET_HOTELWIRETRANSFERS_SUCCESS,
      payload: {
        listHotelWireTransfers: hotelWireTransfers,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelWireTransfersTypes.GET_HOTELWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotelWireTransfers = (hotelWireTransfers: FormData) => async (dispatch: (state: HotelWireTransfersAction) => HotelWireTransfersAction) => {
  dispatch({
    type: HotelWireTransfersTypes.POST_HOTELWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelWireTransfersProcess = new HotelWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelWireTransfersProcess.postHotelWireTransfers(hotelWireTransfers);
    return dispatch({
      type: HotelWireTransfersTypes.POST_HOTELWIRETRANSFERS_SUCCESS,
      payload: {
        hotelWireTransfers: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelWireTransfersTypes.POST_HOTELWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotelWireTransfers = (hotelWireTransfers: FormData) => async (dispatch: (state: HotelWireTransfersAction) => HotelWireTransfersAction) => {
  dispatch({
    type: HotelWireTransfersTypes.PUT_HOTELWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelWireTransfersProcess = new HotelWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelWireTransfersProcess.putHotelWireTransfers(hotelWireTransfers);
    return dispatch({
      type: HotelWireTransfersTypes.PUT_HOTELWIRETRANSFERS_SUCCESS,
      payload: {
        hotelWireTransfers: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelWireTransfersTypes.PUT_HOTELWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotelWireTransfers = (id: number) => async (dispatch: (state: HotelWireTransfersAction) => HotelWireTransfersAction) => {
  dispatch({
    type: HotelWireTransfersTypes.DELETE_HOTELWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelWireTransfersProcess = new HotelWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelWireTransfersProcess.deleteHotelWireTransfers(id);
    return dispatch({
      type: HotelWireTransfersTypes.DELETE_HOTELWIRETRANSFERS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelWireTransfersTypes.DELETE_HOTELWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};