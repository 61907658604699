import { useState } from "react";
import { Modal } from ".";

interface PDF {
  url: string;
  title: string;
  file?: File;
}

interface PDFViewerProps {
  pdf?: PDF;
  onRemove?: (...args: any) => void;
  blankMessage?: string;
}

export const PDFViewer = ({ pdf, onRemove, blankMessage }: PDFViewerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {pdf && onRemove ? (
        <>
          <div className="relative rounded-xl">
            <div className="bg-gray-100 pb-2 py-4 pl-4 pr-6 rounded-t-xl truncate">
              <span>{pdf.title}</span>
            </div>
            <div className="bg-yellow-500 py-1 px-4 rounded-b-xl font-bold">
              PDF
            </div>
            <div
              onClick={() => setIsOpen(true)}
              className="absolute inset-0 hover:bg-black/20 active:bg-black/40 rounded-xl cursor-pointer"
            />
            <span
              onClick={() => onRemove()}
              className="absolute top-1.5 right-1.5 p-1 rounded-lg hover:bg-gray-400/50 active:bg-gray-400"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </span>
          </div>
          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="max-w-5xl"
            title={pdf.title || "PDF"}
          >
            <iframe title={pdf.title || "PDF"} src={pdf.url} className="w-full h-[30rem] mt-4" />
          </Modal>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center text-gray-500">
            {
              blankMessage ? blankMessage : "No hay PDF"
            }
          </div>
      )}
    </>
  )
}
