
import { RestaurantCategoriesProcess } from "../api";
import { RestaurantCategoriesAction, RestaurantCategoriesFilters, RestaurantCategoriesPost, RestaurantCategoriesPut,  } from "../interfaces";
import { RestaurantCategoriesTypes } from "../types";

export const getRestaurantCategoriesPags = (filters?: RestaurantCategoriesFilters) => async (dispatch: (state: RestaurantCategoriesAction) => RestaurantCategoriesAction) => {
  dispatch({
    type: RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIESPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantCategoriesProcess = new RestaurantCategoriesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantCategoriesPag = await restaurantCategoriesProcess.getRestaurantCategories(filters);
    return dispatch({
      type: RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIESPAG_SUCCESS,
      payload: {
        pagRestaurantCategories: restaurantCategoriesPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIESPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getRestaurantCategories = (filters?: RestaurantCategoriesFilters) => async (dispatch: (state: RestaurantCategoriesAction) => RestaurantCategoriesAction) => {
  dispatch({
    type: RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantCategoriesProcess = new RestaurantCategoriesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantCategoriesPag = await restaurantCategoriesProcess.getRestaurantCategories({
      ...filters,
      data_all: 'YES',
    });
    const restaurantCategories = restaurantCategoriesPag.results;
    return dispatch({
      type: RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIES_SUCCESS,
      payload: {
        listRestaurantCategories: restaurantCategories,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantCategoriesTypes.GET_RESTAURANTCATEGORIES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postRestaurantCategories = (restaurantCategories: RestaurantCategoriesPost) => async (dispatch: (state: RestaurantCategoriesAction) => RestaurantCategoriesAction) => {
  dispatch({
    type: RestaurantCategoriesTypes.POST_RESTAURANTCATEGORIES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantCategoriesProcess = new RestaurantCategoriesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantCategoriesTable = await restaurantCategoriesProcess.postRestaurantCategories(restaurantCategories);
    return dispatch({
      type: RestaurantCategoriesTypes.POST_RESTAURANTCATEGORIES_SUCCESS,
      payload: {
        restaurantCategories: restaurantCategoriesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantCategoriesTypes.POST_RESTAURANTCATEGORIES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putRestaurantCategories = (restaurantCategories: RestaurantCategoriesPut) => async (dispatch: (state: RestaurantCategoriesAction) => RestaurantCategoriesAction) => {
  dispatch({
    type: RestaurantCategoriesTypes.PUT_RESTAURANTCATEGORIES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantCategoriesProcess = new RestaurantCategoriesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantCategoriesTable = await restaurantCategoriesProcess.putRestaurantCategories(restaurantCategories);
    return dispatch({
      type: RestaurantCategoriesTypes.PUT_RESTAURANTCATEGORIES_SUCCESS,
      payload: {
        restaurantCategories: restaurantCategoriesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantCategoriesTypes.PUT_RESTAURANTCATEGORIES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteRestaurantCategories = (id: number) => async (dispatch: (state: RestaurantCategoriesAction) => RestaurantCategoriesAction) => {
  dispatch({
    type: RestaurantCategoriesTypes.DELETE_RESTAURANTCATEGORIES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantCategoriesProcess = new RestaurantCategoriesProcess();
    // dont'n import the result of the process, only that dont't have error
    await restaurantCategoriesProcess.deleteRestaurantCategories(id);
    return dispatch({
      type: RestaurantCategoriesTypes.DELETE_RESTAURANTCATEGORIES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantCategoriesTypes.DELETE_RESTAURANTCATEGORIES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

