import { UserRepository } from "../repositories";
import { GetUserById, User, UserFilters, UserImageType, UserPut, UserResponse } from "../../interfaces";

export class UserProcess {
  private userRepository: UserRepository;

  constructor() {
    this.userRepository = new UserRepository();
  }

  async getCurrentUser(): Promise<User> {
    const response = await this.userRepository.getCurrentUser();
    return response.data;
  }

  async getListUsers(filters?: UserFilters): Promise<UserResponse> {
    const response = await this.userRepository.getListUsers(filters);
    return response.data;
  }

  async getUserById(id: number): Promise<GetUserById> {
    const response = await this.userRepository.getUserById(id);
    return response.data;
  }

  async putUser(user: UserPut): Promise<GetUserById> {
    const response = await this.userRepository.putUser(user);
    return response.data;
  }

  async putUserImage(id: number, userImage: FormData): Promise<UserImageType> {
    const response = await this.userRepository.pUtUserImage(id, userImage);
    return response.data;
  }
}
