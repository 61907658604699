
import { EventReservationsAction, EventReservationsPayload } from "../interfaces";
import { EventReservationsTypes } from "../types";

const initialState: EventReservationsPayload = {
  loading: false,
  success: false,
  error: null,
  eventReservations: null,
  listEventReservations: [],
  pagEventReservations: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const eventReservations = (state = initialState, action: EventReservationsAction): EventReservationsPayload => {
  const { loading, success, error, eventReservations, listEventReservations, pagEventReservations } = action.payload || {};
  switch (action.type) {
    case EventReservationsTypes.GET_EVENTRESERVATIONSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventReservationsTypes.GET_EVENTRESERVATIONSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventReservationsTypes.GET_EVENTRESERVATIONSPAG_SUCCESS:
      return {
        ...state,
        pagEventReservations,
        loading,
        success,
      };
    case EventReservationsTypes.GET_EVENTRESERVATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventReservationsTypes.GET_EVENTRESERVATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventReservationsTypes.GET_EVENTRESERVATIONS_SUCCESS:
      return {
        ...state,
        listEventReservations,
        loading,
        success,
      };
    case EventReservationsTypes.GETONLY_EVENTRESERVATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventReservationsTypes.GETONLY_EVENTRESERVATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventReservationsTypes.GETONLY_EVENTRESERVATIONS_SUCCESS:
      return {
        ...state,
        eventReservations,
        loading,
        success,
      };
    case EventReservationsTypes.POST_EVENTRESERVATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventReservationsTypes.POST_EVENTRESERVATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventReservationsTypes.POST_EVENTRESERVATIONS_SUCCESS:
      return {
        ...state,
        eventReservations,
        loading,
        success,
      };
    case EventReservationsTypes.PUT_EVENTRESERVATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventReservationsTypes.PUT_EVENTRESERVATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventReservationsTypes.PUT_EVENTRESERVATIONS_SUCCESS:
      return {
        ...state,
        eventReservations,
        loading,
        success,
      };
    case EventReservationsTypes.DELETE_EVENTRESERVATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventReservationsTypes.DELETE_EVENTRESERVATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventReservationsTypes.DELETE_EVENTRESERVATIONS_SUCCESS:
      return {
        ...state,
        eventReservations,
        loading,
        success,
      };
    default:
      return state;
  }
};

