
import { ParametersAction, ParametersPayload } from "../interfaces";
import { ParametersTypes } from "../types";

const initialState: ParametersPayload = {
  loading: false,
  success: false,
  error: null,
  parameters: null,
  listParameters: [],
  pagParameters: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const parameters = (state = initialState, action: ParametersAction): ParametersPayload => {
  const { loading, success, error, parameters, listParameters, pagParameters } = action.payload || {};
  switch (action.type) {
    case ParametersTypes.GET_PARAMETERSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case ParametersTypes.GET_PARAMETERSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case ParametersTypes.GET_PARAMETERSPAG_SUCCESS:
      return {
        ...state,
        pagParameters,
        loading,
        success,
      };
    case ParametersTypes.GET_PARAMETERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case ParametersTypes.GET_PARAMETERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case ParametersTypes.GET_PARAMETERS_SUCCESS:
      return {
        ...state,
        listParameters,
        loading,
        success,
      };
    case ParametersTypes.GETONLY_PARAMETERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case ParametersTypes.GETONLY_PARAMETERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case ParametersTypes.GETONLY_PARAMETERS_SUCCESS:
      return {
        ...state,
        parameters,
        loading,
        success,
      };
    case ParametersTypes.POST_PARAMETERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case ParametersTypes.POST_PARAMETERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case ParametersTypes.POST_PARAMETERS_SUCCESS:
      return {
        ...state,
        parameters,
        loading,
        success,
      };
    case ParametersTypes.PUT_PARAMETERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case ParametersTypes.PUT_PARAMETERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case ParametersTypes.PUT_PARAMETERS_SUCCESS:
      return {
        ...state,
        parameters,
        loading,
        success,
      };
    case ParametersTypes.DELETE_PARAMETERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case ParametersTypes.DELETE_PARAMETERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case ParametersTypes.DELETE_PARAMETERS_SUCCESS:
      return {
        ...state,
        parameters,
        loading,
        success,
      };
    default:
      return state;
  }
};

