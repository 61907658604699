import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHotel, getHotelBedroom, getHotelReservationPags, putHotelReservation } from '../../redux/actions';
import { HotelReservationFilters, HotelReservationGet, HotelReservationTable, User } from '../../redux/interfaces';
import { RootState } from '../../redux/store';
import useForm from '../../utils/hooks/useForm';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { hotelPaymentMethods } from '../../utils/hotelPaymentMethods';
import { TableBody } from '../../components';
import { useTableMemo } from '../../utils/hooks/useTableMemo';
import { InputDateRange } from '../../components/InputDateRange';

export const HotelReservePendingPage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listHotel } = useSelector((state: RootState) => state.hotel);
  const { listHotelBedroom } = useSelector((state: RootState) => state.hotelBedroom);
  const { pagHotelReservation, loading } = useSelector((state: RootState) => state.hotelReservation);
  const { results: hotelReservations, count, next, previous } = pagHotelReservation as HotelReservationGet;
  // pagination
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize] = useState(6);
  // const [data, setData] = useState(hotelReservations);
  // const [columns, setColumns] = useState([]);
  // new Date(), new Date() + one day
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(new Date().getTime() - 60 * 60 * 24 * 1000 * 7),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;

  const { hotelBedroomId, handleOnChangeInput } = useForm({
    hotelBedroomId: '',
  });

  const pullHotelReservations = () => {
    const filters: HotelReservationFilters = {
      page: pageCurrent,
      page_size: pageSize,
      hotel_bedroom_id: parseInt(hotelBedroomId),
      status: 'PENDING',
      start_date__gte: '',
      start_date__lte: '',
      hotel_bedroom__hotel__campus_id: user.campus.id,
    };
    if (startDate && endDate) {
      filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
      filters['start_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
    }
    getHotelReservationPags(filters)(dispatch);
  };

  useEffect(() => {
    pullHotelReservations();
  }, [pageCurrent, pageSize, hotelBedroomId, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadHotelData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadHotelData = async () => {
    getHotel({
      campus_id: user?.campus.id,
    })(dispatch);
  };
  useEffect(() => {
    if (!listHotel || listHotel.length === 0) return;
    getHotelBedroom({
      hotel_id: listHotel[0].id,
    })(dispatch);
  }, [listHotel]); // eslint-disable-line react-hooks/exhaustive-deps

  // Code for table
  type HotelReservePendingType = {
    start_date: string;
    first_name: string;
    administrative_name: string;
    total_cost: string;
    payment_method: string;
    people_total: string;
    end_date: string;
    state: HotelReservationTable;
    status: string;
    description: string;
    voucher: any;
  };

  const { data, columns } = useTableMemo<HotelReservePendingType>({
    dataMemo: {
      factory: hotelReservations.map((reservation) => {
        return {
          start_date: reservation.start_date,
          first_name: reservation.user.first_name,
          administrative_name: reservation.hotel_bedroom.administrative_name,
          total_cost: `$${reservation.total_cost}`,
          payment_method: hotelPaymentMethods[reservation.payment_method],
          people_total: `${reservation.member_number > 0 ? reservation.member_number + ' socios, ' : ''} ${
            reservation.family_number > 0 ? reservation.family_number + ' dependientes, ' : ''
          } ${reservation.guest_number > 0 ? reservation.guest_number + ' invitados' : ''}`,
          end_date: reservation.end_date,
          state: {
            ...reservation,
          },
          status: reservation.status,
          description: reservation.description,
          voucher: reservation.wire_transfers.length > 0 ? reservation.wire_transfers[0].receipt : '',
        };
      }),
      deps: [hotelReservations],
    },
    columnsMemo: {
      factory: [
        {
          Header: 'Entrada',
          accessor: 'start_date', // accessor is the "key" in the data
        },
        {
          Header: 'Cliente',
          accessor: 'first_name',
        },
        {
          Header: 'Nombre Administrativo',
          accessor: 'administrative_name',
        },
        {
          Header: 'Ganancia',
          accessor: 'total_cost',
        },
        {
          Header: 'Comprobante',
          accessor: 'voucher',
        },
        {
          Header: 'Forma de Pago',
          accessor: 'payment_method',
        },
        {
          Header: 'Ocupantes',
          accessor: 'people_total',
        },
        {
          Header: 'Salida',
          accessor: 'end_date',
        },
        {
          Header: 'Estado',
          accessor: 'state',
        },
      ],
      deps: [],
    },
  });

  const handleReservationByConfirmed = async (reservation: HotelReservationTable) => {
    const { payload } = await putHotelReservation({
      ...reservation,
      user_id: reservation.user.id,
      hotel_bedroom_id: reservation.hotel_bedroom.id,
      status: 'BY_CONFIRMED',
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Estado de la Reserva actualizada con éxito.');
    pullHotelReservations();
  };

  const handleReservationCanceled = async (reservation: HotelReservationTable) => {
    const { payload } = await putHotelReservation({
      ...reservation,
      user_id: reservation.user.id,
      hotel_bedroom_id: reservation.hotel_bedroom.id,
      status: 'CANCELED',
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Estado de la Reserva actualizada con éxito.');
    pullHotelReservations();
  };

  return (
    <>
      <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
        <select
          id="hotelBedroomId"
          name="hotelBedroomId"
          value={hotelBedroomId}
          onChange={handleOnChangeInput}
          required
          className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
        >
          <option value="">Habitaciones (Todas)</option>
          {listHotelBedroom &&
            listHotelBedroom.map((bedroom) => (
              <option key={`bedroom-${bedroom.id}`} value={bedroom.id}>
                {bedroom.name}
              </option>
            ))}
        </select>
        <div className="flex items-center">
          <InputDateRange dateRange={dateRange} setDateRange={setDateRange} className="h-11 font-bold" />
        </div>
      </div>
      <div className="overflow-hidden sm:rounded-lg">
        <TableBody
          columns={columns}
          data={data}
          pageSize={pageSize}
          loading={loading}
          renderRows={(row) => (
            // Apply the row props
            <tr className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50" {...row.getRowProps()}>
              {
                // Loop over the rows cells
                row.cells.map((cell) => {
                  if (cell.column.Header === 'Entrada') {
                    const dateCell = new Date((cell.render('Cell') as any).props.value).toLocaleDateString('es-ES', {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                    });
                    return (
                      <td className="flex flex-row items-center gap-4 px-6 py-4" {...cell.getCellProps()}>
                        <span className="first-letter:capitalize">{dateCell}</span>
                      </td>
                    );
                  }

                  if (cell.column.Header === 'Salida') {
                    const dateCell = new Date((cell.render('Cell') as any).props.value).toLocaleDateString('en-GB', {
                      hour: 'numeric',
                      minute: 'numeric',
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                    });
                    return (
                      <td className="flex flex-row items-center gap-2 px-6 py-4" {...cell.getCellProps()}>
                        {dateCell}
                      </td>
                    );
                  }

                  if (cell.column.Header === 'Estado') {
                    const reservation: HotelReservationTable = (cell.render('Cell') as any).props.value;
                    // two buttons with taildwind
                    return (
                      <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                        <div className="flex flex-row justify-start gap-2">
                          <button
                            onClick={() => handleReservationByConfirmed(reservation)}
                            className="rounded-full bg-green-500 p-1 font-bold text-white hover:bg-green-400 active:bg-green-300 print:hidden"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleReservationCanceled(reservation)}
                            className="rounded-full bg-red-500 p-1 font-bold text-white hover:bg-red-400 active:bg-red-300 print:hidden"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    );
                  }

                  if (cell.column.Header === 'Nota') {
                    return (
                      <td className="px-6 py-4 text-left text-black" {...cell.getCellProps()}>
                        <button
                          onClick={() => {
                            Swal.fire({
                              title: 'Nota',
                              text: (cell.render('Cell') as any).props.value,
                            });
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </td>
                    );
                  }

                  if (cell.column.Header === 'Comprobante') {
                    return (
                      <td className="px-6 py-4 text-left text-black" {...cell.getCellProps()}>
                        <button
                          className="underline-yellow-800 underline"
                          onClick={() => {
                            Swal.fire({
                              imageUrl: (cell.render('Cell') as any).props.value,
                              imageWidth: 400,
                              imageHeight: 400,
                              imageAlt: 'Custom image',
                            });
                          }}
                        >
                          {(cell.render('Cell') as any).props.value === '' ? '' : 'Ver comprobante'}
                        </button>
                      </td>
                    );
                  }

                  // Apply the cell props
                  return (
                    <td className="whitespace-nowrap px-6 py-3" {...cell.getCellProps()}>
                      {
                        // Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  );
                })
              }
            </tr>
          )}
          // For footer
          count={count}
          next={next}
          pageCurrent={pageCurrent}
          previous={previous}
          setPageCurrent={setPageCurrent}
        />
      </div>
    </>
  );
};
