import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { MultipleServicesCategoryFilters, MultipleServicesCategoryGet, MultipleServicesCategoryPost, MultipleServicesCategoryPut, MultipleServicesCategoryTable } from "../../interfaces";

export class MultipleServicesCategoryRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getMultipleServicesCategory(filters?: MultipleServicesCategoryFilters): Promise<AxiosResponse<MultipleServicesCategoryGet>> {
    return this.lmBootApi.get<MultipleServicesCategoryGet>("/multiple-service-categories/", { params: filters });
  }

  postMultipleServicesCategory(msCategory: MultipleServicesCategoryPost): Promise<AxiosResponse<MultipleServicesCategoryTable>> {
    return this.lmBootApi.post<MultipleServicesCategoryTable>("/multiple-service-categories/", msCategory);
  }

  putMultipleServicesCategory(msCategory: MultipleServicesCategoryPut): Promise<AxiosResponse<MultipleServicesCategoryTable>> {
    return this.lmBootApi.put<MultipleServicesCategoryTable>("/multiple-service-categories/", msCategory);
  }

  // dont response data
  deleteMultipleServicesCategory(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/multiple-service-categories/${id}/`);
  }
}
