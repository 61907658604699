import { AnyAction } from "redux";
import { AuthStore } from "../interfaces";
import { AuthTypes } from "../types/auth.types";

const initialState: AuthStore = {
    user: null,
    isLoggedIn: false,
    loading: false,
    success: true,
    successChangePassword: false,
    successResetPassword: false,
    errorMessage: null,
};

export const auth = (state = initialState, action: { type: string; payload: AuthStore } | AnyAction): AuthStore => {
    const { loading, success, isLoggedIn, successChangePassword, successResetPassword } = action.payload || {};
    switch (action.type) {
        case AuthTypes.CREATE_TOKEN_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.CREATE_TOKEN_SUCCESS:
            return {
                ...state,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.CREATE_TOKEN_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        case AuthTypes.GET_USER_AUTH_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.GET_USER_AUTH_SUCCESS:
            return {
                ...state,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.GET_USER_AUTH_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        case AuthTypes.LOGOUT_USER_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.LOGOUT_USER_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case AuthTypes.LOGIN_USER_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        case AuthTypes.PASSWORDRESETCONFIRM_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.PASSWORDRESETCONFIRM_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case AuthTypes.PASSWORDRESETCONFIRM_FAILURE:
            return {
                ...state,
                loading,
                successChangePassword,
            };
        case AuthTypes.PASSWORDRESET_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.PASSWORDRESET_SUCCESS:
            return {
                ...state,
                loading,
                successResetPassword,
            };
        case AuthTypes.PASSWORDRESET_FAILURE:
            return {
                ...state,
                loading,
                successResetPassword,
            };
        default:
            return state;
    }
};


