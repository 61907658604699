import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteRestaurant, getRestaurant } from "../../redux/actions/restaurant.actions";
import { RestaurantTable, User } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import alerts from "../../utils/alerts";

export const RestaurantListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listRestaurant } = useSelector((state: RootState) => state.restaurant);

  const fetchData = () => {
    getRestaurant({
      campus_id: user.campus.id
    })(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const handleDeleteRestaurant = async (restaurant: RestaurantTable) => {
    const { isConfirmed } = await alerts.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${restaurant.name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteRestaurant(restaurant.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success(`${restaurant.name} eliminado con éxito.`);
        fetchData();
      } else {
        toast.error(payload.error?.message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <h1 className="text-gray-500 font-bold text-lg mb-8">Restaurantes</h1>
      <section className="flex flex-col gap-6 pb-10">
        {
          listRestaurant && listRestaurant.map((restaurant, index) => (
            <div
              key={index}
              className="bg-gray-100 rounded-lg p-5 flex flex-col gap-6"
            >
              <div
                className="flex items-center justify-between"
              >
                <span className="font-bold">{restaurant.name}</span>
                <div className="flex flex-row gap-2">
                  {(restaurant.schedule_restaurant.length > 0 && restaurant.schedule_restaurant.every(schedule => schedule.is_activated)) ? (
                    <button
                      onClick={() =>
                        navigate('/restaurant/schedule/edit', {
                          state: {
                            restaurant,
                          },
                        })
                      }
                      className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        navigate('/restaurant/schedule/edit', {
                          state: {
                            restaurant
                          },
                        })
                      }
                      className="cursor-pointer rounded-lg p-1.5 hover:bg-red-200 active:bg-red-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-red-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                  <button
                    onClick={() =>
                      navigate('/restaurant/edit', {
                        state: {
                          restaurant
                        },
                      })
                    }
                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => handleDeleteRestaurant(restaurant)}
                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="flex flex-row justify-end gap-4">
                <button
                  onClick={() =>
                    navigate('/restaurant/menu', {
                      state: {
                        restaurant
                      },
                    })
                  }
                  className="btn-white font-bold"
                >
                  Ver Menú
                </button>
                <button
                  onClick={() =>
                    navigate('/restaurant/menu/week', {
                      state: {
                        restaurant
                      },
                    })
                  }
                  className="btn-yellow font-bold"
                >
                  Agregar almuerzo semanal
                </button>
              </div>
            </div>
          ))
        }
      </section>
    </>
  )
}