export enum MultipleServicesTypes {
  GET_MULTIPLESERVICES_LOADING = "GET_MULTIPLESERVICES_LOADING",
  GET_MULTIPLESERVICES_SUCCESS = "GET_MULTIPLESERVICES_SUCCESS",
  GET_MULTIPLESERVICES_FAILURE = "GET_MULTIPLESERVICES_FAILURE",
  POST_MULTIPLESERVICES_LOADING = "POST_MULTIPLESERVICES_LOADING",
  POST_MULTIPLESERVICES_SUCCESS = "POST_MULTIPLESERVICES_SUCCESS",
  POST_MULTIPLESERVICES_FAILURE = "POST_MULTIPLESERVICES_FAILURE",
  PUT_MULTIPLESERVICES_LOADING = "PUT_MULTIPLESERVICES_LOADING",
  PUT_MULTIPLESERVICES_SUCCESS = "PUT_MULTIPLESERVICES_SUCCESS",
  PUT_MULTIPLESERVICES_FAILURE = "PUT_MULTIPLESERVICES_FAILURE",
  DELETE_MULTIPLESERVICES_LOADING = "DELETE_MULTIPLESERVICES_LOADING",
  DELETE_MULTIPLESERVICES_SUCCESS = "DELETE_MULTIPLESERVICES_SUCCESS",
  DELETE_MULTIPLESERVICES_FAILURE = "DELETE_MULTIPLESERVICES_FAILURE",
}