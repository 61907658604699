import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Lightbox from 'react-image-lightbox';

type ImageType = {
  id?: number;
  url: string;
  multiple_service?: number;
  file?: File
}

type MiniCarouselImgType = {
  images: Array<ImageType>;
  // Recive the image object that will be removed from display
  onRemoveImage: (image: ImageType) => void;
  blankMessage?: string;
}

export const MiniCarouselImg = ({ images, blankMessage, onRemoveImage }: MiniCarouselImgType) => {
  const [imagesEl, setImagesEl] = useState<JSX.Element[]>([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesCarousel, setImagesCarousel] = useState<string[]>([]);
  // Manage the render in HTML from the images
  useEffect(() => {
    const imagesElList = [];
    for (const image of images) {
      imagesElList.push((
        <div key={image.url} className="relative">
          <LazyLoad height={96}>
            <img className="block aspect-square h-24 rounded-lg object-cover" src={image.url} alt="img-random" />
          </LazyLoad>
          <div
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(images.indexOf(image));
            }}
            className="absolute top-0 left-0 h-full w-full cursor-pointer rounded-lg bg-black/50 hover:bg-black/60 active:bg-black/70"
          />
          <svg
            onClick={() => onRemoveImage(image)}
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-1 top-1 h-5 w-5 text-gray-300 hover:cursor-pointer hover:text-gray-200 active:text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      ));
    }
    setImagesEl(imagesElList);
    setImagesCarousel(images.map(image => image.url));
  }, [images]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-row overflow-auto">
      <div className="flex shrink-0 flex-row flex-nowrap gap-4">
        {imagesEl && imagesEl.length > 0 ?
          imagesEl :
          <div className="flex flex-col items-center justify-center text-gray-500">
            {
              blankMessage ? blankMessage : "No hay imágenes cargadas."
            }
          </div>}
        {isOpen && (
          <Lightbox
            animationOnKeyInput={true}
            zoomInLabel="Acercar"
            zoomOutLabel="Alejar"
            closeLabel="Cerrar"
            nextLabel="Siguiente"
            prevLabel="Anterior"
            imagePadding={50}
            mainSrc={imagesCarousel[photoIndex]}
            nextSrc={imagesCarousel[(photoIndex + 1) % imagesCarousel.length]}
            prevSrc={imagesCarousel[(photoIndex + imagesCarousel.length - 1) % imagesCarousel.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imagesCarousel.length - 1) % imagesCarousel.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imagesCarousel.length)
            }
          />
        )}
      </div>
    </div>
  )
}
