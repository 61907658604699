import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelFilters, HotelGet, HotelPost, HotelPut, HotelTable } from "../../interfaces";

export class HotelRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotel(filters?: HotelFilters): Promise<AxiosResponse<HotelGet>> {
    return this.lmBootApi.get<HotelGet>("/hotels/", { params: filters });
  }

  postHotel(msReservation: HotelPost): Promise<AxiosResponse<HotelTable>> {
    return this.lmBootApi.post<HotelTable>("/hotels/", msReservation);
  }

  putHotel(msReservation: HotelPut): Promise<AxiosResponse<HotelTable>> {
    return this.lmBootApi.put<HotelTable>("/hotels/", msReservation);
  }

  // dont response data
  deleteHotel(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/hotels/${id}/`);
  }
}
