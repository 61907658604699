
import { RestaurantDishesRepository } from "../repositories";
import { RestaurantDishesFilters, RestaurantDishesGet, RestaurantDishesTable } from "../../interfaces";

export class RestaurantDishesProcess {
  private restaurantDishesRepository: RestaurantDishesRepository;

  constructor() {
    this.restaurantDishesRepository = new RestaurantDishesRepository();
  }

  async getRestaurantDishes(filters?: RestaurantDishesFilters): Promise<RestaurantDishesGet> {
    const response = await this.restaurantDishesRepository.getRestaurantDishes(filters);
    return response.data;
  }

  async postRestaurantDishes(restaurantDishes: FormData): Promise<RestaurantDishesTable> {
    const response = await this.restaurantDishesRepository.postRestaurantDishes(restaurantDishes);
    return response.data;
  }

  async putRestaurantDishes(restaurantDishes: FormData): Promise<RestaurantDishesTable> {
    const response = await this.restaurantDishesRepository.putRestaurantDishes(restaurantDishes);
    return response.data;
  }

  // dont response data
  async deleteRestaurantDishes(id: number): Promise<any> {
    const response = await this.restaurantDishesRepository.deleteRestaurantDishes(id);
    return response.data;
  }
}
