import { Form, Formik } from "formik"
import { useState } from "react"
import { ButtonSpinner, InputPassword } from "../../components"
import LogoHeader from "../../components/LogoHeader"
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import alerts from "../../utils/alerts";
import { passwordResetConfirm } from "../../redux/actions";
import { useDispatch } from "react-redux";

export const PasswordResetPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = params.token || '';
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        password: "",
        password_confirmation: ""
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(8, "La contraseña debe tener al menos 8 caracteres")
          .required("La contraseña es requerida"),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
          .required("La confirmación de la contraseña es requerida")
      })}
      onSubmit={async (values) => {
        setIsLoading(true);
        const { payload } = await passwordResetConfirm(values.password, token)(dispatch);
        if (payload.successChangePassword) {
          await alerts.success({ title: 'Contraseña actualizada con éxito', text: 'Ya puede iniciar sesión con su nueva contraseña.' });
          navigate('/login');
        } else {
          alerts.error({ title: 'Houston, tenemos un problema', text: payload.error?.message });
        }
        setIsLoading(false);
      }}
    >
      {({ getFieldProps, touched, errors }) => (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="max-w-md w-full p-12 rounded-[15px] bg-white">
            <button className="btn-gray p-2" onClick={() => navigate('/login')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </button>
            <LogoHeader />
            <Form
              className="mt-8 space-y-6"
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="password" className="block font-medium text-black mt-3">
                    Contraseña nueva
                  </label>
                  <InputPassword
                    maxLength={255}
                    autoComplete="current-password"
                    required
                    placeholder="******************"
                    {...getFieldProps('password')}
                  />
                  { touched.password && errors.password && <span className="text-rose-500">{errors.password}</span> }
                </div>
                <div>
                  <label htmlFor="password" className="block font-medium text-black mt-3">
                    Confirmar contraseña nueva
                  </label>
                  <InputPassword
                    maxLength={255}
                    autoComplete="current-password"
                    required
                    placeholder="******************"
                    {...getFieldProps('password_confirmation')}
                  />
                  { touched.password_confirmation && errors.password_confirmation && <span className="text-rose-500">{errors.password_confirmation}</span> }
                </div>
              </div>

              <div>
                <ButtonSpinner isLoading={isLoading} text="Restablecer contraseña" type="submit" color="yellow" />
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  )
}