import { Column, Row, usePagination, useSortBy, useTable } from "react-table";
import { TableFooter, TableFooterType } from ".";

interface TableBodyType<T extends Object> extends TableFooterType {
    columns: Column<T>[];
    data: Array<T>;
    pageSize: number;
    loading: boolean;
    blankMessage?: string;
    renderRows: (row: Row<T>, index: number) => void;
    footerExtra?: JSX.Element;
}

export const TableBody = <D extends Object>({ columns, data, pageSize, loading, renderRows, pageCurrent,
    previous, next, count, setPageCurrent, footerExtra,
    blankMessage = 'No hay reservas que mostrar' }: TableBodyType<D>) => {
    const tableInstance = useTable({ columns, data, initialState: { pageSize } }, useSortBy, usePagination);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page
    } = tableInstance;

    return (
    <>
        <div className="min-w-full max-w-prose cm-table">
            <div className={`relative ${(loading && page.length === 0) ? 'overflow-hidden' : 'overflow-auto'}`}>
                <table className="min-w-full table-fixed divide-y divide-gray-500/25" {...getTableProps()}>
                    <thead>
                        {
                            // Loop over the header rows
                            headerGroups.map((headerGroup) => (
                                // Apply the header row props
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Loop over the headers in each row
                                        headerGroup.headers.map((column) => (
                                            // Apply the header cell props
                                            <th
                                                className="px-6 py-3 text-left text-lg font-bold text-black"
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                            >
                                                <span className="flex flex-row items-center gap-2">
                                                    {
                                                        // Render the header
                                                        column.render('Header')
                                                    }
                                                    {/* Add a sort direction indicator */}
                                                    <span>
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                                </svg>
                                                            )
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </span>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody className="divide-y divide-yellow-900/25 bg-white" {...getTableBodyProps()}>
                        {
                            // Loop over the table rows
                            page.length > 0 ? (
                                page.map((row, i) => {
                                    // Prepare the row for display
                                    prepareRow(row);
                                    return renderRows(row, i);
                                })
                            ) : (
                                <tr key={`tr-${columns.length}`} className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50">
                                    <td colSpan={columns.length} className="px-6 py-4 text-left">{blankMessage}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                {
                    (loading && page.length === 0) && (
                        <div className="absolute inset-0 h-full grid place-items-center bg-black/60">
                            <div className="flex flex-row justify-center items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-8 w-8 text-yellow-500 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span className="text-yellow-500 text-3xl">Cargando...</span>
                            </div>
                        </div>
                    )
                }
            </div>
            <TableFooter count={count} next={next} pageCurrent={pageCurrent} pageSize={pageSize} previous={previous} setPageCurrent={setPageCurrent} />
            {footerExtra}
        </div>
    </>
    )
}
