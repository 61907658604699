import { PaymentResponse, PaymentStatus } from '../redux/interfaces';

export const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const addDays = (date: Date, days: number) => {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
};

export const getDates = (startDate: Date, stopDate: Date) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

export const getFirstAndLastDayOfWeek = (currentDate: Date = new Date()) => {
  // First day of week is monday
  const dayForRest = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
  const firstDayOfWeek = currentDate.setDate(currentDate.getDate() - dayForRest + 1);
  const lastDayOfWeek = currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7);

  return { first: new Date(firstDayOfWeek), last: new Date(lastDayOfWeek) };
};

export const getFirstAndLastDayOfMonth = (currentDate: Date = new Date()) => {
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  return { first: firstDayOfMonth, last: lastDayOfMonth };
};

export const getAllDaysInARange = (startDate: Date, stopDate: Date, currentHour: boolean = false) => {
  const dates = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dates.push(currentHour ? new Date(currentDate) : new Date(currentDate.setHours(0, 0, 0, 0)));
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

export const generateRandomId = (pre: string = 'rand') => {
  return pre + '_' + (+new Date() * Math.random()).toString(36).substring(0, 7);
};

/**
 * Test if the files given overflow the max file size.
 * @param fileList List of files
 * @param maxSize Max file size in bytes. Default is 10MB
 * @returns Returns true if the size of the file is lower than the max size.
 */
export const testSizeImg = (fileList: FileList | null, maxSize = 10000000) => {
  const files: FileList = fileList as FileList;
  for (const i in files) {
    if (Object.prototype.hasOwnProperty.call(files, i)) {
      const file = files[i];
      // 1MB = 1 millón de bytes
      if (file.size > maxSize) {
        return false;
      }
    }
  }
  return true;
};

export const validatePaymentResponse = (paymentResponse: PaymentResponse) => {
  //
  if (paymentResponse.error_code === 0 && paymentResponse.id === null) {
    return {
      status: PaymentStatus.CREATED_ORDER,
      title: 'Orden creada',
      message: 'Se ha creado la orden de pago, por favor espere a que se confirme.',
    };
  } else if (paymentResponse.error_code === 0 && !!paymentResponse.id) {
    return {
      status: PaymentStatus.SUCCESS,
      title: 'Pago exitoso',
      message: 'Esperamos que disfrutes de nuestros servicios.',
    };
  } else if (paymentResponse.error_code === 1 && !!paymentResponse.id) {
    return {
      status: PaymentStatus.DECLINED,
      title: 'Pago declinado',
      message: paymentResponse.error_description!,
    };
  } else {
    return {
      status: PaymentStatus.ERROR,
      title: 'Error',
      message: 'Error al procesar el pago. Contacte con servicio al cliente.',
    };
  }
};
