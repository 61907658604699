
import { CampusNewsProcess } from "../api";
import { CampusNewsAction, CampusNewsFilters } from "../interfaces";
import { CampusNewsTypes } from "../types";

export const getCampusNewsPags = (filters?: CampusNewsFilters) => async (dispatch: (state: CampusNewsAction) => CampusNewsAction) => {
  dispatch({
    type: CampusNewsTypes.GET_CAMPUSNEWSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNewsProcess = new CampusNewsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNewsPag = await campusNewsProcess.getCampusNews(filters);
    return dispatch({
      type: CampusNewsTypes.GET_CAMPUSNEWSPAG_SUCCESS,
      payload: {
        pagCampusNews: campusNewsPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNewsTypes.GET_CAMPUSNEWSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getCampusNews = (filters?: CampusNewsFilters) => async (dispatch: (state: CampusNewsAction) => CampusNewsAction) => {
  dispatch({
    type: CampusNewsTypes.GET_CAMPUSNEWS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNewsProcess = new CampusNewsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNewsPag = await campusNewsProcess.getCampusNews({
      ...filters,
      data_all: 'YES',
    });
    const campusNews = campusNewsPag.results;
    return dispatch({
      type: CampusNewsTypes.GET_CAMPUSNEWS_SUCCESS,
      payload: {
        listCampusNews: campusNews,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNewsTypes.GET_CAMPUSNEWS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postCampusNews = (campusNews: FormData) => async (dispatch: (state: CampusNewsAction) => CampusNewsAction) => {
  dispatch({
    type: CampusNewsTypes.POST_CAMPUSNEWS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNewsProcess = new CampusNewsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNewsTable = await campusNewsProcess.postCampusNews(campusNews);
    return dispatch({
      type: CampusNewsTypes.POST_CAMPUSNEWS_SUCCESS,
      payload: {
        campusNews: campusNewsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNewsTypes.POST_CAMPUSNEWS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putCampusNews = (campusNews: FormData) => async (dispatch: (state: CampusNewsAction) => CampusNewsAction) => {
  dispatch({
    type: CampusNewsTypes.PUT_CAMPUSNEWS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNewsProcess = new CampusNewsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNewsTable = await campusNewsProcess.putCampusNews(campusNews);
    return dispatch({
      type: CampusNewsTypes.PUT_CAMPUSNEWS_SUCCESS,
      payload: {
        campusNews: campusNewsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNewsTypes.PUT_CAMPUSNEWS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteCampusNews = (id: number) => async (dispatch: (state: CampusNewsAction) => CampusNewsAction) => {
  dispatch({
    type: CampusNewsTypes.DELETE_CAMPUSNEWS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNewsProcess = new CampusNewsProcess();
    // dont'n import the result of the process, only that dont't have error
    await campusNewsProcess.deleteCampusNews(id);
    return dispatch({
      type: CampusNewsTypes.DELETE_CAMPUSNEWS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNewsTypes.DELETE_CAMPUSNEWS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

