import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

interface AlertError {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const alerts = {
  success: (options?: AlertError) => {
    const { title = 'Éxito', text = '', confirmButtonText = 'Aceptar' } = options || {};
    return MySwal.fire({
      title,
      html: text,
      icon: 'success',
      confirmButtonText,
      customClass: {
        // confirmButton: 'bg-green-500 text-white focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
        confirmButton: 'bg-yellow-500 text-black focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
      }
    })
  },
  error: (options?: AlertError) => {
    const { title = '¡Error!', text = 'Por favor inténtelo de nuevo más tarde.', confirmButtonText = 'Aceptar' } = options || {};
    return MySwal.fire({
      title,
      html: text,
      icon: 'error',
      confirmButtonText,
      customClass: {
        confirmButton: 'bg-red-400 text-white focus:ring-2 focus:ring-offset-2 focus:ring-red-400'
      }
    })
  },
  warning: (options?: AlertError) => {
    const { title = 'Advertencia', text = 'Una vez realizado no se podrá revertir.',
      confirmButtonText = 'Confirmar', cancelButtonText = 'Cancelar' } = options || {};
    return MySwal.fire({
      title,
      html: text,
      icon: 'warning',
      confirmButtonText,
      showCancelButton: true,
      cancelButtonText,
      focusCancel: true,
      customClass: {
        confirmButton: 'bg-gray-500 text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-400',
        cancelButton: 'bg-red-400 text-white focus:ring-2 focus:ring-offset-2 focus:ring-red-400'
      }
    })
  }
};

export default alerts;