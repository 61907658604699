import { MultipleServicesReservationProcess } from "../api";
import { MultipleServicesReservationAction, MultipleServicesReservationFilters, MultipleServicesReservationPost, MultipleServicesReservationPut } from "../interfaces";
import { MultipleServicesReservationTypes } from "../types";

// apply the reducer
export const getMultipleServicesReservationPags = (filters?: MultipleServicesReservationFilters) => async (dispatch: (state: MultipleServicesReservationAction) => MultipleServicesReservationAction) => {
  dispatch({
    type: MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATIONPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesReservationProcess = new MultipleServicesReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const multipleServicesReservationPag = await multipleServicesReservationProcess.getMultipleServicesReservation(filters);
    return dispatch({
      type: MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATIONPAG_SUCCESS,
      payload: {
        pagMultipleServicesReservation: multipleServicesReservationPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATIONPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getMultipleServicesReservation = (filters?: MultipleServicesReservationFilters) => async (dispatch: (state: MultipleServicesReservationAction) => MultipleServicesReservationAction) => {
  dispatch({
    type: MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesReservationProcess = new MultipleServicesReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const multipleServicesReservationPag = await multipleServicesReservationProcess.getMultipleServicesReservation({
      ...filters,
      data_all: 'YES',
    });
    const multipleServicesReservation = multipleServicesReservationPag.results;
    return dispatch({
      type: MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATION_SUCCESS,
      payload: {
        listMultipleServicesReservation: multipleServicesReservation,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesReservationTypes.GET_MULTIPLESERVICESRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postMultipleServicesReservation = (msReservation: MultipleServicesReservationPost) => async (dispatch: (state: MultipleServicesReservationAction) => MultipleServicesReservationAction) => {
  dispatch({
    type: MultipleServicesReservationTypes.POST_MULTIPLESERVICESRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesReservationProcess = new MultipleServicesReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await multipleServicesReservationProcess.postMultipleServicesReservation(msReservation);
    return dispatch({
      type: MultipleServicesReservationTypes.POST_MULTIPLESERVICESRESERVATION_SUCCESS,
      payload: {
        multipleServicesReservation: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesReservationTypes.POST_MULTIPLESERVICESRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putMultipleServicesReservation = (msReservation: MultipleServicesReservationPut) => async (dispatch: (state: MultipleServicesReservationAction) => MultipleServicesReservationAction) => {
  dispatch({
    type: MultipleServicesReservationTypes.PUT_MULTIPLESERVICESRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesReservationProcess = new MultipleServicesReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await multipleServicesReservationProcess.putMultipleServicesReservation(msReservation);
    return dispatch({
      type: MultipleServicesReservationTypes.PUT_MULTIPLESERVICESRESERVATION_SUCCESS,
      payload: {
        multipleServicesReservation: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message } = error.response.data || {};
    return dispatch({
        type: MultipleServicesReservationTypes.PUT_MULTIPLESERVICESRESERVATION_FAILURE,
        payload: {
            error: {
              message: message || 'Tuvimos un problema al cargar la lista de reservaciones. Por favor, intenta más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteMultipleServicesReservation = (id_reservation: number) => async (dispatch: (state: MultipleServicesReservationAction) => MultipleServicesReservationAction) => {
  dispatch({
    type: MultipleServicesReservationTypes.DELETE_MULTIPLESERVICESRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesReservationProcess = new MultipleServicesReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    await multipleServicesReservationProcess.deleteMultipleServicesReservation(id_reservation);
    return dispatch({
      type: MultipleServicesReservationTypes.DELETE_MULTIPLESERVICESRESERVATION_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesReservationTypes.DELETE_MULTIPLESERVICESRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};