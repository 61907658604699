import { initialStateUser, UserAction, UserPayload } from "../interfaces";
import { UserTypes } from "../types";

const initialState: UserPayload = initialStateUser;

export const user = (state = initialState, action: UserAction): UserPayload => {
  const { loading, success, user, error, listUsers, userById, listUsersPag } = action.payload || {};
  switch (action.type) {
    case UserTypes.GET_USERBYID_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserTypes.GET_USERBYID_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserTypes.GET_USERBYID_SUCCESS:
      return {
        ...state,
        userById,
        loading,
        success,
      };
    case UserTypes.GET_USER_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserTypes.GET_USER_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user,
        loading,
        success,
      };
    case UserTypes.GET_LIST_USER_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserTypes.GET_LIST_USER_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserTypes.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUsers,
        loading,
        success,
      };
    case UserTypes.GET_LIST_USERPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserTypes.GET_LIST_USERPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserTypes.GET_LIST_USERPAG_SUCCESS:
      return {
        ...state,
        listUsersPag,
        loading,
        success,
      };
    case UserTypes.PUT_USER_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserTypes.PUT_USER_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserTypes.PUT_USER_SUCCESS:
      return {
        ...state,
        userById,
        loading,
        success,
      };
    default:
      return state;
  }
};

