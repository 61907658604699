
import { RestaurantDishesProcess } from "../api";
import { RestaurantDishesAction, RestaurantDishesFilters } from "../interfaces";
import { RestaurantDishesTypes } from "../types";

export const getRestaurantDishesPags = (filters?: RestaurantDishesFilters) => async (dispatch: (state: RestaurantDishesAction) => RestaurantDishesAction) => {
  dispatch({
    type: RestaurantDishesTypes.GET_RESTAURANTDISHESPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantDishesProcess = new RestaurantDishesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantDishesPag = await restaurantDishesProcess.getRestaurantDishes(filters);
    return dispatch({
      type: RestaurantDishesTypes.GET_RESTAURANTDISHESPAG_SUCCESS,
      payload: {
        pagRestaurantDishes: restaurantDishesPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantDishesTypes.GET_RESTAURANTDISHESPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getRestaurantDishes = (filters?: RestaurantDishesFilters) => async (dispatch: (state: RestaurantDishesAction) => RestaurantDishesAction) => {
  dispatch({
    type: RestaurantDishesTypes.GET_RESTAURANTDISHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantDishesProcess = new RestaurantDishesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantDishesPag = await restaurantDishesProcess.getRestaurantDishes({
      ...filters,
      data_all: 'YES',
    });
    const restaurantDishes = restaurantDishesPag.results;
    return dispatch({
      type: RestaurantDishesTypes.GET_RESTAURANTDISHES_SUCCESS,
      payload: {
        listRestaurantDishes: restaurantDishes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantDishesTypes.GET_RESTAURANTDISHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postRestaurantDishes = (restaurantDishes: FormData) => async (dispatch: (state: RestaurantDishesAction) => RestaurantDishesAction) => {
  dispatch({
    type: RestaurantDishesTypes.POST_RESTAURANTDISHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantDishesProcess = new RestaurantDishesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantDishesTable = await restaurantDishesProcess.postRestaurantDishes(restaurantDishes);
    return dispatch({
      type: RestaurantDishesTypes.POST_RESTAURANTDISHES_SUCCESS,
      payload: {
        restaurantDishes: restaurantDishesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantDishesTypes.POST_RESTAURANTDISHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putRestaurantDishes = (restaurantDishes: FormData) => async (dispatch: (state: RestaurantDishesAction) => RestaurantDishesAction) => {
  dispatch({
    type: RestaurantDishesTypes.PUT_RESTAURANTDISHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantDishesProcess = new RestaurantDishesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantDishesTable = await restaurantDishesProcess.putRestaurantDishes(restaurantDishes);
    return dispatch({
      type: RestaurantDishesTypes.PUT_RESTAURANTDISHES_SUCCESS,
      payload: {
        restaurantDishes: restaurantDishesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantDishesTypes.PUT_RESTAURANTDISHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteRestaurantDishes = (id: number) => async (dispatch: (state: RestaurantDishesAction) => RestaurantDishesAction) => {
  dispatch({
    type: RestaurantDishesTypes.DELETE_RESTAURANTDISHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantDishesProcess = new RestaurantDishesProcess();
    // dont'n import the result of the process, only that dont't have error
    await restaurantDishesProcess.deleteRestaurantDishes(id);
    return dispatch({
      type: RestaurantDishesTypes.DELETE_RESTAURANTDISHES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantDishesTypes.DELETE_RESTAURANTDISHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

