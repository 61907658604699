import { MultipleServicesRepository } from "../repositories";
import { MultipleServicesFilters, MultipleServicesGet, MultipleServicesPost, MultipleServicesPut, MultipleServicesTable } from "../../interfaces";

export class MultipleServicesProcess {
  private multipleServiceRepository: MultipleServicesRepository;

  constructor() {
    this.multipleServiceRepository = new MultipleServicesRepository();
  }

  async getMultipleServices(filters?: MultipleServicesFilters): Promise<MultipleServicesGet> {
    const response = await this.multipleServiceRepository.getMultipleServices(filters);
    return response.data;
  }

  async postMultipleServices(multipleService: MultipleServicesPost): Promise<MultipleServicesTable> {
    const response = await this.multipleServiceRepository.postMultipleServices(multipleService);
    return response.data;
  }

  async putMultipleServices(multipleService: MultipleServicesPut): Promise<MultipleServicesTable> {
    const response = await this.multipleServiceRepository.putMultipleServices(multipleService);
    return response.data;
  }

  // dont response data
  async deleteMultipleServices(id: number): Promise<any> {
    const response = await this.multipleServiceRepository.deleteMultipleServices(id);
    return response.data;
  }
}
