
import { CampusChatbotAction, CampusChatbotPayload } from "../interfaces";
import { CampusChatbotTypes } from "../types";

const initialState: CampusChatbotPayload = {
  loading: false,
  success: false,
  error: null,
  campusChatbot: null,
  listCampusChatbot: [],
  pagCampusChatbot: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const campusChatbot = (state = initialState, action: CampusChatbotAction): CampusChatbotPayload => {
  const { loading, success, error, campusChatbot, listCampusChatbot, pagCampusChatbot } = action.payload || {};
  switch (action.type) {
    case CampusChatbotTypes.GET_CAMPUSCHATBOTPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusChatbotTypes.GET_CAMPUSCHATBOTPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusChatbotTypes.GET_CAMPUSCHATBOTPAG_SUCCESS:
      return {
        ...state,
        pagCampusChatbot,
        loading,
        success,
      };
    case CampusChatbotTypes.GET_CAMPUSCHATBOT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusChatbotTypes.GET_CAMPUSCHATBOT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusChatbotTypes.GET_CAMPUSCHATBOT_SUCCESS:
      return {
        ...state,
        listCampusChatbot,
        loading,
        success,
      };
    case CampusChatbotTypes.GETONLY_CAMPUSCHATBOT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusChatbotTypes.GETONLY_CAMPUSCHATBOT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusChatbotTypes.GETONLY_CAMPUSCHATBOT_SUCCESS:
      return {
        ...state,
        campusChatbot,
        loading,
        success,
      };
    case CampusChatbotTypes.POST_CAMPUSCHATBOT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusChatbotTypes.POST_CAMPUSCHATBOT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusChatbotTypes.POST_CAMPUSCHATBOT_SUCCESS:
      return {
        ...state,
        campusChatbot,
        loading,
        success,
      };
    case CampusChatbotTypes.PUT_CAMPUSCHATBOT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusChatbotTypes.PUT_CAMPUSCHATBOT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusChatbotTypes.PUT_CAMPUSCHATBOT_SUCCESS:
      return {
        ...state,
        campusChatbot,
        loading,
        success,
      };
    case CampusChatbotTypes.DELETE_CAMPUSCHATBOT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusChatbotTypes.DELETE_CAMPUSCHATBOT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusChatbotTypes.DELETE_CAMPUSCHATBOT_SUCCESS:
      return {
        ...state,
        campusChatbot,
        loading,
        success,
      };
    default:
      return state;
  }
};

