import { MenuAction, SecondMenuItemsType } from "../interfaces/menu.interface";
import { MenuTypes } from "../types/menu.types";


export const addSeconMenuItems = (seconMenuItems: SecondMenuItemsType[]) => (dispatch: (state: MenuAction) => MenuAction) => {
  return dispatch({
    type: MenuTypes.ADD_MENU_ITEMS,
    payload: {
      secondMenuItems: seconMenuItems,
    },
  });
};

export const toggleSideMenu = (toggleSideMenu: boolean) => (dispatch: (state: MenuAction) => MenuAction) => {
  return dispatch({
    type: MenuTypes.SIDE_MENU_TOGGLE,
    payload: {
      secondMenuToggle: toggleSideMenu,
    },
  });
};

type SecondMenuShowType = {
  type: MenuTypes.SIDE_MENU_SHOW | MenuTypes.SIDE_MENU_HIDDEN;
}

export const secondMenuShow = () => (dispatch: (state: SecondMenuShowType) => SecondMenuShowType) => {
  return dispatch({
    type: MenuTypes.SIDE_MENU_SHOW
  });
}
export const secondMenuHidden = () => (dispatch: (state: SecondMenuShowType) => SecondMenuShowType) => {
  return dispatch({
    type: MenuTypes.SIDE_MENU_HIDDEN
  });
}