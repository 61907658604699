
export enum CampusSurveyAskTypes {
  GET_CAMPUSSURVEYASKPAG_LOADING = "GET_CAMPUSSURVEYASKPAG_LOADING",
  GET_CAMPUSSURVEYASKPAG_SUCCESS = "GET_CAMPUSSURVEYASKPAG_SUCCESS",
  GET_CAMPUSSURVEYASKPAG_FAILURE = "GET_CAMPUSSURVEYASKPAG_FAILURE",
  GET_CAMPUSSURVEYASK_LOADING = "GET_CAMPUSSURVEYASK_LOADING",
  GET_CAMPUSSURVEYASK_SUCCESS = "GET_CAMPUSSURVEYASK_SUCCESS",
  GET_CAMPUSSURVEYASK_FAILURE = "GET_CAMPUSSURVEYASK_FAILURE",
  GETONLY_CAMPUSSURVEYASK_LOADING = "GETONLY_CAMPUSSURVEYASK_LOADING",
  GETONLY_CAMPUSSURVEYASK_SUCCESS = "GETONLY_CAMPUSSURVEYASK_SUCCESS",
  GETONLY_CAMPUSSURVEYASK_FAILURE = "GETONLY_CAMPUSSURVEYASK_FAILURE",
  POST_CAMPUSSURVEYASK_LOADING = "POST_CAMPUSSURVEYASK_LOADING",
  POST_CAMPUSSURVEYASK_SUCCESS = "POST_CAMPUSSURVEYASK_SUCCESS",
  POST_CAMPUSSURVEYASK_FAILURE = "POST_CAMPUSSURVEYASK_FAILURE",
  PUT_CAMPUSSURVEYASK_LOADING = "PUT_CAMPUSSURVEYASK_LOADING",
  PUT_CAMPUSSURVEYASK_SUCCESS = "PUT_CAMPUSSURVEYASK_SUCCESS",
  PUT_CAMPUSSURVEYASK_FAILURE = "PUT_CAMPUSSURVEYASK_FAILURE",
  DELETE_CAMPUSSURVEYASK_LOADING = "DELETE_CAMPUSSURVEYASK_LOADING",
  DELETE_CAMPUSSURVEYASK_SUCCESS = "DELETE_CAMPUSSURVEYASK_SUCCESS",
  DELETE_CAMPUSSURVEYASK_FAILURE = "DELETE_CAMPUSSURVEYASK_FAILURE",
}