import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelReservationFilters, HotelReservationGet, HotelReservationPost, HotelReservationPut, HotelReservationTable } from "../../interfaces";

export class HotelReservationRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotelReservation(filters?: HotelReservationFilters): Promise<AxiosResponse<HotelReservationGet>> {
    return this.lmBootApi.get<HotelReservationGet>("/hotel-reservations/", { params: filters });
  }

  postHotelReservation(hotelReservation: HotelReservationPost): Promise<AxiosResponse<HotelReservationTable>> {
    return this.lmBootApi.post<HotelReservationTable>("/hotel-reservations/", hotelReservation);
  }

  putHotelReservation(hotelReservation: HotelReservationPut): Promise<AxiosResponse<HotelReservationTable>> {
    return this.lmBootApi.put<HotelReservationTable>("/hotel-reservations/", hotelReservation);
  }

  // dont response data
  deleteHotelReservation(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/hotel-reservations/${id}/`);
  }
}
