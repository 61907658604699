export enum MultipleServicesCategoryTypes {
  GET_MULTIPLESERVICESCATEGORY_LOADING = "GET_MULTIPLESERVICESCATEGORY_LOADING",
  GET_MULTIPLESERVICESCATEGORY_SUCCESS = "GET_MULTIPLESERVICESCATEGORY_SUCCESS",
  GET_MULTIPLESERVICESCATEGORY_FAILURE = "GET_MULTIPLESERVICESCATEGORY_FAILURE",
  POST_MULTIPLESERVICESCATEGORY_LOADING = "POST_MULTIPLESERVICESCATEGORY_LOADING",
  POST_MULTIPLESERVICESCATEGORY_SUCCESS = "POST_MULTIPLESERVICESCATEGORY_SUCCESS",
  POST_MULTIPLESERVICESCATEGORY_FAILURE = "POST_MULTIPLESERVICESCATEGORY_FAILURE",
  PUT_MULTIPLESERVICESCATEGORY_LOADING = "PUT_MULTIPLESERVICESCATEGORY_LOADING",
  PUT_MULTIPLESERVICESCATEGORY_SUCCESS = "PUT_MULTIPLESERVICESCATEGORY_SUCCESS",
  PUT_MULTIPLESERVICESCATEGORY_FAILURE = "PUT_MULTIPLESERVICESCATEGORY_FAILURE",
  DELETE_MULTIPLESERVICESCATEGORY_LOADING = "DELETE_MULTIPLESERVICESCATEGORY_LOADING",
  DELETE_MULTIPLESERVICESCATEGORY_SUCCESS = "DELETE_MULTIPLESERVICESCATEGORY_SUCCESS",
  DELETE_MULTIPLESERVICESCATEGORY_FAILURE = "DELETE_MULTIPLESERVICESCATEGORY_FAILURE",
}