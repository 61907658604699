import { HotelProcess } from "../api";
import { HotelAction, HotelFilters, HotelPost, HotelPut } from "../interfaces";
import { HotelTypes } from "../types";

// apply the reducer
export const getHotelPags = (filters?: HotelFilters) => async (dispatch: (state: HotelAction) => HotelAction) => {
  dispatch({
    type: HotelTypes.GET_HOTELPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelProcess = new HotelProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelPag = await hotelProcess.getHotel(filters);
    return dispatch({
      type: HotelTypes.GET_HOTELPAG_SUCCESS,
      payload: {
        pagHotel: hotelPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelTypes.GET_HOTELPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotel = (filters?: HotelFilters) => async (dispatch: (state: HotelAction) => HotelAction) => {
  dispatch({
    type: HotelTypes.GET_HOTEL_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelProcess = new HotelProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelPag = await hotelProcess.getHotel({
      ...filters,
      data_all: 'YES',
    });
    const hotel = hotelPag.results;
    return dispatch({
      type: HotelTypes.GET_HOTEL_SUCCESS,
      payload: {
        listHotel: hotel,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelTypes.GET_HOTEL_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotel = (hotel: HotelPost) => async (dispatch: (state: HotelAction) => HotelAction) => {
  dispatch({
    type: HotelTypes.POST_HOTEL_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelProcess = new HotelProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelProcess.postHotel(hotel);
    return dispatch({
      type: HotelTypes.POST_HOTEL_SUCCESS,
      payload: {
        hotel: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelTypes.POST_HOTEL_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotel = (hotel: HotelPut) => async (dispatch: (state: HotelAction) => HotelAction) => {
  dispatch({
    type: HotelTypes.PUT_HOTEL_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelProcess = new HotelProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelProcess.putHotel(hotel);
    return dispatch({
      type: HotelTypes.PUT_HOTEL_SUCCESS,
      payload: {
        hotel: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelTypes.PUT_HOTEL_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotel = (id: number) => async (dispatch: (state: HotelAction) => HotelAction) => {
  dispatch({
    type: HotelTypes.DELETE_HOTEL_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelProcess = new HotelProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelProcess.deleteHotel(id);
    return dispatch({
      type: HotelTypes.DELETE_HOTEL_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelTypes.DELETE_HOTEL_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};