import { HotelBedroomRepository } from "../repositories";
import { HotelBedroomFilters, HotelBedroomGet, HotelBedroomPost, HotelBedroomPut, HotelBedroomTable } from "../../interfaces";

export class HotelBedroomProcess {
  private multipleServiceReservationRepository: HotelBedroomRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelBedroomRepository();
  }

  async getHotelBedroom(filters?: HotelBedroomFilters): Promise<HotelBedroomGet> {
    const response = await this.multipleServiceReservationRepository.getHotelBedroom(filters);
    return response.data;
  }

  async postHotelBedroom(hotelBedroom: HotelBedroomPost): Promise<HotelBedroomTable> {
    const response = await this.multipleServiceReservationRepository.postHotelBedroom(hotelBedroom);
    return response.data;
  }

  async putHotelBedroom(hotelBedroom: HotelBedroomPut): Promise<HotelBedroomTable> {
    const response = await this.multipleServiceReservationRepository.putHotelBedroom(hotelBedroom);
    return response.data;
  }

  // dont response data
  async deleteHotelBedroom(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotelBedroom(id);
    return response.data;
  }
}
