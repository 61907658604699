
export enum EventTypesTypes {
  GET_EVENTTYPESPAG_LOADING = "GET_EVENTTYPESPAG_LOADING",
  GET_EVENTTYPESPAG_SUCCESS = "GET_EVENTTYPESPAG_SUCCESS",
  GET_EVENTTYPESPAG_FAILURE = "GET_EVENTTYPESPAG_FAILURE",
  GET_EVENTTYPES_LOADING = "GET_EVENTTYPES_LOADING",
  GET_EVENTTYPES_SUCCESS = "GET_EVENTTYPES_SUCCESS",
  GET_EVENTTYPES_FAILURE = "GET_EVENTTYPES_FAILURE",
  GETONLY_EVENTTYPES_LOADING = "GETONLY_EVENTTYPES_LOADING",
  GETONLY_EVENTTYPES_SUCCESS = "GETONLY_EVENTTYPES_SUCCESS",
  GETONLY_EVENTTYPES_FAILURE = "GETONLY_EVENTTYPES_FAILURE",
  POST_EVENTTYPES_LOADING = "POST_EVENTTYPES_LOADING",
  POST_EVENTTYPES_SUCCESS = "POST_EVENTTYPES_SUCCESS",
  POST_EVENTTYPES_FAILURE = "POST_EVENTTYPES_FAILURE",
  PUT_EVENTTYPES_LOADING = "PUT_EVENTTYPES_LOADING",
  PUT_EVENTTYPES_SUCCESS = "PUT_EVENTTYPES_SUCCESS",
  PUT_EVENTTYPES_FAILURE = "PUT_EVENTTYPES_FAILURE",
  DELETE_EVENTTYPES_LOADING = "DELETE_EVENTTYPES_LOADING",
  DELETE_EVENTTYPES_SUCCESS = "DELETE_EVENTTYPES_SUCCESS",
  DELETE_EVENTTYPES_FAILURE = "DELETE_EVENTTYPES_FAILURE",
}