
import { CampusNewsAction, CampusNewsPayload } from "../interfaces";
import { CampusNewsTypes } from "../types";

const initialState: CampusNewsPayload = {
  loading: false,
  success: false,
  error: null,
  campusNews: null,
  listCampusNews: [],
  pagCampusNews: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const campusNews = (state = initialState, action: CampusNewsAction): CampusNewsPayload => {
  const { loading, success, error, campusNews, listCampusNews, pagCampusNews } = action.payload || {};
  switch (action.type) {
    case CampusNewsTypes.GET_CAMPUSNEWSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNewsTypes.GET_CAMPUSNEWSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNewsTypes.GET_CAMPUSNEWSPAG_SUCCESS:
      return {
        ...state,
        pagCampusNews,
        loading,
        success,
      };
    case CampusNewsTypes.GET_CAMPUSNEWS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNewsTypes.GET_CAMPUSNEWS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNewsTypes.GET_CAMPUSNEWS_SUCCESS:
      return {
        ...state,
        listCampusNews,
        loading,
        success,
      };
    case CampusNewsTypes.GETONLY_CAMPUSNEWS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNewsTypes.GETONLY_CAMPUSNEWS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNewsTypes.GETONLY_CAMPUSNEWS_SUCCESS:
      return {
        ...state,
        campusNews,
        loading,
        success,
      };
    case CampusNewsTypes.POST_CAMPUSNEWS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNewsTypes.POST_CAMPUSNEWS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNewsTypes.POST_CAMPUSNEWS_SUCCESS:
      return {
        ...state,
        campusNews,
        loading,
        success,
      };
    case CampusNewsTypes.PUT_CAMPUSNEWS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNewsTypes.PUT_CAMPUSNEWS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNewsTypes.PUT_CAMPUSNEWS_SUCCESS:
      return {
        ...state,
        campusNews,
        loading,
        success,
      };
    case CampusNewsTypes.DELETE_CAMPUSNEWS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNewsTypes.DELETE_CAMPUSNEWS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNewsTypes.DELETE_CAMPUSNEWS_SUCCESS:
      return {
        ...state,
        campusNews,
        loading,
        success,
      };
    default:
      return state;
  }
};

