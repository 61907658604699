import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCampusSurveyAnswer, getCampusSurveyAnswerReport } from '../../redux/actions';
import { CampusSurveyAnswerTable, CampusSurveyTable } from '../../redux/interfaces';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import imgSearching from "../../assets/images/undraw_house_searching.svg";

interface IPie {
  id: string;
  label: string;
  value: number;
}

interface IBar {
  id: string;
  value: number;
}

interface IChart {
  type: 'pie' | 'bar';
  ask: string;
  data: (IPie | IBar)[];
  size: number;
  memberAnsweredNames: string[];
}

interface ITextData {
  ask: string;
  answer: string;
  user: string;
}

interface IText {
  type: 'text';
  ask: string;
  data: ITextData[];
  size: number;
  memberAnsweredNames: string[];
}

export const CampusSurveysReportPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { survey } = state as { survey: CampusSurveyTable };
  const [listAsks, setListAsks] = useState<(IChart | IText)[]>([]);
  const [page, setPage] = useState(0);

  const validateType = (asks: CampusSurveyAnswerTable) => {
    if (asks.survey_response_ask_text.length > 0) {
      return 'text';
    }

    if (asks.survey_ask_options.length > 0) {
      if (asks.survey_ask_options[0].type === 'radio') {
        return 'pie';
      } else {
        return 'bar';
      }
    }

    return 'text';
  }

  const answersDataFilter = (asks: CampusSurveyAnswerTable) => {
    const type = validateType(asks);
    let data: (IPie | IBar)[] | ITextData[] = [];
    if (type === 'bar') {
      data = asks.survey_ask_options.map(option => ({
        id: option.value,
        value: option.survey_response_options.filter(option => option.checked).length
      }));
    }

    if (type === 'pie') {
      data = asks.survey_ask_options.map(option => ({
        id: option.value,
        label: option.value,
        value: option.survey_response_options.filter(option => option.checked).length
      }));
    }

    if (type === 'text') {
      data = asks.survey_response_ask_text.map(answer => ({
        ask: answer.survey_ask.ask,
        answer: answer.response,
        user: answer.survey_user_answer.user.first_name
      }));
    }

    return data;
  }

  const fetchData = async () => {
    const { payload } = await getCampusSurveyAnswer({
      survey_id: survey.id,
    })(dispatch);

    if (!payload.success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }

    if (!payload.listCampusSurveyAnswer) {
      return toast.error('No hay respuestas todavía.', { type: 'error' });
    }

    const listCampusSurveyAnswer = payload.listCampusSurveyAnswer;

    setListAsks(listCampusSurveyAnswer.map(asks => {
      const type = validateType(asks);
      let chartData: IChart | IText = {
        type: 'text',
        ask: '',
        data: [],
        size: 0,
        memberAnsweredNames: [],
      };
      if (type === 'text') {
        chartData = {
          type: type,
          ask: asks.ask,
          data: answersDataFilter(asks) as ITextData[],
          size: asks.survey_response_ask_text.length,
          memberAnsweredNames: asks.survey_response_ask_text.map(answer => answer.survey_user_answer.user.first_name),
        };
      }

      if (type === 'pie') {
        chartData = {
          type: type,
          ask: asks.ask,
          data: answersDataFilter(asks) as (IPie | IBar)[],
          size: asks.survey_ask_options[0].survey_response_options.length,
          memberAnsweredNames: asks.survey_ask_options[0].survey_response_options.map(option => option.survey_user_answer.user.first_name),
        };
      }

      if (type === 'bar') {
        chartData = {
          type: type,
          ask: asks.ask,
          data: answersDataFilter(asks) as (IPie | IBar)[],
          size: asks.survey_ask_options[0].survey_response_options.length,
          memberAnsweredNames: asks.survey_ask_options[0].survey_response_options.map(option => option.survey_user_answer.user.first_name)
        };
      }
      return chartData;
    }));
  }

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const handleCreateDownloadReport = async () => {
    const { payload } = await getCampusSurveyAnswerReport({
      survey_id: survey.id,
    })(dispatch);
    const listCampusSurveyAnswerReport = payload.listCampusSurveyAnswerReport || [];
    if (listCampusSurveyAnswerReport.length === 0) {
      return toast.warn('No hay datos para descargar');
    }
    // BEGIN: Create asks
    // Extract asks from first user
    // const asks: string[] = [];
    const asksObj: { [key: string]: any } = {};
    const firstAnswerForUser = listCampusSurveyAnswerReport[0];
    const surveyName = firstAnswerForUser.survey.title.toUpperCase().replace(' ', '_');
    for (const option of firstAnswerForUser.answer_options) {
      // checking what is the ask of the option, if it is not in the list, add it
      if (!asksObj[option.survey_ask_option.survey_ask.ask]) {
        // asks.push(option.survey_ask_option.survey_ask.ask);
        asksObj[option.survey_ask_option.survey_ask.ask] = true;
      }
    }
    // checking what is the ask of the option, if it is not in the list, add it
    for (const answer of firstAnswerForUser.answer_text) {
      if (!asksObj[answer.survey_ask.ask]) {
        // asks.push(answer.survey_ask.ask);
        asksObj[answer.survey_ask.ask] = true;
      }
    }
    // END: Create asks
    // BEGIN: Create answers by user
    const answersForUser: { [key: string]: any; }[] = [];
    for (const answerForUser of listCampusSurveyAnswerReport) {
      let userAnswer: { [key: string]: any; } = {};
      const answers: { [key: string]: any; } = {};
      let user = '';
      for (const option of answerForUser.answer_options) {
        const optionType = option.survey_ask_option.type;
        const optionValue = option.survey_ask_option.value;
        const optionChecked = option.checked;
        const optionAsk = option.survey_ask_option.survey_ask.ask;
        user = option.survey_user_answer.user.first_name;
        if (optionType === 'radio' && optionChecked) {
          answers[optionAsk] = optionValue;
        } else if (optionType === 'checkbox' && optionChecked) { // is checkbox
          if (answers[optionAsk]) {
            answers[optionAsk] = answers[optionAsk] + ',' + optionValue;
          } else {
            answers[optionAsk] = optionValue;
          }
        }
      }
      for (const answer of answerForUser.answer_text) {
        user = answer.survey_user_answer.user.first_name;
        answers[answer.survey_ask.ask] = answer.response;
      }
      userAnswer = {
        name: user,
        answers,
      };
      answersForUser.push(userAnswer);
    }
    // END: Create answers by user

    // BEGIN: Create data_excel
    // Each row is a user, and each column is a ask, so we need to create a matrix of only the answers
    const data_excel: string[][] = [];
    const columns_titles: string[] = ['Nombre'];
    for (const ask in asksObj) {
      columns_titles.push(ask);
    }
    for (const userAnswer of answersForUser) {
      const row: string[] = [];
      row.push(userAnswer.name);
      for (const ask in asksObj) {
        row.push(userAnswer.answers[ask]);
      }
      data_excel.push(row);
    }
    // END: Create data_excel

    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: 'Reporte de sugerencias',
        Subject: 'Reporte de sugerencias',
        Author: 'Círculo Militar',
        CreatedDate: new Date(Date.now()),
    };
    wb.SheetNames.push('Reporte de sugerencias');
    const ws = XLSX.utils.json_to_sheet(data_excel);
    wb.Sheets['Reporte de sugerencias'] = ws;
    // create array with columns width
    const wsCols = columns_titles.map(() => ({ wch: 35 }));
    ws['!cols'] = wsCols;
    // add columns title
    columns_titles.forEach((title, index) => {
        ws[`${String.fromCharCode(65 + index)}1`] = {
            v: title,
            t: 's',
            font: {
                sz: 14,
                bold: true,
            },
        };
    });


    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_${surveyName}_CIRCULOMILITAR.xlsx`);
  }

  return (
    <>
    <div className="mb-8 flex flex-row justify-between gap-4 print:hidden">
      <button className="btn-gray p-2" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row items-center justify-end gap-4 print:hidden">
              Descargar reporte
              <button
                  onClick={handleCreateDownloadReport}
                  className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                          fillRule="evenodd"
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clipRule="evenodd"
                      />
                  </svg>
              </button>
          </div>
          <div className="flex items-center">
            <button
                onClick={() =>
                    navigate('/campus/surveys/new')
                }
                className="btn-yellow flex flex-row items-center gap-2 h-11"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Añadir nueva encuesta
            </button>
          </div>
        </div>
    </div>
    <h2 className="font-bold text-gray-500 mb-6">{ survey.title }</h2>
    <div className="flex flex-row gap-2 items-center mb-12">
      {
        listAsks && listAsks.length > 0 &&
          listAsks.map((_, i) => (
            <button
              key={`pag-${i}`}
              onClick={() => setPage(i)}
              className={page === i ? 'btn-yellow' : 'btn-gray'}
            >
              Pregunta {i+1}
            </button>
          ))
      }
    </div>
    {
      !!listAsks && listAsks.length > 0 ? (
        <>
          <section className="grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)200px] bg-yellow-100 py-2 px-8 mb-6">
            <h3 className="font-bold">{listAsks[page].ask}</h3>
            <p className="text-gray-700">
              {listAsks[page].type === 'bar' ? 'Elección múltiple' : listAsks[page].type === 'pie' ? 'Única elección' : 'Campo de texto'}
            </p>
            <p className="text-gray-700">{listAsks[page].size} Respuestas</p>
          </section>
          {/* Charts */}
          <section>
          {
            listAsks[page].type === 'bar' && (
              <div className="min-w-full max-w-prose h-96">
                <ResponsiveBar
                    data={listAsks[page].data as unknown as BarDatum[]}
                    indexBy="id"
                    keys={['value']}
                    layout="horizontal"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colorBy="indexValue"
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Número de respuestas',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    label={item => `${((item.value! / listAsks[page].size) * 100).toFixed(2)}%`}
                    tooltip={({ value, color, indexValue }) => (
                        <div className="bg-white px-2 py-1 rounded-sm shadow-md flex flex-row items-center justify-center">
                            <p><span className="h-3 w-3 inline-block mr-1.5" style={{backgroundColor: color}}></span>{indexValue}: <strong>{value}</strong></p>
                        </div>
                    )}
                    legends={[
                        {
                            dataFrom: 'indexes',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    role="application"
                />
              </div>
            )
          }
          {
            listAsks[page].type === 'pie' && (
              <div className="min-w-full max-w-prose h-96">
                <ResponsivePie
                  data={listAsks[page].data as (IPie | IBar)[]}
                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                  innerRadius={0}
                  padAngle={3}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'darker',
                              0.2
                          ]
                      ]
                  }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'darker',
                              2
                          ]
                      ]
                  }}
                  legends={[
                      {
                          anchor: 'top-right',
                          direction: 'column',
                          justify: false,
                          translateX: 0,
                          translateY: 56,
                          itemsSpacing: 10,
                          itemWidth: 100,
                          itemHeight: 18,
                          itemTextColor: '#999',
                          itemDirection: 'left-to-right',
                          itemOpacity: 1,
                          symbolSize: 18,
                          symbolShape: 'circle',
                          effects: [
                              {
                                  on: 'hover',
                                  style: {
                                      itemTextColor: '#000'
                                  }
                              }
                          ]
                      }
                  ]}
                  arcLabel={item => `${((item.value / listAsks[page].size) * 100).toFixed(2)}%`}
                />
              </div>
            )
          }
          {
            listAsks[page].type === 'text' &&
            (listAsks[page] as IText).data.map((answer, i) => (
              <div
                key={`text-data-${i}`}
                className="grid grid-cols-[minmax(0,1fr)200px] px-8 items-center justify-between even:bg-gray-50 odd:bg-white odd:hover:bg-gray-50"
              >
                <span className="py-1">{answer.answer}</span>
                <span>
                  {answer.user}
                </span>
              </div>
            ))
          }
          </section>
          {
            listAsks[page].type !== 'text' && (
              <div className="py-10">
                <h3 className="font-bold mb-2">Socios que participaron</h3>
                {listAsks[page].memberAnsweredNames.length > 0 &&  <p>{listAsks[page].memberAnsweredNames.join(', ')}</p>}
              </div>
            )
          }
        </>
      ) : (
        <div className="flex flex-col justify-center items-center gap-2">
          <img src={imgSearching} alt="404" className="w-64" />
          <p className="text-gray-600 text-center font-bold text-2xl">Estamos buscando sus datos...</p>
          <p className="text-gray-600 text-center">Espere un momento por favor</p>
        </div>
      )
    }
    {/* Questions */}
    </>
  )
}