export enum HotelOccupancyPaymentTypes {
  GET_HOTELOCCUPANCYPAYMENTPAG_LOADING = "GET_HOTELOCCUPANCYPAYMENTPAG_LOADING",
  GET_HOTELOCCUPANCYPAYMENTPAG_SUCCESS = "GET_HOTELOCCUPANCYPAYMENTPAG_SUCCESS",
  GET_HOTELOCCUPANCYPAYMENTPAG_FAILURE = "GET_HOTELOCCUPANCYPAYMENTPAG_FAILURE",
  GET_HOTELOCCUPANCYPAYMENT_LOADING = "GET_HOTELOCCUPANCYPAYMENT_LOADING",
  GET_HOTELOCCUPANCYPAYMENT_SUCCESS = "GET_HOTELOCCUPANCYPAYMENT_SUCCESS",
  GET_HOTELOCCUPANCYPAYMENT_FAILURE = "GET_HOTELOCCUPANCYPAYMENT_FAILURE",
  GETONLY_HOTELOCCUPANCYPAYMENT_LOADING = "GETONLY_HOTELOCCUPANCYPAYMENT_LOADING",
  GETONLY_HOTELOCCUPANCYPAYMENT_SUCCESS = "GETONLY_HOTELOCCUPANCYPAYMENT_SUCCESS",
  GETONLY_HOTELOCCUPANCYPAYMENT_FAILURE = "GETONLY_HOTELOCCUPANCYPAYMENT_FAILURE",
  POST_HOTELOCCUPANCYPAYMENT_LOADING = "POST_HOTELOCCUPANCYPAYMENT_LOADING",
  POST_HOTELOCCUPANCYPAYMENT_SUCCESS = "POST_HOTELOCCUPANCYPAYMENT_SUCCESS",
  POST_HOTELOCCUPANCYPAYMENT_FAILURE = "POST_HOTELOCCUPANCYPAYMENT_FAILURE",
  PUT_HOTELOCCUPANCYPAYMENT_LOADING = "PUT_HOTELOCCUPANCYPAYMENT_LOADING",
  PUT_HOTELOCCUPANCYPAYMENT_SUCCESS = "PUT_HOTELOCCUPANCYPAYMENT_SUCCESS",
  PUT_HOTELOCCUPANCYPAYMENT_FAILURE = "PUT_HOTELOCCUPANCYPAYMENT_FAILURE",
  DELETE_HOTELOCCUPANCYPAYMENT_LOADING = "DELETE_HOTELOCCUPANCYPAYMENT_LOADING",
  DELETE_HOTELOCCUPANCYPAYMENT_SUCCESS = "DELETE_HOTELOCCUPANCYPAYMENT_SUCCESS",
  DELETE_HOTELOCCUPANCYPAYMENT_FAILURE = "DELETE_HOTELOCCUPANCYPAYMENT_FAILURE",
}