
import { CampusNotificationsProcess } from "../api";
import { CampusNotificationsAction, CampusNotificationsFilters, CampusNotificationsPost, CampusNotificationsPut,  } from "../interfaces";
import { CampusNotificationsTypes } from "../types";

export const getCampusNotificationsPags = (filters?: CampusNotificationsFilters) => async (dispatch: (state: CampusNotificationsAction) => CampusNotificationsAction) => {
  dispatch({
    type: CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNotificationsProcess = new CampusNotificationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNotificationsPag = await campusNotificationsProcess.getCampusNotifications(filters);
    return dispatch({
      type: CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONSPAG_SUCCESS,
      payload: {
        pagCampusNotifications: campusNotificationsPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getCampusNotifications = (filters?: CampusNotificationsFilters) => async (dispatch: (state: CampusNotificationsAction) => CampusNotificationsAction) => {
  dispatch({
    type: CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNotificationsProcess = new CampusNotificationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNotificationsPag = await campusNotificationsProcess.getCampusNotifications({
      ...filters,
      data_all: 'YES',
    });
    const campusNotifications = campusNotificationsPag.results;
    return dispatch({
      type: CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONS_SUCCESS,
      payload: {
        listCampusNotifications: campusNotifications,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postCampusNotifications = (campusNotifications: CampusNotificationsPost) => async (dispatch: (state: CampusNotificationsAction) => CampusNotificationsAction) => {
  dispatch({
    type: CampusNotificationsTypes.POST_CAMPUSNOTIFICATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNotificationsProcess = new CampusNotificationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNotificationsTable = await campusNotificationsProcess.postCampusNotifications(campusNotifications);
    return dispatch({
      type: CampusNotificationsTypes.POST_CAMPUSNOTIFICATIONS_SUCCESS,
      payload: {
        campusNotifications: campusNotificationsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNotificationsTypes.POST_CAMPUSNOTIFICATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putCampusNotifications = (campusNotifications: CampusNotificationsPut) => async (dispatch: (state: CampusNotificationsAction) => CampusNotificationsAction) => {
  dispatch({
    type: CampusNotificationsTypes.PUT_CAMPUSNOTIFICATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNotificationsProcess = new CampusNotificationsProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusNotificationsTable = await campusNotificationsProcess.putCampusNotifications(campusNotifications);
    return dispatch({
      type: CampusNotificationsTypes.PUT_CAMPUSNOTIFICATIONS_SUCCESS,
      payload: {
        campusNotifications: campusNotificationsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNotificationsTypes.PUT_CAMPUSNOTIFICATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteCampusNotifications = (id: number) => async (dispatch: (state: CampusNotificationsAction) => CampusNotificationsAction) => {
  dispatch({
    type: CampusNotificationsTypes.DELETE_CAMPUSNOTIFICATIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusNotificationsProcess = new CampusNotificationsProcess();
    // dont'n import the result of the process, only that dont't have error
    await campusNotificationsProcess.deleteCampusNotifications(id);
    return dispatch({
      type: CampusNotificationsTypes.DELETE_CAMPUSNOTIFICATIONS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusNotificationsTypes.DELETE_CAMPUSNOTIFICATIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

