export enum HotelImageTypes {
  GET_HOTELIMAGEPAG_LOADING = "GET_HOTELIMAGEPAG_LOADING",
  GET_HOTELIMAGEPAG_SUCCESS = "GET_HOTELIMAGEPAG_SUCCESS",
  GET_HOTELIMAGEPAG_FAILURE = "GET_HOTELIMAGEPAG_FAILURE",
  GET_HOTELIMAGE_LOADING = "GET_HOTELIMAGE_LOADING",
  GET_HOTELIMAGE_SUCCESS = "GET_HOTELIMAGE_SUCCESS",
  GET_HOTELIMAGE_FAILURE = "GET_HOTELIMAGE_FAILURE",
  GETONLY_HOTELIMAGE_LOADING = "GETONLY_HOTELIMAGE_LOADING",
  GETONLY_HOTELIMAGE_SUCCESS = "GETONLY_HOTELIMAGE_SUCCESS",
  GETONLY_HOTELIMAGE_FAILURE = "GETONLY_HOTELIMAGE_FAILURE",
  POST_HOTELIMAGE_LOADING = "POST_HOTELIMAGE_LOADING",
  POST_HOTELIMAGE_SUCCESS = "POST_HOTELIMAGE_SUCCESS",
  POST_HOTELIMAGE_FAILURE = "POST_HOTELIMAGE_FAILURE",
  PUT_HOTELIMAGE_LOADING = "PUT_HOTELIMAGE_LOADING",
  PUT_HOTELIMAGE_SUCCESS = "PUT_HOTELIMAGE_SUCCESS",
  PUT_HOTELIMAGE_FAILURE = "PUT_HOTELIMAGE_FAILURE",
  DELETE_HOTELIMAGE_LOADING = "DELETE_HOTELIMAGE_LOADING",
  DELETE_HOTELIMAGE_SUCCESS = "DELETE_HOTELIMAGE_SUCCESS",
  DELETE_HOTELIMAGE_FAILURE = "DELETE_HOTELIMAGE_FAILURE",
}