import { Popover } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from '../redux/actions';
import { User } from '../redux/interfaces';
import noUserImg from '../assets/images/undraw_handcrafts_cat.png';


export function ProfileMenuPopover({ user }: { user: User }) {
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(logoutUser());
  return (
    <div className="bg-white rounded-lg shadow-lg px-3 py-5 min-w-[270px] z-50" >
      <div className="flex flex-col justify-between" >
        <div className="relative flex items-center justify-between px-2">
          {
            !user.photo_url ?
                <img className={`block h-11 w-11 ring ring-offset-1 ring-gray-300 aspect-square rounded-full object-cover hover:opacity-80 active:opacity-70`} src={noUserImg} alt="Profile" />
              : <img className="block max-h-11 w-11 ring-4 ring-gray-300 aspect-square rounded-full object-cover" src={user.photo_url} alt="" />
          }
          <span className="ml-3 text-md text-black font-bold">
            {user.first_name}
          </span>
          <Popover.Button as="div" className="bg-gray-200 rounded-md cursor-pointer p-1 ml-4 hover:bg-gray-300 focus:bg-gray-400 active:bg-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </Popover.Button>
        </div>
        <div className="flex flex-col mt-4">
          <div className="relative w-full group cursor-pointer px-3 py-2.5">
            <Popover.Button as="div">
              <Link to="/profile">
                <div className="flex items-center text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <span className="ml-2">Perfil</span>
                </div>
                <div className="absolute top-0 left-0 w-full h-full group-hover:bg-gray-400 group-active:bg-gray-500 opacity-20 rounded-md"></div>
              </Link>
            </Popover.Button>
          </div>
          {/* <div className="relative w-full group cursor-pointer px-3 py-2.5">
            <Popover.Button as="div">
              <Link to="/settings">
                <div className="flex items-center text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span className="ml-2">Ajustes</span>
                </div>
                <div className="absolute top-0 left-0 w-full h-full group-hover:bg-gray-400 group-active:bg-gray-500 opacity-20 rounded-md"></div>
              </Link>
            </Popover.Button>
          </div> */}
          <button className="relative w-full group cursor-pointer px-3 py-2.5 border-t-2 border-gray-50" onClick={handleLogout}>
            <div className="flex items-center text-red-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              <span className="ml-2">Cerrar sesión</span>
            </div>
            <div className="absolute top-0 left-0 w-full h-full group-hover:bg-red-400 group-active:bg-red-500 opacity-20 rounded-md"></div>
          </button>
        </div>
      </div >
    </div>
  )
}
