import axios, { AxiosInstance } from "axios";
import * as localStorage from "../../../utils/localStorage";

export const getToken = (): string | null => {
  const token = localStorage.loadObject("access_token");
  if (token) {
    return token;
  }
  return null;
};

export const fetcher = (urlApi: string): AxiosInstance => {
  const API = axios.create({
    baseURL: urlApi,
    headers: {
      "Content-Type": "application/json"
    },
  });

  API.interceptors.request.use(async function (config) {
    const token = getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    }
    return config;
  });

  // API interceptor for response that refresh token if expired and
  // return new token to client side and repeat request with new token
  API.interceptors.response.use(async function (response) {
    return response;
  }, async function (error) {
    if (error.response.status === 401) {
      const refresh_token = localStorage.loadObject("refresh_token");
      if (refresh_token) {
        try {
          const response = await axios.post(`${urlApi}/token/refresh/`, { refresh: refresh_token });
          localStorage.saveObject("access_token", response.data.access);
          error.config.headers['Authorization'] = `Bearer ${response.data.access}`;
          return axios.request(error.config);
        } catch (error) {
          localStorage.clear();
          return Promise.reject(error);;
        }
      }
    }
    return Promise.reject(error);
  });

  return API;
};
