import { HolidaysProcess } from "../api";
import { HolidaysAction, HolidaysFilters, HolidaysPost, HolidaysPut } from "../interfaces";
import { HolidaysTypes } from "../types";

// apply the reducer
export const getHolidays = (filters?: HolidaysFilters) => async (dispatch: (state: HolidaysAction) => HolidaysAction) => {
  dispatch({
    type: HolidaysTypes.GET_HOLIDAYS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const holidaysProcess = new HolidaysProcess();
    // dont'n import the result of the process, only that dont't have error
    const holidaysPag = await holidaysProcess.getHolidays({
      ...filters,
      data_all: 'YES',
    });
    const holidays = holidaysPag.results;
    return dispatch({
      type: HolidaysTypes.GET_HOLIDAYS_SUCCESS,
      payload: {
        listHolidays: holidays,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HolidaysTypes.GET_HOLIDAYS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHolidays = (msSchedule: HolidaysPost) => async (dispatch: (state: HolidaysAction) => HolidaysAction) => {
  dispatch({
    type: HolidaysTypes.POST_HOLIDAYS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const holidaysProcess = new HolidaysProcess();
    // dont'n import the result of the process, only that dont't have error
    const msScheduleRes = await holidaysProcess.postHolidays(msSchedule);
    return dispatch({
      type: HolidaysTypes.POST_HOLIDAYS_SUCCESS,
      payload: {
        holidays: msScheduleRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HolidaysTypes.POST_HOLIDAYS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHolidays = (msSchedule: HolidaysPut) => async (dispatch: (state: HolidaysAction) => HolidaysAction) => {
  dispatch({
    type: HolidaysTypes.PUT_HOLIDAYS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const holidaysProcess = new HolidaysProcess();
    // dont'n import the result of the process, only that dont't have error
    const msScheduleRes = await holidaysProcess.putHolidays(msSchedule);
    return dispatch({
      type: HolidaysTypes.PUT_HOLIDAYS_SUCCESS,
      payload: {
        holidays: msScheduleRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HolidaysTypes.PUT_HOLIDAYS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHolidays = (id_schedule: number) => async (dispatch: (state: HolidaysAction) => HolidaysAction) => {
  dispatch({
    type: HolidaysTypes.DELETE_HOLIDAYS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const holidaysProcess = new HolidaysProcess();
    // dont'n import the result of the process, only that dont't have error
    await holidaysProcess.deleteHolidays(id_schedule);
    return dispatch({
      type: HolidaysTypes.DELETE_HOLIDAYS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HolidaysTypes.DELETE_HOLIDAYS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};