import { ErrorMessage, useField } from 'formik';

interface Props {
    label?: string;
    name: string;
    type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'date' | 'time' | 'datetime-local' | 'month' | 'week' | 'color' | 'textarea';
    placeholder?: string;
    className?: string;
    containerClassName?: string;
    [x: string]: any;
}


export const FormInput = ( { label, className, containerClassName, ...props }: Props ) => {

    const [ field ] = useField(props)

    return (
        <div className={`flex flex-col gap-1 ${containerClassName ? containerClassName : '' }`}>
            { label && <label htmlFor={ props.id || props.name }>{ label }</label> }
            <input className={`input-yellow ${className ? className : ''}`} { ...field } { ...props } />
            <ErrorMessage name={ props.name } component="span" className="text-red-500" />
        </div>
    )
}