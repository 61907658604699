
import { CampusSurveyProcess } from "../api";
import { CampusSurveyAction, CampusSurveyFilters, CampusSurveyPost, CampusSurveyPut,  } from "../interfaces";
import { CampusSurveyTypes } from "../types";

export const getCampusSurveyPags = (filters?: CampusSurveyFilters) => async (dispatch: (state: CampusSurveyAction) => CampusSurveyAction) => {
  dispatch({
    type: CampusSurveyTypes.GET_CAMPUSSURVEYPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyProcess = new CampusSurveyProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyPag = await campusSurveyProcess.getCampusSurvey(filters);
    return dispatch({
      type: CampusSurveyTypes.GET_CAMPUSSURVEYPAG_SUCCESS,
      payload: {
        pagCampusSurvey: campusSurveyPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyTypes.GET_CAMPUSSURVEYPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getCampusSurvey = (filters?: CampusSurveyFilters) => async (dispatch: (state: CampusSurveyAction) => CampusSurveyAction) => {
  dispatch({
    type: CampusSurveyTypes.GET_CAMPUSSURVEY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyProcess = new CampusSurveyProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyPag = await campusSurveyProcess.getCampusSurvey({
      ...filters,
      data_all: 'YES',
    });
    const campusSurvey = campusSurveyPag.results;
    return dispatch({
      type: CampusSurveyTypes.GET_CAMPUSSURVEY_SUCCESS,
      payload: {
        listCampusSurvey: campusSurvey,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyTypes.GET_CAMPUSSURVEY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postCampusSurvey = (campusSurvey: CampusSurveyPost) => async (dispatch: (state: CampusSurveyAction) => CampusSurveyAction) => {
  dispatch({
    type: CampusSurveyTypes.POST_CAMPUSSURVEY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyProcess = new CampusSurveyProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyTable = await campusSurveyProcess.postCampusSurvey(campusSurvey);
    return dispatch({
      type: CampusSurveyTypes.POST_CAMPUSSURVEY_SUCCESS,
      payload: {
        campusSurvey: campusSurveyTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyTypes.POST_CAMPUSSURVEY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putCampusSurvey = (campusSurvey: CampusSurveyPut) => async (dispatch: (state: CampusSurveyAction) => CampusSurveyAction) => {
  dispatch({
    type: CampusSurveyTypes.PUT_CAMPUSSURVEY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyProcess = new CampusSurveyProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusSurveyTable = await campusSurveyProcess.putCampusSurvey(campusSurvey);
    return dispatch({
      type: CampusSurveyTypes.PUT_CAMPUSSURVEY_SUCCESS,
      payload: {
        campusSurvey: campusSurveyTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyTypes.PUT_CAMPUSSURVEY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteCampusSurvey = (id: number) => async (dispatch: (state: CampusSurveyAction) => CampusSurveyAction) => {
  dispatch({
    type: CampusSurveyTypes.DELETE_CAMPUSSURVEY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusSurveyProcess = new CampusSurveyProcess();
    // dont'n import the result of the process, only that dont't have error
    await campusSurveyProcess.deleteCampusSurvey(id);
    return dispatch({
      type: CampusSurveyTypes.DELETE_CAMPUSSURVEY_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusSurveyTypes.DELETE_CAMPUSSURVEY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

