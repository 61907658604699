
import { RestaurantDishesAction, RestaurantDishesPayload } from "../interfaces";
import { RestaurantDishesTypes } from "../types";

const initialState: RestaurantDishesPayload = {
  loading: false,
  success: false,
  error: null,
  restaurantDishes: null,
  listRestaurantDishes: [],
  pagRestaurantDishes: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const restaurantDishes = (state = initialState, action: RestaurantDishesAction): RestaurantDishesPayload => {
  const { loading, success, error, restaurantDishes, listRestaurantDishes, pagRestaurantDishes } = action.payload || {};
  switch (action.type) {
    case RestaurantDishesTypes.GET_RESTAURANTDISHESPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantDishesTypes.GET_RESTAURANTDISHESPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantDishesTypes.GET_RESTAURANTDISHESPAG_SUCCESS:
      return {
        ...state,
        pagRestaurantDishes,
        loading,
        success,
      };
    case RestaurantDishesTypes.GET_RESTAURANTDISHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantDishesTypes.GET_RESTAURANTDISHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantDishesTypes.GET_RESTAURANTDISHES_SUCCESS:
      return {
        ...state,
        listRestaurantDishes,
        loading,
        success,
      };
    case RestaurantDishesTypes.GETONLY_RESTAURANTDISHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantDishesTypes.GETONLY_RESTAURANTDISHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantDishesTypes.GETONLY_RESTAURANTDISHES_SUCCESS:
      return {
        ...state,
        restaurantDishes,
        loading,
        success,
      };
    case RestaurantDishesTypes.POST_RESTAURANTDISHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantDishesTypes.POST_RESTAURANTDISHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantDishesTypes.POST_RESTAURANTDISHES_SUCCESS:
      return {
        ...state,
        restaurantDishes,
        loading,
        success,
      };
    case RestaurantDishesTypes.PUT_RESTAURANTDISHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantDishesTypes.PUT_RESTAURANTDISHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantDishesTypes.PUT_RESTAURANTDISHES_SUCCESS:
      return {
        ...state,
        restaurantDishes,
        loading,
        success,
      };
    case RestaurantDishesTypes.DELETE_RESTAURANTDISHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantDishesTypes.DELETE_RESTAURANTDISHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantDishesTypes.DELETE_RESTAURANTDISHES_SUCCESS:
      return {
        ...state,
        restaurantDishes,
        loading,
        success,
      };
    default:
      return state;
  }
};

