
export enum CampusNotificationsTypes {
  GET_CAMPUSNOTIFICATIONSPAG_LOADING = "GET_CAMPUSNOTIFICATIONSPAG_LOADING",
  GET_CAMPUSNOTIFICATIONSPAG_SUCCESS = "GET_CAMPUSNOTIFICATIONSPAG_SUCCESS",
  GET_CAMPUSNOTIFICATIONSPAG_FAILURE = "GET_CAMPUSNOTIFICATIONSPAG_FAILURE",
  GET_CAMPUSNOTIFICATIONS_LOADING = "GET_CAMPUSNOTIFICATIONS_LOADING",
  GET_CAMPUSNOTIFICATIONS_SUCCESS = "GET_CAMPUSNOTIFICATIONS_SUCCESS",
  GET_CAMPUSNOTIFICATIONS_FAILURE = "GET_CAMPUSNOTIFICATIONS_FAILURE",
  GETONLY_CAMPUSNOTIFICATIONS_LOADING = "GETONLY_CAMPUSNOTIFICATIONS_LOADING",
  GETONLY_CAMPUSNOTIFICATIONS_SUCCESS = "GETONLY_CAMPUSNOTIFICATIONS_SUCCESS",
  GETONLY_CAMPUSNOTIFICATIONS_FAILURE = "GETONLY_CAMPUSNOTIFICATIONS_FAILURE",
  POST_CAMPUSNOTIFICATIONS_LOADING = "POST_CAMPUSNOTIFICATIONS_LOADING",
  POST_CAMPUSNOTIFICATIONS_SUCCESS = "POST_CAMPUSNOTIFICATIONS_SUCCESS",
  POST_CAMPUSNOTIFICATIONS_FAILURE = "POST_CAMPUSNOTIFICATIONS_FAILURE",
  PUT_CAMPUSNOTIFICATIONS_LOADING = "PUT_CAMPUSNOTIFICATIONS_LOADING",
  PUT_CAMPUSNOTIFICATIONS_SUCCESS = "PUT_CAMPUSNOTIFICATIONS_SUCCESS",
  PUT_CAMPUSNOTIFICATIONS_FAILURE = "PUT_CAMPUSNOTIFICATIONS_FAILURE",
  DELETE_CAMPUSNOTIFICATIONS_LOADING = "DELETE_CAMPUSNOTIFICATIONS_LOADING",
  DELETE_CAMPUSNOTIFICATIONS_SUCCESS = "DELETE_CAMPUSNOTIFICATIONS_SUCCESS",
  DELETE_CAMPUSNOTIFICATIONS_FAILURE = "DELETE_CAMPUSNOTIFICATIONS_FAILURE",
}