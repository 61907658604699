import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import alerts from "../../utils/alerts";
import { HotelBankAccountTable, User } from '../../redux/interfaces';
import useForm from '../../utils/hooks/useForm';
import { deleteHotelBankAccount, getHotelBankAccount, postHotelBankAccount, putHotelBankAccount } from '../../redux/actions';
import { RootState } from '../../redux/store';

export const HotelBankingParametersPage = () => {
    const dispatch = useDispatch();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const { listHotelBankAccount } = useSelector((state: RootState) => state.hotelBankAccount);
    const [formAction, setFormAction] = useState<'update-bank-account' | 'create-bank-account'>('create-bank-account');
    const {
        id,
        nameBank,
        accountType,
        numberAccount,
        nameAccountHolder,
        documentNumber,
        email,
        handleOnChangeInput,
        handleUpdateState,
    } = useForm<any>({
        id: -1,
        nameBank: '',
        accountType: '',
        numberAccount: '',
        nameAccountHolder: '',
        documentNumber: '',
        email: ''
    });

    const pullBankAccount = () => {
        getHotelBankAccount({
            campus_id: user.campus.id,
        })(dispatch);
    }

    useEffect(() => {
        pullBankAccount();
    }, []); // eslint-disable-line

    const handEditBankAccount = (bankAccount: HotelBankAccountTable) => {
        handleUpdateState({
            id: bankAccount.id,
            nameBank: bankAccount.name_bank,
            accountType: bankAccount.account_type,
            numberAccount: bankAccount.account_number,
            nameAccountHolder: bankAccount.account_holder,
            documentNumber: bankAccount.document_number,
            email: bankAccount.email,
        });
        setFormAction('update-bank-account');
    };

    const handleSubmitBankAccount = async (e: FormEvent) => {
        // get name from submit button
        e.preventDefault();
        if (formAction === 'create-bank-account') {
            handleCreateBankAccount();
        } else {
            handleUpdateBankAccount();
        }
    };

    const handleCreateBankAccount = async () => {
        const { payload } = await postHotelBankAccount({
            account_holder: nameAccountHolder,
            account_number: numberAccount,
            document_number: documentNumber,
            account_type: accountType,
            email: email,
            name_bank: nameBank,
            campus_id: user.campus.id
        })(dispatch);

        if (!payload.success && payload.error && payload.error.message) {
            return toast.error(payload.error.message);
        }

        toast.success('Se creó la cuenta bancaria correctamente');
        resetToCreateForm();
    }

    const handleUpdateBankAccount = async () => {
        const { payload } = await putHotelBankAccount({
            id: id,
            account_holder: nameAccountHolder,
            account_number: numberAccount,
            document_number: documentNumber,
            account_type: accountType,
            email: email,
            name_bank: nameBank,
            campus_id: user.campus.id
        })(dispatch);

        if (!payload.success && payload.error && payload.error.message) {
            return toast.error(payload.error.message);
        }

        toast.success('Datos bancarios actualizados correctamente');
        resetToCreateForm();
    }

    const handleDeleteBankAccount = async (bankAccount: HotelBankAccountTable) => {
        const userRes = await alerts.warning({
            title: 'Última oportunidad',
            text: `Está apunto de eliminar <b>"${bankAccount.name_bank}"</b>. Una vez realizada esta acción no se podrá revertir.`
        });

        if (!userRes.isConfirmed) return;

        const { payload } = await deleteHotelBankAccount(bankAccount.id)(dispatch);

        if (!payload.success && payload.error && payload.error.message) {
            return toast.error(payload.error.message);
        }

        toast.success('Cuenta bancaria eliminada correctamente');
        resetToCreateForm();
    }

    const reloadForm = () => {
        // Reload all data
        pullBankAccount();
        // Reset Form
        handleUpdateState({
            nameBank: '',
            accountType: '',
            numberAccount: '',
            nameAccountHolder: '',
            documentNumber: '',
            email: ''
        });
    };

    const resetToCreateForm = () => {
        setFormAction('create-bank-account');
        reloadForm();
    }

    return (
        <div>
            <form onSubmit={handleSubmitBankAccount}>
                <div
                    onClick={() => resetToCreateForm()}
                    className={`mb-4 btn-black w-fit ${formAction === 'create-bank-account' ? 'hidden' : 'block'}`}
                >
                    Nuevos datos bancarios
                <svg xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                </div>

                <h2 className="font-bold text-gray-500 mb-9">
                    Parámetros Bancarios
                </h2>

                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 ">
                        <input
                            id="nameBank"
                            name="nameBank"
                            type="text"
                            maxLength={150}
                            value={nameBank}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Nombre de Banco"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="accountType"
                            name="accountType"
                            type="text"
                            maxLength={150}
                            value={accountType}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Tipo de Cuenta"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="numberAccount"
                            name="numberAccount"
                            type="text"
                            maxLength={150}
                            value={numberAccount}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Número de Cuenta"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="nameAccountHolder"
                            name="nameAccountHolder"
                            type="text"
                            maxLength={150}
                            value={nameAccountHolder}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Nombre del Titular de la cuenta"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="documentNumber"
                            name="documentNumber"
                            type="text"
                            maxLength={150}
                            value={documentNumber}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Número de Cédula"
                        />
                    </div>
                    <div className="col-span-2 ">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            maxLength={150}
                            value={email}
                            onChange={handleOnChangeInput}
                            required
                            className="w-full relative  block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                            placeholder="Correo Electrónico"
                        />
                    </div>
                    <div></div>
                    <div className="col-span-2 flex justify-end">
                        <div>
                            <button
                                name={formAction}
                                type="submit"
                                className={`my-8 ${formAction === 'create-bank-account' ? 'btn-yellow' : 'btn-black'}`}
                            >
                                <h1>{formAction === 'create-bank-account' ? 'Crear Cuenta Bancaria' : 'Actualizar Datos Bancarios'}</h1>
                            </button>
                        </div>
                    </div>
                </div>
            </form>


            <h2 className="font-bold text-gray-500 mb-4">
                Cuentas bancarias
            </h2>

            <div className="pb-8 divide-gray-500/25">
                <div className="  divide-gray-500/25 mb-8">
                    {listHotelBankAccount && listHotelBankAccount.map((bankAccount, i) => (
                        <div
                            key={`bankAccount-${i}`}
                            className="flex flex-row items-center justify-between odd:bg-gray-50 even:bg-white even:hover:bg-gray-50"
                        >
                            <span className="basis-3/5 px-4 font-bold text-black">
                                {/* {multipleServicesCategory.name} */}
                                {bankAccount.account_holder}
                            </span>
                            <span className="basis-3/5 px-4 font-bold text-black">
                                {/* {multipleServicesCategory.name} */}
                                {bankAccount.name_bank}
                            </span>
                            <span className="mr-6 flex flex-row gap-1">
                                <button
                                    onClick={() => handEditBankAccount(bankAccount)}
                                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-700"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                </button>
                                <button
                                    onClick={() => handleDeleteBankAccount(bankAccount)}
                                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-700"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
