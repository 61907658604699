import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getRestaurantCategories, postRestaurantCategories, putRestaurantCategories, deleteRestaurantCategories } from '../../redux/actions';
import { RestaurantCategoriesTable, RestaurantTable } from '../../redux/interfaces';
import Alert from '../../utils/alerts';
import { RootState } from '../../redux/store';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormInput } from '../../components/form/FormInput';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';

export const RestaurantCategoryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { restaurant } = state as { restaurant: RestaurantTable };
  const { listRestaurantCategories } = useSelector((state: RootState) => state.restaurantCategories);
  const [ formAction, setFormAction ] = useState<'update-form' | 'create-form'>('create-form');

  // Formik initializer
  const initalValues = {
    id: -1,
    nameCategory: '',
  };
  const validationSchema = Yup.object().shape({
    nameCategory: Yup.string().max(255, 'El nombre no debe tener más de 255 carácteres').required('El nombre es requerido'),
  });

  // Auxiliar functions
  const fetchData = async () => {
    getRestaurantCategories({
      restaurant_id: restaurant.id
    })(dispatch);
  };

  const handleReset = () => {
    // Reload all data
    fetchData();
    // Reset Form
    setFormAction('create-form');
  }

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  type SetValuesType = (values: React.SetStateAction<typeof initalValues>, shouldValidate?: boolean) => void;
  const handleEditCategory = async (category: RestaurantCategoriesTable, setValues: SetValuesType) => {
    setFormAction('update-form');
    setValues({
      id: category.id,
      nameCategory: category.name,
    });
  };

  const handleSubmitCategory = (values: typeof initalValues, actions: FormikHelpers<typeof initalValues>) => {
    if (formAction === 'create-form') {
      handleCreateCategory(values, actions);
    } else {
      handleUpdateCategory(values, actions);
    }
  };

  const handleCreateCategory = async (values: typeof initalValues, actions: FormikHelpers<typeof initalValues>) => {
    const { payload } = await postRestaurantCategories({
      name: values.nameCategory,
      restaurant_id: restaurant.id,
    })(dispatch);

    const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success(`${values.nameCategory} creada con éxito.`);
    actions.resetForm();
  };

  const handleUpdateCategory = async (values: typeof initalValues, actions: FormikHelpers<typeof initalValues>) => {
    const { payload } = await putRestaurantCategories({
      id: values.id,
      name: values.nameCategory,
      restaurant_id: restaurant.id,
    })(dispatch);

    const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success(`${values.nameCategory} actualizada con éxito.`);
    actions.resetForm();
  };

  const handleDeleteCategory = async (category: RestaurantCategoriesTable, resetForm: () => void) => {
    const { isConfirmed } = await Alert.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${category.name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteRestaurantCategories(category.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success(`${category.name} creada con éxito.`);
        resetForm();
      } else {
        toast.error(payload.error?.message, { type: 'error' });
      }
    }
  };

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitCategory}
      onReset={handleReset}
    >
      {({ setValues, resetForm }) => (
        <>
          <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <div className={`flex flex-row ${formAction === 'create-form' ? 'justify-end' : 'justify-between'}`}>
            <button
              type="button"
              onClick={() => resetForm()}
              className={`mb-4 btn-black w-fit ${formAction === 'create-form' ? 'hidden' : 'block'}`}
            >
              Crear nuevo salón
              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <h2 className="font-bold text-gray-500">
            Categorías
          </h2>
          <Form className="mt-4 grid grid-cols-5 items-start gap-4 mb-16">
            <FormInput
              name="nameCategory"
              placeholder='Nombre de la categoría'
              containerClassName="col-span-4"
              required
            />
            <button
              name={formAction}
              type="submit"
              className={`w-full h-11 ${formAction === 'create-form' ? 'btn-yellow' : 'btn-black'}`}
            >
              {formAction === 'create-form' ? 'Agregar' : 'Actualizar'}
            </button>
          </Form>
          {/* NO FORM */}
          <div className="pb-16">
            <h1 className="grid grid-cols-[minmax(0,1fr)_120px] font-bold text-black mb-4">
                <span className="basis-[73%]">Categorías agregadas</span>
                <span>Acciones</span>
            </h1>
            {(listRestaurantCategories &&
              listRestaurantCategories.length > 0) ?
              listRestaurantCategories.map((category, i) => (
                <div
                  key={`form-${i}`}
                  className="grid grid-cols-[minmax(0,1fr)_120px] items-center justify-between text-gray-500 even:bg-gray-50 odd:bg-white odd:hover:bg-gray-50"
                >
                  <span className="px-4 font-bold">{category.name}</span>
                  <span className="mr-6 flex flex-row gap-1">
                    <button
                      onClick={() => handleEditCategory(category, setValues)}
                      className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleDeleteCategory(category, resetForm)}
                      className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </span>
                </div>
              )) : (
                <div className="bg-gray-50 px-4">
                  <span className="font-bold text-black">No hay categorías disponibles</span>
                </div>
              )}
          </div>
        </>
      )}
    </Formik>
  );
};
