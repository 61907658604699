
export enum RestaurantSchedulesTypes {
  GET_RESTAURANTSCHEDULESPAG_LOADING = "GET_RESTAURANTSCHEDULESPAG_LOADING",
  GET_RESTAURANTSCHEDULESPAG_SUCCESS = "GET_RESTAURANTSCHEDULESPAG_SUCCESS",
  GET_RESTAURANTSCHEDULESPAG_FAILURE = "GET_RESTAURANTSCHEDULESPAG_FAILURE",
  GET_RESTAURANTSCHEDULES_LOADING = "GET_RESTAURANTSCHEDULES_LOADING",
  GET_RESTAURANTSCHEDULES_SUCCESS = "GET_RESTAURANTSCHEDULES_SUCCESS",
  GET_RESTAURANTSCHEDULES_FAILURE = "GET_RESTAURANTSCHEDULES_FAILURE",
  GETONLY_RESTAURANTSCHEDULES_LOADING = "GETONLY_RESTAURANTSCHEDULES_LOADING",
  GETONLY_RESTAURANTSCHEDULES_SUCCESS = "GETONLY_RESTAURANTSCHEDULES_SUCCESS",
  GETONLY_RESTAURANTSCHEDULES_FAILURE = "GETONLY_RESTAURANTSCHEDULES_FAILURE",
  POST_RESTAURANTSCHEDULES_LOADING = "POST_RESTAURANTSCHEDULES_LOADING",
  POST_RESTAURANTSCHEDULES_SUCCESS = "POST_RESTAURANTSCHEDULES_SUCCESS",
  POST_RESTAURANTSCHEDULES_FAILURE = "POST_RESTAURANTSCHEDULES_FAILURE",
  PUT_RESTAURANTSCHEDULES_LOADING = "PUT_RESTAURANTSCHEDULES_LOADING",
  PUT_RESTAURANTSCHEDULES_SUCCESS = "PUT_RESTAURANTSCHEDULES_SUCCESS",
  PUT_RESTAURANTSCHEDULES_FAILURE = "PUT_RESTAURANTSCHEDULES_FAILURE",
  DELETE_RESTAURANTSCHEDULES_LOADING = "DELETE_RESTAURANTSCHEDULES_LOADING",
  DELETE_RESTAURANTSCHEDULES_SUCCESS = "DELETE_RESTAURANTSCHEDULES_SUCCESS",
  DELETE_RESTAURANTSCHEDULES_FAILURE = "DELETE_RESTAURANTSCHEDULES_FAILURE",
}