import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactChild, ReactFragment, ReactPortal } from 'react';

type ModalProps = {
  children: ReactChild | ReactChild[] | ReactFragment | ReactPortal;
  className?: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Modal = ({ children, title, isOpen, onClose, className = '' }: ModalProps) => {

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className={`inline-block w-full max-w-md max-h-[calc(100vh-100px)] p-6 my-8 overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${className}`}>
                <Dialog.Title
                  as="h3"
                  className={`text-lg font-bold leading-6 text-gray-500 flex items-center ${title ? 'justify-between' : 'justify-end'}`}
                >
                  {title}
                  <button onClick={onClose} className="bg-gray-200 rounded-md cursor-pointer p-1 ml-4 hover:bg-gray-300 focus:bg-gray-400 active:bg-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </Dialog.Title>
                <Dialog.Description as={Fragment}>
                  {children}
                </Dialog.Description>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}