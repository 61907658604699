import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { Modal } from '../../components';
import { Fragment, useEffect, useState } from 'react';
import { getHotelReservation, getUserById, putHotelReservation } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { HotelReservationTable, User } from '../../redux/interfaces';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { hotelPaymentMethods } from '../../utils/hotelPaymentMethods';
import { RootState } from '../../redux/store';

export const HotelSchedulePage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [reservationModalData, setReservationModalData] = useState<any>();
  const [reservationModalDataPaids, setReservationModalDataPaids] = useState<any>({
    has_paid: true
  });
  const [scheduleReservations, setScheduleReservations] = useState<ScheduleType[]>([]);
  type ScheduleType = {
    title: string;
    date: string;
    data: any;
  }
  const reservationStatus: {
    [key: string]: {
      labelState: string;
      btnSuccessColor: string;
      btnDangerColor: string;
      btnSuccessText: string;
      btnDangerText: string;
      btnSuccessShow: boolean;
      btnDangerShow: boolean;
      btnSuccessClick: (...args: any) => void,
      btnDangerClick: (...args: any) => void,
      introSectionColor: string;
      endSectionColor: string;
      cardBgColor: string;
      cardTextColor: string;
      cardIcon: any;
    }
  } = {
    PENDING: {
      labelState: 'Pendiente',
      btnSuccessColor: 'bg-yellow-500 text-black',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Aceptar Solicitud',
      btnDangerText: 'Rechazar',
      btnSuccessShow: true,
      btnDangerShow: true,
      btnSuccessClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'BY_CONFIRMED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CANCELED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      introSectionColor: 'bg-gray-200',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-yellow-100 border-yellow-500',
      cardTextColor: 'text-yellow-500',
      cardIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
        </svg>
      ),
    },
    BY_CONFIRMED: {
      labelState: 'Por Confirmar',
      btnSuccessColor: 'bg-orange-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Habitación confirmada',
      btnDangerText: 'Rechazar',
      btnSuccessShow: true,
      btnDangerShow: true,
      btnSuccessClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CONFIRMED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CANCELED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      introSectionColor: 'bg-orange-100',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-orange-100 border-orange-500',
      cardTextColor: 'text-orange-500',
      cardIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
        </svg>
      ),
    },
    CONFIRMED: {
      labelState: 'Pendiente Check-In',
      btnSuccessColor: 'bg-green-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Check-in Completo',
      btnDangerText: 'El socio no se presentó',
      btnSuccessShow: true,
      btnDangerShow: true,
      btnSuccessClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CHECKING'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CANCELED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      introSectionColor: 'bg-green-100',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-green-100 border-green-500',
      cardTextColor: 'text-green-500',
      cardIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
        </svg>
      ),
    },
    CHECKING: {
      labelState: 'Habitación Ocupada',
      btnSuccessColor: 'bg-violet-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Check-out Completo',
      btnDangerText: 'Rechazado',
      btnSuccessShow: true,
      btnDangerShow: false,
      btnSuccessClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'FINISHED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CANCELED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      introSectionColor: 'bg-violet-100',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-violet-100 border-violet-500',
      cardTextColor: 'text-violet-500',
      cardIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      ),
    },
    FINISHED: {
      labelState: 'Check-out Completo',
      btnSuccessColor: 'bg-yellow-500 text-black',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Check-in Completo',
      btnDangerText: 'El socio no se presentó',
      btnSuccessShow: false,
      btnDangerShow: false,
      btnSuccessClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'BY_CONFIRMED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putHotelReservation({
          ...reservation,
          user_id: reservation.user.id,
          hotel_bedroom_id: reservation.hotel_bedroom.id,
          status: 'CANCELED'
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        pullMsReservations();
      },
      introSectionColor: 'bg-gray-200',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-gray-100 border-gray-500',
      cardTextColor: 'text-gray-500',
      cardIcon: (
        <Fragment />
      ),
    },
    CANCELED: {
      labelState: 'Cancelado',
      btnSuccessColor: 'bg-gray-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Check-in Completo',
      btnDangerText: 'El socio no se presentó',
      btnSuccessShow: false,
      btnDangerShow: false,
      btnSuccessClick: (e) => {},
      btnDangerClick: (e) => {},
      introSectionColor: 'bg-gray-100',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-gray-100 border-gray-500',
      cardTextColor: 'text-gray-500',
      cardIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
        </svg>
      ),
    }
  }

  const pullMsReservations = async () => {
    const { payload } = await getHotelReservation({
      hotel_bedroom__hotel__campus_id: user.campus.id
    })(dispatch);
    if (!payload.success && payload.error) { return toast.error(payload.error.message) }
    const listHotelReservation = payload.listHotelReservation as HotelReservationTable[];
    const listHotelReservationFiltered = listHotelReservation.filter(reservation => reservation.status !== 'CANCELED');
    setScheduleReservations(listHotelReservationFiltered.map(reservation => ({
      title: reservation.hotel_bedroom.administrative_name,
      date: reservation.start_date,
      data: {
        reservation: {
          ...reservation
        },
        ...reservationStatus[reservation.status]
      }
    })));
  }

  useEffect(() => {
    pullMsReservations();
  }, []); // eslint-disable-line

  const renderEventContent = (eventInfo: any) => {
    const { cardIcon, cardTextColor, cardBgColor } = eventInfo.event.extendedProps.data;
    const { start_date } = eventInfo.event.extendedProps.data.reservation;
    const dateStart = new Date(start_date).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return (
      <div
        className={`relative w-full cursor-pointer rounded-md border-0 border-l-4 px-2 py-1 text-black ${cardBgColor}`}
      >
        <p className={`text-xs font-semibold text-green-900`}>{dateStart}</p>
        <h1 className="overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold">{eventInfo.event.title}</h1>
        <p className={`text-xs font-semibold text-green-900`}>
          {eventInfo.event.extendedProps.data.reservation.people_total}{' '}
          {eventInfo.event.extendedProps.data.reservation.people_total === 1 ? 'persona' : 'personas'}
        </p>
        <span className={`absolute top-2 right-2 ${cardTextColor}`}>{cardIcon}</span>
      </div>
    );
  };

  return (
    <>
      <div className="mb-10 flex flex-row justify-end gap-4">
        <NavLink
          to="/hotel/schedule/table"
          className={({ isActive }) =>
            isActive ? 'btn-yellow max-h-9 min-w-[120px]' : 'btn-gray max-h-9 min-w-[120px]'
          }
        >
          Lista
        </NavLink>
        <NavLink
          to="/hotel/schedule"
          className={({ isActive }) =>
            isActive ? 'btn-yellow max-h-9 min-w-[120px]' : 'btn-gray max-h-9 min-w-[120px]'
          }
        >
          Calendario
        </NavLink>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        eventContent={renderEventContent}
        events={scheduleReservations}
        dayMaxEventRows={2}
        locale="es"
        locales={[esLocale]}
        eventClick={async (info: any) => {
          setIsOpen(true);
          setReservationModalData(info.event.extendedProps.data);
          const { payload } = await getUserById(info.event.extendedProps.data.reservation.user.id)(dispatch);
          if (payload.error) {
            return toast.error('Tuvimos problemas trayendo los pagos del miembro. Por favor intentelo más tarde.');
          }
          setReservationModalDataPaids(payload.userById);
        }}
      />
      <section className="flex flex-row gap-4 py-8">
        <div className="flex flex-row items-center gap-2">
          <div className="h-5 w-5 rounded-full bg-yellow-500" />
          Pendiente
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="h-5 w-5 rounded-full bg-orange-500" />
          Por Confirmar
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="h-5 w-5 rounded-full bg-green-500" />
          Pendiente Check-in
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="h-5 w-5 rounded-full bg-violet-900" />
          Habitación Ocupada
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="h-5 w-5 rounded-full bg-gray-500" />
          Check-out completo
        </div>
      </section>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} className="max-w-3xl">
        <div className="p-3">
          <section className="my-8 flex flex-row justify-between rounded-lg bg-yellow-200 p-4">
            <section className="flex flex-row items-center gap-4">
              {!reservationModalData?.reservation?.user.photo_url ? (
                <img
                  className="block aspect-square h-14 rounded-full object-cover hover:opacity-80 active:opacity-70"
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                  alt="Profile"
                />
              ) : (
                <img
                  className="block aspect-square h-14 rounded-full object-cover hover:opacity-80 active:opacity-70"
                  src={reservationModalData?.reservation?.user.photo_url}
                  alt="Profile"
                />
              )}
              <div className="flex flex-col items-start justify-center gap-1">
                <h2 className="font-bold">{reservationModalData?.reservation?.user.first_name}</h2>
                {reservationModalDataPaids && reservationModalDataPaids.has_paid ? (
                  <span className="rounded-xl bg-white py-1 px-3 text-sm text-green-700">Al día</span>
                ) : (
                  <span className="rounded-xl bg-white py-1 px-3 text-sm text-red-500">{`Pago retrasado ($${reservationModalDataPaids.amount_pay})`}</span>
                )}
              </div>
            </section>
            <section className="text-right">
              <h2 className="font-bold">Teléfono de emergencia</h2>
              <p>{reservationModalData?.reservation?.user.emergency_number}</p>
            </section>
            <section className="text-right">
              <h2 className="font-bold">Teléfono</h2>
              <p>{reservationModalData?.reservation?.user.phone}</p>
            </section>
          </section>
          <section className="mb-6 flex flex-row justify-between gap-6">
            <div className="grow">
              <h1 className="mb-2 text-lg font-bold">
                {reservationModalData?.reservation?.hotel_bedroom.administrative_name}
              </h1>
              <p className="mb-4">
                {reservationModalData?.reservation?.member_number} Socio,{' '}
                {reservationModalData?.reservation?.family_number} Familiar,{' '}
                {reservationModalData?.reservation?.guest_number} Invitado
              </p>
              <span className="flex flex-row items-center gap-4">
                <span className="rounded-lg bg-gray-200 p-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span>{hotelPaymentMethods[reservationModalData?.reservation.payment_method]}</span>
              </span>
              <div className="mt-6 mb-4 w-full border-0 border-b-2 border-gray-200"></div>
              <p className="mb-1 flex flex-row justify-between text-gray-500">
                Costo de reservación: <span>{reservationModalData?.reservation?.reservation_cost}</span>
              </p>
              <p className="mb-1 flex flex-row justify-between bg-yellow-300 text-gray-500">
                A pagar en el hotel: <span>{reservationModalData?.reservation?.hotel_cost}</span>
              </p>
              <p className="mb-1 flex flex-row justify-between font-bold">
                Costo Total: <span>{reservationModalData?.reservation?.total_cost}</span>
              </p>
            </div>
            <div>
              <div
                className={`max-w-xs rounded-t-xl p-4 text-gray-600 ${reservationModalData?.reservation?.introSectionColor}`}
              >
                <h3 className="mb-2">Entrada</h3>
                <p className="mb-2 flex flex-row items-center gap-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  {
                    // hide hours for all dates
                    new Date(reservationModalData?.reservation?.start_date || '')
                      .toLocaleTimeString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                      .split(' ')
                      .splice(0, 6)
                      .join(' ')
                  }
                </p>
                <p className="mb-2 flex flex-row items-center gap-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  {new Date(reservationModalData?.reservation?.start_date || '').toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}
                </p>
              </div>
              <div className={`max-w-xs rounded-b-xl p-4 text-gray-600 ${reservationModalData?.endSectionColor}`}>
                <h3 className="mb-2">Salida</h3>
                <p className="mb-2 flex flex-row items-center gap-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  {
                    // hide hours for all dates
                    new Date(reservationModalData?.reservation?.end_date || '')
                      .toLocaleTimeString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                      .split(' ')
                      .splice(0, 6)
                      .join(' ')
                  }
                </p>
                <p className="mb-2 flex flex-row items-center gap-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  {new Date(reservationModalData?.reservation?.end_date || '').toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}
                </p>
              </div>
            </div>
          </section>
          <footer className="flex flex-row justify-end gap-4">
            {reservationModalData?.btnDangerShow && (
              <button
                onClick={() => reservationModalData?.btnDangerClick(reservationModalData.reservation)}
                className={`btn-gray w-fit ${reservationModalData?.btnDangerColor}`}
              >
                {reservationModalData?.btnDangerText}
              </button>
            )}
            {reservationModalData?.btnSuccessShow && (
              <button
                onClick={() => reservationModalData?.btnSuccessClick(reservationModalData.reservation)}
                className={`btn-yellow w-fit ${reservationModalData?.btnSuccessColor}`}
              >
                {reservationModalData?.btnSuccessText}
              </button>
            )}
          </footer>
        </div>
      </Modal>
    </>
  );
};
