
import { EventCoordinatorsAction, EventCoordinatorsPayload } from "../interfaces";
import { EventCoordinatorsTypes } from "../types";

const initialState: EventCoordinatorsPayload = {
  loading: false,
  success: false,
  error: null,
  eventCoordinators: null,
  listEventCoordinators: [],
  pagEventCoordinators: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const eventCoordinators = (state = initialState, action: EventCoordinatorsAction): EventCoordinatorsPayload => {
  const { loading, success, error, eventCoordinators, listEventCoordinators, pagEventCoordinators } = action.payload || {};
  switch (action.type) {
    case EventCoordinatorsTypes.GET_EVENTCOORDINATORSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventCoordinatorsTypes.GET_EVENTCOORDINATORSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventCoordinatorsTypes.GET_EVENTCOORDINATORSPAG_SUCCESS:
      return {
        ...state,
        pagEventCoordinators,
        loading,
        success,
      };
    case EventCoordinatorsTypes.GET_EVENTCOORDINATORS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventCoordinatorsTypes.GET_EVENTCOORDINATORS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventCoordinatorsTypes.GET_EVENTCOORDINATORS_SUCCESS:
      return {
        ...state,
        listEventCoordinators,
        loading,
        success,
      };
    case EventCoordinatorsTypes.GETONLY_EVENTCOORDINATORS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventCoordinatorsTypes.GETONLY_EVENTCOORDINATORS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventCoordinatorsTypes.GETONLY_EVENTCOORDINATORS_SUCCESS:
      return {
        ...state,
        eventCoordinators,
        loading,
        success,
      };
    case EventCoordinatorsTypes.POST_EVENTCOORDINATORS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventCoordinatorsTypes.POST_EVENTCOORDINATORS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventCoordinatorsTypes.POST_EVENTCOORDINATORS_SUCCESS:
      return {
        ...state,
        eventCoordinators,
        loading,
        success,
      };
    case EventCoordinatorsTypes.PUT_EVENTCOORDINATORS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventCoordinatorsTypes.PUT_EVENTCOORDINATORS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventCoordinatorsTypes.PUT_EVENTCOORDINATORS_SUCCESS:
      return {
        ...state,
        eventCoordinators,
        loading,
        success,
      };
    case EventCoordinatorsTypes.DELETE_EVENTCOORDINATORS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventCoordinatorsTypes.DELETE_EVENTCOORDINATORS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventCoordinatorsTypes.DELETE_EVENTCOORDINATORS_SUCCESS:
      return {
        ...state,
        eventCoordinators,
        loading,
        success,
      };
    default:
      return state;
  }
};

