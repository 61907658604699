
import { RestaurantLunchesProcess } from "../api";
import { RestaurantLunchesAction, RestaurantLunchesFilters } from "../interfaces";
import { RestaurantLunchesTypes } from "../types";

export const getRestaurantLunchesPags = (filters?: RestaurantLunchesFilters) => async (dispatch: (state: RestaurantLunchesAction) => RestaurantLunchesAction) => {
  dispatch({
    type: RestaurantLunchesTypes.GET_RESTAURANTLUNCHESPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantLunchesProcess = new RestaurantLunchesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantLunchesPag = await restaurantLunchesProcess.getRestaurantLunches(filters);
    return dispatch({
      type: RestaurantLunchesTypes.GET_RESTAURANTLUNCHESPAG_SUCCESS,
      payload: {
        pagRestaurantLunches: restaurantLunchesPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantLunchesTypes.GET_RESTAURANTLUNCHESPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getRestaurantLunches = (filters?: RestaurantLunchesFilters) => async (dispatch: (state: RestaurantLunchesAction) => RestaurantLunchesAction) => {
  dispatch({
    type: RestaurantLunchesTypes.GET_RESTAURANTLUNCHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantLunchesProcess = new RestaurantLunchesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantLunchesPag = await restaurantLunchesProcess.getRestaurantLunches({
      ...filters,
      data_all: 'YES',
    });
    const restaurantLunches = restaurantLunchesPag.results;
    return dispatch({
      type: RestaurantLunchesTypes.GET_RESTAURANTLUNCHES_SUCCESS,
      payload: {
        listRestaurantLunches: restaurantLunches,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantLunchesTypes.GET_RESTAURANTLUNCHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postRestaurantLunches = (restaurantLunches: FormData) => async (dispatch: (state: RestaurantLunchesAction) => RestaurantLunchesAction) => {
  dispatch({
    type: RestaurantLunchesTypes.POST_RESTAURANTLUNCHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantLunchesProcess = new RestaurantLunchesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantLunchesTable = await restaurantLunchesProcess.postRestaurantLunches(restaurantLunches);
    return dispatch({
      type: RestaurantLunchesTypes.POST_RESTAURANTLUNCHES_SUCCESS,
      payload: {
        restaurantLunches: restaurantLunchesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantLunchesTypes.POST_RESTAURANTLUNCHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putRestaurantLunches = (restaurantLunches: FormData) => async (dispatch: (state: RestaurantLunchesAction) => RestaurantLunchesAction) => {
  dispatch({
    type: RestaurantLunchesTypes.PUT_RESTAURANTLUNCHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantLunchesProcess = new RestaurantLunchesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantLunchesTable = await restaurantLunchesProcess.putRestaurantLunches(restaurantLunches);
    return dispatch({
      type: RestaurantLunchesTypes.PUT_RESTAURANTLUNCHES_SUCCESS,
      payload: {
        restaurantLunches: restaurantLunchesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantLunchesTypes.PUT_RESTAURANTLUNCHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteRestaurantLunches = (id: number) => async (dispatch: (state: RestaurantLunchesAction) => RestaurantLunchesAction) => {
  dispatch({
    type: RestaurantLunchesTypes.DELETE_RESTAURANTLUNCHES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantLunchesProcess = new RestaurantLunchesProcess();
    // dont'n import the result of the process, only that dont't have error
    await restaurantLunchesProcess.deleteRestaurantLunches(id);
    return dispatch({
      type: RestaurantLunchesTypes.DELETE_RESTAURANTLUNCHES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantLunchesTypes.DELETE_RESTAURANTLUNCHES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

