
export enum RestaurantCategoriesTypes {
  GET_RESTAURANTCATEGORIESPAG_LOADING = "GET_RESTAURANTCATEGORIESPAG_LOADING",
  GET_RESTAURANTCATEGORIESPAG_SUCCESS = "GET_RESTAURANTCATEGORIESPAG_SUCCESS",
  GET_RESTAURANTCATEGORIESPAG_FAILURE = "GET_RESTAURANTCATEGORIESPAG_FAILURE",
  GET_RESTAURANTCATEGORIES_LOADING = "GET_RESTAURANTCATEGORIES_LOADING",
  GET_RESTAURANTCATEGORIES_SUCCESS = "GET_RESTAURANTCATEGORIES_SUCCESS",
  GET_RESTAURANTCATEGORIES_FAILURE = "GET_RESTAURANTCATEGORIES_FAILURE",
  GETONLY_RESTAURANTCATEGORIES_LOADING = "GETONLY_RESTAURANTCATEGORIES_LOADING",
  GETONLY_RESTAURANTCATEGORIES_SUCCESS = "GETONLY_RESTAURANTCATEGORIES_SUCCESS",
  GETONLY_RESTAURANTCATEGORIES_FAILURE = "GETONLY_RESTAURANTCATEGORIES_FAILURE",
  POST_RESTAURANTCATEGORIES_LOADING = "POST_RESTAURANTCATEGORIES_LOADING",
  POST_RESTAURANTCATEGORIES_SUCCESS = "POST_RESTAURANTCATEGORIES_SUCCESS",
  POST_RESTAURANTCATEGORIES_FAILURE = "POST_RESTAURANTCATEGORIES_FAILURE",
  PUT_RESTAURANTCATEGORIES_LOADING = "PUT_RESTAURANTCATEGORIES_LOADING",
  PUT_RESTAURANTCATEGORIES_SUCCESS = "PUT_RESTAURANTCATEGORIES_SUCCESS",
  PUT_RESTAURANTCATEGORIES_FAILURE = "PUT_RESTAURANTCATEGORIES_FAILURE",
  DELETE_RESTAURANTCATEGORIES_LOADING = "DELETE_RESTAURANTCATEGORIES_LOADING",
  DELETE_RESTAURANTCATEGORIES_SUCCESS = "DELETE_RESTAURANTCATEGORIES_SUCCESS",
  DELETE_RESTAURANTCATEGORIES_FAILURE = "DELETE_RESTAURANTCATEGORIES_FAILURE",
}