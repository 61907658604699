import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteMultipleServices,
  getMultipleServicesCategory,
  getMultipleServicesSchedule,
  postMultipleServices,
  putMultipleServices,
} from '../../redux/actions';
import { deleteMultipleServicesImages, getMultipleServicesImages, postMultipleServicesImages } from '../../redux/actions/multipleServicesImages.actions';
import {
  MultipleServicesCategoryTableWSchedule,
  MultipleServicesTable,
  MultipleServicesTableWSchedule,
  User,
} from '../../redux/interfaces';
import Alert from '../../utils/alerts';
import useForm from '../../utils/hooks/useForm';
import { MiniCarouselImg } from '../../components';
import ReactDatePicker from 'react-datepicker';
import { RootState } from '../../redux/store';
import { testSizeImg } from '../../utils/utils';

type ImageType = {
  id?: number;
  url: string;
  multiple_service?: number;
  file?: File
}

export const ServiceNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [multipleServicesCategory, setMultipleServicesCategory] = useState<MultipleServicesCategoryTableWSchedule[]>(
    [],
  );
  const [categoryOptionsEl, setCategoryOptionsEl] = useState<JSX.Element[]>([]);
  const [images, setImages] = useState<ImageType[]>([]);
  const [imagesOriginal, setImagesOriginal] = useState<ImageType[]>([]);
  const [maxUsageTime, setMaxUseTime] = useState(0);
  const [maximunUsageTime, setMaximunUsageTime] = useState(new Date(new Date().setHours(0, 30, 0, 0)));
  const [formAction, setFormAction] = useState<'update-multiple-service' | 'create-multiple-service'>(
    'create-multiple-service',
  );
  const {
    id_multiple_service,
    capacity,
    name,
    multiple_service_category,
    description,
    handleOnChangeInput,
    handleUpdateState,
  } = useForm<any>({
    id_multiple_service: 0,
    capacity: '',
    multiple_service_category: '',
    name: '',
    description: '',
  });

  const pullMultipleServices = async () => {
    const multipleServicesCategoryAction = await getMultipleServicesCategory({
      campus_id: user.campus.id,
    })(dispatch);
    const { payload } = multipleServicesCategoryAction;
    const { success } = payload;
    const listMultipleServicesCategory = payload.listMultipleServicesCategory || [];
    setMultipleServicesCategory(
      listMultipleServicesCategory.map((multipleServicesCategory) => {
        return {
          ...multipleServicesCategory,
          multiple_service: [
            ...multipleServicesCategory.multiple_service.map((multipleService) => ({
              ...multipleService,
              schedules: [
                // This schedule is invalid & inexistent, but in the next line we will add the valid ones
                {
                  id: 16,
                  day: 'lunes',
                  start_time: '15:00:00',
                  end_time: '18:00:00',
                  is_activated: true,
                  multiple_service: 7,
                  is_deleted: false,
                  created_at: '2022-01-19T19:32:44.616041Z',
                  updated_at: '2022-01-19T19:32:44.616068Z',
                  deleted_at: null,
                },
              ],
            })),
          ],
        };
      }),
    );

    if (!success) {
      toast('Error: problemas de conexión. Vuelva a intentarlo más tarde.', { type: 'error' });
    }
    // setmutipleServiceCategory(multipleServicesCategoryAction.payload.multipleServicesCategory);
    setCategoryOptionsEl(
      listMultipleServicesCategory.map((multipleServicesCategory, i) => (
        <option key={`multiple-service-category-${i}`} value={multipleServicesCategory.id}>
          {multipleServicesCategory.name}
        </option>
      )) || [],
    );

    // Get loss data
    const multipleServicesCategoryWSchedules = [] as MultipleServicesCategoryTableWSchedule[];
    for (const msCategory of listMultipleServicesCategory) {
      let multipleServiceWSchedule = [] as MultipleServicesTableWSchedule[];
      for (const multipleService of msCategory.multiple_service) {
        const multipleServicesScheduleAction = await getMultipleServicesSchedule({
          multiple_service_id: multipleService.id,
        })(dispatch);
        const {
          payload: { success },
        } = multipleServicesScheduleAction;

        if (!success) {
          toast('¡Cáspita!: parece que tenemos problemas de conexión. Por favor vuelva a intentarlo más tarde.', {
            type: 'error',
          });
        }

        const listMultipleServicesSchedule = multipleServicesScheduleAction.payload.listMultipleServicesSchedule || [];
        multipleServiceWSchedule.push({
          ...multipleService,
          schedules: listMultipleServicesSchedule,
        });
      }

      multipleServicesCategoryWSchedules.push({
        ...msCategory,
        multiple_service: [...multipleServiceWSchedule],
      });
    }

    setMultipleServicesCategory(multipleServicesCategoryWSchedules);
  };

  const reloadForm = () => {
    // Reload all data
    pullMultipleServices();
    // Reset Form
    setImages([]);
    setImagesOriginal([]);
    setFormAction('create-multiple-service');
    handleUpdateState({
      id_multiple_service: 0,
      capacity: '',
      multiple_service_category: '',
      name: '',
      description: '',
    });
    setMaximunUsageTime(new Date(new Date().setHours(0, 30, 0, 0)));
  };

  useEffect(() => {
    pullMultipleServices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => setMaxUseTime((maximunUsageTime?.getHours() || 0) * 60 + (maximunUsageTime?.getMinutes() || 0)),
    [maximunUsageTime],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditMultipleService = async (multipleService: MultipleServicesTable) => {
    // setIsOpen(true);
    setFormAction('update-multiple-service');
    handleUpdateState({
      id_multiple_service: multipleService.id,
      capacity: multipleService.capacity,
      multiple_service_category: multipleService.multiple_service_category,
      name: multipleService.name,
      description: multipleService.description,
    });
    setMaximunUsageTime(
      new Date(
        new Date().setHours(multipleService.maximun_usage_time / 60, multipleService.maximun_usage_time % 60, 0, 0),
      ),
    );

    const imagesList: ImageType[] = [];
    for (const imageId of multipleService.images) {
      const { payload } = await getMultipleServicesImages({ id: imageId, campus_id: user.campus.id })(dispatch);
      if (payload.listMultipleServicesImages && payload.listMultipleServicesImages[0]) {
        const image = payload.listMultipleServicesImages[0];
        imagesList.push({
          id: image.id,
          url: image.url,
          multiple_service: image.multiple_service,
        });
      }
    }
    setImages(imagesList);
    setImagesOriginal(imagesList);
  };

  const handleSubmitServiceMultipleNew = async (e: FormEvent) => {
    // get name from submit button
    e.preventDefault();
    if (formAction === 'create-multiple-service') {
      handleCreateMultipleService();
    } else {
      handleUpdateMultipleService();
    }
  };

  const handleCreateMultipleService = async () => {
    const { payload } = await postMultipleServices({
      capacity,
      description,
      maximun_usage_time: maxUsageTime,
      multiple_service_category,
      name,
    })(dispatch);
    const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success('Servicio creado con éxito.');
    toast.info('Subiendo imágenes...');

    // Upload images
    const imagesToPost = new FormData();
    const multipleServices = payload.multipleServices as MultipleServicesTable;

    imagesToPost.append('multiple_service', multipleServices.id.toString());
    for (const image of images) {
      if (!image.id && image.file) {
        imagesToPost.append('images', image.file);
      }
    }

    const allPromiseRequest = [];
    imagesToPost.getAll('images').length > 0 &&
      allPromiseRequest.push(postMultipleServicesImages(imagesToPost)(dispatch));

    const responses = await Promise.all(allPromiseRequest);
    const allSuccess = responses.every((response) => response.payload.success);

    if (!allSuccess) {
      return toast.error(
        '¡Oops!, tuvimos un problema al subir las imágenes. Por favor vuelva a intentarlo más tarde.',
        { type: 'error' },
      );
    }
    toast.success('Imágenes subidas con éxito.');
    reloadForm();
  };

  const handleUpdateMultipleService = async () => {
    const { payload } = await putMultipleServices({
      id: id_multiple_service,
      capacity,
      description,
      maximun_usage_time: maxUsageTime,
      multiple_service_category,
      name,
    })(dispatch);
    const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success('Servicio actualizado con éxito.');
    toast.info('Actualizando imágenes...');
    // Upload images
    const imagesToPost = new FormData();

    imagesToPost.append('multiple_service', id_multiple_service);
    for (const image of images) {
      if (!image.id && image.file) {
        imagesToPost.append('images', image.file);
      }
    }

    const imagesToDelete = imagesOriginal.filter((image) => !images.find((image2) => image2.id === image.id));
    // Send All request in paralel
    const allPromiseRequest = [];

    imagesToPost.getAll('images').length > 0 &&
      allPromiseRequest.push(postMultipleServicesImages(imagesToPost)(dispatch));
    imagesToDelete.length > 0 &&
      allPromiseRequest.push(...imagesToDelete.map((image) => deleteMultipleServicesImages(image.id || -1)(dispatch)));

    const responses = await Promise.all(allPromiseRequest);
    const allSuccess = responses.every((response) => response.payload.success);

    if (!allSuccess) {
      return toast.error(
        '¡Oops!, tuvimos un problema al actualizar las imágenes. Por favor vuelva a intentarlo más tarde.',
        { type: 'error' },
      );
    }
    toast.success('Imágenes actualizadas con éxito.');
    reloadForm();
  };

  const handleDeleteMultipleService = async (multipleService: MultipleServicesTable) => {
    const { isConfirmed } = await Alert.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${multipleService.name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteMultipleServices(multipleService.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success('Servicio eliminado con éxito.');
        pullMultipleServices();
      } else {
        toast.error('Error: problemas de conexión. Vuelva a intentarlo más tarde.', { type: 'error' });
      }
    }
  };

  const handleCatchImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', {
        type: 'error',
      });
    }

    const imagesList: ImageType[] = Object.values(e.target.files || {}).map((file) => ({
      url: URL.createObjectURL(file),
      file,
    }));
    setImages((prevState) => [...prevState, ...imagesList]);
  };

  const removeImage = (image: ImageType) => {
    setImages(images.filter((imageItem) => imageItem !== image));
  };

  const resetForm = () => {
    setFormAction('create-multiple-service');
    reloadForm();
  };

  return (
    <div>
      <form onSubmit={handleSubmitServiceMultipleNew}>
        <div
          onClick={() => resetForm()}
          className={`btn-black mb-4 w-fit ${formAction === 'create-multiple-service' ? 'hidden' : 'block'}`}
        >
          Crear nuevo servicio
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 inline-block h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h2 className="font-bold text-gray-500">Servicios Múltiples</h2>
        <div className="mt-6 grid grid-cols-4 items-center gap-4">
          <input
            id="name"
            name="name"
            type="text"
            maxLength={250}
            value={name}
            onChange={handleOnChangeInput}
            required
            className="relative mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
            placeholder="Nombre del servicio"
          />
          <input
            id="capacity"
            name="capacity"
            type="number"
            value={capacity}
            onChange={handleOnChangeInput}
            required
            className="relative mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
            placeholder="Aforo"
          />
          <div>
            <ReactDatePicker
              selected={maximunUsageTime}
              onChange={(date: Date) => setMaximunUsageTime(date)}
              onChangeRaw={(e) => e.preventDefault()}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              minTime={new Date(new Date().setHours(0, 30, 0))}
              maxTime={new Date(new Date().setHours(3, 0, 0))}
              timeCaption="Horas uso"
              timeFormat="HH:mm"
              dateFormat="H:mm"
              className="input-yellow mt-2 w-full"
              required
            />
          </div>
          <select
            id="multiple_service_category"
            name="multiple_service_category"
            value={multiple_service_category}
            onChange={handleOnChangeInput}
            required
            className="mt-2 block h-11 rounded-lg border-[1px] border-gray-300 bg-gray-50 py-3 px-2 leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
          >
            <option value="" disabled>
              Categoría
            </option>
            {categoryOptionsEl}
          </select>
        </div>
        <textarea
          name="description"
          id="description"
          rows={4}
          value={description}
          onChange={handleOnChangeInput}
          required
          placeholder="Descripción del servicio"
          className="mt-4 w-full rounded-lg border-[1px] border-gray-300 bg-gray-50 focus:border-yellow-500 focus:ring-yellow-500"
        ></textarea>
        <div>
          <h2 className="mt-6 font-bold text-gray-500">Imágenes</h2>
          <div className="mt-4 flex flex-row justify-between">
            <MiniCarouselImg images={images} onRemoveImage={removeImage} />
            <div className="ml-8 shrink-0">
              <div className="mb-2 block">
                <input
                  multiple
                  type="file"
                  name="file"
                  id="file"
                  accept="image/png, image/jpeg"
                  onChange={handleCatchImages}
                  className="absolute -z-10 h-0 w-0 overflow-hidden opacity-0"
                />
                <label htmlFor="file" className="btn-yellow">
                  Subir Imagen
                </label>
              </div>
              <button
                name={formAction}
                type="submit"
                className={`mt-2 w-full ${formAction === 'create-multiple-service' ? 'btn-yellow' : 'btn-black'}`}
              >
                {formAction === 'create-multiple-service' ? 'Agregar' : 'Actualizar'}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="mt-6 w-full border border-gray-200"></div>
      <div className="pb-16">
        <h2 className="mt-6 font-bold text-gray-500">Servicios</h2>
        {multipleServicesCategory && multipleServicesCategory.length > 0 ? (
          multipleServicesCategory.map(({ name, multiple_service }, i) => (
            <div key={`multiple-service-category-${i}`} className="mt-6">
              <h3 className="grid grid-cols-[minmax(0,1fr)_150px_120px] text-sm font-bold text-gray-500">
                <span className="basis-[73%]">{name}</span>
                <span>Aforo</span>
              </h3>
              <div className="mt-4 divide-y divide-gray-500/25">
                {multiple_service.length === 0 ? (
                  <div className="bg-gray-50 px-4">
                    <span className="font-bold text-black">No hay servicios múltiples disponibles</span>
                  </div>
                ) : (
                  multiple_service.map((multipleService, i) => (
                    <div
                      key={`multiple-service-${i}`}
                      className="grid grid-cols-[minmax(0,1fr)_150px_120px] items-center justify-between odd:bg-gray-50 even:bg-white even:hover:bg-gray-50"
                    >
                      <span className="basis-3/5 px-4 font-bold">{multipleService.name}</span>
                      <span>{multipleService.capacity}</span>
                      <span className="mr-6 flex flex-row gap-1">
                        <button
                          onClick={() => handleEditMultipleService(multipleService)}
                          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-700"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteMultipleService(multipleService)}
                          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-700"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        {multipleService.schedules.length > 0 ? (
                          <button
                            onClick={() =>
                              navigate('/services/schedule/edit', {
                                state: {
                                  multipleService: multipleService,
                                },
                              })
                            }
                            className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-gray-700"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              navigate('/services/schedule/edit', {
                                state: {
                                  multipleService: multipleService,
                                },
                              })
                            }
                            className="cursor-pointer rounded-lg p-1.5 hover:bg-red-200 active:bg-red-300"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-red-700"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        )}
                      </span>
                    </div>
                  ))
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="bg-gray-50 px-4">
            <span className="font-bold text-black">No hay categorías disponibles</span>
          </div>
        )}
      </div>
    </div>
  );
};;
