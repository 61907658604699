
export enum CampusNewsTypes {
  GET_CAMPUSNEWSPAG_LOADING = "GET_CAMPUSNEWSPAG_LOADING",
  GET_CAMPUSNEWSPAG_SUCCESS = "GET_CAMPUSNEWSPAG_SUCCESS",
  GET_CAMPUSNEWSPAG_FAILURE = "GET_CAMPUSNEWSPAG_FAILURE",
  GET_CAMPUSNEWS_LOADING = "GET_CAMPUSNEWS_LOADING",
  GET_CAMPUSNEWS_SUCCESS = "GET_CAMPUSNEWS_SUCCESS",
  GET_CAMPUSNEWS_FAILURE = "GET_CAMPUSNEWS_FAILURE",
  GETONLY_CAMPUSNEWS_LOADING = "GETONLY_CAMPUSNEWS_LOADING",
  GETONLY_CAMPUSNEWS_SUCCESS = "GETONLY_CAMPUSNEWS_SUCCESS",
  GETONLY_CAMPUSNEWS_FAILURE = "GETONLY_CAMPUSNEWS_FAILURE",
  POST_CAMPUSNEWS_LOADING = "POST_CAMPUSNEWS_LOADING",
  POST_CAMPUSNEWS_SUCCESS = "POST_CAMPUSNEWS_SUCCESS",
  POST_CAMPUSNEWS_FAILURE = "POST_CAMPUSNEWS_FAILURE",
  PUT_CAMPUSNEWS_LOADING = "PUT_CAMPUSNEWS_LOADING",
  PUT_CAMPUSNEWS_SUCCESS = "PUT_CAMPUSNEWS_SUCCESS",
  PUT_CAMPUSNEWS_FAILURE = "PUT_CAMPUSNEWS_FAILURE",
  DELETE_CAMPUSNEWS_LOADING = "DELETE_CAMPUSNEWS_LOADING",
  DELETE_CAMPUSNEWS_SUCCESS = "DELETE_CAMPUSNEWS_SUCCESS",
  DELETE_CAMPUSNEWS_FAILURE = "DELETE_CAMPUSNEWS_FAILURE",
}