
import { RestaurantSchedulesAction, RestaurantSchedulesPayload } from "../interfaces";
import { RestaurantSchedulesTypes } from "../types";

const initialState: RestaurantSchedulesPayload = {
  loading: false,
  success: false,
  error: null,
  restaurantSchedules: null,
  listRestaurantSchedules: [],
  pagRestaurantSchedules: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const restaurantSchedules = (state = initialState, action: RestaurantSchedulesAction): RestaurantSchedulesPayload => {
  const { loading, success, error, restaurantSchedules, listRestaurantSchedules, pagRestaurantSchedules } = action.payload || {};
  switch (action.type) {
    case RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULESPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULESPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULESPAG_SUCCESS:
      return {
        ...state,
        pagRestaurantSchedules,
        loading,
        success,
      };
    case RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULES_SUCCESS:
      return {
        ...state,
        listRestaurantSchedules,
        loading,
        success,
      };
    case RestaurantSchedulesTypes.GETONLY_RESTAURANTSCHEDULES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantSchedulesTypes.GETONLY_RESTAURANTSCHEDULES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantSchedulesTypes.GETONLY_RESTAURANTSCHEDULES_SUCCESS:
      return {
        ...state,
        restaurantSchedules,
        loading,
        success,
      };
    case RestaurantSchedulesTypes.POST_RESTAURANTSCHEDULES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantSchedulesTypes.POST_RESTAURANTSCHEDULES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantSchedulesTypes.POST_RESTAURANTSCHEDULES_SUCCESS:
      return {
        ...state,
        restaurantSchedules,
        loading,
        success,
      };
    case RestaurantSchedulesTypes.PUT_RESTAURANTSCHEDULES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantSchedulesTypes.PUT_RESTAURANTSCHEDULES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantSchedulesTypes.PUT_RESTAURANTSCHEDULES_SUCCESS:
      return {
        ...state,
        restaurantSchedules,
        loading,
        success,
      };
    case RestaurantSchedulesTypes.DELETE_RESTAURANTSCHEDULES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantSchedulesTypes.DELETE_RESTAURANTSCHEDULES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantSchedulesTypes.DELETE_RESTAURANTSCHEDULES_SUCCESS:
      return {
        ...state,
        restaurantSchedules,
        loading,
        success,
      };
    default:
      return state;
  }
};

