import { AuthRepository } from "../repositories/auth.repository";
import { AuthResponse } from "../../interfaces/auth.interface";

export class AuthProcess {
    private authRepository: AuthRepository;

    constructor() {
        this.authRepository = new AuthRepository();
    }
    async loginUserWithEmailAndPassword(email: string, password: string): Promise<AuthResponse> {
        const response = await this.authRepository.loginUserWithEmailAndPassword(email, password);
        return response.data;
    }

    async passwordResetConfirm(token: string, newPassword: string): Promise<any> {
        const response = await this.authRepository.passwordResetConfirm(token, newPassword);
        return response.data;
    }

    async passwordReset(email: string): Promise<any> {
        const response = await this.authRepository.passwordReset(email);
        return response.data;
    }
}
