import { MultipleServicesScheduleRepository } from "../repositories";
import { MultipleServicesScheduleFilters, MultipleServicesScheduleGet, MultipleServicesSchedulePost, MultipleServicesSchedulePut, MultipleServicesScheduleTable } from "../../interfaces";

export class MultipleServicesScheduleProcess {
  private multipleServiceScheduleRepository: MultipleServicesScheduleRepository;

  constructor() {
    this.multipleServiceScheduleRepository = new MultipleServicesScheduleRepository();
  }

  async getMultipleServicesSchedule(filters?: MultipleServicesScheduleFilters): Promise<MultipleServicesScheduleGet> {
    const response = await this.multipleServiceScheduleRepository.getMultipleServicesSchedule(filters);
    return response.data;
  }

  async getMultipleServicesScheduleById(multiple_service_id: number): Promise<MultipleServicesScheduleGet> {
    const response = await this.multipleServiceScheduleRepository.getMultipleServicesScheduleById(multiple_service_id);
    return response.data;
  }

  async postMultipleServicesSchedule(msSchedule: MultipleServicesSchedulePost): Promise<MultipleServicesScheduleTable> {
    const response = await this.multipleServiceScheduleRepository.postMultipleServicesSchedule(msSchedule);
    return response.data;
  }

  async putMultipleServicesSchedule(msSchedule: MultipleServicesSchedulePut): Promise<MultipleServicesScheduleTable> {
    const response = await this.multipleServiceScheduleRepository.putMultipleServicesSchedule(msSchedule);
    return response.data;
  }

  // dont response data
  async deleteMultipleServicesSchedule(id: number): Promise<any> {
    const response = await this.multipleServiceScheduleRepository.deleteMultipleServicesSchedule(id);
    return response.data;
  }
}
