
import { UserGroupAction, UserGroupPayload } from "../interfaces";
import { UserGroupTypes } from "../types";

const initialState: UserGroupPayload = {
  loading: false,
  success: false,
  error: null,
  userGroup: null,
  listUserGroup: [],
  pagUserGroup: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const userGroup = (state = initialState, action: UserGroupAction): UserGroupPayload => {
  const { loading, success, error, userGroup, listUserGroup, pagUserGroup } = action.payload || {};
  switch (action.type) {
    case UserGroupTypes.GET_USERGROUPPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserGroupTypes.GET_USERGROUPPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserGroupTypes.GET_USERGROUPPAG_SUCCESS:
      return {
        ...state,
        pagUserGroup,
        loading,
        success,
      };
    case UserGroupTypes.GET_USERGROUP_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserGroupTypes.GET_USERGROUP_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserGroupTypes.GET_USERGROUP_SUCCESS:
      return {
        ...state,
        listUserGroup,
        loading,
        success,
      };
    case UserGroupTypes.GETONLY_USERGROUP_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserGroupTypes.GETONLY_USERGROUP_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserGroupTypes.GETONLY_USERGROUP_SUCCESS:
      return {
        ...state,
        userGroup,
        loading,
        success,
      };
    case UserGroupTypes.POST_USERGROUP_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserGroupTypes.POST_USERGROUP_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserGroupTypes.POST_USERGROUP_SUCCESS:
      return {
        ...state,
        userGroup,
        loading,
        success,
      };
    case UserGroupTypes.PUT_USERGROUP_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserGroupTypes.PUT_USERGROUP_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserGroupTypes.PUT_USERGROUP_SUCCESS:
      return {
        ...state,
        userGroup,
        loading,
        success,
      };
    case UserGroupTypes.DELETE_USERGROUP_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case UserGroupTypes.DELETE_USERGROUP_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case UserGroupTypes.DELETE_USERGROUP_SUCCESS:
      return {
        ...state,
        userGroup,
        loading,
        success,
      };
    default:
      return state;
  }
};

