export const hotelBedroomStatus = [
  {
    value: 'PENDING',
    label: 'Pendiente',
  },
  {
    value: 'BY_CONFIRMED',
    label: 'Por Confirmar'
  },
  {
    value: 'CONFIRMED',
    label: 'Pendiente Check-In'
  },
  {
    value: 'CHECKING',
    label: 'Habitación Ocupada'
  },
  {
    value: 'FINISHED',
    label: 'Check-out Completo'
  },
  {
    value: 'CANCELED',
    label: 'Cancelado'
  },
]