
import { EventWireTransfersProcess } from "../api";
import { EventWireTransfersAction, EventWireTransfersFilters } from "../interfaces";
import { EventWireTransfersTypes } from "../types";

export const getEventWireTransfersPags = (filters?: EventWireTransfersFilters) => async (dispatch: (state: EventWireTransfersAction) => EventWireTransfersAction) => {
  dispatch({
    type: EventWireTransfersTypes.GET_EVENTWIRETRANSFERSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventWireTransfersProcess = new EventWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventWireTransfersPag = await eventWireTransfersProcess.getEventWireTransfers(filters);
    return dispatch({
      type: EventWireTransfersTypes.GET_EVENTWIRETRANSFERSPAG_SUCCESS,
      payload: {
        pagEventWireTransfers: eventWireTransfersPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventWireTransfersTypes.GET_EVENTWIRETRANSFERSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getEventWireTransfers = (filters?: EventWireTransfersFilters) => async (dispatch: (state: EventWireTransfersAction) => EventWireTransfersAction) => {
  dispatch({
    type: EventWireTransfersTypes.GET_EVENTWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventWireTransfersProcess = new EventWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventWireTransfersPag = await eventWireTransfersProcess.getEventWireTransfers({
      ...filters,
      data_all: 'YES',
    });
    const eventWireTransfers = eventWireTransfersPag.results;
    return dispatch({
      type: EventWireTransfersTypes.GET_EVENTWIRETRANSFERS_SUCCESS,
      payload: {
        listEventWireTransfers: eventWireTransfers,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventWireTransfersTypes.GET_EVENTWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postEventWireTransfers = (eventWireTransfers: FormData) => async (dispatch: (state: EventWireTransfersAction) => EventWireTransfersAction) => {
  dispatch({
    type: EventWireTransfersTypes.POST_EVENTWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventWireTransfersProcess = new EventWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventWireTransfersTable = await eventWireTransfersProcess.postEventWireTransfers(eventWireTransfers);
    return dispatch({
      type: EventWireTransfersTypes.POST_EVENTWIRETRANSFERS_SUCCESS,
      payload: {
        eventWireTransfers: eventWireTransfersTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventWireTransfersTypes.POST_EVENTWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putEventWireTransfers = (eventWireTransfers: FormData) => async (dispatch: (state: EventWireTransfersAction) => EventWireTransfersAction) => {
  dispatch({
    type: EventWireTransfersTypes.PUT_EVENTWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventWireTransfersProcess = new EventWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventWireTransfersTable = await eventWireTransfersProcess.putEventWireTransfers(eventWireTransfers);
    return dispatch({
      type: EventWireTransfersTypes.PUT_EVENTWIRETRANSFERS_SUCCESS,
      payload: {
        eventWireTransfers: eventWireTransfersTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventWireTransfersTypes.PUT_EVENTWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteEventWireTransfers = (id: number) => async (dispatch: (state: EventWireTransfersAction) => EventWireTransfersAction) => {
  dispatch({
    type: EventWireTransfersTypes.DELETE_EVENTWIRETRANSFERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventWireTransfersProcess = new EventWireTransfersProcess();
    // dont'n import the result of the process, only that dont't have error
    await eventWireTransfersProcess.deleteEventWireTransfers(id);
    return dispatch({
      type: EventWireTransfersTypes.DELETE_EVENTWIRETRANSFERS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventWireTransfersTypes.DELETE_EVENTWIRETRANSFERS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

