
import { UserGroupRepository } from "../repositories";
import { UserGroupFilters, UserGroupGet, UserGroupPost, UserGroupPut, UserGroupTable } from "../../interfaces";

export class UserGroupProcess {
  private userGroupRepository: UserGroupRepository;

  constructor() {
    this.userGroupRepository = new UserGroupRepository();
  }

  async getUserGroup(filters?: UserGroupFilters): Promise<UserGroupGet> {
    const response = await this.userGroupRepository.getUserGroup(filters);
    return response.data;
  }

  async postUserGroup(userGroup: UserGroupPost): Promise<UserGroupTable> {
    const response = await this.userGroupRepository.postUserGroup(userGroup);
    return response.data;
  }

  async putUserGroup(userGroup: UserGroupPut): Promise<UserGroupTable> {
    const response = await this.userGroupRepository.putUserGroup(userGroup);
    return response.data;
  }

  // dont response data
  async deleteUserGroup(id: number): Promise<any> {
    const response = await this.userGroupRepository.deleteUserGroup(id);
    return response.data;
  }
}
