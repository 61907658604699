import { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TableBody } from "../../components";
import { getListCampus, getListUsers, getListUsersPag, getUserGroup, putUser } from "../../redux/actions";
import { User, UserFilters, UserResponse } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import useForm from "../../utils/hooks/useForm";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { rolsCampusAdmin } from "../../utils/rolsCampusAdmin";
import { generateRandomId } from "../../utils/utils";

export const CampusUsersPage = () => {
  // Fetch data from the server
  const dispatch = useDispatch();
  const { listUsersPag, loading } = useSelector((state: RootState) => state.user);
  const { listUserGroup } = useSelector((state: RootState) => state.userGroup);
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const {
    results,/* : listUser, */
    count,
    next,
    previous,
} = listUsersPag as UserResponse;
const listUser = results.filter(user => !user.groups.some(group => group.name === 'campus_admin'));
  // pagination
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize] = useState(6);
  // searching user reactive
  const [search, setSearch] = useState('');
  // form
  const {
    filterState,
    handleOnChangeInput,
  } = useForm({
    id: -1,
    username: '',
    firstName: '',
    email: '',
    emergencyNumber: '',
    campusId: '',
    groupId: '',
    isActive: false,
    filterState: ''
  });


  const fetchData = () => {
    const filters: UserFilters = {
        page: pageCurrent,
        page_size: pageSize,
        is_active: !filterState ? '' : filterState === 'yes' ? 'true' : 'false',
        groups__name__exclude: 'super_admin',
        first_name__icontains: search,
        campus_id: user.campus.id
    }
    getListUsersPag(filters)(dispatch);
  };
  
  useEffect(() => {
    fetchData();
  }, [pageCurrent, pageSize, filterState, search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUserGroup()(dispatch);
    getListCampus()(dispatch);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type UsersType = {
      name: string;
      email: string;
      documentId: string;
      phone: string;
      is_active: User;
      campus: User;
      group: User;
      options: User;
  };

  const { data, columns } = useTableMemo<UsersType>({
    dataMemo: {
        factory: listUser.map((user) => {
            return {
                name: user.first_name,
                email: user.email,
                documentId: user.document_number,
                phone: user.phone,
                is_active: user,
                campus: user,
                group: user,
                options: user,
            };
        }),
        deps: [listUser]
    },
    columnsMemo: {
        factory: [
          {
              Header: 'Socios',
              accessor: 'name', // accessor is the "key" in the data
          },
          {
              Header: 'Correo Electrónico',
              accessor: 'email',
          },
          {
              Header: 'Cédula',
              accessor: 'documentId',
          },
          {
              Header: 'Celular',
              accessor: 'phone',
          },
          {
              Header: 'Rol',
              accessor: 'group',
          },
          {
              Header: 'Estado',
              accessor: 'is_active',
          },
      ],
        deps: [],
    }
  });

  const handleChangeActive = async (e: ChangeEvent<HTMLSelectElement>, user: User) => {
    // get value
    const isActive = e.target.value;
    const { payload } = await putUser({
      id: user.id,
      is_active: isActive === "true" ? true : false,
      campus: user.campus.id,
      email: user.email,
      first_name: user.first_name,
      emergency_number: user.emergency_number,
      groups: user.groups.map((group) => group.id),
      username: user.email,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Estado del usuario actualizado con éxito.');
    fetchData();
  }

  const handleUpdateGroup = async (e: ChangeEvent<HTMLSelectElement>, user: User) => {
    const groupId = parseInt(e.target.value);
    const { payload } = await putUser({
      id: user.id,
      is_active: user.is_active,
      campus: user.campus.id,
      email: user.email,
      first_name: user.first_name,
      emergency_number: user.emergency_number,
      groups: [groupId],
      username: user.email,
    })(dispatch);
    if (!payload.success && payload.error) return toast.error(payload.error.message);
    toast.success('Rol del usuario actualizado con éxito.');
    fetchData();
  }

  const handleCreateDownloadReport = async () => {
    const filters: UserFilters = {
        is_active: !filterState ? '' : filterState === 'yes' ? 'true' : 'false',
        groups__name__exclude: 'super_admin',
        first_name__icontains: search,
        campus_id: user.campus.id
    }
    const { payload } = await getListUsers(filters)(dispatch);
    const listUsersAux = payload.listUsers || [];
    const listUsers = listUsersAux.filter(user => !user.groups.some(group => group.name === 'campus_admin'));
    const data_excel = listUsers.map(user => ([
        user.first_name,
        user.email,
        user.document_number,
        user.phone,
        rolsCampusAdmin.find(rol => rol.value === user.groups[0].name)?.label || 'Desconocido',
        user.is_active ? 'Aprobado' : 'Suspendido',
    ]));
    const columns_titles = ['Nombre', 'Correo Electrónico', 'Cédula', 'Celular', 'Rol', 'Estado'];
    // Code for excel download
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: 'Reporte de usuarios',
        Subject: 'Reporte de usuarios',
        Author: 'Círculo Militar',
        CreatedDate: new Date(Date.now()),
    };
    wb.SheetNames.push('Reporte de usuarios');
    const ws = XLSX.utils.json_to_sheet(data_excel);
    wb.Sheets['Reporte de usuarios'] = ws;
    // add columns width
    ws['!cols'] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
    ];
    // add columns title
    columns_titles.forEach((title, index) => {
        ws[`${String.fromCharCode(65 + index)}1`] = {
            v: title,
            t: 's',
            font: {
                sz: 14,
                bold: true,
            },
        };
    });


    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_REPORTE_USUARIOS.xlsx`);
}

const listActiveCampus: { value: number; label: string; }[] = [];
if (listUserGroup) {
  for (const rol of listUserGroup) {
    if (!rolsCampusAdmin.some(rolCampus => (!rolCampus.needSuperAdmin) && (rolCampus.value === rol.name))) continue;
    listActiveCampus.push({
      value: rol.id,
      label: rolsCampusAdmin.find(rolCampus => rolCampus.value === rol.name)!.label,
    });
  }
}


  return (
    <>
      <div className="flex flex-row justify-between mb-6">
        <h1 className="font-bold text-2xl">Lista de usuarios</h1>
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row items-center justify-end gap-4 print:hidden">
              Descargar reporte
              <button
                  onClick={handleCreateDownloadReport}
                  className="btn-yellow"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                          fillRule="evenodd"
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clipRule="evenodd"
                      />
                  </svg>
              </button>
          </div>
          <select
              id="filterState"
              name="filterState"
              value={filterState}
              onChange={handleOnChangeInput}
              required
              className="h-11 select-yellow"
          >
              <option value="">Estado (todos)</option>
              <option value="yes">Aprobado</option>
              <option value="no">Suspendido</option>
          </select>
          <div className="flex flex-row items-center relative">
            <input
              type="text"
              className="input-yellow"
              placeholder="Buscar usuario"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <div
              className="absolute z-10 right-1.5 text-gray-500 hover:text-gray-600 active:text-gray-800 cursor-pointer"
              onClick={() => setSearch('')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <TableBody
            columns={columns} data={data} pageSize={pageSize} loading={loading}
            renderRows={row => (
                // Apply the row props
                <tr
                    className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                    {...row.getRowProps()}
                >
                    {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                            if (cell.column.Header === 'Estado') {
                                const user = (cell.render('Cell') as any).props.value;
                                return (
                                    <td
                                        className="flex flex-row items-center gap-4 px-6 py-4 first-letter:capitalize"
                                        {...cell.getCellProps()}
                                    >
                                        <select
                                          id="isActive"
                                          name="isActive"
                                          value={!!user.is_active ? 'true' : 'false'}
                                          onChange={e => handleChangeActive(e, user)}
                                          required
                                          className="select-yellow"
                                        >
                                          <option value="true">
                                            Aprobado
                                          </option>
                                          <option value="false">
                                            Suspendido
                                          </option>
                                        </select>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Rol') {
                              const user = (cell.render('Cell') as any).props.value;
                                // two buttons with taildwind
                                return (
                                    <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                        <select
                                            id="newState"
                                            name="newState"
                                            value={user.groups[0].id}
                                            onChange={(e) => handleUpdateGroup(e, user)}
                                            className="select-yellow"
                                        >
                                          {
                                            listActiveCampus && listActiveCampus.map((group, i) => (
                                              <option key={generateRandomId(`${i}_group`)} value={group.value}>
                                                {group.label}
                                              </option>
                                            ))
                                          }
                                        </select>
                                    </td>
                                );
                            }

                            // Apply the cell props
                            return (
                                <td
                                    className="whitespace-nowrap px-6 py-3"
                                    {...cell.getCellProps()}
                                >
                                    {
                                        // Render the cell contents
                                        cell.render('Cell')
                                    }
                                </td>
                            );
                        })
                    }
                </tr>
            )}
            // For footer
            count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
            // Blank message
            blankMessage="No hay usuarios que mostrar"
        />
    </div>
    </>
  )
}
