
export enum CampusChatbotTypes {
  GET_CAMPUSCHATBOTPAG_LOADING = "GET_CAMPUSCHATBOTPAG_LOADING",
  GET_CAMPUSCHATBOTPAG_SUCCESS = "GET_CAMPUSCHATBOTPAG_SUCCESS",
  GET_CAMPUSCHATBOTPAG_FAILURE = "GET_CAMPUSCHATBOTPAG_FAILURE",
  GET_CAMPUSCHATBOT_LOADING = "GET_CAMPUSCHATBOT_LOADING",
  GET_CAMPUSCHATBOT_SUCCESS = "GET_CAMPUSCHATBOT_SUCCESS",
  GET_CAMPUSCHATBOT_FAILURE = "GET_CAMPUSCHATBOT_FAILURE",
  GETONLY_CAMPUSCHATBOT_LOADING = "GETONLY_CAMPUSCHATBOT_LOADING",
  GETONLY_CAMPUSCHATBOT_SUCCESS = "GETONLY_CAMPUSCHATBOT_SUCCESS",
  GETONLY_CAMPUSCHATBOT_FAILURE = "GETONLY_CAMPUSCHATBOT_FAILURE",
  POST_CAMPUSCHATBOT_LOADING = "POST_CAMPUSCHATBOT_LOADING",
  POST_CAMPUSCHATBOT_SUCCESS = "POST_CAMPUSCHATBOT_SUCCESS",
  POST_CAMPUSCHATBOT_FAILURE = "POST_CAMPUSCHATBOT_FAILURE",
  PUT_CAMPUSCHATBOT_LOADING = "PUT_CAMPUSCHATBOT_LOADING",
  PUT_CAMPUSCHATBOT_SUCCESS = "PUT_CAMPUSCHATBOT_SUCCESS",
  PUT_CAMPUSCHATBOT_FAILURE = "PUT_CAMPUSCHATBOT_FAILURE",
  DELETE_CAMPUSCHATBOT_LOADING = "DELETE_CAMPUSCHATBOT_LOADING",
  DELETE_CAMPUSCHATBOT_SUCCESS = "DELETE_CAMPUSCHATBOT_SUCCESS",
  DELETE_CAMPUSCHATBOT_FAILURE = "DELETE_CAMPUSCHATBOT_FAILURE",
}