
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getEventCoordinators, getEventReservationsPags, getEventSaloons, putEventReservations } from '../../redux/actions';
import { EventReservationsFilters, EventReservationsGet, EventReservationsTable, User } from '../../redux/interfaces';
import { RootState } from "../../redux/store";
import useForm from "../../utils/hooks/useForm";
import Swal from "sweetalert2";
import { TableBody } from "../../components";
import { InputDateRange } from "../../components/InputDateRange";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { eventReserveStatus } from "../../utils/eventReserveStatus";
import { toast } from "react-toastify";

export const EventPendingReservesPage = () => {
    const dispatch = useDispatch();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const { listEventSaloons } = useSelector((state: RootState) => state.eventSaloons);
    const { listEventCoordinators: listEventCoordinatorsFull = [] } = useSelector((state: RootState) => state.eventCoordinators);
    // slice first item from listEventCoordinators
    const listEventCoordinators = listEventCoordinatorsFull.slice(1);
    const { pagEventReservations, loading } = useSelector((state: RootState) => state.eventReservations);
    const {
        results: eventReservations,
        count,
        next,
        previous,
    } = pagEventReservations as EventReservationsGet;
    // pagination
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(4);
    // new Date(), new Date() + one day
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(new Date().getTime() - 60 * 60 * 24 * 1000 * 7), new Date()]);
    const [startDate, endDate] = dateRange;
    // status values
    const statusCellOptions: {
        [key: string]: {
            label: string;
            color: string;
        };
    } = {};
    for (const status of eventReserveStatus) {
        statusCellOptions[status.value] = {
            label: status.label,
            color: status.color,
        };
    }
    // form values
    const { eventSaloonId, handleOnChangeInput } = useForm({eventSaloonId: ''});

    const fetchData = () => {
        const filters: EventReservationsFilters = {
            event_type__campus_id: user.campus.id,
            page: pageCurrent,
            page_size: pageSize,
            event_salon_id: parseInt(eventSaloonId),
            status: 'PENDING',
            start_date__gte: '',
            start_date__lte: '',
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['start_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        getEventReservationsPags(filters)(dispatch);
    }

    // fetch data with filters
    useEffect(() => {
        fetchData();
    }, [pageCurrent, pageSize, eventSaloonId, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getEventSaloons({
            campus_id: user.campus.id,
        })(dispatch);
        getEventCoordinators({
            campus_id: user.campus.id
          })(dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateStatus = async (status: string, reservation: EventReservationsTable) => {
        const { payload } = await putEventReservations({
            ...reservation,
            user_id: reservation.user.id,
            event_type_id: reservation.event_type.id,
            event_coordinator_id: reservation.event_coordinator.id,
            event_salon_id: reservation.event_salon.id,
            status: status,
            total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchData();
    }

    const handleUpdateCoordinator = async (coordinatorId: string, reservation: EventReservationsTable) => {
        const { payload } = await putEventReservations({
            ...reservation,
            user_id: reservation.user.id,
            event_type_id: reservation.event_type.id,
            event_coordinator_id: parseInt(coordinatorId),
            event_salon_id: reservation.event_salon.id,
            status: reservation.status,
            total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchData();
    }

    // Code for table
    type EventReservationsType = {
        first_name: string;
        total_cost: string;
        event_type: string;
        package_type: string;
        people_total: string;
        start_date: string;
        hours: string;
        coordinator_name: any;
        status: any;
        description: string;
        voucher: any;
    };

    const { data, columns } = useTableMemo<EventReservationsType>({
        dataMemo: {
            factory: eventReservations.map((reservation) => {
                const startDate = new Date(reservation.start_date).toLocaleDateString('es-ES', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                });
                const hourStart = new Date(reservation.start_date).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                const hourEnd = new Date(reservation.end_date).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return {
                    first_name: reservation.user.first_name,
                    total_cost: `$${reservation.total_price}`,
                    event_type: reservation.event_type.name,
                    package_type: reservation.event_package,
                    people_total: `${reservation.total_people}/${reservation.event_salon.capacity}`,
                    start_date: startDate,
                    hours: `${hourStart} - ${hourEnd}`,
                    coordinator_name: reservation,
                    status: reservation,
                    description: reservation.description,
                    voucher: reservation.event_reservation_wire_transfers?.[0]?.receipt || '',
                };
            }),
            deps: [eventReservations]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Cliente',
                    accessor: 'first_name',
                },
                {
                    Header: 'Precio de Reserva',
                    accessor: 'total_cost',
                },
                {
                    Header: 'Tipo de Evento',
                    accessor: 'event_type',
                },
                {
                    Header: 'Paquete',
                    accessor: 'package_type',
                },
                {
                    Header: 'Asistentes',
                    accessor: 'people_total',
                },
                {
                    Header: 'Fecha',
                    accessor: 'start_date',
                },
                {
                    Header: 'Hora',
                    accessor: 'hours',
                },
                {
                    Header: 'Coordinador',
                    accessor: 'coordinator_name',
                },
                {
                    Header: 'Estado',
                    accessor: 'status',
                },
                {
                    Header: 'Nota',
                    accessor: 'description',
                },
                {
                    Header: 'Comprobante',
                    accessor: 'voucher',
                },
            ],
            deps: []
        }
    })


    return (
        <>
            <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
                <select
                    id="eventSaloonId"
                    name="eventSaloonId"
                    value={eventSaloonId}
                    onChange={handleOnChangeInput}
                    required
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Salones (todos)</option>
                    {
                        listEventSaloons && listEventSaloons.map((saloon) => (
                            <option key={`bedroom-${saloon.id}`} value={saloon.id}>{saloon.name}</option>
                        ))
                    }
                </select>
                <div className="flex items-center">
                    <InputDateRange
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        className="h-11 font-bold"
                    />
                </div>
            </div>
            <div className="overflow-hidden">
                <TableBody
                    columns={columns} data={data} pageSize={pageSize} loading={loading}
                    renderRows={row => (
                        <tr
                            className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                            {...row.getRowProps()}
                        >
                            {
                                row.cells.map((cell) => {
                                    if (cell.column.Header === 'Nota') {
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <button onClick={() => {
                                                    Swal.fire({
                                                        title: 'Nota',
                                                        text: (cell.render('Cell') as any).props.value ? (cell.render('Cell') as any).props.value : 'No hay nota',
                                                    })
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Coordinador') {
                                        const reservation: EventReservationsTable = (cell.render('Cell') as any).props.value;
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <select
                                                    id="eventCoordinatorId"
                                                    name="eventCoordinatorId"
                                                    value={reservation.event_coordinator.id}
                                                    onChange={(e) => handleUpdateCoordinator(e.target.value, reservation)}
                                                    required
                                                    className="select-yellow text-sm h-9"
                                                >
                                                    <option value="" disabled>Seleccione un Coordinador</option>
                                                    {
                                                        listEventCoordinators && listEventCoordinators.map((coordinator) => (
                                                            <option key={`coordinator-${coordinator.id}`} value={coordinator.id}>{coordinator.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Estado') {
                                        const reservation: EventReservationsTable = (cell.render('Cell') as any).props.value;
                                        // two buttons with taildwind
                                        return (
                                            <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                                <div className="flex flex-row justify-start gap-2">
                                                    <button
                                                        onClick={() => handleUpdateStatus('RESERVED', reservation)}
                                                        className="bg-green-500 font-bold p-1 rounded-full hover:bg-green-400 active:bg-green-300 text-white print:hidden"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        onClick={() => handleUpdateStatus('CANCELLED', reservation)}
                                                        className="bg-red-500 font-bold p-1 rounded-full hover:bg-red-400 active:bg-red-300 text-white print:hidden"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                    </div>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Comprobante') {
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <button className="underline" onClick={() => {
                                                    if ((cell.render('Cell') as any).props.value) {
                                                        Swal.fire({
                                                            imageUrl: (cell.render('Cell') as any).props.value,
                                                            imageWidth: 400,
                                                            imageHeight: 400,
                                                            imageAlt: 'Custom image',
                                                        })
                                                    } else {
                                                        Swal.fire({
                                                            title: 'No hay comprobante',
                                                            text: 'No se ha subido un comprobante',
                                                        })
                                                    }
                                                }}>
                                                    {(cell.render('Cell') as any).props.value === '' ? '' : 'Ver comprobante'}
                                                </button>
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            className="whitespace-nowrap px-6 py-3"
                                            {...cell.getCellProps()}
                                        >
                                            <span className="inline-block first-letter:capitalize">{cell.render('Cell')}</span>
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    )}
                    // For footer
                    count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
                />
            </div>
        </>
    );
};
