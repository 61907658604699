import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TableBody } from "../../components";
import { getCampusNotificationsPags } from "../../redux/actions";
import { CampusNewsFilters, CampusNotificationsGet } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
const removeMd = require('remove-markdown');

export const CampusNotificationsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pagCampusNotifications, loading } = useSelector((state: RootState) => state.campusNotifications);
    const {
        results: notifications,
        count,
        next,
        previous,
    } = pagCampusNotifications as CampusNotificationsGet;
    // pagination
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(4);

    const fetchData = () => {
        const filters: CampusNewsFilters = {
            page: pageCurrent,
            page_size: pageSize,
        }
        getCampusNotificationsPags(filters)(dispatch);
    }

    // fetch data with filters
    useEffect(() => {
        fetchData();
    }, [pageCurrent, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps
    // Code for table
    type CampusNewsType = {
        administrator: string;
        title: string;
        description: string;
    };

    const { data, columns } = useTableMemo<CampusNewsType>({
        dataMemo: {
            factory: notifications.map((notification) => ({
                administrator: notification.user.first_name,
                title: notification.title,
                description: notification.description,
            })),
            deps: [notifications]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Administrador',
                    accessor: 'administrator',
                },
                {
                    Header: 'Título',
                    accessor: 'title',
                },
                {
                    Header: 'Cuerpo',
                    accessor: 'description',
                }
            ],
            deps: []
        }
    });

    return (
        <>
            <div className="flex flex-row justify-between mb-8">
                <h1 className="text-gray-500 font-bold text-lg">Notificaciones</h1>
                <button
                    onClick={() =>
                        navigate('/campus/notifications/new')
                    }
                    className="btn-yellow flex flex-row items-center gap-2 h-11"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Añadir nueva notificación
                </button>
            </div>
            <TableBody
                columns={columns} data={data} pageSize={pageSize} loading={loading}
                renderRows={row => (
                    <tr
                        className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                        {...row.getRowProps()}
                    >
                        {
                            row.cells.map((cell) => {
                                if (cell.column.Header === 'Administrador') {
                                    return (
                                        <td
                                            className="px-6 py-4"
                                            {...cell.getCellProps()}
                                        >
                                            <div className="flex flex-row items-center gap-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                                </svg>
                                                {cell.render('Cell')}
                                            </div>
                                        </td>
                                    );
                                }

                                if (cell.column.Header === 'Cuerpo') {
                                    const description = (cell.render('Cell') as any).props.value;
                                    return (
                                        <td
                                            className="px-6 py-4"
                                            {...cell.getCellProps()}
                                        >
                                            <div className="truncate max-w-sm">
                                                {removeMd(description)}
                                            </div>
                                        </td>
                                    );
                                }

                                return (
                                    <td
                                        className="whitespace-nowrap px-6 py-3"
                                        {...cell.getCellProps()}
                                    >
                                        <span className="inline-block first-letter:capitalize">{cell.render('Cell')}</span>
                                    </td>
                                );
                            })
                        }
                    </tr>
                )}
                // blank message
                blankMessage="No hay notificaciones que mostrar"
                // For footer
                count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
            />
        </>
    )
}