import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteEventTypes, getEventTypes } from '../../redux/actions';
import { EventTypesTable, User } from '../../redux/interfaces';
import { RootState } from '../../redux/store';
import alerts from '../../utils/alerts';

export const EventTypeListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listEventTypes } = useSelector((state: RootState) => state.eventTypes);
  const fetchData = async () => {
    getEventTypes({
      campus_id: user.campus.id
    })(dispatch);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCoordinator = async (eventType: EventTypesTable) => {
    const { isConfirmed } = await alerts.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${eventType.name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteEventTypes(eventType.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success('Salón eliminado con éxito.');
        fetchData();
      } else {
        toast.error(payload.error?.message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <div className="pb-16">
        <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
          <div className="flex flex-row items-center justify-end gap-4 print:hidden">
              Añadir Evento
              <NavLink
                  to="/event/new"
                  // onClick={handleCreateDownloadReport}
                  className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
              </NavLink>
          </div>
        </div>
        <h1 className="grid grid-cols-[minmax(0,1fr)_120px] font-bold text-black mb-4">
            <span className="basis-[73%]">Nombre del Evento</span>
            <span>Acciones</span>
        </h1>
        {(listEventTypes &&
          listEventTypes.length > 0) ?
          listEventTypes.map((eventType, i) => (
            <div
              key={`form-${i}`}
              className="grid grid-cols-[minmax(0,1fr)_120px] items-center justify-between text-gray-500 even:bg-gray-50 odd:bg-white odd:hover:bg-gray-50"
            >
              <span className="px-4 font-bold">{eventType.name}</span>
              <span className="mr-6 flex flex-row gap-1">
                <button
                  // onClick={() => handleEditCoordinator(eventType, setValues)}
                  onClick={() =>
                    navigate('/event/edit', {
                      state: {
                        eventType
                      },
                    })
                  }
                  className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
                <button
                  onClick={() => handleDeleteCoordinator(eventType)}
                  className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </span>
            </div>
          )) : (
            <div className="bg-gray-50 px-4">
              <span className="font-bold text-black">No hay eventos registrados</span>
            </div>
          )}
      </div>
    </>
  )
}
