
import { CampusNewsRepository } from "../repositories";
import { CampusNewsFilters, CampusNewsGet, CampusNewsTable } from "../../interfaces";

export class CampusNewsProcess {
  private campusNewsRepository: CampusNewsRepository;

  constructor() {
    this.campusNewsRepository = new CampusNewsRepository();
  }

  async getCampusNews(filters?: CampusNewsFilters): Promise<CampusNewsGet> {
    const response = await this.campusNewsRepository.getCampusNews(filters);
    return response.data;
  }

  async postCampusNews(campusNews: FormData): Promise<CampusNewsTable> {
    const response = await this.campusNewsRepository.postCampusNews(campusNews);
    return response.data;
  }

  async putCampusNews(campusNews: FormData): Promise<CampusNewsTable> {
    const response = await this.campusNewsRepository.putCampusNews(campusNews);
    return response.data;
  }

  // dont response data
  async deleteCampusNews(id: number): Promise<any> {
    const response = await this.campusNewsRepository.deleteCampusNews(id);
    return response.data;
  }
}
