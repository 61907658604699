import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { getSuggestions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { SuggestionsFilters, User } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getFirstAndLastDayOfMonth } from "../../utils/utils";
import img404 from "../../assets/images/undraw_house_searching.svg";

export const CampusSuggestionsPage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listSuggestions } = useSelector((state: RootState) => state.suggestions);
  const [startDate, setStartDate] = useState(new Date());
  const [monthDate, setMonthDate] = useState<[Date, Date]>([getFirstAndLastDayOfMonth(startDate).first, getFirstAndLastDayOfMonth(startDate).last]);

  const fetchData = () => {
    const filters: SuggestionsFilters = {
      campus_id: user.campus.id,
      created_at__gte: '',
      created_at__lte: '',
    }
    const [ startDate, endDate ] = monthDate;
    if (startDate && endDate) {
        filters['created_at__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
        filters['created_at__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
    }
    getSuggestions(filters)(dispatch);
  }
  // fetch data with filters
  useEffect(() => {
    const daysOfMonth = getFirstAndLastDayOfMonth(startDate);
    setMonthDate([daysOfMonth.first, daysOfMonth.last]);
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [monthDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateDownloadReport = async () => {
    const filters: SuggestionsFilters = {
        campus_id: user.campus.id,
        created_at__gte: '',
        created_at__lte: '',
    }
    const [ startDate, endDate ] = monthDate;
    if (startDate && endDate) {
        filters['created_at__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
        filters['created_at__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
    }
    const { payload } = await getSuggestions(filters)(dispatch);
    const listSuggestions = payload.listSuggestions || [];
    const data_excel = listSuggestions.map(reservation => ([
        reservation.user.first_name,
        new Date(reservation.created_at).toLocaleDateString(),
        reservation.description,
    ]));
    const columns_titles = ['Cliente', 'Fecha', 'Sugerencia'];
    // Code for excel download
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: 'Reporte de sugerencias',
        Subject: 'Reporte de sugerencias',
        Author: 'Círculo Militar',
        CreatedDate: new Date(Date.now()),
    };
    wb.SheetNames.push('Reporte de sugerencias');
    const ws = XLSX.utils.json_to_sheet(data_excel);
    wb.Sheets['Reporte de sugerencias'] = ws;
    // add columns width
    ws['!cols'] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
    ];
    // add columns title
    columns_titles.forEach((title, index) => {
        ws[`${String.fromCharCode(65 + index)}1`] = {
            v: title,
            t: 's',
            font: {
                sz: 14,
                bold: true,
            },
        };
    });


    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_SUGERENCIAS_CIRCULOMILITAR.xlsx`);
}

  return (
    <>
      <div
        className="flex flex-row justify-between items-center mb-6"
      >
        <h1 className="font-bold text-gray-500 text-lg">Sugerencias</h1>
        <div className="flex flex-row gap-2 h-11">
          <div className="flex flex-row items-center justify-end gap-4 print:hidden">
              Descargar reporte
              <button
                  onClick={handleCreateDownloadReport}
                  className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                          fillRule="evenodd"
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clipRule="evenodd"
                      />
                  </svg>
              </button>
          </div>
          <ReactDatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            locale={es}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            className="input-yellow bg-yellow-500 font-bold w-28"
            wrapperClassName="w-28"
          />
        </div>
      </div>
      <section className="flex flex-col gap-4 divide-y">
          {
            (listSuggestions && listSuggestions.length > 0) ?
            listSuggestions.map((suggestion, index) => (
                <div key={index}>
                  <div className="flex flex-row justify-between bg-gray-100 px-2 py-1">
                      <span className="text-gray-600 font-bold">{suggestion.user.first_name}</span>
                      <span className="text-gray-600">{
                        new Date(suggestion.created_at).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        })
                      }</span>
                  </div>
                  <div>
                    <p className="text-gray-700 px-2 py-1">{suggestion.description}</p>
                  </div>
                </div>
            )) : (
              <div className="flex flex-col justify-center items-center gap-4 mt-10">
                <img src={img404} alt="404" className="max-h-52 w-60" />
                <p className="text-gray-600 text-center font-bold text-2xl">No hay sugerencias a la vista</p>
              </div>
            )
          }
      </section>
    </>
  )
}