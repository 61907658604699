import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "../../components";
import { deleteMultipleServicesCategory, getMultipleServicesCategory, postMultipleServicesCategory, putMultipleServicesCategory } from "../../redux/actions";
import { MultipleServicesCategoryPayload, MultipleServicesCategoryTable, User } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import alerts from "../../utils/alerts";
import useForm from "../../utils/hooks/useForm";

export const ServiceCategoryNewPage = () => {
  // dispatch
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const multipleServicesCategoryPayload: MultipleServicesCategoryPayload = useSelector((state: any) => state.multipleServicesCategory);
  const multipleServicesCategory = multipleServicesCategoryPayload.listMultipleServicesCategory || [];
  const { name, nameEdit, idEdit, handleOnChangeInput, handleUpdateState } = useForm({
    name: '',
    nameEdit: '',
    idEdit: 0
  });
  const [ isOpenModal, setIsOpenModal ] = useState(false);

  useEffect(() => {
    getMultipleServicesCategory({
      campus_id: user.campus.id
    })(dispatch);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteMultipleServiceCategory = async (multipleServicesCategory: MultipleServicesCategoryTable) => {
    const userRes = await alerts.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${multipleServicesCategory.name}"</b>. Una vez realizada esta acción no se podrá revertir.`
    });

    if (userRes.isConfirmed) {
      const { payload } = await deleteMultipleServicesCategory(multipleServicesCategory.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success('Se eliminó correctamente');
        getMultipleServicesCategory({
          campus_id: user.campus.id
        })(dispatch);
      } else {
        toast.error('Ocurrió un error, intente nuevamente');
      }
    }
  }

  const handleOpenModal = (multipleServicesCategory: MultipleServicesCategoryTable) => {
    handleUpdateState({
      nameEdit: multipleServicesCategory.name,
      idEdit: multipleServicesCategory.id
    });

    setIsOpenModal(true);
  }

  const handleEditMultipleServiceCategory = async (e: FormEvent) => {
    e.preventDefault();
    const { payload } = await putMultipleServicesCategory({
      name: nameEdit.trim(),
      id: idEdit,
      campus: user.campus.id
    })(dispatch);
    const { success } = payload;
    if (success) {
      toast.success('Se editó correctamente');
      getMultipleServicesCategory({
        campus_id: user.campus.id
      })(dispatch);
      setIsOpenModal(false);
    } else {
      toast.error('Ocurrió un error');
    }
  }

  const handlePostCategoryNew = async (e: FormEvent) => {
    e.preventDefault();
    const { payload } = await postMultipleServicesCategory({
      name,
      campus: user.campus.id,
    })(dispatch);
    const { success } = payload;
    if (success) {
      toast.success('Categoría creada con éxito.');
      getMultipleServicesCategory({
        campus_id: user.campus.id
      })(dispatch);
    } else {
      toast.error('Error al crear la categoría.');
    }

  }

  return (
      <>
          <div className="mx-auto max-w-3xl">
              <form action="post" onSubmit={handlePostCategoryNew}>
                  <h2 className="font-bold text-gray-500">Categorías de Servicios Múltiples</h2>
                  <div className="mt-6 flex flex-row items-center gap-4">
                      <input
                          id="name"
                          name="name"
                          type="text"
                          maxLength={150}
                          value={name}
                          onChange={handleOnChangeInput}
                          required
                          className="relative mt-2 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                          placeholder="Piscinas"
                      />
                      <button
                          type="submit"
                          className="relative mt-2 flex w-36 justify-center rounded-md border border-transparent bg-yellow-500 py-3 px-4 text-sm font-bold text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                      >
                          Agregar
                      </button>
                  </div>
              </form>
              <div>
                  <div className="mt-12">
                      <h3 className="text-sm font-bold text-gray-500">Categorías agregadas</h3>
                      <div className="mt-4 divide-y divide-gray-500/25">
                          {multipleServicesCategory.map((multipleServicesCategory, i) => (
                              <div
                                  key={`multipleServicesCategory-${i}`}
                                  className="flex flex-row items-center justify-between odd:bg-gray-50 even:bg-white even:hover:bg-gray-50"
                              >
                                  <span className="basis-3/5 px-4 font-bold text-black">
                                      {multipleServicesCategory.name}
                                  </span>
                                  <span className="mr-6 flex flex-row gap-1">
                                      <button
                                          onClick={() => handleOpenModal(multipleServicesCategory)}
                                          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                      >
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5 text-gray-700"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                          >
                                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                          </svg>
                                      </button>
                                      <button
                                          onClick={() => handleDeleteMultipleServiceCategory(multipleServicesCategory)}
                                          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                      >
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5 text-gray-700"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                          >
                                              <path
                                                  fillRule="evenodd"
                                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                  clipRule="evenodd"
                                              />
                                          </svg>
                                      </button>
                                  </span>
                              </div>
                          ))}
                      </div>
                  </div>
              </div>
          </div>
          <Modal title="Editar Categoría" isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)}>
              <form
                  onSubmit={(e) => handleEditMultipleServiceCategory(e)}
                  className="flex flex-col items-center justify-center gap-2"
              >
                  <input
                      id="nameEdit"
                      name="nameEdit"
                      type="text"
                      maxLength={150}
                      value={nameEdit}
                      onChange={handleOnChangeInput}
                      required
                      className="relative mt-4 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                      placeholder="Piscinas"
                  />
                  <div className="flex w-full justify-end">
                      <button
                          type="submit"
                          className="mt-2 w-36 rounded-md border border-transparent bg-yellow-500 py-3 px-4 text-sm font-bold text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                      >
                          Actualizar
                      </button>
                  </div>
              </form>
          </Modal>
      </>
  );
}