
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { RestaurantDishesFilters, RestaurantDishesGet, RestaurantDishesTable } from "../../interfaces";

export class RestaurantDishesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getRestaurantDishes(filters?: RestaurantDishesFilters): Promise<AxiosResponse<RestaurantDishesGet>> {
    return this.lmBootApi.get<RestaurantDishesGet>("/restaurant-dishes/", { params: filters });
  }

  postRestaurantDishes(restaurantDishes: FormData): Promise<AxiosResponse<RestaurantDishesTable>> {
    return this.lmBootApi.post<RestaurantDishesTable>("/restaurant-dishes/", restaurantDishes);
  }

  putRestaurantDishes(restaurantDishes: FormData): Promise<AxiosResponse<RestaurantDishesTable>> {
    return this.lmBootApi.put<RestaurantDishesTable>("/restaurant-dishes/", restaurantDishes);
  }

  // dont response data
  deleteRestaurantDishes(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/restaurant-dishes/${id}/`);
  }
}

