
export enum EventWireTransfersTypes {
  GET_EVENTWIRETRANSFERSPAG_LOADING = "GET_EVENTWIRETRANSFERSPAG_LOADING",
  GET_EVENTWIRETRANSFERSPAG_SUCCESS = "GET_EVENTWIRETRANSFERSPAG_SUCCESS",
  GET_EVENTWIRETRANSFERSPAG_FAILURE = "GET_EVENTWIRETRANSFERSPAG_FAILURE",
  GET_EVENTWIRETRANSFERS_LOADING = "GET_EVENTWIRETRANSFERS_LOADING",
  GET_EVENTWIRETRANSFERS_SUCCESS = "GET_EVENTWIRETRANSFERS_SUCCESS",
  GET_EVENTWIRETRANSFERS_FAILURE = "GET_EVENTWIRETRANSFERS_FAILURE",
  GETONLY_EVENTWIRETRANSFERS_LOADING = "GETONLY_EVENTWIRETRANSFERS_LOADING",
  GETONLY_EVENTWIRETRANSFERS_SUCCESS = "GETONLY_EVENTWIRETRANSFERS_SUCCESS",
  GETONLY_EVENTWIRETRANSFERS_FAILURE = "GETONLY_EVENTWIRETRANSFERS_FAILURE",
  POST_EVENTWIRETRANSFERS_LOADING = "POST_EVENTWIRETRANSFERS_LOADING",
  POST_EVENTWIRETRANSFERS_SUCCESS = "POST_EVENTWIRETRANSFERS_SUCCESS",
  POST_EVENTWIRETRANSFERS_FAILURE = "POST_EVENTWIRETRANSFERS_FAILURE",
  PUT_EVENTWIRETRANSFERS_LOADING = "PUT_EVENTWIRETRANSFERS_LOADING",
  PUT_EVENTWIRETRANSFERS_SUCCESS = "PUT_EVENTWIRETRANSFERS_SUCCESS",
  PUT_EVENTWIRETRANSFERS_FAILURE = "PUT_EVENTWIRETRANSFERS_FAILURE",
  DELETE_EVENTWIRETRANSFERS_LOADING = "DELETE_EVENTWIRETRANSFERS_LOADING",
  DELETE_EVENTWIRETRANSFERS_SUCCESS = "DELETE_EVENTWIRETRANSFERS_SUCCESS",
  DELETE_EVENTWIRETRANSFERS_FAILURE = "DELETE_EVENTWIRETRANSFERS_FAILURE",
}