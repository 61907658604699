import { MultipleServicesAction, MultipleServicesPayload, UserPayload } from "../interfaces";
import { MultipleServicesTypes } from "../types";

const initialState: UserPayload = {
  loading: false,
  success: true,
};

export const multipleServices = (state = initialState, action: MultipleServicesAction): MultipleServicesPayload => {
  const { loading, success, error, multipleServices } = action.payload || {};
  switch (action.type) {
    case MultipleServicesTypes.GET_MULTIPLESERVICES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesTypes.GET_MULTIPLESERVICES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesTypes.GET_MULTIPLESERVICES_SUCCESS:
      return {
        ...state,
        multipleServices,
        loading,
        success,
      };
    case MultipleServicesTypes.POST_MULTIPLESERVICES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesTypes.POST_MULTIPLESERVICES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesTypes.POST_MULTIPLESERVICES_SUCCESS:
      return {
        ...state,
        multipleServices,
        loading,
        success,
      };
    case MultipleServicesTypes.PUT_MULTIPLESERVICES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesTypes.PUT_MULTIPLESERVICES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesTypes.PUT_MULTIPLESERVICES_SUCCESS:
      return {
        ...state,
        multipleServices,
        loading,
        success,
      };
    case MultipleServicesTypes.DELETE_MULTIPLESERVICES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesTypes.DELETE_MULTIPLESERVICES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesTypes.DELETE_MULTIPLESERVICES_SUCCESS:
      return {
        ...state,
        multipleServices,
        loading,
        success,
      };
    default:
      return state;
  }
};

