import { HotelImageRepository } from "../repositories";
import { HotelImageFilters, HotelImageGet, HotelImageTable } from "../../interfaces";

export class HotelImageProcess {
  private multipleServiceReservationRepository: HotelImageRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelImageRepository();
  }

  async getHotelImage(filters?: HotelImageFilters): Promise<HotelImageGet> {
    const response = await this.multipleServiceReservationRepository.getHotelImage(filters);
    return response.data;
  }

  async postHotelImage(hotelImage: FormData): Promise<HotelImageTable> {
    const response = await this.multipleServiceReservationRepository.postHotelImage(hotelImage);
    return response.data;
  }

  async putHotelImage(hotelImage: FormData): Promise<HotelImageTable> {
    const response = await this.multipleServiceReservationRepository.putHotelImage(hotelImage);
    return response.data;
  }

  // dont response data
  async deleteHotelImage(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotelImage(id);
    return response.data;
  }
}
