
import { RestaurantAction, RestaurantPayload } from "../interfaces";
import { RestaurantTypes } from "../types";

const initialState: RestaurantPayload = {
  loading: false,
  success: false,
  error: null,
  restaurant: null,
  listRestaurant: [],
  pagRestaurant: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const restaurant = (state = initialState, action: RestaurantAction): RestaurantPayload => {
  const { loading, success, error, restaurant, listRestaurant, pagRestaurant } = action.payload || {};
  switch (action.type) {
    case RestaurantTypes.GET_RESTAURANTPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantTypes.GET_RESTAURANTPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantTypes.GET_RESTAURANTPAG_SUCCESS:
      return {
        ...state,
        pagRestaurant,
        loading,
        success,
      };
    case RestaurantTypes.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantTypes.GET_RESTAURANT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantTypes.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        listRestaurant,
        loading,
        success,
      };
    case RestaurantTypes.GETONLY_RESTAURANT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantTypes.GETONLY_RESTAURANT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantTypes.GETONLY_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurant,
        loading,
        success,
      };
    case RestaurantTypes.POST_RESTAURANT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantTypes.POST_RESTAURANT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantTypes.POST_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurant,
        loading,
        success,
      };
    case RestaurantTypes.PUT_RESTAURANT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantTypes.PUT_RESTAURANT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantTypes.PUT_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurant,
        loading,
        success,
      };
    case RestaurantTypes.DELETE_RESTAURANT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantTypes.DELETE_RESTAURANT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantTypes.DELETE_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurant,
        loading,
        success,
      };
    default:
      return state;
  }
};

