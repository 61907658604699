export enum HolidaysTypes {
  GET_HOLIDAYS_LOADING = "GET_HOLIDAYS_LOADING",
  GET_HOLIDAYS_SUCCESS = "GET_HOLIDAYS_SUCCESS",
  GET_HOLIDAYS_FAILURE = "GET_HOLIDAYS_FAILURE",
  POST_HOLIDAYS_LOADING = "POST_HOLIDAYS_LOADING",
  POST_HOLIDAYS_SUCCESS = "POST_HOLIDAYS_SUCCESS",
  POST_HOLIDAYS_FAILURE = "POST_HOLIDAYS_FAILURE",
  PUT_HOLIDAYS_LOADING = "PUT_HOLIDAYS_LOADING",
  PUT_HOLIDAYS_SUCCESS = "PUT_HOLIDAYS_SUCCESS",
  PUT_HOLIDAYS_FAILURE = "PUT_HOLIDAYS_FAILURE",
  DELETE_HOLIDAYS_LOADING = "DELETE_HOLIDAYS_LOADING",
  DELETE_HOLIDAYS_SUCCESS = "DELETE_HOLIDAYS_SUCCESS",
  DELETE_HOLIDAYS_FAILURE = "DELETE_HOLIDAYS_FAILURE",
}