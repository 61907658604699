import { CampusSurveyAnswerProcess } from '../api';
import { CampusSurveyAnswerAction, CampusSurveyAnswerFilters, CampusSurveyAnswerReportFilters } from '../interfaces';
import { CampusSurveyAnswerTypes } from '../types';

export const getCampusSurveyAnswer =
  (filters?: CampusSurveyAnswerFilters) =>
  async (dispatch: (state: CampusSurveyAnswerAction) => CampusSurveyAnswerAction) => {
    dispatch({
      type: CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWER_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const campusSurveyAnswerProcess = new CampusSurveyAnswerProcess();
      // dont'n import the result of the process, only that dont't have error
      const campusSurveyAnswer = await campusSurveyAnswerProcess.getCampusSurveyAnswer({
        ...filters,
        data_all: 'YES',
      });
      return dispatch({
        type: CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWER_SUCCESS,
        payload: {
          listCampusSurveyAnswer: campusSurveyAnswer,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWER_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };

export const getCampusSurveyAnswerReport =
  (filters?: CampusSurveyAnswerReportFilters) =>
  async (dispatch: (state: CampusSurveyAnswerAction) => CampusSurveyAnswerAction) => {
    dispatch({
      type: CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWERREPORT_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const campusSurveyAnswerProcess = new CampusSurveyAnswerProcess();
      // dont'n import the result of the process, only that dont't have error
      const campusSurveyAnswerReport = await campusSurveyAnswerProcess.getCampusSurveyAnswerReport({
        ...filters,
        data_all: 'YES',
      });
      return dispatch({
        type: CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWERREPORT_SUCCESS,
        payload: {
          listCampusSurveyAnswerReport: campusSurveyAnswerReport,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: CampusSurveyAnswerTypes.GET_CAMPUSSURVEYANSWERREPORT_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };
