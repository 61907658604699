import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRestaurantCategories, postRestaurantLunches } from '../../redux/actions';
import { RestaurantLunchesPost, RestaurantLunchesTable, RestaurantTable } from '../../redux/interfaces';
import { MiniCarouselImg } from '../../components';
import { FormikErrors, useFormik } from 'formik';
import { getFirstAndLastDayOfWeek, testSizeImg } from '../../utils/utils';

type ImageType = {
  id?: number;
  url: string;
  restaurant?: number;
  file?: File
}

interface DataStructure {
  day: string;
  label: string;
  date: string;
  meals: RestaurantLunchesTable[];
}

export const RestaurantMenuWeekNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { restaurant } = state as { restaurant: RestaurantTable };
  const { dayOfWeek } = state as { dayOfWeek: DataStructure };
  const [images, setImages] = useState<ImageType[]>([]);

  useEffect(() => {
    getRestaurantCategories({
      restaurant_id: restaurant.id
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    getFieldProps,
    handleSubmit,
    touched,
    errors
  } = useFormik({
    initialValues: {
      nameProduct: '',
      plateNumber: '',
      dateLunch: dayOfWeek.date.split('T')[0] || '',
      description: '',
      // for errors
      cover_image: '',
    },
    validate: (values) => {
      const errors: FormikErrors<any> = {};
      if (!values.nameProduct) {
        errors.nameProduct = 'El nombre es requerido';
      }
      if (!values.description) {
        errors.description = 'La descripción es requerida';
      }
      if (!values.plateNumber) {
        errors.plateNumber = 'El precio es requerido';
      }
      if (!values.dateLunch) {
        errors.dateLunch = 'La fecha es requerida';
      }
      if (!images || images.length === 0) {
        errors.cover_image = 'Subir al menos una imagen';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      if (!images || images.length === 0) return;

      const date = new Date(`${values.dateLunch}T00:00:00.000`); // values.dateLunch: 2022-03-09
      const daysString = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];

      const formData = new FormData();
      const formDataFields: RestaurantLunchesPost = {
        day: daysString[date.getDay()],
        date: date.toISOString(),
        product_name: values.nameProduct,
        description: values.description,
        plate_number: values.plateNumber,
        plate_image: images[0].file,
        restaurant_id: ''+restaurant.id
      };

      toast.info(`Creando ${values.nameProduct}...`);

      Object.entries(formDataFields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const { payload } = await postRestaurantLunches(formData)(dispatch);
      if (!payload.success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      toast.success(`${values.nameProduct} creado con éxito.`);
      helpers.resetForm();
      setImages([]);
    }
  });

  const handleCatchImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', { type: 'error' });
    }

    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
    setImages((prevState) => [...prevState, ...imagesList]);
  }

  const removeImage = (image: ImageType) => {
    setImages(images.filter((imageItem) => imageItem !== image));
  }

  return (
    <>
      <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      <div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
        >
          <h2 className="font-bold text-gray-500">
            Agregar al menú (
              Semana {` `}
              {getFirstAndLastDayOfWeek(new Date(dayOfWeek.date)).first.getDate()}
              -
              {getFirstAndLastDayOfWeek(new Date(dayOfWeek.date)).last.getDate()}
              {` `}de{` `}
              {new Date(dayOfWeek.date).toLocaleString('es-ES', { month: 'long' })}
            )
          </h2>
          <div className="mt-4 grid grid-cols-3 items-center gap-4">
            <input
              id="nameProduct"
              type="text"
              maxLength={250}
              {...getFieldProps('nameProduct')}
              required
              className="input-yellow"
              placeholder="Nombre del producto"
            />
            <input
              id="plateNumber"
              type="number"
              {...getFieldProps('plateNumber')}
              required
              className="input-yellow"
              placeholder="# de platos"
            />
            <input
              id="dateLunch"
              type="date"
              {...getFieldProps('dateLunch')}
              required
              className="input-yellow"
              placeholder="Precio"
            />
          </div>
          <div className="grid grid-cols-3 -mt-4">
            <span className="grow text-rose-500">{ touched.nameProduct && errors.nameProduct && errors.nameProduct}</span>
            <span className="grow ml-2 text-rose-500">{ touched.plateNumber && errors.plateNumber && errors.plateNumber}</span>
            <span className="grow order-last ml-4 text-rose-500">{ touched.dateLunch && errors.dateLunch && errors.dateLunch}</span>
          </div>
          <textarea
            id="description"
            rows={4}
            {...getFieldProps('description')}
            required
            placeholder="Descripción"
            className="input-yellow w-full h-20"
          ></textarea>
          { touched.description && errors.description && <span className="text-rose-500">{errors.description}</span> }
          <div>
            <h2 className="font-bold text-gray-500">
              Imágenes <span className="text-sm font-normal text-orange-500">(Tamaño máximo 10Mb, Dimensión mínima 640x640)</span>
            </h2>
            <div className="mt-4 flex flex-row justify-between">
              <MiniCarouselImg images={images} onRemoveImage={removeImage} />
              <div className="ml-8 shrink-0">
                <div className="block mb-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    onChange={handleCatchImages}
                    disabled={images.length > 0}
                    className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
                  />
                  <label htmlFor="file" className={`btn-gray ${images.length > 0 ? 'cursor-default' : ''}`}>Subir Imagen</label>
                </div>
                <button
                  type="submit"
                  className="mt-2 w-full btn-yellow"
                >
                  Guardar
                </button>
              </div>
            </div>
            { errors.cover_image && <span className="text-rose-500">{errors.cover_image}</span> }
          </div>
        </form>
      </div>
    </>
  );
};
