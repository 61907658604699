
import { CampusContributionRepository } from "../repositories";
import { CampusContributionFilters, CampusContributionGet, CampusContributionPost, CampusContributionPut, CampusContributionTable } from "../../interfaces";

export class CampusContributionProcess {
  private campusContributionRepository: CampusContributionRepository;

  constructor() {
    this.campusContributionRepository = new CampusContributionRepository();
  }

  async getCampusContribution(filters?: CampusContributionFilters): Promise<CampusContributionGet> {
    const response = await this.campusContributionRepository.getCampusContribution(filters);
    return response.data;
  }

  async postCampusContribution(campusContribution: CampusContributionPost): Promise<CampusContributionTable> {
    const response = await this.campusContributionRepository.postCampusContribution(campusContribution);
    return response.data;
  }

  async putCampusContribution(campusContribution: CampusContributionPut): Promise<CampusContributionTable> {
    const response = await this.campusContributionRepository.putCampusContribution(campusContribution);
    return response.data;
  }

  // dont response data
  async deleteCampusContribution(id: number): Promise<any> {
    const response = await this.campusContributionRepository.deleteCampusContribution(id);
    return response.data;
  }
}
