import { MessageStatus } from '../redux/interfaces';

interface Props {
  title: string;
  body: string;
  footer: string;
  status: MessageStatus;
}

export const Message = ({ title, body, footer, status = MessageStatus.SUCCESS }: Props) => {
  return (
    <div
      aria-labelledby="swal2-title"
      aria-describedby="swal2-html-container"
      className="swal2-popup swal2-modal swal2-icon-success swal2-show w-auto"
      tabIndex={-1}
      role="dialog"
      aria-live="assertive"
      aria-modal="true"
      style={{ display: 'grid' }}
    >
      {status === MessageStatus.SUCCESS && (
        <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: 'flex' }}>
          <div className="swal2-success-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
          <span className="swal2-success-line-tip" /> <span className="swal2-success-line-long" />
          <div className="swal2-success-ring" />
          <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
          <div className="swal2-success-circular-line-right" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
        </div>
      )}
      {status === MessageStatus.INFO && (
        <div className="swal2-icon swal2-warning swal2-icon-show" style={{ display: 'flex' }}>
          <div className="swal2-icon-content">!</div>
        </div>
      )}
      {status === MessageStatus.ERROR && (
        <div className="swal2-icon swal2-error swal2-icon-show" style={{ display: 'flex' }}>
          <span className="swal2-x-mark">
            <span className="swal2-x-mark-line-left" />
            <span className="swal2-x-mark-line-right" />
          </span>
        </div>
      )}
      <h2 className="swal2-title" id="swal2-title">
        {title}
      </h2>
      <div className="swal2-html-container" id="swal2-html-container">
        {body}
      </div>
      <div className="text-center text-gray-400">{footer}</div>
    </div>
  );
};
