
export enum CampusContributionTypes {
  GET_CAMPUSCONTRIBUTIONPAG_LOADING = "GET_CAMPUSCONTRIBUTIONPAG_LOADING",
  GET_CAMPUSCONTRIBUTIONPAG_SUCCESS = "GET_CAMPUSCONTRIBUTIONPAG_SUCCESS",
  GET_CAMPUSCONTRIBUTIONPAG_FAILURE = "GET_CAMPUSCONTRIBUTIONPAG_FAILURE",
  GET_CAMPUSCONTRIBUTION_LOADING = "GET_CAMPUSCONTRIBUTION_LOADING",
  GET_CAMPUSCONTRIBUTION_SUCCESS = "GET_CAMPUSCONTRIBUTION_SUCCESS",
  GET_CAMPUSCONTRIBUTION_FAILURE = "GET_CAMPUSCONTRIBUTION_FAILURE",
  GETONLY_CAMPUSCONTRIBUTION_LOADING = "GETONLY_CAMPUSCONTRIBUTION_LOADING",
  GETONLY_CAMPUSCONTRIBUTION_SUCCESS = "GETONLY_CAMPUSCONTRIBUTION_SUCCESS",
  GETONLY_CAMPUSCONTRIBUTION_FAILURE = "GETONLY_CAMPUSCONTRIBUTION_FAILURE",
  POST_CAMPUSCONTRIBUTION_LOADING = "POST_CAMPUSCONTRIBUTION_LOADING",
  POST_CAMPUSCONTRIBUTION_SUCCESS = "POST_CAMPUSCONTRIBUTION_SUCCESS",
  POST_CAMPUSCONTRIBUTION_FAILURE = "POST_CAMPUSCONTRIBUTION_FAILURE",
  PUT_CAMPUSCONTRIBUTION_LOADING = "PUT_CAMPUSCONTRIBUTION_LOADING",
  PUT_CAMPUSCONTRIBUTION_SUCCESS = "PUT_CAMPUSCONTRIBUTION_SUCCESS",
  PUT_CAMPUSCONTRIBUTION_FAILURE = "PUT_CAMPUSCONTRIBUTION_FAILURE",
  DELETE_CAMPUSCONTRIBUTION_LOADING = "DELETE_CAMPUSCONTRIBUTION_LOADING",
  DELETE_CAMPUSCONTRIBUTION_SUCCESS = "DELETE_CAMPUSCONTRIBUTION_SUCCESS",
  DELETE_CAMPUSCONTRIBUTION_FAILURE = "DELETE_CAMPUSCONTRIBUTION_FAILURE",
}