import { HotelOccupancyPaymentAction, HotelOccupancyPaymentPayload } from "../interfaces";
import { HotelOccupancyPaymentTypes } from "../types";

const initialState: HotelOccupancyPaymentPayload = {
  loading: false,
  success: false,
  error: null,
  hotelOccupancyPayment: null,
  listHotelOccupancyPayment: [],
  pagHotelOccupancyPayment: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotelOccupancyPayment = (state = initialState, action: HotelOccupancyPaymentAction): HotelOccupancyPaymentPayload => {
  const { loading, success, error, hotelOccupancyPayment, listHotelOccupancyPayment, pagHotelOccupancyPayment } = action.payload || {};
  switch (action.type) {
    case HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENTPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENTPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENTPAG_SUCCESS:
      return {
        ...state,
        pagHotelOccupancyPayment,
        loading,
        success,
      };
    case HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelOccupancyPaymentTypes.GET_HOTELOCCUPANCYPAYMENT_SUCCESS:
      return {
        ...state,
        listHotelOccupancyPayment,
        loading,
        success,
      };
    case HotelOccupancyPaymentTypes.GETONLY_HOTELOCCUPANCYPAYMENT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelOccupancyPaymentTypes.GETONLY_HOTELOCCUPANCYPAYMENT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelOccupancyPaymentTypes.GETONLY_HOTELOCCUPANCYPAYMENT_SUCCESS:
      return {
        ...state,
        hotelOccupancyPayment,
        loading,
        success,
      };
    case HotelOccupancyPaymentTypes.POST_HOTELOCCUPANCYPAYMENT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelOccupancyPaymentTypes.POST_HOTELOCCUPANCYPAYMENT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelOccupancyPaymentTypes.POST_HOTELOCCUPANCYPAYMENT_SUCCESS:
      return {
        ...state,
        hotelOccupancyPayment,
        loading,
        success,
      };
    case HotelOccupancyPaymentTypes.PUT_HOTELOCCUPANCYPAYMENT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelOccupancyPaymentTypes.PUT_HOTELOCCUPANCYPAYMENT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelOccupancyPaymentTypes.PUT_HOTELOCCUPANCYPAYMENT_SUCCESS:
      return {
        ...state,
        hotelOccupancyPayment,
        loading,
        success,
      };
    case HotelOccupancyPaymentTypes.DELETE_HOTELOCCUPANCYPAYMENT_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelOccupancyPaymentTypes.DELETE_HOTELOCCUPANCYPAYMENT_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelOccupancyPaymentTypes.DELETE_HOTELOCCUPANCYPAYMENT_SUCCESS:
      return {
        ...state,
        hotelOccupancyPayment,
        loading,
        success,
      };
    default:
      return state;
  }
};

