
import { EventCalendarPage, EventCalendarTablePage, EventCoordinatorsNewPage, EventHistoryServicePage, EventPendingReservesPage, EventReservationNewPage, EventSaloonsNewPage, EventTypeEditPage, EventTypeListPage, EventTypeNewPage } from "../../pages/events";
import { HotelBankingParametersPage } from "../../pages/hotel";
import { UserRole } from "../../utils/enums/userRoles.enum";

export const eventsRoutes = [
  // EVENTS MODULE
  {
    path: '/event/reservation/pending',
    to: '/event/reservation/pending',
    name: 'Reservas Pendientes',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventPendingReservesPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
        </svg>
    )
  },
  {
    path: '/event/calendar',
    to: '/event/calendar',
    name: 'Agenda',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventCalendarPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/event/calendar/table',
    to: '/event/calendar/table',
    name: 'Agenda',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventCalendarTablePage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/event/reservation/new',
    to: '/event/reservation/new',
    name: 'Reserva Manual',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventReservationNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
    )
  },
  {
    path: '/event/new',
    to: '/event/new',
    name: 'Agregar tipo de evento',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventTypeNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
    )
  },
  {
    path: '/event/edit',
    to: '/event/edit',
    name: 'Agregar tipo de evento',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventTypeEditPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
    )
  },
  {
    path: '/event/list',
    to: '/event/list',
    name: 'Agregar tipo de evento',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventTypeListPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
    )
  },
  {
    path: '/event/service/history',
    to: '/event/service/history',
    name: 'Reporte de reservas',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventHistoryServicePage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
        </svg>
    )
  },
  {
    path: '/event/bank/account',
    to: '/event/bank/account',
    name: 'Parámetros Bancarios',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: HotelBankingParametersPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
    )
  },
  {
    path: '/event/coordinators',
    to: '/event/coordinators',
    name: 'Coordinadores',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventCoordinatorsNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
        </svg>
    )
  },
  {
    path: '/event/saloons',
    to: '/event/saloons',
    name: 'Salones',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.EVENT_ADMIN],
    Component: EventSaloonsNewPage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
        </svg>
    )
  },
]