import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { AuthResponse } from "../../interfaces/auth.interface";

export class AuthRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    loginUserWithEmailAndPassword(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return this.lmBootApi.post<AuthResponse>("/token/", { email, password });
    }

    passwordResetConfirm(token: string, newPassword: string): Promise<AxiosResponse<any>> {
        return this.lmBootApi.post<AuthResponse>("/password_reset/confirm/", { token, password: newPassword });
    }

    passwordReset(email: string): Promise<AxiosResponse<any>> {
        return this.lmBootApi.post<AuthResponse>("/password_reset/", { email });
    }
}
