import { HotelAction, HotelPayload } from "../interfaces";
import { HotelTypes } from "../types";

const initialState: HotelPayload = {
  loading: false,
  success: false,
  error: null,
  hotel: null,
  listHotel: [],
  pagHotel: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotel = (state = initialState, action: HotelAction): HotelPayload => {
  const { loading, success, error, hotel, listHotel, pagHotel } = action.payload || {};
  switch (action.type) {
    case HotelTypes.GET_HOTELPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelTypes.GET_HOTELPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelTypes.GET_HOTELPAG_SUCCESS:
      return {
        ...state,
        pagHotel,
        loading,
        success,
      };
    case HotelTypes.GET_HOTEL_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelTypes.GET_HOTEL_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelTypes.GET_HOTEL_SUCCESS:
      return {
        ...state,
        listHotel,
        loading,
        success,
      };
    case HotelTypes.GETONLY_HOTEL_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelTypes.GETONLY_HOTEL_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelTypes.GETONLY_HOTEL_SUCCESS:
      return {
        ...state,
        hotel,
        loading,
        success,
      };
    case HotelTypes.POST_HOTEL_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelTypes.POST_HOTEL_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelTypes.POST_HOTEL_SUCCESS:
      return {
        ...state,
        hotel,
        loading,
        success,
      };
    case HotelTypes.PUT_HOTEL_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelTypes.PUT_HOTEL_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelTypes.PUT_HOTEL_SUCCESS:
      return {
        ...state,
        hotel,
        loading,
        success,
      };
    case HotelTypes.DELETE_HOTEL_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelTypes.DELETE_HOTEL_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelTypes.DELETE_HOTEL_SUCCESS:
      return {
        ...state,
        hotel,
        loading,
        success,
      };
    default:
      return state;
  }
};

