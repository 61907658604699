
import { EventTypesProcess } from "../api";
import { EventTypesAction, EventTypesFilters } from "../interfaces";
import { EventTypesTypes } from "../types";

export const getEventTypesPags = (filters?: EventTypesFilters) => async (dispatch: (state: EventTypesAction) => EventTypesAction) => {
  dispatch({
    type: EventTypesTypes.GET_EVENTTYPESPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventTypesProcess = new EventTypesProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventTypesPag = await eventTypesProcess.getEventTypes(filters);
    return dispatch({
      type: EventTypesTypes.GET_EVENTTYPESPAG_SUCCESS,
      payload: {
        pagEventTypes: eventTypesPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventTypesTypes.GET_EVENTTYPESPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getEventTypes = (filters?: EventTypesFilters) => async (dispatch: (state: EventTypesAction) => EventTypesAction) => {
  dispatch({
    type: EventTypesTypes.GET_EVENTTYPES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventTypesProcess = new EventTypesProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventTypesPag = await eventTypesProcess.getEventTypes({
      ...filters,
      data_all: 'YES',
    });
    const eventTypes = eventTypesPag.results;
    return dispatch({
      type: EventTypesTypes.GET_EVENTTYPES_SUCCESS,
      payload: {
        listEventTypes: eventTypes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventTypesTypes.GET_EVENTTYPES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postEventTypes = (eventTypes: FormData) => async (dispatch: (state: EventTypesAction) => EventTypesAction) => {
  dispatch({
    type: EventTypesTypes.POST_EVENTTYPES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventTypesProcess = new EventTypesProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventTypesTable = await eventTypesProcess.postEventTypes(eventTypes);
    return dispatch({
      type: EventTypesTypes.POST_EVENTTYPES_SUCCESS,
      payload: {
        eventTypes: eventTypesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventTypesTypes.POST_EVENTTYPES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putEventTypes = (eventTypes: FormData) => async (dispatch: (state: EventTypesAction) => EventTypesAction) => {
  dispatch({
    type: EventTypesTypes.PUT_EVENTTYPES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventTypesProcess = new EventTypesProcess();
    // dont'n import the result of the process, only that dont't have error
    const eventTypesTable = await eventTypesProcess.putEventTypes(eventTypes);
    return dispatch({
      type: EventTypesTypes.PUT_EVENTTYPES_SUCCESS,
      payload: {
        eventTypes: eventTypesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventTypesTypes.PUT_EVENTTYPES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteEventTypes = (id: number) => async (dispatch: (state: EventTypesAction) => EventTypesAction) => {
  dispatch({
    type: EventTypesTypes.DELETE_EVENTTYPES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const eventTypesProcess = new EventTypesProcess();
    // dont'n import the result of the process, only that dont't have error
    await eventTypesProcess.deleteEventTypes(id);
    return dispatch({
      type: EventTypesTypes.DELETE_EVENTTYPES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: EventTypesTypes.DELETE_EVENTTYPES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

