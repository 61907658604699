import { Campus, GenericAction, GenericFilters, GenericPayload, GenericResponse, Group } from ".";

export interface UserFilters extends GenericFilters {
  first_name__icontains?: string;
  document_number?: string;
  is_active?: string;
  phone?: number;
  email?: string;
  date_birth?: string;
  campus_id?: number;
  groups__name?: string;
  groups__name__exclude?: string;
}

export interface UserResponse extends GenericResponse {
  results: User[];
}

export type UserState = {
  email: string;
  document_number: string;
  first_name: string;
  date_joined: string;
  phone: string;
  photo_url: string;
  date_birth: string;
  emergency_number: string;
  address: string;
  campus: {
    name: string;
  };
}

export interface User {
  id: number;
  email: string;
  username: string;
  document_number: string;
  is_active: boolean;
  last_login: string | null;
  is_superuser: boolean;
  first_name: string;
  is_staff: boolean;
  date_joined: string;
  phone: string;
  photo_url: string;
  date_birth: string;
  emergency_number: string;
  address: string;
  campus: Campus;
  groups: Group[];
  user_permissions: any[];
}

export interface GetUserById {
    id: number,
    email: string,
    username: string,
    document_number: string,
    is_active: boolean,
    last_login: string,
    is_superuser: boolean,
    first_name: string,
    is_staff: boolean,
    date_joined: string,
    phone: string,
    photo_url: string,
    date_birth: string,
    emergency_number: string,
    address: string,
    campus: {
        id: 2,
        is_deleted: false,
        created_at: string,
        updated_at: string,
        deleted_at: null,
        name: string
    },
    groups: [
        {
            id: number,
            permissions: [],
            name: string
        }
    ],
    user_permissions: [],
    has_paid: boolean,
    amount_pay: number
}

export interface UserPayload extends GenericPayload {
  user?: User;
  listUsers?: User[];
  listUsersPag?: UserResponse | null;
  userById?: GetUserById | null;
}

export interface UserAction extends GenericAction {
  payload: UserPayload;
}
export interface UserImageType {
  id: number;
  photo_url: string;
}
/**
 * @description Interface for Update API.
 */
export interface UserPut {
  id: number;
  username: string;
  email: string;
  first_name: string;
  emergency_number: string;
  campus: number;
  groups: number[];
  is_active: boolean;
}

export const initialuser: User = {
  id: 0,
  email: '',
  username: '',
  document_number: '',
  is_active: true,
  last_login: '',
  is_superuser: false,
  first_name: 'Círculo Militar',
  is_staff: false,
  date_joined: '',
  phone: '',
  photo_url: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
  date_birth: '',
  emergency_number: '',
  address: '',
  campus: {
    created_at: '',
    id: 0,
    name: '',
    updated_at: '',
    deleted_at: '',
    is_deleted: false,
  },
  groups: [],
  user_permissions: [],
}

export const initialStateUser: UserPayload = {
  loading: false,
  success: false,
  user: initialuser,
  userById: null,
  listUsersPag: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
}