export enum MessageStatus {
  SUCCESS,
  INFO,
  ERROR,
}

export enum PaymentStatus {
  CREATED_ORDER,
  SUCCESS,
  DECLINED,
  ERROR,
}

export interface IPaymentValidation {
  status: PaymentStatus;
  title: string;
  message: string;
}

export interface PaymentResponse {
  error_code: number;
  error_description: null | string;
  id: null | string;
  resultDetails: ResultDetails;
  recurring: Recurring;
  customParameters: CustomParameters;
  paymentBrand: string;
  card: Card;
  cart: Cart[];
  subtotal: string;
  tax: string;
  total: string;
}

export interface Card {
  bin: string;
  binCountry: string;
  holder: string;
}

export interface Cart {
  code: string;
  description: string;
  quantity: number;
  subtotal: string;
  tax: string;
  total: string;
}

export interface CustomParameters {
  SHOPPER_interes: string;
  SHOPPER_gracia: null;
}

export interface Recurring {
  numberOfInstallments: string;
}

export interface ResultDetails {
  AuthCode: string;
  ReferenceNbr: string;
  AcquirerResponse: string;
}
