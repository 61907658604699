export enum MultipleServicesImagesTypes {
  GET_MULTIPLESERVICESIMAGES_LOADING = "GET_MULTIPLESERVICESIMAGES_LOADING",
  GET_MULTIPLESERVICESIMAGES_SUCCESS = "GET_MULTIPLESERVICESIMAGES_SUCCESS",
  GET_MULTIPLESERVICESIMAGES_FAILURE = "GET_MULTIPLESERVICESIMAGES_FAILURE",
  POST_MULTIPLESERVICESIMAGES_LOADING = "POST_MULTIPLESERVICESIMAGES_LOADING",
  POST_MULTIPLESERVICESIMAGES_SUCCESS = "POST_MULTIPLESERVICESIMAGES_SUCCESS",
  POST_MULTIPLESERVICESIMAGES_FAILURE = "POST_MULTIPLESERVICESIMAGES_FAILURE",
  PUT_MULTIPLESERVICESIMAGES_LOADING = "PUT_MULTIPLESERVICESIMAGES_LOADING",
  PUT_MULTIPLESERVICESIMAGES_SUCCESS = "PUT_MULTIPLESERVICESIMAGES_SUCCESS",
  PUT_MULTIPLESERVICESIMAGES_FAILURE = "PUT_MULTIPLESERVICESIMAGES_FAILURE",
  DELETE_MULTIPLESERVICESIMAGES_LOADING = "DELETE_MULTIPLESERVICESIMAGES_LOADING",
  DELETE_MULTIPLESERVICESIMAGES_SUCCESS = "DELETE_MULTIPLESERVICESIMAGES_SUCCESS",
  DELETE_MULTIPLESERVICESIMAGES_FAILURE = "DELETE_MULTIPLESERVICESIMAGES_FAILURE",
}