
import { CampusContributionProcess } from "../api";
import { CampusContributionAction, CampusContributionFilters, CampusContributionPost, CampusContributionPut,  } from "../interfaces";
import { CampusContributionTypes } from "../types";

export const getCampusContributionPags = (filters?: CampusContributionFilters) => async (dispatch: (state: CampusContributionAction) => CampusContributionAction) => {
  dispatch({
    type: CampusContributionTypes.GET_CAMPUSCONTRIBUTIONPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusContributionProcess = new CampusContributionProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusContributionPag = await campusContributionProcess.getCampusContribution(filters);
    return dispatch({
      type: CampusContributionTypes.GET_CAMPUSCONTRIBUTIONPAG_SUCCESS,
      payload: {
        pagCampusContribution: campusContributionPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusContributionTypes.GET_CAMPUSCONTRIBUTIONPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getCampusContribution = (filters?: CampusContributionFilters) => async (dispatch: (state: CampusContributionAction) => CampusContributionAction) => {
  dispatch({
    type: CampusContributionTypes.GET_CAMPUSCONTRIBUTION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusContributionProcess = new CampusContributionProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusContributionPag = await campusContributionProcess.getCampusContribution({
      ...filters,
      data_all: 'YES',
    });
    const campusContribution = campusContributionPag.results;
    return dispatch({
      type: CampusContributionTypes.GET_CAMPUSCONTRIBUTION_SUCCESS,
      payload: {
        listCampusContribution: campusContribution,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusContributionTypes.GET_CAMPUSCONTRIBUTION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postCampusContribution = (campusContribution: CampusContributionPost) => async (dispatch: (state: CampusContributionAction) => CampusContributionAction) => {
  dispatch({
    type: CampusContributionTypes.POST_CAMPUSCONTRIBUTION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusContributionProcess = new CampusContributionProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusContributionTable = await campusContributionProcess.postCampusContribution(campusContribution);
    return dispatch({
      type: CampusContributionTypes.POST_CAMPUSCONTRIBUTION_SUCCESS,
      payload: {
        campusContribution: campusContributionTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusContributionTypes.POST_CAMPUSCONTRIBUTION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putCampusContribution = (campusContribution: CampusContributionPut) => async (dispatch: (state: CampusContributionAction) => CampusContributionAction) => {
  dispatch({
    type: CampusContributionTypes.PUT_CAMPUSCONTRIBUTION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusContributionProcess = new CampusContributionProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusContributionTable = await campusContributionProcess.putCampusContribution(campusContribution);
    return dispatch({
      type: CampusContributionTypes.PUT_CAMPUSCONTRIBUTION_SUCCESS,
      payload: {
        campusContribution: campusContributionTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusContributionTypes.PUT_CAMPUSCONTRIBUTION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteCampusContribution = (id: number) => async (dispatch: (state: CampusContributionAction) => CampusContributionAction) => {
  dispatch({
    type: CampusContributionTypes.DELETE_CAMPUSCONTRIBUTION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusContributionProcess = new CampusContributionProcess();
    // dont'n import the result of the process, only that dont't have error
    await campusContributionProcess.deleteCampusContribution(id);
    return dispatch({
      type: CampusContributionTypes.DELETE_CAMPUSCONTRIBUTION_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusContributionTypes.DELETE_CAMPUSCONTRIBUTION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

