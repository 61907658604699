import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getEventReservations, getEventReservationsPags, getEventSaloons } from '../../redux/actions';
import { EventReservationsFilters, EventReservationsGet, User } from '../../redux/interfaces';
// import xlsx
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { RootState } from "../../redux/store";
import useForm from "../../utils/hooks/useForm";
import Swal from "sweetalert2";
import { TableBody } from "../../components";
import { InputDateRange } from "../../components/InputDateRange";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { eventReserveStatus } from "../../utils/eventReserveStatus";

export const EventHistoryServicePage = () => {
    const dispatch = useDispatch();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const { listEventSaloons } = useSelector((state: RootState) => state.eventSaloons);
    const { pagEventReservations, loading } = useSelector((state: RootState) => state.eventReservations);
    const {
        results: eventReservations,
        count,
        next,
        previous,
    } = pagEventReservations as EventReservationsGet;
    // pagination
    const [filterState, setFilterState] = useState('');
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(4);
    // new Date(), new Date() + one day
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(new Date().getTime() - 60 * 60 * 24 * 1000 * 7), new Date()]);
    const [startDate, endDate] = dateRange;
    // status values
    const statusCellOptions: {
        [key: string]: {
            label: string;
            color: string;
        };
    } = {};
    for (const status of eventReserveStatus) {
        statusCellOptions[status.value] = {
            label: status.label,
            color: status.color,
        };
    }
    // form values
    const { eventSaloonId, handleOnChangeInput } = useForm({eventSaloonId: ''});
    // fetch data with filters
    useEffect(() => {
        const filters: EventReservationsFilters = {
            event_type__campus_id: user.campus.id,
            page: pageCurrent,
            page_size: pageSize,
            event_salon_id: parseInt(eventSaloonId),
            status: filterState,
            start_date__gte: '',
            start_date__lte: '',
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['start_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        getEventReservationsPags(filters)(dispatch);
    }, [pageCurrent, pageSize, filterState, eventSaloonId, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getEventSaloons({
            campus_id: user.campus.id,
        })(dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type EventReservationsType = {
        first_name: string;
        total_cost: string;
        event_type: string;
        package_type: string;
        people_total: string;
        start_date: string;
        hours: string;
        coordinator_name: string;
        status: string;
        description: string;
        voucher: any;
    };

    const { data, columns } = useTableMemo<EventReservationsType>({
        dataMemo: {
            factory: eventReservations.map((reservation) => {
                const startDate = new Date(reservation.start_date).toLocaleDateString('es-ES', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                });
                const hourStart = new Date(reservation.start_date).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                const hourEnd = new Date(reservation.end_date).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return {
                    first_name: reservation.user.first_name,
                    total_cost: `$${reservation.total_price}`,
                    event_type: reservation.event_type.name,
                    package_type: reservation.event_package,
                    people_total: `${reservation.total_people}/${reservation.event_salon.capacity}`,
                    start_date: startDate,
                    hours: `${hourStart} - ${hourEnd}`,
                    coordinator_name: reservation.event_coordinator.name,
                    status: statusCellOptions?.[reservation.status]?.label || '',
                    description: reservation.description,
                    voucher: reservation.event_reservation_wire_transfers?.[0]?.receipt || '',
                };
            }),
            deps: [eventReservations]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Cliente',
                    accessor: 'first_name',
                },
                {
                    Header: 'Precio de Reserva',
                    accessor: 'total_cost',
                },
                {
                    Header: 'Tipo de Evento',
                    accessor: 'event_type',
                },
                {
                    Header: 'Paquete',
                    accessor: 'package_type',
                },
                {
                    Header: 'Asistentes',
                    accessor: 'people_total',
                },
                {
                    Header: 'Fecha',
                    accessor: 'start_date',
                },
                {
                    Header: 'Hora',
                    accessor: 'hours',
                },
                {
                    Header: 'Coordinador',
                    accessor: 'coordinator_name',
                },
                {
                    Header: 'Estado',
                    accessor: 'status',
                },
                {
                    Header: 'Nota',
                    accessor: 'description',
                },
                {
                    Header: 'Comprobante',
                    accessor: 'voucher',
                },
            ],
            deps: []
        }
    })

    const handleCreateDownloadReport = async () => {
        const filters: EventReservationsFilters = {
            event_type__campus_id: user.campus.id,
            status: filterState,
            start_date__gte: '',
            start_date__lte: '',
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['start_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        const { payload } = await getEventReservations(filters)(dispatch);
        const listEventReservations = payload.listEventReservations || [];
        const data_excel = listEventReservations.map(reservation => ([
            reservation.user.first_name,
            reservation.total_price,
            reservation.event_type.name,
            reservation.event_package,
            `${reservation.total_people}/${reservation.event_salon.capacity}`,
            new Date(reservation.start_date).toLocaleDateString(),
            `${new Date(reservation.start_date).toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })} - ${new Date(reservation.end_date).toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })}`,
            reservation.event_coordinator.name,
            statusCellOptions[reservation.status].label,
            reservation.description
        ]));
        const columns_titles = ['Cliente', 'Precio de Reserva', 'Tipo de Evento', 'Paquete', 'Asistentes', 'Fecha', 'Hora', 'Coordinador', 'Estado', 'Nota'];
        // Code for excel download
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Reporte de servicios',
            Subject: 'Reporte de servicios',
            Author: 'Círculo Militar',
            CreatedDate: new Date(Date.now()),
        };
        wb.SheetNames.push('Reporte de servicios');
        const ws = XLSX.utils.json_to_sheet(data_excel);
        wb.Sheets['Reporte de servicios'] = ws;
        // add columns width
        ws['!cols'] = [
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
        ];
        // add columns title
        columns_titles.forEach((title, index) => {
            ws[`${String.fromCharCode(65 + index)}1`] = {
                v: title,
                t: 's',
                font: {
                    sz: 14,
                    bold: true,
                },
            };
        });


        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        const s2ab = (s: any) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_REPORTE_RESERVAS_HABITACIONES.xlsx`);
    }

    return (
        <>
            <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
                <div className="flex flex-row items-center justify-end gap-4 print:hidden">
                    Descargar reporte
                    <button
                        onClick={handleCreateDownloadReport}
                        className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                <select
                    id="eventSaloonId"
                    name="eventSaloonId"
                    value={eventSaloonId}
                    onChange={handleOnChangeInput}
                    required
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Salones (todos)</option>
                    {
                        listEventSaloons && listEventSaloons.map((saloon) => (
                            <option key={`bedroom-${saloon.id}`} value={saloon.id}>{saloon.name}</option>
                        ))
                    }
                </select>
                <div className="flex items-center">
                    <InputDateRange
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        className="h-11 font-bold"
                    />
                </div>
                <select
                    id="filterState"
                    name="filterState"
                    value={filterState}
                    onChange={(e) => setFilterState(e.target.value)}
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Estados (Todos)</option>
                    {
                        eventReserveStatus.map((status) => (
                            <option value={status.value}>{status.label}</option>
                        ))
                    }
                </select>
            </div>
            <div className="overflow-hidden">
                <TableBody
                    columns={columns} data={data} pageSize={pageSize} loading={loading}
                    renderRows={row => (
                        <tr
                            className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                            {...row.getRowProps()}
                        >
                            {
                                row.cells.map((cell) => {
                                    if (cell.column.Header === 'Nota') {
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <button onClick={() => {
                                                    Swal.fire({
                                                        title: 'Nota',
                                                        text: (cell.render('Cell') as any).props.value ? (cell.render('Cell') as any).props.value : 'No hay nota',
                                                    })
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Comprobante') {
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <button className="underline" onClick={() => {
                                                    if ((cell.render('Cell') as any).props.value) {
                                                        Swal.fire({
                                                            imageUrl: (cell.render('Cell') as any).props.value,
                                                            imageWidth: 400,
                                                            imageHeight: 400,
                                                            imageAlt: 'Custom image',
                                                        })
                                                    } else {
                                                        Swal.fire({
                                                            title: 'No hay comprobante',
                                                            text: 'No se ha subido un comprobante',
                                                        })
                                                    }
                                                }}>
                                                    {(cell.render('Cell') as any).props.value === '' ? '' : 'Ver comprobante'}
                                                </button>
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            className="whitespace-nowrap px-6 py-3"
                                            {...cell.getCellProps()}
                                        >
                                            <span className="inline-block first-letter:capitalize">{cell.render('Cell')}</span>
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    )}
                    // For footer
                    count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
                />
            </div>
        </>
    );
};
