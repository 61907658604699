
import { CampusNotificationsAction, CampusNotificationsPayload } from "../interfaces";
import { CampusNotificationsTypes } from "../types";

const initialState: CampusNotificationsPayload = {
  loading: false,
  success: false,
  error: null,
  campusNotifications: null,
  listCampusNotifications: [],
  pagCampusNotifications: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const campusNotifications = (state = initialState, action: CampusNotificationsAction): CampusNotificationsPayload => {
  const { loading, success, error, campusNotifications, listCampusNotifications, pagCampusNotifications } = action.payload || {};
  switch (action.type) {
    case CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONSPAG_SUCCESS:
      return {
        ...state,
        pagCampusNotifications,
        loading,
        success,
      };
    case CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNotificationsTypes.GET_CAMPUSNOTIFICATIONS_SUCCESS:
      return {
        ...state,
        listCampusNotifications,
        loading,
        success,
      };
    case CampusNotificationsTypes.GETONLY_CAMPUSNOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNotificationsTypes.GETONLY_CAMPUSNOTIFICATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNotificationsTypes.GETONLY_CAMPUSNOTIFICATIONS_SUCCESS:
      return {
        ...state,
        campusNotifications,
        loading,
        success,
      };
    case CampusNotificationsTypes.POST_CAMPUSNOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNotificationsTypes.POST_CAMPUSNOTIFICATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNotificationsTypes.POST_CAMPUSNOTIFICATIONS_SUCCESS:
      return {
        ...state,
        campusNotifications,
        loading,
        success,
      };
    case CampusNotificationsTypes.PUT_CAMPUSNOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNotificationsTypes.PUT_CAMPUSNOTIFICATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNotificationsTypes.PUT_CAMPUSNOTIFICATIONS_SUCCESS:
      return {
        ...state,
        campusNotifications,
        loading,
        success,
      };
    case CampusNotificationsTypes.DELETE_CAMPUSNOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusNotificationsTypes.DELETE_CAMPUSNOTIFICATIONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusNotificationsTypes.DELETE_CAMPUSNOTIFICATIONS_SUCCESS:
      return {
        ...state,
        campusNotifications,
        loading,
        success,
      };
    default:
      return state;
  }
};

