import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { MemberRequest, MemberResponse, RegisterResponse, UserFilters } from "../../interfaces";

export class RegisterRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getMember(filters?: UserFilters): Promise<AxiosResponse<MemberResponse>> {
    return this.lmBootApi.get<MemberResponse>("/users/", { params: filters });
  }

  getMemberByDocumentAndBirthday(document: string, birthday: string): Promise<AxiosResponse<MemberResponse>> {
    return this.lmBootApi.get<MemberResponse>(`/member/fiters/`, { params: { document_number: document, date_birth: birthday } });
  }

  registerUser(member: MemberRequest): Promise<AxiosResponse<RegisterResponse>> {
    return this.lmBootApi.post<RegisterResponse>("/users/", member);
  }

  getListCampus(): Promise<AxiosResponse<any>> {
    return this.lmBootApi.get<any>("/campus/list/");
  }
}
