
import { RestaurantLunchesAction, RestaurantLunchesPayload } from "../interfaces";
import { RestaurantLunchesTypes } from "../types";

const initialState: RestaurantLunchesPayload = {
  loading: false,
  success: false,
  error: null,
  restaurantLunches: null,
  listRestaurantLunches: [],
  pagRestaurantLunches: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const restaurantLunches = (state = initialState, action: RestaurantLunchesAction): RestaurantLunchesPayload => {
  const { loading, success, error, restaurantLunches, listRestaurantLunches, pagRestaurantLunches } = action.payload || {};
  switch (action.type) {
    case RestaurantLunchesTypes.GET_RESTAURANTLUNCHESPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantLunchesTypes.GET_RESTAURANTLUNCHESPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantLunchesTypes.GET_RESTAURANTLUNCHESPAG_SUCCESS:
      return {
        ...state,
        pagRestaurantLunches,
        loading,
        success,
      };
    case RestaurantLunchesTypes.GET_RESTAURANTLUNCHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantLunchesTypes.GET_RESTAURANTLUNCHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantLunchesTypes.GET_RESTAURANTLUNCHES_SUCCESS:
      return {
        ...state,
        listRestaurantLunches,
        loading,
        success,
      };
    case RestaurantLunchesTypes.GETONLY_RESTAURANTLUNCHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantLunchesTypes.GETONLY_RESTAURANTLUNCHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantLunchesTypes.GETONLY_RESTAURANTLUNCHES_SUCCESS:
      return {
        ...state,
        restaurantLunches,
        loading,
        success,
      };
    case RestaurantLunchesTypes.POST_RESTAURANTLUNCHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantLunchesTypes.POST_RESTAURANTLUNCHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantLunchesTypes.POST_RESTAURANTLUNCHES_SUCCESS:
      return {
        ...state,
        restaurantLunches,
        loading,
        success,
      };
    case RestaurantLunchesTypes.PUT_RESTAURANTLUNCHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantLunchesTypes.PUT_RESTAURANTLUNCHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantLunchesTypes.PUT_RESTAURANTLUNCHES_SUCCESS:
      return {
        ...state,
        restaurantLunches,
        loading,
        success,
      };
    case RestaurantLunchesTypes.DELETE_RESTAURANTLUNCHES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case RestaurantLunchesTypes.DELETE_RESTAURANTLUNCHES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case RestaurantLunchesTypes.DELETE_RESTAURANTLUNCHES_SUCCESS:
      return {
        ...state,
        restaurantLunches,
        loading,
        success,
      };
    default:
      return state;
  }
};

