import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { GetUserById, User, UserFilters, UserImageType, UserPut, UserResponse } from "../../interfaces";

export class UserRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCurrentUser(): Promise<AxiosResponse<User>> {
    return this.lmBootApi.get<User>("/users/token");
  }

  getListUsers(filters?: UserFilters): Promise<AxiosResponse<UserResponse>> {
    return this.lmBootApi.get<UserResponse>("/users/", { params: filters });
  }

  getUserById(id: number): Promise<AxiosResponse<GetUserById>> {
    return this.lmBootApi.get<GetUserById>(`/users/${id}/`);
  }

  putUser(user: UserPut): Promise<AxiosResponse<GetUserById>> {
    return this.lmBootApi.put<GetUserById>(`/users/${user.id}/`, user);
  }

  pUtUserImage(id: number, userImage: FormData): Promise<AxiosResponse<UserImageType>> {
    return this.lmBootApi.put<UserImageType>(`/users/${id}/photo`, userImage);
  }
}
