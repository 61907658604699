import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { deleteHotelOccupancyPayment, postHotelOccupancyPayment, putHotelBedroom, putHotelOccupancyPayment } from "../../redux/actions";
import { HotelBedroomTable, HotelOccupancyPaymentPost, HotelOccupancyPaymentPut } from "../../redux/interfaces";
import useForm from '../../utils/hooks/useForm';

export const HotelBedroomPriceEditPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { bedroom } = state as { bedroom: HotelBedroomTable };
    type HotelOccupancyType = {
        id?: number;
        member_type: string;
        people_total: number;
        price: number;
        hotel_bedroom_id: number;
    }
    const [memberPrices, setMemberPrices] = useState<HotelOccupancyType[]>([]);
    const [familyPrices, setFamilyPrices] = useState<HotelOccupancyType[]>([]);
    const [guessPrices, setGuessPrices] = useState<HotelOccupancyType[]>([]);
    const [memberPricesOriginal, setMemberPricesOriginal] = useState<HotelOccupancyType[]>([]);
    const [familyPricesOriginal, setFamilyPricesOriginal] = useState<HotelOccupancyType[]>([]);
    const [guessPricesOriginal, setGuessPricesOriginal] = useState<HotelOccupancyType[]>([]);
    const {
        totalPriceMember,
        totalPriceFamily,
        totalPriceGuess,
        isPayBedroom,
        handleOnChangeInput,
        handleUpdateField,
        handleUpdateState
    } = useForm<any>({
        totalPriceMember: 0,
        totalPriceFamily: 0,
        totalPriceGuess: 0,
        isPayBedroom: false,
    });

    useEffect(() => {
        if (bedroom) {
            const memberPricesList = bedroom.occupancy_payments.filter(item => item.member_type === "member_user").map((item, i) => ({
                id: item.id,
                member_type: item.member_type,
                people_total: i + 1,
                price: item.price,
                hotel_bedroom_id: bedroom.id,
            }));
            setMemberPrices(memberPricesList.length > 0 ? memberPricesList : [{
                price: 0,
                hotel_bedroom_id: bedroom.id,
                member_type: 'member_user',
                people_total: 1
            }]);
            setMemberPricesOriginal(memberPricesList.length > 0 ? memberPricesList : [{
                price: 0,
                hotel_bedroom_id: bedroom.id,
                member_type: 'member_user',
                people_total: 1
            }]);
            const familyPricesList = bedroom.occupancy_payments.filter(item => item.member_type === "family_user").map((item, i) => ({
                id: item.id,
                member_type: item.member_type,
                people_total: i + 1,
                price: item.price,
                hotel_bedroom_id: bedroom.id,
            }))
            setFamilyPrices(familyPricesList.length > 0 ? familyPricesList : [{
                price: 0,
                hotel_bedroom_id: bedroom.id,
                member_type: 'family_user',
                people_total: 1
            }]);
            setFamilyPricesOriginal(familyPricesList.length > 0 ? familyPricesList : [{
                price: 0,
                hotel_bedroom_id: bedroom.id,
                member_type: 'family_user',
                people_total: 1
            }]);
            const guessPricesList = bedroom.occupancy_payments.filter(item => item.member_type === "guest_user").map((item, i) => ({
                id: item.id,
                member_type: item.member_type,
                people_total: i + 1,
                price: item.price,
                hotel_bedroom_id: bedroom.id,
            }))
            setGuessPrices(guessPricesList.length > 0 ? guessPricesList : [{
                price: 0,
                hotel_bedroom_id: bedroom.id,
                member_type: 'guest_user',
                people_total: 1
            }]);
            setGuessPricesOriginal(guessPricesList.length > 0 ? guessPricesList : [{
                price: 0,
                hotel_bedroom_id: bedroom.id,
                member_type: 'guest_user',
                people_total: 1
            }]);
            handleUpdateState({
                totalPriceMember: bedroom.price_member,
                totalPriceFamily: bedroom.price_family,
                totalPriceGuess: bedroom.price_guest,
                isPayBedroom: bedroom.is_pay_bedroom
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    ////////////////////////////////////////////////////////////////////

    const handleForm = async (e: FormEvent) => {
        e.preventDefault();
        if (isPayBedroom) {
            const { payload } =  await putHotelBedroom({
                ...bedroom,
                is_pay_bedroom: true,
                price_member: totalPriceMember,
                price_family: totalPriceFamily,
                price_guest: totalPriceGuess,
                hotel_id: bedroom.hotel.id
            })(dispatch);
            const { success } = payload;
            if (!success) {
                return toast.error(payload.error?.message, { type: 'error' });
            }
            toast.success('Precios actualizados con éxito.');
        } else {
            toast.info('Actualizando precios...');
            const allPromiseRequest = [];
            /// Alse above but in forof
            const memberPricestoToPost: HotelOccupancyPaymentPost[] = [];
            const memberPricestoToPut: HotelOccupancyPaymentPut[] = [];
            for (const memberPrice of memberPrices) {
                if (memberPrice.id) {
                    memberPricestoToPut.push({
                        id: memberPrice.id,
                        people_total: memberPrice.people_total,
                        price: memberPrice.price,
                        hotel_bedroom_id: bedroom.id,
                        member_type: memberPrice.member_type,
                    });
                } else {
                    memberPricestoToPost.push({
                        member_type: memberPrice.member_type,
                        people_total: memberPrice.people_total,
                        price: memberPrice.price,
                        hotel_bedroom_id: bedroom.id,
                    });
                }
            }
            const memberPricestoToPutOriginal: HotelOccupancyPaymentPut[] = [];
            for (const memberPrice of memberPricesOriginal) {
                if (memberPrice.id) {
                    memberPricestoToPutOriginal.push({
                        id: memberPrice.id,
                        people_total: memberPrice.people_total,
                        price: memberPrice.price,
                        hotel_bedroom_id: bedroom.id,
                        member_type: memberPrice.member_type,
                    });
                }
            }
            const memberPricesToDelete = memberPricestoToPutOriginal.filter(memberPrice => memberPricestoToPut.findIndex(memberPrice2 => memberPrice2.id === memberPrice.id) === -1);
            memberPricestoToPost.length > 0 && allPromiseRequest.push(...memberPricestoToPost.map(memberPrice => postHotelOccupancyPayment(memberPrice)(dispatch)));
            memberPricestoToPut.length > 0 && allPromiseRequest.push(...memberPricestoToPut.map(memberPrice => putHotelOccupancyPayment(memberPrice)(dispatch)));
            memberPricesToDelete.length > 0 && allPromiseRequest.push(...memberPricesToDelete.map(memberPrice => deleteHotelOccupancyPayment(memberPrice.id)(dispatch)));



            /// Alse above but in forof
            const familyPricestoToPost: HotelOccupancyPaymentPost[] = [];
            const familyPricestoToPut: HotelOccupancyPaymentPut[] = [];
            for (const familyPrice of familyPrices) {
                if (familyPrice.id) {
                    familyPricestoToPut.push({
                        id: familyPrice.id,
                        people_total: familyPrice.people_total,
                        price: familyPrice.price,
                        hotel_bedroom_id: bedroom.id,
                        member_type: familyPrice.member_type,
                    });
                } else {
                    familyPricestoToPost.push({
                        member_type: familyPrice.member_type,
                        people_total: familyPrice.people_total,
                        price: familyPrice.price,
                        hotel_bedroom_id: bedroom.id,
                    });
                }
            }
            const familyPricestoToPutOriginal: HotelOccupancyPaymentPut[] = [];
            for (const familyPrice of familyPricesOriginal) {
                if (familyPrice.id) {
                    familyPricestoToPutOriginal.push({
                        id: familyPrice.id,
                        people_total: familyPrice.people_total,
                        price: familyPrice.price,
                        hotel_bedroom_id: bedroom.id,
                        member_type: familyPrice.member_type,
                    });
                }
            }
            const familyPricesToDelete = familyPricestoToPutOriginal.filter(familyPrice => familyPricestoToPut.findIndex(familyPrice2 => familyPrice2.id === familyPrice.id) === -1);
            familyPricestoToPost.length > 0 && allPromiseRequest.push(...familyPricestoToPost.map(familyPrice => postHotelOccupancyPayment(familyPrice)(dispatch)));
            familyPricestoToPut.length > 0 && allPromiseRequest.push(...familyPricestoToPut.map(familyPrice => putHotelOccupancyPayment(familyPrice)(dispatch)));
            familyPricesToDelete.length > 0 && allPromiseRequest.push(...familyPricesToDelete.map(familyPrice => deleteHotelOccupancyPayment(familyPrice.id)(dispatch)));



            /// Alse above but in forof
            const guessPricestoToPost: HotelOccupancyPaymentPost[] = [];
            const guessPricestoToPut: HotelOccupancyPaymentPut[] = [];
            for (const guessPrice of guessPrices) {
                if (guessPrice.id) {
                    guessPricestoToPut.push({
                        id: guessPrice.id,
                        people_total: guessPrice.people_total,
                        price: guessPrice.price,
                        hotel_bedroom_id: bedroom.id,
                        member_type: guessPrice.member_type,
                    });
                } else {
                    guessPricestoToPost.push({
                        member_type: guessPrice.member_type,
                        people_total: guessPrice.people_total,
                        price: guessPrice.price,
                        hotel_bedroom_id: bedroom.id,
                    });
                }
            }
            const guessPricestoToPutOriginal: HotelOccupancyPaymentPut[] = [];
            for (const guessPrice of guessPricesOriginal) {
                if (guessPrice.id) {
                    guessPricestoToPutOriginal.push({
                        id: guessPrice.id,
                        people_total: guessPrice.people_total,
                        price: guessPrice.price,
                        hotel_bedroom_id: bedroom.id,
                        member_type: guessPrice.member_type,
                    });
                }
            }
            const guessPricesToDelete = guessPricestoToPutOriginal.filter(guessPrice => guessPricestoToPut.findIndex(guessPrice2 => guessPrice2.id === guessPrice.id) === -1);
            guessPricestoToPost.length > 0 && allPromiseRequest.push(...guessPricestoToPost.map(familyPrice => postHotelOccupancyPayment(familyPrice)(dispatch)));
            guessPricestoToPut.length > 0 && allPromiseRequest.push(...guessPricestoToPut.map(familyPrice => putHotelOccupancyPayment(familyPrice)(dispatch)));
            guessPricesToDelete.length > 0 && allPromiseRequest.push(...guessPricesToDelete.map(guessPrice => deleteHotelOccupancyPayment(guessPrice.id)(dispatch)));

            allPromiseRequest.push(putHotelBedroom({
                ...bedroom,
                is_pay_bedroom: false,
                hotel_id: bedroom.hotel.id
            })(dispatch));

            const responses = await Promise.all(allPromiseRequest);
            const allSuccess = responses.every(response => response.payload.success);

            if (!allSuccess) {
                return toast.error(
                    '¡Oops!, tuvimos un problema al actualizar los precios. Por favor vuelva a intentarlo más tarde.',
                    { type: 'error' }
                );
            }
            toast.success('Precios actualizados con éxito.');
        }
    };

    return (
        <section >
            <div>
                <button onClick={() => navigate(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block text-gray-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </button>
                <h1 className="ml-8 inline-block font-bold text-gray-500 mb-10">
                    {bedroom.name} {` `}
                    ({bedroom.people_total} {bedroom.people_total === 1 ? 'persona' : 'personas'})
                </h1>
            </div>
            <form onSubmit={handleForm}>
                <div>
                    <label className={`inline-block font-bold mb-10 ${isPayBedroom ? 'text-black' : 'text-gray-500'}`}>
                        <input className="mr-4 h-7 w-7 rounded-md bg-yellow-50 border-yellow-500 checked:bg-yellow-500 checked:border-transparent accent-yellow-500 border-2 focus:ring-2 focus:ring-yellow-500 active:ring-2 active:ring-yellow-500"
                            name="isGoing"
                            type="checkbox"
                            checked={isPayBedroom}
                            onChange={() => handleUpdateField('isPayBedroom', !isPayBedroom)}
                        />
                        Pago por habitación completa
                    </label>
                </div>
                <div className="ml-8 flex flex-row gap-4 items-center">
                    <input
                        id="totalPriceMember"
                        name="totalPriceMember"
                        type="number"
                        value={totalPriceMember}
                        onChange={handleOnChangeInput}
                        min={0}
                        required={!isPayBedroom}
                        disabled={!isPayBedroom}
                        className="basis-1/3 input-yellow"
                        placeholder="Precio Total para Socio"
                    />
                    <input
                        id="totalPriceFamily"
                        name="totalPriceFamily"
                        type="number"
                        value={totalPriceFamily}
                        onChange={handleOnChangeInput}
                        min={0}
                        required={!isPayBedroom}
                        disabled={!isPayBedroom}
                        className="basis-1/3 input-yellow"
                        placeholder="Precio Total para Familiar"
                    />
                    <input
                        id="totalPriceGuess"
                        name="totalPriceGuess"
                        type="number"
                        value={totalPriceGuess}
                        onChange={handleOnChangeInput}
                        min={0}
                        required={!isPayBedroom}
                        disabled={!isPayBedroom}
                        className="basis-1/3 input-yellow"
                        placeholder="Precio Total para invitado"
                    />
                </div>
                <div className="mt-6">
                    <label className={`inline-block font-bold mb-10 ${!isPayBedroom ? 'text-black' : 'text-gray-500'}`}>
                        <input className={`mr-4 h-7 w-7 rounded-md ${!isPayBedroom ? 'bg-yellow-500 border-transparent' : 'bg-yellow-50 border-yellow-500'} accent-yellow-500 focus:ring-2 focus:ring-yellow-500 active:ring-2 active:ring-yellow-500`}
                            name="isGoing"
                            type="checkbox"
                            checked={!isPayBedroom}
                            onChange={() => handleUpdateField('isPayBedroom', !isPayBedroom)}
                        />
                        Pago por ocupación
                    </label>
                </div>

                <div className="relative flex row-row flex-wrap gap-8 mb-10">
                    <h1 className="ml-8 inline-block font-bold text-gray-500">Socio</h1>
                    {
                        memberPrices && memberPrices.map((member, indexMember) => (
                            <div key={`member-prices-${indexMember}`} className="w-full gap-4">
                                <h1 className={`ml-8 inline-block font-bold mx-8 ${!isPayBedroom ? 'text-black' : 'text-gray-500'}`}> {indexMember + 1} Persona</h1>
                                <input
                                    type="number" name="name" id="name"
                                    value={member.price}
                                    // replace price with value from input
                                    onChange={(e) => setMemberPrices([
                                        ...memberPrices.slice(0, indexMember),
                                        {
                                            ...member,
                                            price: parseFloat(e.target.value)
                                        },
                                        ...memberPrices.slice(indexMember + 1)
                                    ])}
                                    className="input-yellow mx-8"
                                    required={isPayBedroom}
                                    disabled={isPayBedroom}
                                    placeholder="Ingrese un valor"
                                />
                                {
                                    indexMember === 0 ? (
                                        <button
                                            type="button"
                                            // add new socio to state
                                            onClick={() => setMemberPrices([...memberPrices, {
                                                price: 0,
                                                hotel_bedroom_id: bedroom.id,
                                                member_type: 'member_user',
                                                people_total: memberPrices.length + 1
                                            }])}
                                            disabled={isPayBedroom || memberPrices.length >= bedroom.people_total}
                                            className={`rounded-full p-2 text-white ring-offset-2 ${!isPayBedroom && memberPrices.length < bedroom.people_total ? 'bg-yellow-500 hover:bg-amber-300 active:bg-amber-400 active:ring active:ring-amber-300' : 'bg-yellow-300'}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            // remove actual Socio from state
                                            onClick={() => setMemberPrices(memberPrices.filter(h => h !== member))}
                                            disabled={isPayBedroom}
                                            className={`rounded-full p-2 px-1.5 ${!isPayBedroom ? 'text-black hover:bg-red-50 ring-offset-2 active:bg-red-300 active:ring active:ring-red-300' : 'text-gray-500'}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="relative flex row-row flex-wrap gap-8 mb-10">
                    <h1 className="ml-8 inline-block font-bold text-gray-500">Dependiente</h1>
                    {
                        familyPrices && familyPrices.map((dependency, indexFamily) => (
                            <div key={`family-prices-${indexFamily}`} className="w-full gap-4">
                                <h1 className={`ml-8 inline-block font-bold mx-8 ${!isPayBedroom ? 'text-black' : 'text-gray-500'}`}> {indexFamily + 1} Persona</h1>
                                <input
                                    type="number" name="name" id="name"
                                    value={dependency.price}
                                    // replace value from state with value from onChange
                                    onChange={(e) => setFamilyPrices([
                                        ...familyPrices.slice(0, indexFamily),
                                        {
                                            ...dependency,
                                            price: parseFloat(e.target.value)
                                        },
                                        ...familyPrices.slice(indexFamily + 1)
                                    ])}
                                    className="input-yellow mx-8"
                                    required={isPayBedroom}
                                    disabled={isPayBedroom}
                                    placeholder="Ingrese un valor"
                                />

                                {
                                    indexFamily === 0 ? (
                                        <button
                                            type="button"
                                            // add new Dependiente to state
                                            onClick={() => setFamilyPrices([...familyPrices, {
                                                price: 0,
                                                hotel_bedroom_id: bedroom.id,
                                                member_type: 'family_user',
                                                people_total: familyPrices.length + 1
                                            }])}
                                            disabled={isPayBedroom || guessPrices.length >= bedroom.people_total}
                                            className={`rounded-full  p-2 text-white ring-offset-2 ${!isPayBedroom && guessPrices.length < bedroom.people_total ? 'bg-yellow-500 hover:bg-amber-300 active:bg-amber-400 active:ring active:ring-amber-300' : 'bg-yellow-300'}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            // remove actual Dependiente from state
                                            onClick={() => setFamilyPrices(familyPrices.filter(h => h !== dependency))}
                                            disabled={isPayBedroom}
                                            className={`rounded-full p-2 px-1.5 ${!isPayBedroom ? 'text-black hover:bg-red-50 ring-offset-2 active:bg-red-300 active:ring active:ring-red-300' : 'text-gray-500'}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="relative flex row-row flex-wrap gap-8 mb-10">
                    <h1 className="ml-8 inline-block font-bold text-gray-500">Invitado</h1>
                    {
                        guessPrices && guessPrices.map((guess, indexGuess) => (
                            <div key={`guessPrices-${indexGuess}`} className="w-full gap-4">
                                <h1 className={`ml-8 inline-block font-bold mx-8 ${!isPayBedroom ? 'text-black' : 'text-gray-500'}`}>{indexGuess + 1} Persona </h1>
                                <input
                                    type="number" name="name" id="name"
                                    value={guess.price}
                                    // replace value from state with value from onChange
                                    onChange={(e) => setGuessPrices([
                                        ...guessPrices.slice(0, indexGuess),
                                        {
                                            ...guess,
                                            price: parseFloat(e.target.value)
                                        },
                                        ...guessPrices.slice(indexGuess + 1)
                                    ])}
                                    className="input-yellow mx-8"
                                    required={isPayBedroom}
                                    disabled={isPayBedroom}
                                    placeholder="Ingrese un valor"
                                />

                                {
                                    indexGuess === 0 ? (
                                        <button
                                            type="button"
                                            // add new familiar to state
                                            onClick={() => setGuessPrices([...guessPrices, {
                                                price: 0,
                                                hotel_bedroom_id: bedroom.id,
                                                member_type: 'guest_user',
                                                people_total: guessPrices.length + 1
                                            }])}
                                            disabled={isPayBedroom || guessPrices.length >= bedroom.people_total}
                                            className={`rounded-full  p-2 text-white ring-offset-2 ${!isPayBedroom && guessPrices.length < bedroom.people_total ? 'bg-yellow-500 hover:bg-amber-300 active:bg-amber-400 active:ring active:ring-amber-300' : 'bg-yellow-300'}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            // remove actual familiar from state
                                            onClick={() => setGuessPrices(guessPrices.filter(h => h !== guess))}
                                            disabled={isPayBedroom}
                                            className={`rounded-full p-2 px-1.5 ${!isPayBedroom ? 'text-black hover:bg-red-50 ring-offset-2 active:bg-red-300 active:ring active:ring-red-300' : 'text-gray-500'}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>

                <div className="grid grid-cols-3">
                    <div className="flex justify-end col-start-2 md:col-start-1">
                        <button type="submit" className="mb-4 w-48 rounded-lg btn-yellow p-2 font-bold">Guardar</button>
                    </div>
                </div>

            </form>
        </section >
    );
};