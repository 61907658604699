import { HotelBedroomProcess } from "../api";
import { HotelBedroomAction, HotelBedroomFilters, HotelBedroomPost, HotelBedroomPut } from "../interfaces";
import { HotelBedroomTypes } from "../types";

// apply the reducer
export const getHotelBedroomPags = (filters?: HotelBedroomFilters) => async (dispatch: (state: HotelBedroomAction) => HotelBedroomAction) => {
  dispatch({
    type: HotelBedroomTypes.GET_HOTELBEDROOMPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBedroomProcess = new HotelBedroomProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelBedroomPag = await hotelBedroomProcess.getHotelBedroom(filters);
    return dispatch({
      type: HotelBedroomTypes.GET_HOTELBEDROOMPAG_SUCCESS,
      payload: {
        pagHotelBedroom: hotelBedroomPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBedroomTypes.GET_HOTELBEDROOMPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotelBedroom = (filters?: HotelBedroomFilters) => async (dispatch: (state: HotelBedroomAction) => HotelBedroomAction) => {
  dispatch({
    type: HotelBedroomTypes.GET_HOTELBEDROOM_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBedroomProcess = new HotelBedroomProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelBedroomPag = await hotelBedroomProcess.getHotelBedroom({
      ...filters,
      data_all: 'YES',
    });
    const hotelBedroom = hotelBedroomPag.results;
    return dispatch({
      type: HotelBedroomTypes.GET_HOTELBEDROOM_SUCCESS,
      payload: {
        listHotelBedroom: hotelBedroom,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBedroomTypes.GET_HOTELBEDROOM_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotelBedroom = (hotelBedroom: HotelBedroomPost) => async (dispatch: (state: HotelBedroomAction) => HotelBedroomAction) => {
  dispatch({
    type: HotelBedroomTypes.POST_HOTELBEDROOM_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBedroomProcess = new HotelBedroomProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelBedroomProcess.postHotelBedroom(hotelBedroom);
    return dispatch({
      type: HotelBedroomTypes.POST_HOTELBEDROOM_SUCCESS,
      payload: {
        hotelBedroom: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBedroomTypes.POST_HOTELBEDROOM_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotelBedroom = (hotelBedroom: HotelBedroomPut) => async (dispatch: (state: HotelBedroomAction) => HotelBedroomAction) => {
  dispatch({
    type: HotelBedroomTypes.PUT_HOTELBEDROOM_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBedroomProcess = new HotelBedroomProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelBedroomProcess.putHotelBedroom(hotelBedroom);
    return dispatch({
      type: HotelBedroomTypes.PUT_HOTELBEDROOM_SUCCESS,
      payload: {
        hotelBedroom: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBedroomTypes.PUT_HOTELBEDROOM_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotelBedroom = (id: number) => async (dispatch: (state: HotelBedroomAction) => HotelBedroomAction) => {
  dispatch({
    type: HotelBedroomTypes.DELETE_HOTELBEDROOM_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelBedroomProcess = new HotelBedroomProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelBedroomProcess.deleteHotelBedroom(id);
    return dispatch({
      type: HotelBedroomTypes.DELETE_HOTELBEDROOM_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelBedroomTypes.DELETE_HOTELBEDROOM_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};