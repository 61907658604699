
import { RestaurantCategoriesRepository } from "../repositories";
import { RestaurantCategoriesFilters, RestaurantCategoriesGet, RestaurantCategoriesPost, RestaurantCategoriesPut, RestaurantCategoriesTable } from "../../interfaces";

export class RestaurantCategoriesProcess {
  private restaurantCategoriesRepository: RestaurantCategoriesRepository;

  constructor() {
    this.restaurantCategoriesRepository = new RestaurantCategoriesRepository();
  }

  async getRestaurantCategories(filters?: RestaurantCategoriesFilters): Promise<RestaurantCategoriesGet> {
    const response = await this.restaurantCategoriesRepository.getRestaurantCategories(filters);
    return response.data;
  }

  async postRestaurantCategories(restaurantCategories: RestaurantCategoriesPost): Promise<RestaurantCategoriesTable> {
    const response = await this.restaurantCategoriesRepository.postRestaurantCategories(restaurantCategories);
    return response.data;
  }

  async putRestaurantCategories(restaurantCategories: RestaurantCategoriesPut): Promise<RestaurantCategoriesTable> {
    const response = await this.restaurantCategoriesRepository.putRestaurantCategories(restaurantCategories);
    return response.data;
  }

  // dont response data
  async deleteRestaurantCategories(id: number): Promise<any> {
    const response = await this.restaurantCategoriesRepository.deleteRestaurantCategories(id);
    return response.data;
  }
}
