export interface TableFooterType {
    pageCurrent: number;
    previous: string | number | null;
    next: string | number | null;
    count: number;
    pageSize: number;
    setPageCurrent: (pageCurrent: number) => void;
}

export const TableFooter = ({ pageCurrent, previous, next, count, pageSize, setPageCurrent }: TableFooterType) => {
  return (
    <>
        <div className="flex items-center justify-between border-t border-gray-500/25 bg-white px-4 py-3 print:hidden sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <button
                    onClick={() => setPageCurrent(pageCurrent - 1)}
                    disabled={!previous}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Previous
                </button>
                <button
                    onClick={() => setPageCurrent(pageCurrent + 1)}
                    disabled={!next}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Página <span className="font-medium">{pageCurrent}</span> de{' '}
                        <span className="font-medium">{Math.ceil(count / pageSize) === 0 ? 1 : Math.ceil(count / pageSize)}</span>
                    </p>
                </div>
                <div>
                    <nav
                        className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        <button
                            onClick={() => setPageCurrent(pageCurrent - 1)}
                            disabled={!previous}
                            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Previous</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                        </button>
                        {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                        <button
                            onClick={() => setPageCurrent(pageCurrent + 1)}
                            disabled={!next}
                            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Next</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </>
    )
}
