import { UserProcess } from "../api";
import { UserAction, UserFilters, UserPut } from "../interfaces";
import { UserTypes } from "../types";

// apply the reducer
export const getCurrentUser = () => async (dispatch: (state: UserAction) => UserAction) => {
  dispatch({
    type: UserTypes.GET_USER_LOADING,
    payload: {
      loading: true,
      success: false
    },
  });
  try {
    const userProcess = new UserProcess();
    // dont'n import the result of the process, only that dont't have error
    const user = await userProcess.getCurrentUser();
    return dispatch({
      type: UserTypes.GET_USER_SUCCESS,
      payload: {
        user,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserTypes.GET_USER_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getListUsers = (filters?: UserFilters) => async (dispatch: (state: UserAction) => UserAction) => {
  dispatch({
    type: UserTypes.GET_LIST_USER_LOADING,
    payload: {
      loading: true,
      success: false
    },
  });
  try {
    const userProcess = new UserProcess();
    // dont'n import the result of the process, only that dont't have error
    const listUsers = await userProcess.getListUsers({
      data_all: 'YES',
      ...filters
    });
    return dispatch({
      type: UserTypes.GET_LIST_USER_SUCCESS,
      payload: {
        listUsers: listUsers.results,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserTypes.GET_LIST_USER_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getListUsersPag = (filters?: UserFilters) => async (dispatch: (state: UserAction) => UserAction) => {
  dispatch({
    type: UserTypes.GET_LIST_USERPAG_LOADING,
    payload: {
      loading: true,
      success: false
    },
  });
  try {
    const userProcess = new UserProcess();
    // dont'n import the result of the process, only that dont't have error
    const listUsers = await userProcess.getListUsers(filters);
    return dispatch({
      type: UserTypes.GET_LIST_USERPAG_SUCCESS,
      payload: {
        listUsersPag: listUsers,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserTypes.GET_LIST_USERPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getUserById = (id: number) => async (dispatch: (state: UserAction) => UserAction) => {
  dispatch({
    type: UserTypes.GET_USERBYID_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userProcess = new UserProcess();
    // dont'n import the result of the process, only that dont't have error
    const user = await userProcess.getUserById(id);
    return dispatch({
      type: UserTypes.GET_USERBYID_SUCCESS,
      payload: {
        userById: user,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserTypes.GET_USERBYID_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putUser = (user: UserPut) => async (dispatch: (state: UserAction) => UserAction) => {
  dispatch({
    type: UserTypes.PUT_USER_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userProcess = new UserProcess();
    // dont'n import the result of the process, only that dont't have error
    const userTable = await userProcess.putUser(user);
    return dispatch({
      type: UserTypes.PUT_USER_SUCCESS,
      payload: {
        userById: userTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserTypes.PUT_USER_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putUserImage = (id: number, userImage: FormData) => async (dispatch: (state: UserAction) => UserAction) => {
  dispatch({
    type: UserTypes.PUT_USERPHOTO_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userProcess = new UserProcess();
    // dont'n import the result of the process, only that dont't have error
    await userProcess.putUserImage(id, userImage);
    return dispatch({
      type: UserTypes.PUT_USERPHOTO_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
      type: UserTypes.PUT_USERPHOTO_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};