
import { CampusSurveyAskAction, CampusSurveyAskPayload } from "../interfaces";
import { CampusSurveyAskTypes } from "../types";

const initialState: CampusSurveyAskPayload = {
  loading: false,
  success: false,
  error: null,
  campusSurveyAsk: null,
  listCampusSurveyAsk: [],
  pagCampusSurveyAsk: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const campusSurveyAsk = (state = initialState, action: CampusSurveyAskAction): CampusSurveyAskPayload => {
  const { loading, success, error, campusSurveyAsk, listCampusSurveyAsk, pagCampusSurveyAsk } = action.payload || {};
  switch (action.type) {
    case CampusSurveyAskTypes.GET_CAMPUSSURVEYASKPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyAskTypes.GET_CAMPUSSURVEYASKPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyAskTypes.GET_CAMPUSSURVEYASKPAG_SUCCESS:
      return {
        ...state,
        pagCampusSurveyAsk,
        loading,
        success,
      };
    case CampusSurveyAskTypes.GET_CAMPUSSURVEYASK_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyAskTypes.GET_CAMPUSSURVEYASK_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyAskTypes.GET_CAMPUSSURVEYASK_SUCCESS:
      return {
        ...state,
        listCampusSurveyAsk,
        loading,
        success,
      };
    case CampusSurveyAskTypes.GETONLY_CAMPUSSURVEYASK_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyAskTypes.GETONLY_CAMPUSSURVEYASK_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyAskTypes.GETONLY_CAMPUSSURVEYASK_SUCCESS:
      return {
        ...state,
        campusSurveyAsk,
        loading,
        success,
      };
    case CampusSurveyAskTypes.POST_CAMPUSSURVEYASK_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyAskTypes.POST_CAMPUSSURVEYASK_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyAskTypes.POST_CAMPUSSURVEYASK_SUCCESS:
      return {
        ...state,
        campusSurveyAsk,
        loading,
        success,
      };
    case CampusSurveyAskTypes.PUT_CAMPUSSURVEYASK_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyAskTypes.PUT_CAMPUSSURVEYASK_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyAskTypes.PUT_CAMPUSSURVEYASK_SUCCESS:
      return {
        ...state,
        campusSurveyAsk,
        loading,
        success,
      };
    case CampusSurveyAskTypes.DELETE_CAMPUSSURVEYASK_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyAskTypes.DELETE_CAMPUSSURVEYASK_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyAskTypes.DELETE_CAMPUSSURVEYASK_SUCCESS:
      return {
        ...state,
        campusSurveyAsk,
        loading,
        success,
      };
    default:
      return state;
  }
};

