import { ErrorMessage, useField } from "formik";

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  [x: string]: any;
}

export const FormTextarea = ({ label, className, containerClassName, ...props }: Props ) => {
  const [ field ] = useField({ ...props, type: 'textarea' });
  return (
    <div className={`flex flex-col gap-1 ${containerClassName ? containerClassName : '' }`}>
        { label && <label htmlFor={ props.id || props.name }>{ label }</label> }
        <textarea className={`input-yellow ${className ? className : ''}`} { ...field } { ...props }></textarea>
        <ErrorMessage name={ props.name } component="span" className="text-red-500" />
    </div>
  )
}
