
export enum ParametersTypes {
  GET_PARAMETERSPAG_LOADING = "GET_PARAMETERSPAG_LOADING",
  GET_PARAMETERSPAG_SUCCESS = "GET_PARAMETERSPAG_SUCCESS",
  GET_PARAMETERSPAG_FAILURE = "GET_PARAMETERSPAG_FAILURE",
  GET_PARAMETERS_LOADING = "GET_PARAMETERS_LOADING",
  GET_PARAMETERS_SUCCESS = "GET_PARAMETERS_SUCCESS",
  GET_PARAMETERS_FAILURE = "GET_PARAMETERS_FAILURE",
  GETONLY_PARAMETERS_LOADING = "GETONLY_PARAMETERS_LOADING",
  GETONLY_PARAMETERS_SUCCESS = "GETONLY_PARAMETERS_SUCCESS",
  GETONLY_PARAMETERS_FAILURE = "GETONLY_PARAMETERS_FAILURE",
  POST_PARAMETERS_LOADING = "POST_PARAMETERS_LOADING",
  POST_PARAMETERS_SUCCESS = "POST_PARAMETERS_SUCCESS",
  POST_PARAMETERS_FAILURE = "POST_PARAMETERS_FAILURE",
  PUT_PARAMETERS_LOADING = "PUT_PARAMETERS_LOADING",
  PUT_PARAMETERS_SUCCESS = "PUT_PARAMETERS_SUCCESS",
  PUT_PARAMETERS_FAILURE = "PUT_PARAMETERS_FAILURE",
  DELETE_PARAMETERS_LOADING = "DELETE_PARAMETERS_LOADING",
  DELETE_PARAMETERS_SUCCESS = "DELETE_PARAMETERS_SUCCESS",
  DELETE_PARAMETERS_FAILURE = "DELETE_PARAMETERS_FAILURE",
}