import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteHotelBedroom,
  deleteHotelImage,
  getHotel,
  getHotelBedroom,
  postHotelBedroom,
  postHotelImage,
  putHotel,
  putHotelBedroom
} from '../../redux/actions';
import {
  HotelBedroomTable,
  User
} from '../../redux/interfaces';
import Alert from '../../utils/alerts';
import useForm from '../../utils/hooks/useForm';
import { MiniCarouselImg, Modal } from '../../components';
import { RootState } from '../../redux/store';
import { testSizeImg } from '../../utils/utils';

type ImageType = {
  id?: number;
  url: string;
  hotel_bedroom?: number;
  file?: File
}

export const HotelBedroomNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listHotelBedroom } = useSelector((state: RootState) => state.hotelBedroom);
  const { listHotel } = useSelector((state: RootState) => state.hotel);
  const [images, setImages] = useState<ImageType[]>([]);
  const [imagesOriginal, setImagesOriginal] = useState<ImageType[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [formAction, setFormAction] = useState<'update-form' | 'create-form'>('create-form');
  const {
    id,
    nameBedroom,
    nameAdministrative,
    description,
    noPeopleBedroom,
    hasBreakfast,
    hasMultipleServices,
    noBeds,
    reserveCostPercentage,
    handleOnChangeInput,
    handleUpdateState,
  } = useForm<any>({
    id: 0,
    nameBedroom: '',
    nameAdministrative: '',
    description: '',
    noPeopleBedroom: '',
    hasBreakfast: '',
    hasMultipleServices: '',
    noBeds: '',
    reserveCostPercentage: ''
  });

  const pullListBedroom = async () => {
    if (!listHotel || listHotel.length === 0) return;
    getHotelBedroom({
      hotel_id: listHotel[0].id,
      campus_id: user.campus.id
    })(dispatch);
  };

  const reloadForm = () => {
    // Reload all data
    pullListBedroom();
    // Reset Form
    setImages([]);
    setImagesOriginal([]);
    setFormAction('create-form');
    handleUpdateState({
      id: 0,
      nameBedroom: '',
      nameAdministrative: '',
      description: '',
      noPeopleBedroom: '',
      hasBreakfast: '',
      hasMultipleServices: '',
      noBeds: '',
      reserveCostPercentage: ''
    });
  };

  useEffect(() => {
    getHotel({
      campus_id: user.campus.id,
    })(dispatch);
    pullListBedroom();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!listHotel || listHotel.length === 0) return;
    getHotelBedroom({
      hotel_id: listHotel[0].id,
    })(dispatch);
    handleUpdateState({
      reserveCostPercentage: listHotel[0].reservation_cost
    });
  }, [listHotel]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditBedroom = async (bedroom: HotelBedroomTable) => {
    // setIsOpen(true);
    setFormAction('update-form');
    handleUpdateState({
      id: bedroom.id,
      nameBedroom: bedroom.name,
      nameAdministrative: bedroom.administrative_name,
      description: bedroom.description,
      noPeopleBedroom: bedroom.people_total,
      hasBreakfast: bedroom.is_breakfast,
      hasMultipleServices: bedroom.is_service_multiple,
      noBeds: bedroom.number_beds
    });
    setImages(bedroom.images);
    setImagesOriginal([...bedroom.images]);
  };

  const handleSubmitBedroom = async (e: FormEvent) => {
    // get name from submit button
    e.preventDefault();
    if (formAction === 'create-form') {
      handleCreateBedroom();
    } else {
      handleUpdateBedroom();
    }
  };

  const handleCreateBedroom = async () => {
    if (!listHotel || listHotel.length === 0) return;

    const { payload } = await postHotelBedroom({
      name: nameBedroom,
      administrative_name: nameAdministrative,
      description: description,
      people_total: noPeopleBedroom,
      is_breakfast: hasBreakfast === 'true',
      is_service_multiple: hasMultipleServices === 'true',
      number_beds: noBeds,
      is_pay_bedroom: false,
      price_member: 0,
      price_family: 0,
      price_guest: 0,
      hotel_id: listHotel[0].id, //!WARNING: need improve validation
    })(dispatch);
    const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success('Habitación creada con éxito.');

    if (images.length === 0) {
      return reloadForm();
    }

    toast.info('Subiendo imágenes...');

    // Upload images
    const imagesToPost = new FormData();
    const bedroom = payload.hotelBedroom as HotelBedroomTable;

    imagesToPost.append('hotel_bedroom', bedroom.id.toString());
    for (const image of images) {
      if (!image.id && image.file){
        imagesToPost.append('images', image.file);
      }
    }

    const allPromiseRequest = [];
    imagesToPost.getAll('images').length > 0 && allPromiseRequest.push(postHotelImage(imagesToPost)(dispatch));

    const responses = await Promise.all(allPromiseRequest);
    const allSuccess = responses.every(response => response.payload.success);

    if (!allSuccess) {
      return toast.error(
        '¡Oops!, tuvimos un problema al subir las imágenes. Por favor vuelva a intentarlo más tarde.',
        { type: 'error' }
      );
    }
    toast.success('Imágenes subidas con éxito.');
    reloadForm();
  };

  const handleUpdateBedroom = async () => {
    if (!listHotel || listHotel.length === 0) return;

    const { payload } = await putHotelBedroom({
      id: id,
      name: nameBedroom,
      administrative_name: nameAdministrative,
      description: description,
      people_total: noPeopleBedroom,
      is_breakfast: hasBreakfast === 'true',
      is_service_multiple: hasMultipleServices === 'true',
      number_beds: noBeds,
      is_pay_bedroom: false,
      price_family: 0,
      price_guest: 0,
      price_member: 0,
      hotel_id: listHotel[0].id,
    })(dispatch);
    const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success('Servicio actualizado con éxito.');

    // Upload images
    const imagesToPost = new FormData();

    if (!payload.hotelBedroom) {
      return toast.error('¡Oops!, tuvimos un problema al actualizar la habitación. Por favor vuelva a intentarlo más tarde.', { type: 'error' });
    }

    imagesToPost.append('hotel_bedroom', payload.hotelBedroom.id.toString());
    for (const image of images) {
      if (!image.id && image.file){
        imagesToPost.append('images', image.file);
      }
    }

    const imagesToDelete = imagesOriginal.filter(image => !images.find(image2 => image2.id === image.id));
    // Send All request in paralel
    const allPromiseRequest = [];

    if (images.length === 0 && imagesOriginal.length === 0) {
      return reloadForm();
    }

    toast.info('Actualizando imágenes...');

    imagesToPost.getAll('images').length > 0 && allPromiseRequest.push(postHotelImage(imagesToPost)(dispatch));
    imagesToDelete.length > 0 && allPromiseRequest.push(...imagesToDelete.map(image => deleteHotelImage(image.id || -1)(dispatch)));

    const responses = await Promise.all(allPromiseRequest);
    const allSuccess = responses.every(response => response.payload.success);

    if (!allSuccess) {
      return toast.error(
        '¡Oops!, tuvimos un problema al actualizar las imágenes. Por favor vuelva a intentarlo más tarde.',
        { type: 'error' }
      );
    }
    toast.success('Imágenes actualizadas con éxito.');
    reloadForm();
  };

  const handleDeleteBedroom = async (bedroom: HotelBedroomTable) => {
    const { isConfirmed } = await Alert.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${bedroom.name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteHotelBedroom(bedroom.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success('Servicio eliminado con éxito.');
        pullListBedroom();
      } else {
        toast.error('Error: problemas de conexión. Vuelva a intentarlo más tarde.', { type: 'error' });
      }
    }
  };

  const handleCatchImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', { type: 'error' });
    }

    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
    setImages((prevState) => [...prevState, ...imagesList]);
  }

  const removeImage = (image: ImageType) => {
    setImages(images.filter((imageItem) => imageItem !== image));
  }

  const resetForm = () => {
    setFormAction('create-form');
    reloadForm();
  }

  const handleOpenModal = () => {
    // handleUpdateState({
    //   nameEdit: multipleServicesCategory.name,
    //   idEdit: multipleServicesCategory.id
    // });
    
    setIsOpenModal(true);
  }

  const handleEditReservationCost = async (e: FormEvent) => {
      e.preventDefault();
      if (!listHotel) return;
      const { payload } = await putHotel({
        ...listHotel[0],
        campus_id: listHotel[0].campus.id,
        reservation_cost: reserveCostPercentage
      })(dispatch);
      const { success } = payload;
    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }
    toast.success('Costo de reserva actualizado con éxito.');
    setIsOpenModal(false);
    getHotel({
      campus_id: user.campus.id,
    })(dispatch);
  }

  return (
    <>
      <div>
        <form onSubmit={handleSubmitBedroom}>
          <div className={`flex flex-row ${formAction === 'create-form' ? 'justify-end' : 'justify-between'}`}>
            <button
              type="button"
              onClick={() => resetForm()}
              className={`mb-4 btn-black w-fit ${formAction === 'create-form' ? 'hidden' : 'block'}`}
            >
              Crear nueva habitación
              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => handleOpenModal()}
              className={`mb-4 btn-yellow w-fit`}
            >
              % costo de reserva
              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <h2 className="font-bold text-gray-500">
            Agregar Habitación
          </h2>

          <div className="mt-4 grid grid-cols-2 items-center gap-4">
            <input
              id="nameBedroom"
              name="nameBedroom"
              type="text"
              maxLength={250}
              value={nameBedroom}
              onChange={handleOnChangeInput}
              required
              className="relative mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
              placeholder="Nombre de la habitación"
            />
            <input
              id="nameAdministrative"
              name="nameAdministrative"
              type="text"
              maxLength={250}
              value={nameAdministrative}
              onChange={handleOnChangeInput}
              required
              className="relative mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
              placeholder="Nombre administrativo"
            />
          </div>
          <textarea
            name="description"
            id="description"
            rows={4}
            value={description}
            onChange={handleOnChangeInput}
            required
            placeholder="Descripción de la habitación"
            className="mt-4 w-full rounded-lg border-[1px] border-gray-300 bg-gray-50 focus:border-yellow-500 focus:ring-yellow-500"
          ></textarea>
          <div className="mt-2 grid grid-cols-4 items-center gap-4">
            <input
              id="noPeopleBedroom"
              name="noPeopleBedroom"
              type="number"
              value={noPeopleBedroom}
              onChange={handleOnChangeInput}
              required
              className="relative mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
              placeholder="Nro. de personas por habitación"
            />
            <select
              id="hasBreakfast"
              name="hasBreakfast"
              value={hasBreakfast}
              onChange={handleOnChangeInput}
              required
              className="mt-2 block h-11 rounded-lg border-[1px] border-gray-300 bg-gray-50 py-3 px-2 leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
            >
              <option value="" disabled>
                Desayunos
              </option>
              <option value="true">
                Sí
              </option>
              <option value="false">
                No
              </option>
            </select>
            <select
              id="hasMultipleServices"
              name="hasMultipleServices"
              value={hasMultipleServices}
              onChange={handleOnChangeInput}
              required
              className="mt-2 block h-11 rounded-lg border-[1px] border-gray-300 bg-gray-50 py-3 px-2 leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
            >
              <option value="" disabled>
                Servicios múltiples
              </option>
              <option value="true">
                Sí
              </option>
              <option value="false">
                No
              </option>
            </select>
            <input
              id="noBeds"
              name="noBeds"
              type="number"
              value={noBeds}
              onChange={handleOnChangeInput}
              min={1}
              max={3}
              required
              className="relative mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
              placeholder="Nro. de Camas"
            />
          </div>
          <div className="border-0 border-b border-gray-500 my-6"></div>
          <div>
            <h2 className="mt-6 font-bold text-gray-500">
              Imágenes <span className="text-sm font-normal text-orange-500">(Tamaño máximo 10Mb, Dimensión mínima 640x640)</span>
            </h2>
            <div className="mt-4 flex flex-row justify-between">
              <MiniCarouselImg images={images} onRemoveImage={removeImage} />
              <div className="ml-8 shrink-0">
                <div className="block mb-2">
                  <input
                    multiple
                    type="file"
                    name="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    onChange={handleCatchImages}
                    className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
                  />
                  <label htmlFor="file" className="btn-yellow">Seleccionar Imágenes</label>
                </div>
                <button
                  name={formAction}
                  type="submit"
                  className={`mt-2 w-full ${formAction === 'create-form' ? 'btn-yellow' : 'btn-black'}`}
                >
                  {formAction === 'create-form' ? 'Guardar Habitación' : 'Actualizar Habitación'}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="pb-16">
          <h2 className="mt-6 font-bold text-gray-500">Habitaciones publicadas</h2>
          {(listHotelBedroom &&
            listHotelBedroom.length > 0) ?
            listHotelBedroom.map((bedroom, i) => (
              <div
                key={`form-${i}`}
                className="grid grid-cols-[minmax(0,1fr)_120px] items-center justify-between odd:bg-gray-50 even:bg-white even:hover:bg-gray-50"
              >
                <span className="basis-3/5 px-4 font-bold">{bedroom.name}</span>
                <span className="mr-6 flex flex-row gap-1">
                  <button
                    onClick={() => handleEditBedroom(bedroom)}
                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => handleDeleteBedroom(bedroom)}
                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {(bedroom.occupancy_payments.length > 0 || bedroom.is_pay_bedroom) ? (
                    <button
                      onClick={() =>
                        navigate('/hotel/bedroom/prices/edit', {
                          state: {
                            bedroom: bedroom,
                          },
                        })
                      }
                      className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        navigate('/hotel/bedroom/prices/edit', {
                          state: {
                            bedroom: bedroom,
                          },
                        })
                      }
                      className="cursor-pointer rounded-lg p-1.5 hover:bg-red-200 active:bg-red-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-red-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                </span>
              </div>
            )) : (
              <div className="bg-gray-50 px-4">
                <span className="font-bold text-black">No hay habitaciones publicadas</span>
              </div>
            )}
        </div>
      </div>
      <Modal title="Costo de reserva" isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)}>
        <form
            onSubmit={(e) => handleEditReservationCost(e)}
            className="flex flex-col items-center justify-center gap-2"
        >
            <input
                id="reserveCostPercentage"
                name="reserveCostPercentage"
                type="text"
                maxLength={150}
                value={reserveCostPercentage}
                onChange={handleOnChangeInput}
                required
                className="relative mt-4 block h-11 w-full appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                placeholder="Ingresar el % de reserva a cobrar"
            />
            <div className="flex w-full justify-end">
                <button
                    type="submit"
                    className="mt-2 w-36 rounded-md border border-transparent bg-yellow-500 py-3 px-4 text-sm font-bold text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                >
                    Actualizar
                </button>
            </div>
        </form>
      </Modal>
    </>
  );
};
