
import { UserGroupProcess } from "../api";
import { UserGroupAction, UserGroupFilters, UserGroupPost, UserGroupPut,  } from "../interfaces";
import { UserGroupTypes } from "../types";

// apply the reducer
export const getUserGroupPags = (filters?: UserGroupFilters) => async (dispatch: (state: UserGroupAction) => UserGroupAction) => {
  dispatch({
    type: UserGroupTypes.GET_USERGROUPPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userGroupProcess = new UserGroupProcess();
    // dont'n import the result of the process, only that dont't have error
    const userGroupPag = await userGroupProcess.getUserGroup(filters);
    return dispatch({
      type: UserGroupTypes.GET_USERGROUPPAG_SUCCESS,
      payload: {
        pagUserGroup: userGroupPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserGroupTypes.GET_USERGROUPPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getUserGroup = (filters?: UserGroupFilters) => async (dispatch: (state: UserGroupAction) => UserGroupAction) => {
  dispatch({
    type: UserGroupTypes.GET_USERGROUP_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userGroupProcess = new UserGroupProcess();
    // dont'n import the result of the process, only that dont't have error
    const userGroupPag = await userGroupProcess.getUserGroup({
      ...filters,
      data_all: 'YES',
      page_size: 20
    });
    const userGroup = userGroupPag.results;
    return dispatch({
      type: UserGroupTypes.GET_USERGROUP_SUCCESS,
      payload: {
        listUserGroup: userGroup,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserGroupTypes.GET_USERGROUP_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postUserGroup = (userGroup: UserGroupPost) => async (dispatch: (state: UserGroupAction) => UserGroupAction) => {
  dispatch({
    type: UserGroupTypes.POST_USERGROUP_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userGroupProcess = new UserGroupProcess();
    // dont'n import the result of the process, only that dont't have error
    const userGroupTable = await userGroupProcess.postUserGroup(userGroup);
    return dispatch({
      type: UserGroupTypes.POST_USERGROUP_SUCCESS,
      payload: {
        userGroup: userGroupTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserGroupTypes.POST_USERGROUP_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putUserGroup = (userGroup: UserGroupPut) => async (dispatch: (state: UserGroupAction) => UserGroupAction) => {
  dispatch({
    type: UserGroupTypes.PUT_USERGROUP_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userGroupProcess = new UserGroupProcess();
    // dont'n import the result of the process, only that dont't have error
    const userGroupTable = await userGroupProcess.putUserGroup(userGroup);
    return dispatch({
      type: UserGroupTypes.PUT_USERGROUP_SUCCESS,
      payload: {
        userGroup: userGroupTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserGroupTypes.PUT_USERGROUP_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteUserGroup = (id: number) => async (dispatch: (state: UserGroupAction) => UserGroupAction) => {
  dispatch({
    type: UserGroupTypes.DELETE_USERGROUP_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const userGroupProcess = new UserGroupProcess();
    // dont'n import the result of the process, only that dont't have error
    await userGroupProcess.deleteUserGroup(id);
    return dispatch({
      type: UserGroupTypes.DELETE_USERGROUP_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: UserGroupTypes.DELETE_USERGROUP_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

