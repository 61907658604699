import { MultipleServicesCategoryProcess } from "../api";
import { MultipleServicesCategoryAction, MultipleServicesCategoryFilters, MultipleServicesCategoryPost, MultipleServicesCategoryPut } from "../interfaces";
import { MultipleServicesCategoryTypes } from "../types";


// apply the reducer
export const getMultipleServicesCategory = (filters?: MultipleServicesCategoryFilters) => async (dispatch: (state: MultipleServicesCategoryAction) => MultipleServicesCategoryAction) => {
  dispatch({
    type: MultipleServicesCategoryTypes.GET_MULTIPLESERVICESCATEGORY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesCategoryProcess = new MultipleServicesCategoryProcess();
    // dont'n import the result of the process, only that dont't have error
    const multipleServicesCategoryPag = await multipleServicesCategoryProcess.getMultipleServicesCategory({
      ...filters,
      data_all: 'YES',
    });
    const multipleServicesCategory = multipleServicesCategoryPag.results;
    return dispatch({
      type: MultipleServicesCategoryTypes.GET_MULTIPLESERVICESCATEGORY_SUCCESS,
      payload: {
        listMultipleServicesCategory: multipleServicesCategory,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesCategoryTypes.GET_MULTIPLESERVICESCATEGORY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postMultipleServicesCategory = (msCategory: MultipleServicesCategoryPost) => async (dispatch: (state: MultipleServicesCategoryAction) => MultipleServicesCategoryAction) => {
  dispatch({
    type: MultipleServicesCategoryTypes.POST_MULTIPLESERVICESCATEGORY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesCategoryProcess = new MultipleServicesCategoryProcess();
    // dont'n import the result of the process, only that dont't have error
    const msCategoryRes = await multipleServicesCategoryProcess.postMultipleServicesCategory(msCategory);
    return dispatch({
      type: MultipleServicesCategoryTypes.POST_MULTIPLESERVICESCATEGORY_SUCCESS,
      payload: {
        multipleServicesCategory: msCategoryRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesCategoryTypes.POST_MULTIPLESERVICESCATEGORY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putMultipleServicesCategory = (msCategory: MultipleServicesCategoryPut) => async (dispatch: (state: MultipleServicesCategoryAction) => MultipleServicesCategoryAction) => {
  dispatch({
    type: MultipleServicesCategoryTypes.PUT_MULTIPLESERVICESCATEGORY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesCategoryProcess = new MultipleServicesCategoryProcess();
    // dont'n import the result of the process, only that dont't have error
    const msCategoryRes = await multipleServicesCategoryProcess.putMultipleServicesCategory(msCategory);
    return dispatch({
      type: MultipleServicesCategoryTypes.PUT_MULTIPLESERVICESCATEGORY_SUCCESS,
      payload: {
        multipleServicesCategory: msCategoryRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesCategoryTypes.PUT_MULTIPLESERVICESCATEGORY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteMultipleServicesCategory = (id_category: number) => async (dispatch: (state: MultipleServicesCategoryAction) => MultipleServicesCategoryAction) => {
  dispatch({
    type: MultipleServicesCategoryTypes.DELETE_MULTIPLESERVICESCATEGORY_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesCategoryProcess = new MultipleServicesCategoryProcess();
    // dont'n import the result of the process, only that dont't have error
    await multipleServicesCategoryProcess.deleteMultipleServicesCategory(id_category);
    return dispatch({
      type: MultipleServicesCategoryTypes.DELETE_MULTIPLESERVICESCATEGORY_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesCategoryTypes.DELETE_MULTIPLESERVICESCATEGORY_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};