import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getHotelBedroom, getHotelReservation, getHotelReservationPags } from '../../redux/actions';
import { HotelReservationFilters, HotelReservationGet, User } from '../../redux/interfaces';
// import xlsx
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { RootState } from "../../redux/store";
import { hotelPaymentMethods } from "../../utils/hotelPaymentMethods";
import useForm from "../../utils/hooks/useForm";
import Swal from "sweetalert2";
import { hotelBedroomStatus } from "../../utils/hotelBedroomStatus";
import { TableBody } from "../../components";
import { InputDateRange } from "../../components/InputDateRange";
import { useTableMemo } from "../../utils/hooks/useTableMemo";

export const HotelHistoryServicePage = () => {
    const dispatch = useDispatch();
    const [filterState, setFilterState] = useState('');
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const { listHotelBedroom } = useSelector((state: RootState) => state.hotelBedroom);
    const { pagHotelReservation, loading } = useSelector((state: RootState) => state.hotelReservation);
    const {
        results: hotelReservations,
        count,
        next,
        previous,
    } = pagHotelReservation as HotelReservationGet;
    // pagination
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(4);
    // new Date(), new Date() + one day
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(new Date().getTime() - 60 * 60 * 24 * 1000 * 7), new Date()]);
    const [startDate, endDate] = dateRange;
    // status values
    const statusCellOptions: {
        [key: string]: {
            label: string;
            color: string;
        };
    } = {
        PENDING: {
            label: 'Pendiente',
            color: 'yellow',
        },
        BY_CONFIRMED: {
            label: 'Por Confirmar',
            color: 'orange',
        },
        CONFIRMED: {
            label: 'Pendiente Check-In',
            color: 'green',
        },
        CHECKING: {
            label: 'Habitación Ocupada',
            color: 'violet',
        },
        FINISHED: {
            label: 'Check-out Completo',
            color: 'gray',
        },
        CANCELED: {
            label: 'Cancelado',
            color: 'red',
        },
    };

    const { hotelBedroomId, handleOnChangeInput } = useForm({
        hotelBedroomId: '',
    })

    useEffect(() => {
        const filters: HotelReservationFilters = {
            page: pageCurrent,
            page_size: pageSize,
            hotel_bedroom_id: parseInt(hotelBedroomId),
            status: filterState,
            start_date__gte: '',
            start_date__lte: '',
            hotel_bedroom__hotel__campus_id: user.campus.id
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['start_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        getHotelReservationPags(filters)(dispatch);
    }, [pageCurrent, pageSize, filterState, hotelBedroomId, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getHotelBedroom({
            campus_id: user.campus.id
        })(dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type MultipleServicesReservationType = {
        start_date: string;
        first_name: string;
        administrative_name: string;
        total_cost: string;
        payment_method: string;
        people_total: string;
        end_date: string;
        status: string;
        description: string;
        voucher: any;
    };

    const { data, columns } = useTableMemo<MultipleServicesReservationType>({
        dataMemo: {
            factory: hotelReservations.map((reservation) => {
                return {
                    start_date: reservation.start_date,
                    first_name: reservation.user.first_name,
                    administrative_name: reservation.hotel_bedroom.administrative_name,
                    total_cost: `$${reservation.total_cost}`,
                    payment_method: hotelPaymentMethods[reservation.payment_method],
                    people_total: `${reservation.member_number > 0 ? reservation.member_number + ' socios, ' : ''} ${reservation.family_number > 0 ? reservation.family_number + ' dependientes, ' : ''} ${reservation.guest_number > 0 ? reservation.guest_number + ' invitados' : ''}`,
                    end_date: reservation.end_date,
                    status: reservation.status,
                    description: reservation.description,
                    voucher: reservation.wire_transfers.length > 0 ? reservation.wire_transfers[0].receipt : '',
                };
            }),
            deps: [hotelReservations]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Entrada',
                    accessor: 'start_date', // accessor is the "key" in the data
                },
                {
                    Header: 'Cliente',
                    accessor: 'first_name',
                },
                {
                    Header: 'Nombre administrativo',
                    accessor: 'administrative_name',
                },
                {
                    Header: 'Tarifa',
                    accessor: 'total_cost',
                },
                {
                    Header: 'Forma de Pago',
                    accessor: 'payment_method',
                },
                {
                    Header: 'Ocupantes',
                    accessor: 'people_total',
                },
                {
                    Header: 'Salida',
                    accessor: 'end_date',
                },
                {
                    Header: 'Estado',
                    accessor: 'status',
                },
                {
                    Header: 'Nota',
                    accessor: 'description',
                },
                {
                    Header: 'Comprobante',
                    accessor: 'voucher',
                },
            ],
            deps: []
        }
    })

    const handleCreateDownloadReport = async () => {
        const filters: HotelReservationFilters = {
            page_size: count,
            hotel_bedroom_id: parseInt(hotelBedroomId),
            status: filterState,
            start_date__gte: '',
            start_date__lte: '',
            hotel_bedroom__hotel__campus_id: user.campus.id
        }
        if (startDate && endDate) {
            filters['start_date__gte'] = new Date(startDate.setHours(0, 0, 0)).toISOString();
            filters['start_date__lte'] = new Date(endDate.setHours(23, 59, 59)).toISOString();
        }
        const { payload } = await getHotelReservation(filters)(dispatch);
        const listHotelReservation = payload.listHotelReservation || [];
        const data_excel = listHotelReservation.map(reservation => ([
            new Date(reservation.start_date).toLocaleDateString(),
            new Date(reservation.start_date).toLocaleTimeString(),
            reservation.user.first_name,
            reservation.hotel_bedroom.administrative_name,
            reservation.total_cost,
            hotelPaymentMethods[reservation.payment_method],
            reservation.member_number,
            reservation.family_number,
            reservation.guest_number,
            new Date(reservation.end_date).toLocaleDateString(),
            new Date(reservation.end_date).toLocaleTimeString(),
            statusCellOptions[reservation.status].label,
        ]));
        const columns_titles = ['Fecha Entrada', 'Hora Entrada', 'Cliente', 'Nombre Administrativo', 'Tarifa', 'Forma de Pago', 'Número Socios', 'Número Dependientes', 'Número Invitados', 'Fecha Salida', 'Hora Salida', 'Estado'];
        // Code for excel download
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Reporte de servicios',
            Subject: 'Reporte de servicios',
            Author: 'Círculo Militar',
            CreatedDate: new Date(Date.now()),
        };
        wb.SheetNames.push('Reporte de servicios');
        const ws = XLSX.utils.json_to_sheet(data_excel);
        wb.Sheets['Reporte de servicios'] = ws;
        // add columns width
        ws['!cols'] = [
            { wch: 15 },
            { wch: 15 },
            { wch: 40 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
        ];
        // add columns title
        columns_titles.forEach((title, index) => {
            ws[`${String.fromCharCode(65 + index)}1`] = {
                v: title,
                t: 's',
                font: {
                    sz: 14,
                    bold: true,
                },
            };
        });


        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        const s2ab = (s: any) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_REPORTE_RESERVAS_HABITACIONES.xlsx`);
    }

    return (
        <>
            <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
                <div className="flex flex-row items-center justify-end gap-4 print:hidden">
                    Descargar reporte
                    <button
                        onClick={handleCreateDownloadReport}
                        className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                <select
                    id="hotelBedroomId"
                    name="hotelBedroomId"
                    value={hotelBedroomId}
                    onChange={handleOnChangeInput}
                    required
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Habitaciones (Todas)</option>
                    {
                        listHotelBedroom && listHotelBedroom.map((bedroom) => (
                            <option key={`bedroom-${bedroom.id}`} value={bedroom.id}>{bedroom.name}</option>
                        ))
                    }
                </select>
                <div className="flex items-center">
                    <InputDateRange
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        className="h-11 font-bold"
                    />
                </div>
                <select
                    id="campusFrecuency"
                    name="campusFrecuency"
                    value={filterState}
                    onChange={(e) => setFilterState(e.target.value)}
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Estados (Todos)</option>
                    {
                        hotelBedroomStatus.map((status) => (
                            <option value={status.value}>{status.label}</option>
                        ))
                    }
                </select>
            </div>
            <div className="overflow-hidden">
                <TableBody
                    columns={columns} data={data} pageSize={pageSize} loading={loading}
                    renderRows={row => (
                        // Apply the row props
                        <tr
                            className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                            {...row.getRowProps()}
                        >
                            {
                                // Loop over the rows cells
                                row.cells.map((cell) => {
                                    if (cell.column.Header === 'Entrada') {
                                        const dateCell = new Date(
                                            (cell.render('Cell') as any).props.value,
                                        ).toLocaleDateString('es-ES', {
                                            weekday: 'long',
                                            day: 'numeric',
                                            month: 'long',
                                        });
                                        return (
                                            <td
                                                className="flex flex-row items-center gap-4 px-6 py-4"
                                                {...cell.getCellProps()}
                                            >
                                                <span className="first-letter:capitalize">{dateCell}</span>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Salida') {
                                        const dateCell = new Date(
                                            (cell.render('Cell') as any).props.value,
                                        ).toLocaleDateString('en-GB', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                        });
                                        return (
                                            <td
                                                className="flex flex-row items-center gap-2 px-6 py-4"
                                                {...cell.getCellProps()}
                                            >
                                                {dateCell}
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Estado') {
                                        const statusCell = statusCellOptions[(cell.render('Cell') as any).props.value];
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <span className={`inline-flex items-center px-3 py-1 font-semibold leading-5 text-${statusCell.color}-500`}>
                                                    {statusCell.label}
                                                </span>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Nota') {
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <button onClick={() => {
                                                    Swal.fire({
                                                        title: 'Nota',
                                                        text: (cell.render('Cell') as any).props.value,
                                                    })
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Comprobante') {
                                        return (
                                            <td
                                                className="px-6 py-4 text-left text-black"
                                                {...cell.getCellProps()}
                                            >
                                                <button className="underline underline-yellow-800" onClick={() => {
                                                    Swal.fire({
                                                        imageUrl: (cell.render('Cell') as any).props.value,
                                                        imageWidth: 400,
                                                        imageHeight: 400,
                                                        imageAlt: 'Custom image',
                                                    })
                                                }}>
                                                    {(cell.render('Cell') as any).props.value === '' ? '' : 'Ver comprobante'}
                                                </button>
                                            </td>
                                        );
                                    }

                                    // Apply the cell props
                                    return (
                                        <td
                                            className="whitespace-nowrap px-6 py-3"
                                            {...cell.getCellProps()}
                                        >
                                            {
                                                // Render the cell contents
                                                cell.render('Cell')
                                            }
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    )}
                    // For footer
                    count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
                />
            </div>
        </>
    );
};
