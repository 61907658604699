// import { useNavigate } from "react-router-dom";
import { deleteCampusChatbot, getCampusChatbot, postCampusChatbot } from "../../redux/actions";
import { CampusChatbotPost, User } from "../../redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FormEvent, useEffect, useState } from "react";
import img404 from "../../assets/images/undraw_house_searching.svg";
import alerts from "../../utils/alerts";
import { toast } from "react-toastify";

interface IValidations {
  type: string;
}

interface IInput {
  type: 'input' | 'email';
  name: string;
  label: string;
  placeholder: string;
  value: string;
  validations?: IValidations[];
  children: Array<IInput>;
  // extras
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  meta?: any;
}

interface CampusChatbotTable {
  id?: number;
  name?: string;
  is_deleted: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  chatbot_parent: number | null;
  ask: string;
  response: string;
  level: number;
  campus: number;
  chatbot_children: CampusChatbotTable[];
}

export const CampusChatbotPage = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [listCampusChatbot, setListCampusChatbot] = useState<CampusChatbotTable[]>([]);
  const [page, setPage] = useState(0);
  // data for recursion
  const [dataStructure, setDataStructure] = useState<IInput[]>([]);
  const [dataStructureRemoved, setDataStructureRemoved] = useState<number[]>([]);
  const colors: {[key: number]: {
    bg: string;
  }} = {
    1: {
      bg: 'bg-yellow-500',
    },
    2: {
      bg: 'bg-orange-500',
    },
    3: {
      bg: 'bg-black',
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await getCampusChatbot({
        campus_id: user.campus.id,
      })(dispatch);
      setListCampusChatbot(payload.listCampusChatbot as CampusChatbotTable[]);
      // const listCampusChatbot = payload.listCampusChatbot as CampusChatbotTable[];
      // const obj = generatorJSX(listCampusChatbot);

      // setDataStructure([...obj]);
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (listCampusChatbot.length === 0) return;
    const obj = generatorFieldStructure([listCampusChatbot[page]]);
    setDataStructure([...obj]);
  }, [listCampusChatbot, page]); // eslint-disable-line


  const generatorFieldStructure = (dataStructure: CampusChatbotTable[]) => {
    if (dataStructure === undefined) return dataStructure;
    const obj: IInput[] = [];
    for (const level1 of dataStructure) {
      if (!level1) continue; // this only for react hot reload
      const nameAsk = 'ask_'+(+new Date() * Math.random()).toString(36).substring(0,7);
      const nameAnswer = 'response_'+(+new Date() * Math.random()).toString(36).substring(0,7);
      obj.push({
        type: 'input',
        label: '',
        name: nameAsk,
        placeholder: 'Pregunta',
        value: level1?.ask || '',
        children: [],
        // extras
        className: 'bg-yellow-100',
        containerClassName: 'w-full col-start-2',
        meta: {
          typeData: 'ask',
          sibling: nameAnswer,
          id: level1?.id || null,
          name: level1?.name || null,
        }
      });
      obj.push({
        type: 'input',
        label: '',
        name: nameAnswer,
        placeholder: 'Respuesta',
        value: level1?.response || '',
        children: generatorFieldStructure(level1.chatbot_children),
        className: 'bg-gray-100',
        containerClassName: 'w-full col-start-2',
        meta: {
          typeData: 'answer',
          sibling: nameAsk,
          id: level1?.id || null,
          name: level1?.name || null,
        }
      });
    }
    return obj;
  }

  // update value of a field with recursion
  const updateField = (dataStructure: IInput[], input: IInput, value: string): IInput[] => {
    const newDataStructure: IInput[] = [];
    for (const data of dataStructure) {
      if (data.name === input.name) {
        newDataStructure.push({
          ...data,
          value
        });
      } else {
        newDataStructure.push({
          ...data,
          children: updateField(data.children, input, value)
        });
      }
    }
    return newDataStructure;
  }

  // add a item with recursion into listCampusChatbot
  const addItemChatbot = (listCampusChatbot: CampusChatbotTable[], item: IInput, levelItem: number): CampusChatbotTable[] => {
    const newListCampusChatbot: CampusChatbotTable[] = [];
    for (const data of listCampusChatbot) {
      if ((data.id === item.meta.id) || (data.name === item.meta.name)) {
        newListCampusChatbot.push({
          ...data,
          chatbot_children: [
            ...data.chatbot_children,
            {
              name: 'chat_'+(+new Date() * Math.random()).toString(36).substring(0,7),
              ask: '',
              response: '',
              chatbot_parent: data.id as number,
              chatbot_children: [],
              level: levelItem + 1,
              is_deleted: false,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              deleted_at: null,
              campus: user.campus.id,
            }
          ]
        });
      } else {
        newListCampusChatbot.push({
          ...data,
          chatbot_children: data.chatbot_children.length > 0 ? addItemChatbot(data.chatbot_children, item, levelItem) : []
        });
      }
    }
    return newListCampusChatbot;
  }

  // use filter for remove a item with recursion into listCampusChatbot
  const removeItemChatbot = (listCampusChatbot: CampusChatbotTable[], item: IInput): CampusChatbotTable[] => {
    const newListCampusChatbot: CampusChatbotTable[] = [];
    for (const data of listCampusChatbot) {
      if ((data.id === item.meta.id) || (data.name === item.meta.name)) {
        continue;
      } else {
        newListCampusChatbot.push({
          ...data,
          chatbot_children: data.chatbot_children.length > 0 ? removeItemChatbot(data.chatbot_children, item) : []
        });
      }
    }
    return newListCampusChatbot;
  }

  const updateItemChatbot = (listCampusChatbot: CampusChatbotTable[], item: IInput, value: string): CampusChatbotTable[] => {
    const newListCampusChatbot: CampusChatbotTable[] = [];
    for (const data of listCampusChatbot) {
      if ((data.id === item.meta.id) || (data.name === item.meta.name)) {
        const chatbotRow: CampusChatbotTable = {
          ...data,
          chatbot_children: data.chatbot_children.length > 0 ? updateItemChatbot(data.chatbot_children, item, value) : []
        }
        if (item.meta.typeData === 'ask') {
          chatbotRow.ask = value;
        } else {
          chatbotRow.response = value;
        }
        newListCampusChatbot.push(chatbotRow);
      } else {
        newListCampusChatbot.push({
          ...data,
          chatbot_children: data.chatbot_children.length > 0 ? updateItemChatbot(data.chatbot_children, item, value) : []
        });
      }
    }
    return newListCampusChatbot;
  }

  const handleRemoveFirstLevelChatbot = async () => {
    const { isConfirmed } = await alerts.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar esta pregunta y todas sus sub-preguntas. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      page === listCampusChatbot.length - 1 && setPage(listCampusChatbot.length - 2);
      setListCampusChatbot(state => removeItemChatbot(state, dataStructure[0]));
      !!dataStructure[0].meta.id && setDataStructureRemoved(state => [...state, dataStructure[0].meta.id]);
    }
  }

  const fieldsGenerator = (data: IInput[], level: number): any => (
    data.map((input: IInput, i) => {
      const { type, name, label, value, ...props } = input;
      return (
        <div key={`${name}-${i}`}>
          {
            (type === 'input' || type === 'email') && (
              <div className={`grid ${level > 1 ? 'grid-cols-[20px_minmax(0,1fr)_40px]' : 'grid-cols-[20px_minmax(0,1fr)]'} items-center gap-3`}>
                <div
                  className={props.meta?.typeData === 'ask' ? `${colors[level]?.bg || 'bg-black'} h-5 w-5 [clip-path:_polygon(100%_50%,_0_0,_0_100%)] inline-block` : 'hidden'}
                ></div>
                <div className={`flex flex-col gap-1 ${(props as IInput).containerClassName} ? (props as IInput).containerClassName} : '' }`}>
                    <input
                      className={`input-yellow ${(props as IInput).className ? (props as IInput).className : ''}`}
                      type="input"
                      name={name}
                      placeholder={(props as IInput).placeholder}
                      value={value}
                      onChange={e => setDataStructure(updateField(dataStructure, input, e.target.value))}
                      onBlur={e => setListCampusChatbot(state => updateItemChatbot(state, input, e.target.value))}
                    />
                </div>
                {
                  level > 1 && props.meta?.typeData === 'ask' && (
                    <button
                      onClick={() => {
                        setListCampusChatbot(state => removeItemChatbot(state, input));
                        !!input.meta.id && setDataStructureRemoved(state => [...state, input.meta.id]);
                      }}
                      type="button"
                      className="rounded-full p-2.5 text-black hover:bg-red-50 active:bg-red-300 col-start-3"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )
                }
                <div className={props.children.length > 0 ? `col-start-2 col-end-4 flex flex-col gap-4`: 'hidden'}>
                  {
                      props.children.length > 0 && fieldsGenerator(props.children, level + 1)
                  }
                </div>
                <div className={
                    props.meta?.typeData === 'answer' ? `col-start-2 col-end-3 flex flex-col gap-4`: 'hidden'}>
                  {
                      level < 3 &&
                      props.meta?.typeData === 'answer' &&
                        <div
                          className="col-start-2 flex flex-row items-center gap-4"
                        >
                          <div
                            className={`${colors[level + 1]?.bg || 'bg-black'} h-5 w-5 [clip-path:_polygon(100%_50%,_0_0,_0_100%)] inline-block`}
                          ></div>
                          <button
                            // add new field
                            onClick={() => setListCampusChatbot(state => addItemChatbot(state, input, level))}
                            type="button"
                            className="btn-gray rounded-full p-2"
                            disabled={level >= 3}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                          </button>
                          <span className={level >= 3 ? 'text-gray-500' : 'text-black'}>Añadir una sub-pregunta</span>
                        </div>
                  }
                </div>
              </div>
            )
          }
        </div>
      );
    })
  )

  const chatbotListToPost = (listCampusChatbot: CampusChatbotTable[]): CampusChatbotPost[] => {
    const listChatbotPost: CampusChatbotPost[] = [];
    for (const chatbot of listCampusChatbot) {
      listChatbotPost.push({
        id: !!chatbot.id ? chatbot.id : null,
        ask: chatbot.ask,
        campus: chatbot.campus,
        chatbot_parent: chatbot.chatbot_parent,
        children: chatbot.chatbot_children.length > 0 ? chatbotListToPost(chatbot.chatbot_children) : [],
        level: chatbot.level,
        response: chatbot.response,
      });
    }
    return listChatbotPost;
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    toast.info('Guardando...');
    const { payload } = await postCampusChatbot(chatbotListToPost(listCampusChatbot))(dispatch);

    if (!payload.success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }

    const { payload: { success }} = await deleteCampusChatbot(dataStructureRemoved)(dispatch);

    if (!success) {
      return toast.error(payload.error?.message, { type: 'error' });
    }

    toast.success(`Preguntas actualizadas con éxito.`);
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-6">
        <h1 className="text-gray-500 text-xl font-bold">Chatbot</h1>
        <div className="flex flex-row gap-2 items-center">
          {
            listCampusChatbot && listCampusChatbot.length > 0 &&
              listCampusChatbot.map((_, i) => (
                <button
                  key={`pag-${i}`}
                  onClick={() => setPage(i)}
                  className={page === i ? 'btn-yellow' : 'btn-gray'}
                >
                  {i+1}
                </button>
              ))
          }
          <button
            onClick={() => {
              setPage(listCampusChatbot.length);
              setListCampusChatbot((state): CampusChatbotTable[] => {
                return [
                  ...state,
                  {
                    name: 'chat_'+(+new Date() * Math.random()).toString(36).substring(0,7),
                    ask: '',
                    campus: user.campus.id,
                    chatbot_children: [],
                    chatbot_parent: null,
                    created_at: new Date().toISOString(),
                    deleted_at: null,
                    updated_at: new Date().toISOString(),
                    is_deleted: false,
                    level: 1,
                    response: '',
                  }
                ];
              });
            }}
            className={`btn-yellow`}
          >
            Añadir otra pregunta
          </button>
        </div>
      </div>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit}
      >
        {
          listCampusChatbot.length > 0 ? fieldsGenerator(dataStructure, 1) :
          (
            <div className="flex flex-col justify-center items-center gap-4 py-6">
              <img src={img404} alt="404" className="max-h-52 w-60" />
              <p className="text-gray-600 text-center font-bold text-2xl">Al parecer no tiene preguntas asignadas</p>
              <p className="text-gray-600 text-center text-xl">Añada una pregunta para empezar</p>
            </div>
          )
        }
        <div className="flex flex-row justify-end gap-4 mb-10">
          <button
            // remove first level of listCampusChatbot
            onClick={handleRemoveFirstLevelChatbot}
            type="button"
            className="btn-gray w-40 flex flex-row gap-2"
            disabled={listCampusChatbot.length === 0}
          >
            Eliminar
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
          <button
            type="submit"
            className="btn-yellow w-40"
            disabled={listCampusChatbot.length === 0}
          >
            Guardar
          </button>
        </div>
      </form>
    </>
  )
}