import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteRestaurantDishes, getRestaurantCategories } from "../../redux/actions";
import { RestaurantDishesTableReduced, RestaurantTable } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import alerts from "../../utils/alerts";

export const RestaurantMenuPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { restaurant } = state as { restaurant: RestaurantTable };
  const { listRestaurantCategories } = useSelector((state: RootState) => state.restaurantCategories);

  // Auxiliar functions
  const fetchData = async () => {
    getRestaurantCategories({
      restaurant_id: restaurant.id
    })(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteDish = async (dish: RestaurantDishesTableReduced) => {
    const { isConfirmed } = await alerts.warning({
      title: 'Última oportunidad',
      text: `Está apunto de eliminar <b>"${dish.product_name}"</b>. Una vez realizada esta acción no se podrá revertir.`,
    });

    if (isConfirmed) {
      const { payload } = await deleteRestaurantDishes(dish.id)(dispatch);
      const { success } = payload;
      if (success) {
        toast.success(`${dish.product_name} eliminado con éxito.`);
        fetchData();
      } else {
        toast.error(payload.error?.message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <div
        className="flex flex-row justify-between"
      >
      <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      <div className="flex flex-row gap-2">
        <button
          onClick={() =>
            navigate('/restaurant/menu/new', {
              state: {
                restaurant
              },
            })
          }
          className="btn-gray p-2 mb-4"
        >
          Agregar al menú
        </button>
        <button
          onClick={() =>
            navigate('/restaurant/category/new', {
              state: {
                restaurant
              },
            })
          }
          className="btn-yellow p-2 mb-4"
        >
          Categorías
        </button>
      </div>

      </div>

      <div className="pb-16">
        <h2 className="mt-6 font-bold text-gray-500 text-lg mb-6">Restaurante {restaurant.name}</h2>
        {(listRestaurantCategories &&
          listRestaurantCategories.length > 0) ?
          listRestaurantCategories.map(({ name, dish_restaurant_category: dishes }, i) => (
            <div key={`multiple-service-category-${i}`} className="mt-6">
              <h3 className="grid grid-cols-[minmax(0,1fr)_150px_120px] text-sm font-bold text-gray-500">
                <span className="basis-[73%]">{name}</span>
              </h3>
              <div className="mt-4 divide-y divide-gray-500/25">
                {dishes.length === 0 ? (
                  <div className="bg-gray-50 px-4">
                    <span className="font-bold text-black">No hay platos en su menú</span>
                  </div>
                ) : (
                  dishes.map((dish, i) => (
                    <div
                      key={`multiple-service-${i}`}
                      className="grid grid-cols-[minmax(0,1fr)_150px_120px] items-center justify-between odd:bg-gray-50 even:bg-white even:hover:bg-gray-50"
                    >
                      <span className="basis-3/5 px-4 font-bold">{dish.product_name}</span>
                      <span>${dish.price}</span>
                      <span className="mr-6 flex flex-row gap-1">
                        <button
                          onClick={() =>
                            navigate('/restaurant/menu/edit', {
                              state: {
                                restaurant,
                                dish
                              },
                            })
                          }
                          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-700"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteDish(dish)}
                          className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-700"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                  ))
                )}
              </div>
            </div>
          )) : (
            <div className="bg-gray-50 px-4">
              <span className="font-bold text-black">No hay categorías disponibles</span>
            </div>
          )}
      </div>
    </>
  )
}