export enum HotelReservationTypes {
  GET_HOTELRESERVATIONPAG_LOADING = "GET_HOTELRESERVATIONPAG_LOADING",
  GET_HOTELRESERVATIONPAG_SUCCESS = "GET_HOTELRESERVATIONPAG_SUCCESS",
  GET_HOTELRESERVATIONPAG_FAILURE = "GET_HOTELRESERVATIONPAG_FAILURE",
  GET_HOTELRESERVATION_LOADING = "GET_HOTELRESERVATION_LOADING",
  GET_HOTELRESERVATION_SUCCESS = "GET_HOTELRESERVATION_SUCCESS",
  GET_HOTELRESERVATION_FAILURE = "GET_HOTELRESERVATION_FAILURE",
  GETONLY_HOTELRESERVATION_LOADING = "GETONLY_HOTELRESERVATION_LOADING",
  GETONLY_HOTELRESERVATION_SUCCESS = "GETONLY_HOTELRESERVATION_SUCCESS",
  GETONLY_HOTELRESERVATION_FAILURE = "GETONLY_HOTELRESERVATION_FAILURE",
  POST_HOTELRESERVATION_LOADING = "POST_HOTELRESERVATION_LOADING",
  POST_HOTELRESERVATION_SUCCESS = "POST_HOTELRESERVATION_SUCCESS",
  POST_HOTELRESERVATION_FAILURE = "POST_HOTELRESERVATION_FAILURE",
  PUT_HOTELRESERVATION_LOADING = "PUT_HOTELRESERVATION_LOADING",
  PUT_HOTELRESERVATION_SUCCESS = "PUT_HOTELRESERVATION_SUCCESS",
  PUT_HOTELRESERVATION_FAILURE = "PUT_HOTELRESERVATION_FAILURE",
  DELETE_HOTELRESERVATION_LOADING = "DELETE_HOTELRESERVATION_LOADING",
  DELETE_HOTELRESERVATION_SUCCESS = "DELETE_HOTELRESERVATION_SUCCESS",
  DELETE_HOTELRESERVATION_FAILURE = "DELETE_HOTELRESERVATION_FAILURE",
}