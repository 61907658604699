import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TableBody } from "../../components";
import { deleteCampusNews, getCampusNewsPags, putCampusNews } from "../../redux/actions";
import { CampusNewsFilters, CampusNewsGet, CampusNewsPut, CampusNewsTable, User } from "../../redux/interfaces";
import { RootState } from "../../redux/store";
import alerts from "../../utils/alerts";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
const removeMd = require('remove-markdown');

export const CampusNewsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const { pagCampusNews, loading } = useSelector((state: RootState) => state.campusNews);
    const {
        results: news,
        count,
        next,
        previous,
    } = pagCampusNews as CampusNewsGet;
    // Code for table
    type CampusNewsType = {
        administrator: string;
        news: string;
        state: any;
        actions: any;
    };
    // pagination
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(4);

    const fetchData = () => {
        const filters: CampusNewsFilters = {
            page: pageCurrent,
            page_size: pageSize,
            start_date__gte: '',
            start_date__lte: '',
            campus_id: user.campus.id
        }
        getCampusNewsPags(filters)(dispatch);
    }

    // fetch data with filters
    useEffect(() => {
        fetchData();
    }, [pageCurrent, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

    const { data, columns } = useTableMemo<CampusNewsType>({
        dataMemo: {
            factory: news.map((cNews) => ({
                administrator: cNews.user.first_name,
                news: cNews.description,
                state: cNews,
                actions: cNews,
            })),
            deps: [news]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Administrador',
                    accessor: 'administrator',
                },
                {
                    Header: 'Noticia',
                    accessor: 'news',
                },
                {
                    Header: 'Estado',
                    accessor: 'state',
                },
                {
                    Header: 'Acciones',
                    accessor: 'actions',
                },
            ],
            deps: []
        }
    });

    const handleUpdateState = async (state: string, news: CampusNewsTable) => {
        const formData = new FormData();
        const formDataFields: CampusNewsPut = {
            id: news.id,
            title: news.title,
            description: news.description,
            campus_id: news.campus.id.toString(),
            image_url: news.image_url,
            user_id: news.user.id.toString(),
            status: state,
        };

        Object.entries(formDataFields).forEach(([key, value]) => {
            formData.append(key, value);
        });

        const { payload } = await putCampusNews(formData)(dispatch);
        if (!payload.success) {
            return toast.error(payload.error?.message, { type: 'error' });
        }
        toast.success(`Estado actualizado con éxito.`);
        fetchData();
    }

    const handleDeleteNews = async (news: CampusNewsTable) => {
        const { isConfirmed } = await alerts.warning({
            title: 'Última oportunidad',
            text: `Está apunto de eliminar <b>"${news.title}"</b>. Una vez realizada esta acción no se podrá revertir.`,
        });

        if (isConfirmed) {
            const { payload } = await deleteCampusNews(news.id)(dispatch);
            const { success } = payload;
            if (success) {
                toast.success('Noticia eliminada con éxito.');
                fetchData();
            } else {
                toast.error(payload.error?.message, { type: 'error' });
            }
        }
    };

  return (
    <>
        <div className="flex flex-row justify-between mb-8">
            <h1 className="text-gray-500 font-bold text-lg">Noticias</h1>
            <button
                onClick={() =>
                    navigate('/campus/news/new')
                }
                className="btn-yellow flex flex-row items-center gap-2 h-11"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Añadir nueva noticia
            </button>
        </div>
        <TableBody
            columns={columns} data={data} pageSize={pageSize} loading={loading}
            renderRows={row => (
                <tr
                    className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                    {...row.getRowProps()}
                >
                    {
                        row.cells.map((cell) => {
                            if (cell.column.Header === 'Administrador') {
                                return (
                                    <td
                                        className="px-6 py-4"
                                        {...cell.getCellProps()}
                                    >
                                        <div className="flex flex-row items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                            </svg>
                                            {cell.render('Cell')}
                                        </div>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Noticia') {
                                const description = (cell.render('Cell') as any).props.value;
                                return (
                                    <td
                                        className="px-6 py-4"
                                        {...cell.getCellProps()}
                                    >
                                        <div className="truncate max-w-xs">
                                            {removeMd(description)}
                                        </div>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Estado') {
                                const news: CampusNewsTable = (cell.render('Cell') as any).props.value;
                                // two buttons with taildwind
                                return (
                                    <td
                                        className="px-6 py-4 text-left text-black"
                                        {...cell.getCellProps()}
                                    >
                                        <select
                                            id="status"
                                            name="status"
                                            value={news.status}
                                            onChange={(e) => handleUpdateState(e.target.value, news)}
                                            required
                                            className="select-yellow text-sm h-9"
                                        >
                                            <option value="" disabled>Seleccione un estado</option>
                                            <option value="PUBLISHED">Publicado</option>
                                            <option value="SAVED">Guardado</option>
                                        </select>
                                    </td>
                                );
                            }

                            if (cell.column.Header === 'Acciones') {
                                const news: CampusNewsTable = (cell.render('Cell') as any).props.value;
                                // two buttons with taildwind
                                return (
                                    <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                        <div className="flex flex-row justify-start gap-2">
                                        <button
                                        onClick={() =>
                                            navigate('/campus/news/edit', {
                                            state: {
                                                news
                                            },
                                            })
                                        }
                                        className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-700"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                        </svg>
                                        </button>
                                        <button
                                        onClick={() => handleDeleteNews(news)}
                                        className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-700"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                        </button>
                                        </div>
                                    </td>
                                );
                            }

                            return (
                                <td
                                    className="whitespace-nowrap px-6 py-3"
                                    {...cell.getCellProps()}
                                >
                                    <span className="inline-block first-letter:capitalize">{cell.render('Cell')}</span>
                                </td>
                            );
                        })
                    }
                </tr>
            )}
            // blank message
            blankMessage="No hay noticias que mostrar"
            // For footer
            count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
        />
    </>
  )
}