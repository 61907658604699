
import { CampusChatbotRepository } from "../repositories";
import { CampusChatbotFilters, CampusChatbotGet, CampusChatbotPost, CampusChatbotPut, CampusChatbotTable } from "../../interfaces";

export class CampusChatbotProcess {
  private campusChatbotRepository: CampusChatbotRepository;

  constructor() {
    this.campusChatbotRepository = new CampusChatbotRepository();
  }

  async getCampusChatbot(filters?: CampusChatbotFilters): Promise<CampusChatbotGet> {
    const response = await this.campusChatbotRepository.getCampusChatbot(filters);
    return response.data;
  }

  async postCampusChatbot(campusChatbot: CampusChatbotPost[]): Promise<CampusChatbotTable> {
    const response = await this.campusChatbotRepository.postCampusChatbot(campusChatbot);
    return response.data;
  }

  async putCampusChatbot(campusChatbot: CampusChatbotPut): Promise<CampusChatbotTable> {
    const response = await this.campusChatbotRepository.putCampusChatbot(campusChatbot);
    return response.data;
  }

  // dont response data
  async deleteCampusChatbot(ids: number[]): Promise<any> {
    const response = await this.campusChatbotRepository.deleteCampusChatbot(ids);
    return response.data;
  }
}
