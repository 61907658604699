
export enum RestaurantTypes {
  GET_RESTAURANTPAG_LOADING = "GET_RESTAURANTPAG_LOADING",
  GET_RESTAURANTPAG_SUCCESS = "GET_RESTAURANTPAG_SUCCESS",
  GET_RESTAURANTPAG_FAILURE = "GET_RESTAURANTPAG_FAILURE",
  GET_RESTAURANT_LOADING = "GET_RESTAURANT_LOADING",
  GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_FAILURE = "GET_RESTAURANT_FAILURE",
  GETONLY_RESTAURANT_LOADING = "GETONLY_RESTAURANT_LOADING",
  GETONLY_RESTAURANT_SUCCESS = "GETONLY_RESTAURANT_SUCCESS",
  GETONLY_RESTAURANT_FAILURE = "GETONLY_RESTAURANT_FAILURE",
  POST_RESTAURANT_LOADING = "POST_RESTAURANT_LOADING",
  POST_RESTAURANT_SUCCESS = "POST_RESTAURANT_SUCCESS",
  POST_RESTAURANT_FAILURE = "POST_RESTAURANT_FAILURE",
  PUT_RESTAURANT_LOADING = "PUT_RESTAURANT_LOADING",
  PUT_RESTAURANT_SUCCESS = "PUT_RESTAURANT_SUCCESS",
  PUT_RESTAURANT_FAILURE = "PUT_RESTAURANT_FAILURE",
  DELETE_RESTAURANT_LOADING = "DELETE_RESTAURANT_LOADING",
  DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS",
  DELETE_RESTAURANT_FAILURE = "DELETE_RESTAURANT_FAILURE",
}