export enum HotelTypes {
  GET_HOTELPAG_LOADING = "GET_HOTELPAG_LOADING",
  GET_HOTELPAG_SUCCESS = "GET_HOTELPAG_SUCCESS",
  GET_HOTELPAG_FAILURE = "GET_HOTELPAG_FAILURE",
  GET_HOTEL_LOADING = "GET_HOTEL_LOADING",
  GET_HOTEL_SUCCESS = "GET_HOTEL_SUCCESS",
  GET_HOTEL_FAILURE = "GET_HOTEL_FAILURE",
  GETONLY_HOTEL_LOADING = "GETONLY_HOTEL_LOADING",
  GETONLY_HOTEL_SUCCESS = "GETONLY_HOTEL_SUCCESS",
  GETONLY_HOTEL_FAILURE = "GETONLY_HOTEL_FAILURE",
  POST_HOTEL_LOADING = "POST_HOTEL_LOADING",
  POST_HOTEL_SUCCESS = "POST_HOTEL_SUCCESS",
  POST_HOTEL_FAILURE = "POST_HOTEL_FAILURE",
  PUT_HOTEL_LOADING = "PUT_HOTEL_LOADING",
  PUT_HOTEL_SUCCESS = "PUT_HOTEL_SUCCESS",
  PUT_HOTEL_FAILURE = "PUT_HOTEL_FAILURE",
  DELETE_HOTEL_LOADING = "DELETE_HOTEL_LOADING",
  DELETE_HOTEL_SUCCESS = "DELETE_HOTEL_SUCCESS",
  DELETE_HOTEL_FAILURE = "DELETE_HOTEL_FAILURE",
}