import { HotelOccupancyPaymentRepository } from "../repositories";
import { HotelOccupancyPaymentFilters, HotelOccupancyPaymentGet, HotelOccupancyPaymentPost, HotelOccupancyPaymentPut, HotelOccupancyPaymentTable } from "../../interfaces";

export class HotelOccupancyPaymentProcess {
  private multipleServiceReservationRepository: HotelOccupancyPaymentRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelOccupancyPaymentRepository();
  }

  async getHotelOccupancyPayment(filters?: HotelOccupancyPaymentFilters): Promise<HotelOccupancyPaymentGet> {
    const response = await this.multipleServiceReservationRepository.getHotelOccupancyPayment(filters);
    return response.data;
  }

  async postHotelOccupancyPayment(hotelOccupancyPayment: HotelOccupancyPaymentPost): Promise<HotelOccupancyPaymentTable> {
    const response = await this.multipleServiceReservationRepository.postHotelOccupancyPayment(hotelOccupancyPayment);
    return response.data;
  }

  async putHotelOccupancyPayment(hotelOccupancyPayment: HotelOccupancyPaymentPut): Promise<HotelOccupancyPaymentTable> {
    const response = await this.multipleServiceReservationRepository.putHotelOccupancyPayment(hotelOccupancyPayment);
    return response.data;
  }

  // dont response data
  async deleteHotelOccupancyPayment(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotelOccupancyPayment(id);
    return response.data;
  }
}
