import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelBedroomFilters, HotelBedroomGet, HotelBedroomPost, HotelBedroomPut, HotelBedroomTable } from "../../interfaces";

export class HotelBedroomRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotelBedroom(filters?: HotelBedroomFilters): Promise<AxiosResponse<HotelBedroomGet>> {
    return this.lmBootApi.get<HotelBedroomGet>("/hotel-bedrooms/", { params: filters });
  }

  postHotelBedroom(hotelBedroom: HotelBedroomPost): Promise<AxiosResponse<HotelBedroomTable>> {
    return this.lmBootApi.post<HotelBedroomTable>("/hotel-bedrooms/", hotelBedroom);
  }

  putHotelBedroom(hotelBedroom: HotelBedroomPut): Promise<AxiosResponse<HotelBedroomTable>> {
    return this.lmBootApi.put<HotelBedroomTable>("/hotel-bedrooms/", hotelBedroom);
  }

  // dont response data
  deleteHotelBedroom(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/hotel-bedrooms/${id}/`);
  }
}
