
import { EventCoordinatorsRepository } from "../repositories";
import { EventCoordinatorsFilters, EventCoordinatorsGet, EventCoordinatorsPost, EventCoordinatorsPut, EventCoordinatorsTable } from "../../interfaces";

export class EventCoordinatorsProcess {
  private eventCoordinatorsRepository: EventCoordinatorsRepository;

  constructor() {
    this.eventCoordinatorsRepository = new EventCoordinatorsRepository();
  }

  async getEventCoordinators(filters?: EventCoordinatorsFilters): Promise<EventCoordinatorsGet> {
    const response = await this.eventCoordinatorsRepository.getEventCoordinators(filters);
    return response.data;
  }

  async postEventCoordinators(eventCoordinators: EventCoordinatorsPost): Promise<EventCoordinatorsTable> {
    const response = await this.eventCoordinatorsRepository.postEventCoordinators(eventCoordinators);
    return response.data;
  }

  async putEventCoordinators(eventCoordinators: EventCoordinatorsPut): Promise<EventCoordinatorsTable> {
    const response = await this.eventCoordinatorsRepository.putEventCoordinators(eventCoordinators);
    return response.data;
  }

  // dont response data
  async deleteEventCoordinators(id: number): Promise<any> {
    const response = await this.eventCoordinatorsRepository.deleteEventCoordinators(id);
    return response.data;
  }
}
