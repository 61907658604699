export enum MultipleServicesReservationTypes {
  GET_MULTIPLESERVICESRESERVATIONPAG_LOADING = "GET_MULTIPLESERVICESRESERVATIONPAG_LOADING",
  GET_MULTIPLESERVICESRESERVATIONPAG_SUCCESS = "GET_MULTIPLESERVICESRESERVATIONPAG_SUCCESS",
  GET_MULTIPLESERVICESRESERVATIONPAG_FAILURE = "GET_MULTIPLESERVICESRESERVATIONPAG_FAILURE",
  GET_MULTIPLESERVICESRESERVATION_LOADING = "GET_MULTIPLESERVICESRESERVATION_LOADING",
  GET_MULTIPLESERVICESRESERVATION_SUCCESS = "GET_MULTIPLESERVICESRESERVATION_SUCCESS",
  GET_MULTIPLESERVICESRESERVATION_FAILURE = "GET_MULTIPLESERVICESRESERVATION_FAILURE",
  GETONLY_MULTIPLESERVICESRESERVATION_LOADING = "GETONLY_MULTIPLESERVICESRESERVATION_LOADING",
  GETONLY_MULTIPLESERVICESRESERVATION_SUCCESS = "GETONLY_MULTIPLESERVICESRESERVATION_SUCCESS",
  GETONLY_MULTIPLESERVICESRESERVATION_FAILURE = "GETONLY_MULTIPLESERVICESRESERVATION_FAILURE",
  POST_MULTIPLESERVICESRESERVATION_LOADING = "POST_MULTIPLESERVICESRESERVATION_LOADING",
  POST_MULTIPLESERVICESRESERVATION_SUCCESS = "POST_MULTIPLESERVICESRESERVATION_SUCCESS",
  POST_MULTIPLESERVICESRESERVATION_FAILURE = "POST_MULTIPLESERVICESRESERVATION_FAILURE",
  PUT_MULTIPLESERVICESRESERVATION_LOADING = "PUT_MULTIPLESERVICESRESERVATION_LOADING",
  PUT_MULTIPLESERVICESRESERVATION_SUCCESS = "PUT_MULTIPLESERVICESRESERVATION_SUCCESS",
  PUT_MULTIPLESERVICESRESERVATION_FAILURE = "PUT_MULTIPLESERVICESRESERVATION_FAILURE",
  DELETE_MULTIPLESERVICESRESERVATION_LOADING = "DELETE_MULTIPLESERVICESRESERVATION_LOADING",
  DELETE_MULTIPLESERVICESRESERVATION_SUCCESS = "DELETE_MULTIPLESERVICESRESERVATION_SUCCESS",
  DELETE_MULTIPLESERVICESRESERVATION_FAILURE = "DELETE_MULTIPLESERVICESRESERVATION_FAILURE",
}