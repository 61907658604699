import { useMemo } from "react";
import { Column } from "react-table";

interface Props<T extends Object> {
  dataMemo: {
    factory: Array<T>;
    deps: Array<any>;
  };
  columnsMemo: {
      factory: Column<T>[];
      deps: Array<any>;
  };
}

export const useTableMemo = <T extends Object>({ dataMemo, columnsMemo }: Props<T>) => {
  type TableColumn = Column<T>[];
  const data = useMemo<T[]>(() => dataMemo.factory, [...dataMemo.deps]); // eslint-disable-line react-hooks/exhaustive-deps
  const columns = useMemo<TableColumn>(() => columnsMemo.factory, [...columnsMemo.deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
      data,
      columns,
  }
}