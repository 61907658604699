import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonSpinner } from "../../components";
import { deleteMultipleServicesSchedule, getMultipleServicesSchedule, postMultipleServicesSchedule, putMultipleServicesSchedule } from '../../redux/actions';
import { MultipleServicesSchedulePost, MultipleServicesSchedulePut, MultipleServicesScheduleTable, MultipleServicesTableWSchedule } from '../../redux/interfaces';
import alerts from "../../utils/alerts";

export const ServiceScheduleEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { multipleService } = state as { multipleService: MultipleServicesTableWSchedule };
  const [loading, setLoading] = useState(false);

  type DayInputsType = {
    id?: number;
    startTime: string;
    endTime: string;
  };

  type DayType = {
    id?: number;
    name: string;
    startTime: string;
    endTime: string;
    checked: boolean;
    children: DayInputsType[];
  };

  type DaysType = {
    [key: string]: DayType;
  };

  const [days, setDays] = useState<DaysType>({
    lunes: {
      name: 'lunes',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    martes: {
      name: 'martes',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    miercoles: {
      name: 'miercoles',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    jueves: {
      name: 'jueves',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    viernes: {
      name: 'viernes',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    sabado: {
      name: 'sabado',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    domingo: {
      name: 'domingo',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    feriado: {
      name: 'feriado',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
  });
  const [daysOriginal, setDaysOriginal] = useState<DaysType>({
    lunes: {
      name: 'lunes',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    martes: {
      name: 'martes',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    miercoles: {
      name: 'miercoles',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    jueves: {
      name: 'jueves',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    viernes: {
      name: 'viernes',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    sabado: {
      name: 'sabado',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    domingo: {
      name: 'domingo',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
    feriado: {
      name: 'feriado',
      startTime: '',
      endTime: '',
      checked: false,
      children: [],
    },
  });

  useEffect(() => {
    const pullMultipleServices = async () => {
      const multipleServicesScheduleAction = await getMultipleServicesSchedule({
        multiple_service_id: multipleService.id,
      })(dispatch);
      const {
        payload: { success },
      } = multipleServicesScheduleAction;

      if (!success) {
        toast('¡Cáspita!: parece que tenemos problemas de conexión. Por favor vuelva a intentarlo más tarde.', {
          type: 'error',
        });
      }

      const multipleServicesSchedule = multipleServicesScheduleAction.payload.listMultipleServicesSchedule as MultipleServicesScheduleTable[];

      let daysStructure: any = {};
      for (const mSSchdule of multipleServicesSchedule) {
        const { day, start_time, end_time, id, is_activated } = mSSchdule;
        if (!daysStructure[day]) {
          daysStructure[day] = {
            id,
            name: day,
            startTime: start_time,
            endTime: end_time,
            checked: is_activated,
            children: [],
          };
        } else {
          daysStructure[day].children.push({
            id,
            startTime: start_time,
            endTime: end_time,
            checked: is_activated,
          });
        }
      }
      setDays({
        ...days,
        ...daysStructure,
      });
      setDaysOriginal({
        ...daysOriginal,
        ...daysStructure,
      });
    };
    pullMultipleServices();
  }, []); // eslint-disable-line

  const handleSubitSchedule = async (e: FormEvent) => {
    e.preventDefault();
    const allPromiseRequest = [];
    const daysToSendPost: MultipleServicesSchedulePost[] = [];
    const daysToSendPut: MultipleServicesSchedulePut[] = [];
    const warnings: string[] = [];
    for (const i in days) {
      if (Object.prototype.hasOwnProperty.call(days, i)) {
        const day = days[i];
        if (day.id) {
          daysToSendPut.push({
            id: day.id,
            day: day.name,
            start_time: day.startTime,
            end_time: day.endTime,
            is_activated: day.checked,
            multiple_service: multipleService.id,
          });
        } else {
          if (day.checked) {
            daysToSendPost.push({
              day: day.name,
              start_time: day.startTime,
              end_time: day.endTime,
              is_activated: day.checked,
              multiple_service: multipleService.id,
            });
          }
        }
        // Begin validation day with data not checked
        if (!day.checked) {
          if (day.endTime !== '' || day.startTime !== '') {
            !warnings.includes(day.name) && warnings.push(day.name);
          }
        }
        // End validation day with data not checked
        for (const otherDay of day.children) {
          if (otherDay.id) {
            daysToSendPut.push({
              id: otherDay.id,
              day: day.name,
              start_time: otherDay.startTime,
              end_time: otherDay.endTime,
              is_activated: day.checked,
              multiple_service: multipleService.id,
            });
          } else {
            if (day.checked) {
              daysToSendPost.push({
                day: day.name,
                start_time: otherDay.startTime,
                end_time: otherDay.endTime,
                is_activated: day.checked,
                multiple_service: multipleService.id,
              });
            }
          }
          // Begin validation day with data not checked
          if (!day.checked) {
            if (otherDay.endTime !== '' || otherDay.startTime !== '') {
              !warnings.includes(day.name) && warnings.push(day.name);
            }
          }
          // End validation day with data not checked
        }
      }
    }

    let noContinue = false;

    if (warnings.length > 0) {
      const warningMessage = warnings.length > 1 ? /*html*/`Los días <span class="text-rose-500 font-bold">${warnings.join(', ')}</span> no están activados, pero tienen horarios. Si continúa sus horarios no se guardarán.` : /*html*/`El día <span class="text-rose-500 font-bold">${warnings.join(', ')}</span> no está activado, pero tiene horarios. Si continúa sus horarios no se guardarán.`;
      const { isConfirmed } = await alerts.warning({
        title: '¿Está seguro que desea continuar?',
        text: warningMessage
      });
      noContinue = !isConfirmed;
    }

    if (noContinue) return;

    toast.info('Actualizando configuración de horarios...');
    setLoading(true);

    const daysToSendPutOriginal: MultipleServicesSchedulePut[] = [];
    // Compare original days with new days, if there is a difference, delete the old days
    for (const i in daysOriginal) {
      if (Object.prototype.hasOwnProperty.call(daysOriginal, i)) {
        const day = daysOriginal[i];
        if (day.id) {
          daysToSendPutOriginal.push({
            id: day.id,
            day: day.name,
            start_time: day.startTime,
            end_time: day.endTime,
            is_activated: day.checked,
            multiple_service: multipleService.id,
          });
        }
        for (const otherDay of day.children) {
          if (otherDay.id) {
            daysToSendPutOriginal.push({
              id: otherDay.id,
              day: day.name,
              start_time: otherDay.startTime,
              end_time: otherDay.endTime,
              is_activated: day.checked,
              multiple_service: multipleService.id,
            });
          }
        }
      }
    }
    const daysToDelete = daysToSendPutOriginal.filter(day => daysToSendPut.findIndex(day2 => day2.id === day.id) === -1);

    daysToSendPost.length > 0 && allPromiseRequest.push(...daysToSendPost.map(dayToSendPost => postMultipleServicesSchedule(dayToSendPost)(dispatch)));
    daysToSendPut.length > 0 && allPromiseRequest.push(...daysToSendPut.map(dayToSendPut => putMultipleServicesSchedule(dayToSendPut)(dispatch)));
    daysToDelete.length > 0 && allPromiseRequest.push(...daysToDelete.map(dayToDelete => deleteMultipleServicesSchedule(dayToDelete.id)(dispatch)));

    const responses = await Promise.all(allPromiseRequest);
    const allSuccess = responses.every(response => response.payload.success);

    if (!allSuccess) {
      return toast.error(
        '¡Oops!, tuvimos un problema al actualizar los horarios. Por favor vuelva a intentarlo más tarde.',
        { type: 'error' }
      );
    }
    toast.success('Se ha guardado la nueva configuración de horarios.');
    setLoading(false);
  }

  return (
    <div className="mx-auto max-w-3xl">
      <h1 className="flex items-center gap-4 font-bold text-gray-500">
        <button onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        {multipleService.name}
      </h1>
      <form onSubmit={handleSubitSchedule}>
        <div className="flex flex-col gap-10 py-10">
          {Object.keys(days).map((dayName, i) => {
            const day = days[dayName];
            const [ hour, minute ] = day.startTime.split(':').map(Number);
            const limitTimeWMaxUsage = new Date(new Date(new Date().setHours(hour, minute, 0)).getTime() + multipleService.maximun_usage_time * 60 * 1000).toLocaleTimeString();
            const dayEl = (
              <div
                key={`days-hours=${i}`}
                className="grid grid-cols-[30px_100px_repeat(2,minmax(0,1fr))_50px] items-center gap-8"
              >
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="mr-4 h-7 w-7 rounded-md bg-yellow-50 border-yellow-500 checked:bg-yellow-500 checked:border-transparen accent-yellow-500 border-2 focus:ring-2 focus:ring-yellow-500 active:ring-2 active:ring-yellow-500"
                  checked={day.checked}
                  onChange={({ target }) => {
                    const { checked } = target;
                    setDays({
                      ...days,
                      [dayName]: {
                        ...day,
                        checked,
                      },
                    });
                  }}
                />
                <label htmlFor="remember" className="basis-1/6 font-bold text-black">
                  {day.name}
                </label>
                <input
                  id="capacity"
                  name="capacity"
                  type="time"
                  value={day.startTime}
                  step={1800}
                  onChange={({ target }) => {
                    const { value } = target;
                    setDays({
                      ...days,
                      [dayName]: {
                        ...day,
                        startTime: value,
                        checked: true,
                      },
                    });
                  }}
                  required={day.checked}
                  className="relative col-start-3 mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                  placeholder="Abre a la(s)"
                />
                <input
                  id="capacity"
                  name="capacity"
                  type="time"
                  value={day.endTime}
                  min={day.startTime}
                  max={limitTimeWMaxUsage}
                  onChange={({ target }) => {
                    const { value } = target;
                    setDays({
                      ...days,
                      [dayName]: {
                        ...day,
                        endTime: value,
                        checked: true,
                      },
                    });
                  }}
                  required={day.checked}
                  className="relative col-start-4 mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                  placeholder="Cierra a la(s)"
                />
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    // update children from each day
                    onClick={() =>
                      setDays({
                        ...days,
                        [dayName]: {
                          ...day,
                          children: [...day.children, { startTime: '', endTime: '' }],
                        },
                      })
                    }
                    className="rounded-full bg-yellow-500 p-2 text-white ring-offset-2 hover:bg-amber-300 active:bg-amber-400 active:ring active:ring-amber-300"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            );
            const childrenEl = day.children.map((dayChildren, i) => {
              const [ hour, minute ] = dayChildren.startTime.split(':').map(Number);
              const limitTimeWMaxUsageChildren = new Date(new Date(new Date().setHours(hour, minute, 0)).getTime() + multipleService.maximun_usage_time * 60 * 1000).toLocaleTimeString();
              return (
                <div
                  key={`days-hours-children-${i}`}
                  className="grid grid-cols-[30px_100px_repeat(2,minmax(0,1fr))_50px] items-center gap-8"
                >
                  <input
                    id="capacity"
                    name="capacity"
                    type="time"
                    step={1800}
                    value={dayChildren.startTime}
                    onChange={({ target }) => {
                      const { value } = target;
                      dayChildren.startTime = value;
                      setDays({
                        ...days,
                        [dayName]: {
                          ...day,
                          children: [...day.children],
                          checked: true,
                        },
                      });
                    }}
                    required={day.checked}
                    className="relative col-start-3 mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                    placeholder="Abre a la(s)"
                  />
                  <input
                    id="capacity"
                    name="capacity"
                    type="time"
                    value={dayChildren.endTime}
                    min={dayChildren.startTime}
                    max={limitTimeWMaxUsageChildren}
                    onChange={({ target }) => {
                      const { value } = target;
                      dayChildren.endTime = value;
                      setDays({
                        ...days,
                        [dayName]: {
                          ...day,
                          children: [...day.children],
                          checked: true,
                        },
                      });
                    }}
                    required={day.checked}
                    className="relative col-start-4 mt-2 block h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                    placeholder="Cierra a la(s)"
                  />
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      onClick={() =>
                        setDays({
                          ...days,
                          [dayName]: {
                            ...day,
                            children: [...day.children.slice(0, i), ...day.children.slice(i + 1)],
                          },
                        })
                      }
                      className="rounded-full p-2.5 text-black hover:bg-red-50 active:bg-red-300"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              );
            });

            return day.children.length > 0 ? [dayEl, ...childrenEl] : dayEl;
          })}
        </div>
        <div className="flex justify-center">
          <ButtonSpinner isLoading={loading} text="Actualizar" type="submit" color="yellow" className="mb-6" />
        </div>
      </form>
    </div>
  );
};
