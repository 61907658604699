import { MultipleServicesCategoryRepository } from "../repositories";
import { MultipleServicesCategoryFilters, MultipleServicesCategoryGet, MultipleServicesCategoryPost, MultipleServicesCategoryPut, MultipleServicesCategoryTable } from "../../interfaces";

export class MultipleServicesCategoryProcess {
  private multipleServiceCategoryRepository: MultipleServicesCategoryRepository;

  constructor() {
    this.multipleServiceCategoryRepository = new MultipleServicesCategoryRepository();
  }

  async getMultipleServicesCategory(filters?: MultipleServicesCategoryFilters): Promise<MultipleServicesCategoryGet> {
    const response = await this.multipleServiceCategoryRepository.getMultipleServicesCategory(filters);
    return response.data;
  }

  async postMultipleServicesCategory(msCategory: MultipleServicesCategoryPost): Promise<MultipleServicesCategoryTable> {
    const response = await this.multipleServiceCategoryRepository.postMultipleServicesCategory(msCategory);
    return response.data;
  }

  async putMultipleServicesCategory(msCategory: MultipleServicesCategoryPut): Promise<MultipleServicesCategoryTable> {
    const response = await this.multipleServiceCategoryRepository.putMultipleServicesCategory(msCategory);
    return response.data;
  }

  // dont response data
  async deleteMultipleServicesCategory(id: number): Promise<any> {
    const response = await this.multipleServiceCategoryRepository.deleteMultipleServicesCategory(id);
    return response.data;
  }
}
