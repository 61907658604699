import { Form, Formik } from "formik";
import { FormInput, FormSelect, FormTextarea } from "../../components";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { postCampusNotifications } from "../../redux/actions";
import { User } from "../../redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";

export const CampusNotificationsNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  return (
    <Formik
      initialValues={{
        title: '',
        description: '',
        module: ''
      }}
      validationSchema={Yup.object({
        title: Yup.string().required('El titulo es requerido'),
        description: Yup.string().required('La descripción es requerida'),
        module: Yup.string().required('El módulo es requerido')
      })}
      onSubmit={async (values) => {
        const { payload } = await postCampusNotifications({
          title: values.title,
          description: values.description,
          module: values.module,
          campus_id: user.campus.id,
          user_id: user.id
        })(dispatch);

        const { success } = payload;
        if (!success) {
          return toast.error(payload.error?.message, { type: 'error' });
        }
        toast.success('Notificación enviada.');
        navigate(-1);
      }}
    >
      <>
        <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        <h1 className="text-gray-500 text-lg font-bold mb-6">Notificaciones</h1>
        <Form
          className="flex flex-col gap-4"
        >
          <FormInput
            name="title"
            type="text"
            placeholder="Título de la notificación"
            required
          />
          <FormTextarea
            name="description"
            placeholder="Cuerpo de la notificación"
            className="h-32"
            required
          />
          <FormSelect
            name="module"
            required
          >
            <option value="" disabled>Link al módulo</option>
            <option value="restaurant">Restaurantes</option>
            <option value="hotel">Hoteles</option>
            <option value="multiple-service">Servicios Multiples</option>
            <option value="event">Eventos</option>
            <option value="contribution">Aportaciones</option>
            <option value="survey">Encuestas</option>
            <option value="general">General</option>
          </FormSelect>
          <div className="flex flex-row justify-end">
            <button
              type="submit"
              className="btn-yellow w-32"
            >
              Enviar
            </button>
          </div>
        </Form>
      </>
    </Formik>
  )
}