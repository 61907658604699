import { ErrorMessage, Field, useField } from 'formik';

interface Props {
    label?: string;
    name: string;
    placeholder?: string;
    [x: string]: any;
    className?: string;
    containerClassName?: string;
}


export const FormSelect = ( { label, className, containerClassName, ...props }: Props ) => {

    const [ field ] = useField(props)

    return (
        <div className={`flex flex-col gap-1 ${containerClassName ? containerClassName : '' }`}>
            { label && <label htmlFor={ props.id || props.name }>{ label }</label> }
            {/* <select className={`input-yellow ${className ? className : ''}`} { ...field } { ...props } /> */}
            <Field component="select" { ...field } { ...props } className={`input-yellow ${className ? className : ''}`} />
            <ErrorMessage name={ props.name } component="span" className="text-red-500" />
        </div>
    )
}