
import { EventSaloonsAction, EventSaloonsPayload } from "../interfaces";
import { EventSaloonsTypes } from "../types";

const initialState: EventSaloonsPayload = {
  loading: false,
  success: false,
  error: null,
  eventSaloons: null,
  listEventSaloons: [],
  pagEventSaloons: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const eventSaloons = (state = initialState, action: EventSaloonsAction): EventSaloonsPayload => {
  const { loading, success, error, eventSaloons, listEventSaloons, pagEventSaloons } = action.payload || {};
  switch (action.type) {
    case EventSaloonsTypes.GET_EVENTSALOONSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventSaloonsTypes.GET_EVENTSALOONSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventSaloonsTypes.GET_EVENTSALOONSPAG_SUCCESS:
      return {
        ...state,
        pagEventSaloons,
        loading,
        success,
      };
    case EventSaloonsTypes.GET_EVENTSALOONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventSaloonsTypes.GET_EVENTSALOONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventSaloonsTypes.GET_EVENTSALOONS_SUCCESS:
      return {
        ...state,
        listEventSaloons,
        loading,
        success,
      };
    case EventSaloonsTypes.GETONLY_EVENTSALOONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventSaloonsTypes.GETONLY_EVENTSALOONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventSaloonsTypes.GETONLY_EVENTSALOONS_SUCCESS:
      return {
        ...state,
        eventSaloons,
        loading,
        success,
      };
    case EventSaloonsTypes.POST_EVENTSALOONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventSaloonsTypes.POST_EVENTSALOONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventSaloonsTypes.POST_EVENTSALOONS_SUCCESS:
      return {
        ...state,
        eventSaloons,
        loading,
        success,
      };
    case EventSaloonsTypes.PUT_EVENTSALOONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventSaloonsTypes.PUT_EVENTSALOONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventSaloonsTypes.PUT_EVENTSALOONS_SUCCESS:
      return {
        ...state,
        eventSaloons,
        loading,
        success,
      };
    case EventSaloonsTypes.DELETE_EVENTSALOONS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventSaloonsTypes.DELETE_EVENTSALOONS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventSaloonsTypes.DELETE_EVENTSALOONS_SUCCESS:
      return {
        ...state,
        eventSaloons,
        loading,
        success,
      };
    default:
      return state;
  }
};

