export const eventReserveStatus = [
  {
    value: 'PENDING',
    label: 'Pendiente',
    color: 'yellow-500'
  },
  {
    value: 'RESERVED',
    label: 'Reservado',
    color: 'orange-500'
  },
  {
    value: 'CONTRACTED',
    label: 'Firmado contrato',
    color: 'green-500'
  },
  {
    value: 'INVOICED',
    label: 'Facturado',
    color: 'black'
  },
  {
    value: 'CANCELLED',
    label: 'Cancelado',
    color: 'gray-500'
  },
]