export enum HotelBedroomTypes {
  GET_HOTELBEDROOMPAG_LOADING = "GET_HOTELBEDROOMPAG_LOADING",
  GET_HOTELBEDROOMPAG_SUCCESS = "GET_HOTELBEDROOMPAG_SUCCESS",
  GET_HOTELBEDROOMPAG_FAILURE = "GET_HOTELBEDROOMPAG_FAILURE",
  GET_HOTELBEDROOM_LOADING = "GET_HOTELBEDROOM_LOADING",
  GET_HOTELBEDROOM_SUCCESS = "GET_HOTELBEDROOM_SUCCESS",
  GET_HOTELBEDROOM_FAILURE = "GET_HOTELBEDROOM_FAILURE",
  GETONLY_HOTELBEDROOM_LOADING = "GETONLY_HOTELBEDROOM_LOADING",
  GETONLY_HOTELBEDROOM_SUCCESS = "GETONLY_HOTELBEDROOM_SUCCESS",
  GETONLY_HOTELBEDROOM_FAILURE = "GETONLY_HOTELBEDROOM_FAILURE",
  POST_HOTELBEDROOM_LOADING = "POST_HOTELBEDROOM_LOADING",
  POST_HOTELBEDROOM_SUCCESS = "POST_HOTELBEDROOM_SUCCESS",
  POST_HOTELBEDROOM_FAILURE = "POST_HOTELBEDROOM_FAILURE",
  PUT_HOTELBEDROOM_LOADING = "PUT_HOTELBEDROOM_LOADING",
  PUT_HOTELBEDROOM_SUCCESS = "PUT_HOTELBEDROOM_SUCCESS",
  PUT_HOTELBEDROOM_FAILURE = "PUT_HOTELBEDROOM_FAILURE",
  DELETE_HOTELBEDROOM_LOADING = "DELETE_HOTELBEDROOM_LOADING",
  DELETE_HOTELBEDROOM_SUCCESS = "DELETE_HOTELBEDROOM_SUCCESS",
  DELETE_HOTELBEDROOM_FAILURE = "DELETE_HOTELBEDROOM_FAILURE",
}