
export enum EventReservationsTypes {
  GET_EVENTRESERVATIONSPAG_LOADING = "GET_EVENTRESERVATIONSPAG_LOADING",
  GET_EVENTRESERVATIONSPAG_SUCCESS = "GET_EVENTRESERVATIONSPAG_SUCCESS",
  GET_EVENTRESERVATIONSPAG_FAILURE = "GET_EVENTRESERVATIONSPAG_FAILURE",
  GET_EVENTRESERVATIONS_LOADING = "GET_EVENTRESERVATIONS_LOADING",
  GET_EVENTRESERVATIONS_SUCCESS = "GET_EVENTRESERVATIONS_SUCCESS",
  GET_EVENTRESERVATIONS_FAILURE = "GET_EVENTRESERVATIONS_FAILURE",
  GETONLY_EVENTRESERVATIONS_LOADING = "GETONLY_EVENTRESERVATIONS_LOADING",
  GETONLY_EVENTRESERVATIONS_SUCCESS = "GETONLY_EVENTRESERVATIONS_SUCCESS",
  GETONLY_EVENTRESERVATIONS_FAILURE = "GETONLY_EVENTRESERVATIONS_FAILURE",
  POST_EVENTRESERVATIONS_LOADING = "POST_EVENTRESERVATIONS_LOADING",
  POST_EVENTRESERVATIONS_SUCCESS = "POST_EVENTRESERVATIONS_SUCCESS",
  POST_EVENTRESERVATIONS_FAILURE = "POST_EVENTRESERVATIONS_FAILURE",
  PUT_EVENTRESERVATIONS_LOADING = "PUT_EVENTRESERVATIONS_LOADING",
  PUT_EVENTRESERVATIONS_SUCCESS = "PUT_EVENTRESERVATIONS_SUCCESS",
  PUT_EVENTRESERVATIONS_FAILURE = "PUT_EVENTRESERVATIONS_FAILURE",
  DELETE_EVENTRESERVATIONS_LOADING = "DELETE_EVENTRESERVATIONS_LOADING",
  DELETE_EVENTRESERVATIONS_SUCCESS = "DELETE_EVENTRESERVATIONS_SUCCESS",
  DELETE_EVENTRESERVATIONS_FAILURE = "DELETE_EVENTRESERVATIONS_FAILURE",
}