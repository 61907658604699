import { LazyExoticComponent } from "react";
import { hotelsRoutes, multipleServicesRoutes, usersRoutes, boilerplateRoutes, eventsRoutes } from "./routes";
import { campusAdminRoutes } from "./routes";
import { restaurantRoutes } from "./routes";

type JSXComponent = () => JSX.Element;

export interface RouteType {
    to: string;
    path: string;
    Component: LazyExoticComponent<JSXComponent> | JSXComponent;
    name: string;
    secondMenuShow: boolean;
    icon?: any;
    showMenu: boolean;
    roles: string[];
}

export const routes: RouteType[] = [
    ...usersRoutes,
    ...multipleServicesRoutes,
    ...hotelsRoutes,
    ...eventsRoutes,
    ...restaurantRoutes,
    ...campusAdminRoutes,


    ...boilerplateRoutes,
]