
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { CampusSurveyFilters, CampusSurveyGet, CampusSurveyPost, CampusSurveyPut, CampusSurveyTable } from "../../interfaces";

export class CampusSurveyRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCampusSurvey(filters?: CampusSurveyFilters): Promise<AxiosResponse<CampusSurveyGet>> {
    return this.lmBootApi.get<CampusSurveyGet>("/surveys/", { params: filters });
  }

  postCampusSurvey(campusSurvey: CampusSurveyPost): Promise<AxiosResponse<CampusSurveyTable>> {
    return this.lmBootApi.post<CampusSurveyTable>("/surveys/", campusSurvey);
  }

  putCampusSurvey(campusSurvey: CampusSurveyPut): Promise<AxiosResponse<CampusSurveyTable>> {
    return this.lmBootApi.put<CampusSurveyTable>("/surveys/", campusSurvey);
  }

  // dont response data
  deleteCampusSurvey(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/surveys/${id}/`);
  }
}

