import { HotelReservationAction, HotelReservationPayload } from "../interfaces";
import { HotelReservationTypes } from "../types";

const initialState: HotelReservationPayload = {
  loading: false,
  success: false,
  error: null,
  hotelReservation: null,
  listHotelReservation: [],
  pagHotelReservation: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotelReservation = (state = initialState, action: HotelReservationAction): HotelReservationPayload => {
  const { loading, success, error, hotelReservation, listHotelReservation, pagHotelReservation } = action.payload || {};
  switch (action.type) {
    case HotelReservationTypes.GET_HOTELRESERVATIONPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelReservationTypes.GET_HOTELRESERVATIONPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelReservationTypes.GET_HOTELRESERVATIONPAG_SUCCESS:
      return {
        ...state,
        pagHotelReservation,
        loading,
        success,
      };
    case HotelReservationTypes.GET_HOTELRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelReservationTypes.GET_HOTELRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelReservationTypes.GET_HOTELRESERVATION_SUCCESS:
      return {
        ...state,
        listHotelReservation,
        loading,
        success,
      };
    case HotelReservationTypes.GETONLY_HOTELRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelReservationTypes.GETONLY_HOTELRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelReservationTypes.GETONLY_HOTELRESERVATION_SUCCESS:
      return {
        ...state,
        hotelReservation,
        loading,
        success,
      };
    case HotelReservationTypes.POST_HOTELRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelReservationTypes.POST_HOTELRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelReservationTypes.POST_HOTELRESERVATION_SUCCESS:
      return {
        ...state,
        hotelReservation,
        loading,
        success,
      };
    case HotelReservationTypes.PUT_HOTELRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelReservationTypes.PUT_HOTELRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelReservationTypes.PUT_HOTELRESERVATION_SUCCESS:
      return {
        ...state,
        hotelReservation,
        loading,
        success,
      };
    case HotelReservationTypes.DELETE_HOTELRESERVATION_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelReservationTypes.DELETE_HOTELRESERVATION_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelReservationTypes.DELETE_HOTELRESERVATION_SUCCESS:
      return {
        ...state,
        hotelReservation,
        loading,
        success,
      };
    default:
      return state;
  }
};

