import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { Modal } from '../../components';
import { useEffect, useState } from 'react';
import { getMultipleServicesReservation, getUserById, putMultipleServicesReservation } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MultipleServicesReservationTable, User } from '../../redux/interfaces';
import { toast } from 'react-toastify';
import { RootState } from '../../redux/store';

export const ServiceSchedulePage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [reservationModalData, setReservationModalData] = useState<MultipleServicesReservationTable>();
  const [reservationModalDataPaids, setReservationModalDataPaids] = useState<any>({
    has_paid: true
  });
  const [scheduleReservations, setScheduleReservations] = useState<ScheduleType[]>([]);

  type ScheduleType = {
    title: string;
    date: string;
    data: MultipleServicesReservationTable;
  }
  const pullMsReservations = async () => {
    const { payload } = await getMultipleServicesReservation({
      status: 'APPROVED',
      multiple_service_schedule__multiple_service__multiple_service_category__campus_id: user.campus.id
    })(dispatch);
    if (!payload.success) { toast.error('Tuvimos problemas trayendo los reservaciones. Por favor intentelo más tarde.') }
    const listMultipleServicesReservation = payload.listMultipleServicesReservation as MultipleServicesReservationTable[];
    setScheduleReservations(listMultipleServicesReservation.map(reservation => ({
      title: reservation.user.first_name,
      date: reservation.start_date,
      data: reservation
    })));
  }

  useEffect(() => {
    pullMsReservations();
  }, []); // eslint-disable-line

  const renderEventContent = (eventInfo: any) => {
    const dateStart = new Date(eventInfo.event.extendedProps.data.start_date).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return (
      <div className="bg-green-100 border-0 border-l-4 border-green-500 px-2 py-1 text-black rounded-md cursor-pointer w-full">
        <p className="text-xs font-semibold text-green-900">{dateStart}</p>
        <h1 className="font-bold text-base text-ellipsis whitespace-nowrap overflow-hidden">{eventInfo.event.title}</h1>
        <p className="text-xs font-semibold text-green-900">{eventInfo.event.extendedProps.data.people_total} {eventInfo.event.extendedProps.data.people_total === 1 ? 'persona' : 'personas'}</p>
      </div>
    )
  }

  const handleBookingFinished = async () => {
    if (reservationModalData) {
      const { payload } = await putMultipleServicesReservation({
        id: reservationModalData.id,
        end_date: reservationModalData.end_date,
        family_number: reservationModalData.family_number,
        guest_number: reservationModalData.guest_number,
        member_number: reservationModalData.member_number,
        people_total: reservationModalData.people_total,
        multiple_service_schedule_id: reservationModalData.multiple_service_schedule.id,
        start_date: reservationModalData.start_date,
        user_id: reservationModalData.user.id,
        status: 'FINISHED'
      })(dispatch);
      if (!payload.success) return toast.error('Tuvimos un problema al actualizar el estado de la reserva. Por favor intentelo más tarde.');
      setIsOpen(false);
      toast.success('Estado de la Reserva actualizada con éxito.');
      pullMsReservations();
    }
  }

  const handleBookingIncompleted = async () => {
    if (reservationModalData) {
      const { payload } = await putMultipleServicesReservation({
        id: reservationModalData.id,
        end_date: reservationModalData.end_date,
        family_number: reservationModalData.family_number,
        guest_number: reservationModalData.guest_number,
        member_number: reservationModalData.member_number,
        people_total: reservationModalData.people_total,
        multiple_service_schedule_id: reservationModalData.multiple_service_schedule.id,
        start_date: reservationModalData.start_date,
        user_id: reservationModalData.user.id,
        status: 'INCOMPLETED'
      })(dispatch);
      if (!payload.success) return toast.error('Tuvimos un problema al actualizar el estado de la reserva. Por favor intentelo más tarde.');
      setIsOpen(false);
      toast.success('Estado de la Reserva actualizada con éxito.');
      pullMsReservations();
    }
  }

  return (
    <>
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        eventContent={renderEventContent}
        events={scheduleReservations}
        dayMaxEventRows={2}
        locale="es"
        locales={[ esLocale ]}
        eventClick={async (info: any) => {
          setIsOpen(true);
          setReservationModalData(info.event.extendedProps.data);
          const { payload } = await getUserById(info.event.extendedProps.data.user.id)(dispatch);
          if (payload.error) { toast.error('Tuvimos problemas trayendo los pagos del miembro. Por favor intentelo más tarde.') }
          setReservationModalDataPaids(payload.userById);
        }}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} className="max-w-3xl" >
        <div className="p-3">
          <section className="flex flex-row justify-between bg-yellow-200 rounded-lg p-4 my-8">
            <section className="flex flex-row gap-4 items-center">
              {!reservationModalData?.user.photo_url
                  ? <img className="block h-14 aspect-square rounded-full object-cover hover:opacity-80 active:opacity-70" src="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Profile" />
                  : <img className="block h-14 aspect-square rounded-full object-cover hover:opacity-80 active:opacity-70" src={reservationModalData?.user.photo_url} alt="Profile" />
              }
              <div className="flex flex-col justify-center items-start gap-1">
                <h2 className="font-bold">{reservationModalData?.user.first_name}</h2>
                {
                  reservationModalDataPaids && reservationModalDataPaids.has_paid ? (
                    <span className="bg-white rounded-xl py-1 px-3 text-green-700 text-sm">Al día</span>
                  ) : (
                    <span className="bg-white rounded-xl py-1 px-3 text-red-500 text-sm">{`Pago retrasado ($${reservationModalDataPaids.amount_pay})`}</span>
                  )
                }
              </div>
            </section>
            <section className="text-right">
              <h2 className="font-bold">Teléfono de emergencia</h2>
              <p>{reservationModalData?.user.emergency_number}</p>
            </section>
            <section className="text-right">
              <h2 className="font-bold">Teléfono</h2>
              <p>{reservationModalData?.user.phone}</p>
            </section>
          </section>
          <section className="flex flex-row justify-between gap-6 mb-6">
            <div className="grow">
              <h1 className="font-bold text-lg">{reservationModalData?.multiple_service_schedule.multiple_service.name}</h1>
              <div className="mt-6 mb-4 w-full border-0 border-b-2 border-gray-200"></div>
              <p className="flex flex-row justify-between mb-1 text-gray-500">Reservacion socio (1): <span>{reservationModalData?.member_number}</span></p>
              <p className="flex flex-row justify-between mb-1 text-gray-500">Reservación dependiente (1): <span>{reservationModalData?.family_number}</span></p>
              <p className="flex flex-row justify-between mb-1 text-gray-500">Reservación invitado (1): <span>{reservationModalData?.guest_number}</span></p>
              <p className="flex flex-row justify-between mb-1 font-bold">Total personas: <span>{reservationModalData?.people_total}</span></p>
            </div>
            <div className="bg-gray-50 text-gray-600 rounded-2xl p-4 max-w-xs">
              <h3 className="mb-2">Horario</h3>
              <p className="flex flex-row items-center gap-2 mb-2">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                  </svg>
                </span>
                {
                  // hide hours for all dates
                  new Date(reservationModalData?.start_date || "").toLocaleTimeString('es-ES', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }).split(' ').splice(0, 6).join(' ')
                }
              </p>
              <p className="flex flex-row items-center gap-2 mb-2">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                  </svg>
                </span>
                {
                  new Date(reservationModalData?.start_date || "").toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })
                }
              </p>
            </div>
          </section>
          <footer className="flex flex-row justify-end gap-4">
            <button
              onClick={handleBookingIncompleted}
              className="btn-gray w-fit"
            >Incompleto</button>
            <button
              onClick={handleBookingFinished}
              className="btn-yellow w-fit"
            >Completado</button>
          </footer>
        </div>
      </Modal>
    </>
  )
};
