
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { EventCoordinatorsFilters, EventCoordinatorsGet, EventCoordinatorsPost, EventCoordinatorsPut, EventCoordinatorsTable } from "../../interfaces";

export class EventCoordinatorsRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getEventCoordinators(filters?: EventCoordinatorsFilters): Promise<AxiosResponse<EventCoordinatorsGet>> {
    return this.lmBootApi.get<EventCoordinatorsGet>("/event-coordinators/", { params: filters });
  }

  postEventCoordinators(eventCoordinators: EventCoordinatorsPost): Promise<AxiosResponse<EventCoordinatorsTable>> {
    return this.lmBootApi.post<EventCoordinatorsTable>("/event-coordinators/", eventCoordinators);
  }

  putEventCoordinators(eventCoordinators: EventCoordinatorsPut): Promise<AxiosResponse<EventCoordinatorsTable>> {
    return this.lmBootApi.put<EventCoordinatorsTable>("/event-coordinators/", eventCoordinators);
  }

  // dont response data
  deleteEventCoordinators(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/event-coordinators/${id}/`);
  }
}

