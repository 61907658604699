
import { CampusChatbotPage, CampusContributionsPage, CampusNewsEditPage, CampusNewsNewPage, CampusNewsPage, CampusNotificationsNewPage, CampusNotificationsPage, CampusPrivacyPoliciesPage, CampusSuggestionsPage, CampusSurveysEditPage, CampusSurveysNewPage, CampusSurveysPage, CampusSurveysReportPage, CampusTermsAndConditionsPage, CampusUsersPage } from "../../pages/campus";
import { UserRole } from "../../utils/enums/userRoles.enum";

export const campusAdminRoutes = [
  // CAMPUSADMIN MODULE
  {
    path: '/campus/users',
    to: '/campus/users',
    name: 'Usuarios',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusUsersPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
      </svg>
    )
  },
  {
    path: '/campus/news',
    to: '/campus/news',
    name: 'Noticias',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusNewsPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
      </svg>
    )
  },
  {
    path: '/campus/notifications',
    to: '/campus/notifications',
    name: 'Notificaciones',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusNotificationsPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
      </svg>
    )
  },
  {
    path: '/campus/notifications/new',
    to: '/campus/notifications/new',
    name: 'Crear notificación',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusNotificationsNewPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
      </svg>
    )
  },
  {
    path: '/campus/surveys',
    to: '/campus/surveys',
    name: 'Encuestas',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusSurveysPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
      </svg>
    )
  },
  {
    path: '/campus/surveys/edit',
    to: '/campus/surveys/edit',
    name: 'Editar Encuestas',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusSurveysEditPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
      </svg>
    )
  },
  {
    path: '/campus/surveys/new',
    to: '/campus/surveys/new',
    name: 'Nueva Encuesta',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusSurveysNewPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
      </svg>
    )
  },
  {
    path: '/campus/surveys/report',
    to: '/campus/surveys/report',
    name: 'Reportes de Encuestas',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusSurveysReportPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
      </svg>
    )
  },
  {
    path: '/campus/suggestions',
    to: '/campus/suggestions',
    name: 'Sugerencias',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusSuggestionsPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
        <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
      </svg>
    )
  },
  {
    path: '/campus/contributions',
    to: '/campus/contributions',
    name: 'Aportaciones',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusContributionsPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/campus/termsandconditions',
    to: '/campus/termsandconditions',
    name: 'Terminos y condiciones',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusTermsAndConditionsPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/campus/privacypolicies',
    to: '/campus/privacypolicies',
    name: 'Políticas de privacidad',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusPrivacyPoliciesPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/campus/chatbot',
    to: '/campus/chatbot',
    name: 'Chatbot',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusChatbotPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/campus/news/new',
    to: '/campus/news/new',
    name: 'Crear nueva noticia',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusNewsNewPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    path: '/campus/news/edit',
    to: '/campus/news/edit',
    name: 'Editar nueva noticia',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.CAMPUS_ADMIN],
    Component: CampusNewsEditPage,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
      </svg>
    )
  },
]