
import { SuggestionsRepository } from "../repositories";
import { SuggestionsFilters, SuggestionsGet, SuggestionsPost, SuggestionsPut, SuggestionsTable } from "../../interfaces";

export class SuggestionsProcess {
  private suggestionsRepository: SuggestionsRepository;

  constructor() {
    this.suggestionsRepository = new SuggestionsRepository();
  }

  async getSuggestions(filters?: SuggestionsFilters): Promise<SuggestionsGet> {
    const response = await this.suggestionsRepository.getSuggestions(filters);
    return response.data;
  }

  async postSuggestions(suggestions: SuggestionsPost): Promise<SuggestionsTable> {
    const response = await this.suggestionsRepository.postSuggestions(suggestions);
    return response.data;
  }

  async putSuggestions(suggestions: SuggestionsPut): Promise<SuggestionsTable> {
    const response = await this.suggestionsRepository.putSuggestions(suggestions);
    return response.data;
  }

  // dont response data
  async deleteSuggestions(id: number): Promise<any> {
    const response = await this.suggestionsRepository.deleteSuggestions(id);
    return response.data;
  }
}
