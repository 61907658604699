import { MultipleServicesReservationRepository } from "../repositories";
import { MultipleServicesReservationFilters, MultipleServicesReservationGet, MultipleServicesReservationPost, MultipleServicesReservationPut, MultipleServicesReservationTable } from "../../interfaces";

export class MultipleServicesReservationProcess {
  private multipleServiceReservationRepository: MultipleServicesReservationRepository;

  constructor() {
    this.multipleServiceReservationRepository = new MultipleServicesReservationRepository();
  }

  async getMultipleServicesReservation(filters?: MultipleServicesReservationFilters): Promise<MultipleServicesReservationGet> {
    const response = await this.multipleServiceReservationRepository.getMultipleServicesReservation(filters);
    return response.data;
  }

  async getMultipleServicesReservationById(multiple_service_id: number): Promise<MultipleServicesReservationGet> {
    const response = await this.multipleServiceReservationRepository.getMultipleServicesReservationById(multiple_service_id);
    return response.data;
  }

  async postMultipleServicesReservation(msReservation: MultipleServicesReservationPost): Promise<MultipleServicesReservationTable> {
    const response = await this.multipleServiceReservationRepository.postMultipleServicesReservation(msReservation);
    return response.data;
  }

  async putMultipleServicesReservation(msReservation: MultipleServicesReservationPut): Promise<MultipleServicesReservationTable> {
    const response = await this.multipleServiceReservationRepository.putMultipleServicesReservation(msReservation);
    return response.data;
  }

  // dont response data
  async deleteMultipleServicesReservation(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteMultipleServicesReservation(id);
    return response.data;
  }
}
