
import { RestaurantRepository } from "../repositories";
import { RestaurantFilters, RestaurantGet, RestaurantTable } from "../../interfaces";

export class RestaurantProcess {
  private restaurantRepository: RestaurantRepository;

  constructor() {
    this.restaurantRepository = new RestaurantRepository();
  }

  async getRestaurant(filters?: RestaurantFilters): Promise<RestaurantGet> {
    const response = await this.restaurantRepository.getRestaurant(filters);
    return response.data;
  }

  async postRestaurant(restaurant: FormData): Promise<RestaurantTable> {
    const response = await this.restaurantRepository.postRestaurant(restaurant);
    return response.data;
  }

  async putRestaurant(restaurant: FormData): Promise<RestaurantTable> {
    const response = await this.restaurantRepository.putRestaurant(restaurant);
    return response.data;
  }

  // dont response data
  async deleteRestaurant(id: number): Promise<any> {
    const response = await this.restaurantRepository.deleteRestaurant(id);
    return response.data;
  }
}
