import React from 'react';
import logo from '../assets/images/logo.svg';

// pass className from props
export default function LogoHeader(props: React.PropsWithRef<{ className?: string }>) {
  return (
    <div {...props}>
      <img src={logo} className="mx-auto h-[88px] w-auto" alt="logo" />
      <h2 className="mt-4 text-center text-2xl font-extrabold text-gray-600 font-title">CÍRCULO MILITAR</h2>
      <p className="text-center text-xl text-gray-600 font-title">
        ¡Mucho más que un buen club!
      </p>
    </div>
  )
}
