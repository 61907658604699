
export enum CampusSurveyTypes {
  GET_CAMPUSSURVEYPAG_LOADING = "GET_CAMPUSSURVEYPAG_LOADING",
  GET_CAMPUSSURVEYPAG_SUCCESS = "GET_CAMPUSSURVEYPAG_SUCCESS",
  GET_CAMPUSSURVEYPAG_FAILURE = "GET_CAMPUSSURVEYPAG_FAILURE",
  GET_CAMPUSSURVEY_LOADING = "GET_CAMPUSSURVEY_LOADING",
  GET_CAMPUSSURVEY_SUCCESS = "GET_CAMPUSSURVEY_SUCCESS",
  GET_CAMPUSSURVEY_FAILURE = "GET_CAMPUSSURVEY_FAILURE",
  GETONLY_CAMPUSSURVEY_LOADING = "GETONLY_CAMPUSSURVEY_LOADING",
  GETONLY_CAMPUSSURVEY_SUCCESS = "GETONLY_CAMPUSSURVEY_SUCCESS",
  GETONLY_CAMPUSSURVEY_FAILURE = "GETONLY_CAMPUSSURVEY_FAILURE",
  POST_CAMPUSSURVEY_LOADING = "POST_CAMPUSSURVEY_LOADING",
  POST_CAMPUSSURVEY_SUCCESS = "POST_CAMPUSSURVEY_SUCCESS",
  POST_CAMPUSSURVEY_FAILURE = "POST_CAMPUSSURVEY_FAILURE",
  PUT_CAMPUSSURVEY_LOADING = "PUT_CAMPUSSURVEY_LOADING",
  PUT_CAMPUSSURVEY_SUCCESS = "PUT_CAMPUSSURVEY_SUCCESS",
  PUT_CAMPUSSURVEY_FAILURE = "PUT_CAMPUSSURVEY_FAILURE",
  DELETE_CAMPUSSURVEY_LOADING = "DELETE_CAMPUSSURVEY_LOADING",
  DELETE_CAMPUSSURVEY_SUCCESS = "DELETE_CAMPUSSURVEY_SUCCESS",
  DELETE_CAMPUSSURVEY_FAILURE = "DELETE_CAMPUSSURVEY_FAILURE",
}