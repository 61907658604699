
import { CampusChatbotProcess } from "../api";
import { CampusChatbotAction, CampusChatbotFilters, CampusChatbotPost, CampusChatbotPut,  } from "../interfaces";
import { CampusChatbotTypes } from "../types";

export const getCampusChatbotPags = (filters?: CampusChatbotFilters) => async (dispatch: (state: CampusChatbotAction) => CampusChatbotAction) => {
  dispatch({
    type: CampusChatbotTypes.GET_CAMPUSCHATBOTPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusChatbotProcess = new CampusChatbotProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusChatbotPag = await campusChatbotProcess.getCampusChatbot(filters);
    return dispatch({
      type: CampusChatbotTypes.GET_CAMPUSCHATBOTPAG_SUCCESS,
      payload: {
        pagCampusChatbot: campusChatbotPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusChatbotTypes.GET_CAMPUSCHATBOTPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getCampusChatbot = (filters?: CampusChatbotFilters) => async (dispatch: (state: CampusChatbotAction) => CampusChatbotAction) => {
  dispatch({
    type: CampusChatbotTypes.GET_CAMPUSCHATBOT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusChatbotProcess = new CampusChatbotProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusChatbotPag = await campusChatbotProcess.getCampusChatbot({
      ...filters,
      data_all: 'YES',
    });
    const campusChatbot = campusChatbotPag.results;
    return dispatch({
      type: CampusChatbotTypes.GET_CAMPUSCHATBOT_SUCCESS,
      payload: {
        listCampusChatbot: campusChatbot,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusChatbotTypes.GET_CAMPUSCHATBOT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postCampusChatbot = (campusChatbot: CampusChatbotPost[]) => async (dispatch: (state: CampusChatbotAction) => CampusChatbotAction) => {
  dispatch({
    type: CampusChatbotTypes.POST_CAMPUSCHATBOT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusChatbotProcess = new CampusChatbotProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusChatbotTable = await campusChatbotProcess.postCampusChatbot(campusChatbot);
    return dispatch({
      type: CampusChatbotTypes.POST_CAMPUSCHATBOT_SUCCESS,
      payload: {
        campusChatbot: campusChatbotTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusChatbotTypes.POST_CAMPUSCHATBOT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putCampusChatbot = (campusChatbot: CampusChatbotPut) => async (dispatch: (state: CampusChatbotAction) => CampusChatbotAction) => {
  dispatch({
    type: CampusChatbotTypes.PUT_CAMPUSCHATBOT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusChatbotProcess = new CampusChatbotProcess();
    // dont'n import the result of the process, only that dont't have error
    const campusChatbotTable = await campusChatbotProcess.putCampusChatbot(campusChatbot);
    return dispatch({
      type: CampusChatbotTypes.PUT_CAMPUSCHATBOT_SUCCESS,
      payload: {
        campusChatbot: campusChatbotTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusChatbotTypes.PUT_CAMPUSCHATBOT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteCampusChatbot = (ids: number[]) => async (dispatch: (state: CampusChatbotAction) => CampusChatbotAction) => {
  dispatch({
    type: CampusChatbotTypes.DELETE_CAMPUSCHATBOT_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const campusChatbotProcess = new CampusChatbotProcess();
    // dont'n import the result of the process, only that dont't have error
    await campusChatbotProcess.deleteCampusChatbot(ids);
    return dispatch({
      type: CampusChatbotTypes.DELETE_CAMPUSCHATBOT_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: CampusChatbotTypes.DELETE_CAMPUSCHATBOT_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

