import { AnyAction } from "redux";
import { RegisterStore } from "../interfaces";
import { RegisterTypes } from "../types";

const initialState: RegisterStore = {
  member: null,
  isRegistered: false,
  loading: false,
  success: true,
  campus: null,
};

type ActionType = {
  type: string;
  payload: RegisterStore;
}

export const register = (state = initialState, action: ActionType | AnyAction): RegisterStore => {
  const { loading, success, member, isRegistered, campus } = action.payload || {};
  switch (action.type) {
    case RegisterTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        isRegistered,
        loading,
      };
    case RegisterTypes.CREATE_USER_LOADING:
      return {
        ...state,
        isRegistered,
        loading
      };
    case RegisterTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        isRegistered,
        loading,
        success,
      };
    case RegisterTypes.GET_USER_DATA_FAILURE:
      return {
        ...state,
        loading,
      };
    case RegisterTypes.GET_USER_DATA_LOADING:
      return {
        ...state,
        member,
        loading
      };
    case RegisterTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        member,
        loading,
        success,
      };
    case RegisterTypes.GET_LIST_CAMPUS_FAILURE:
      return {
        ...state,
        loading,
      };
    case RegisterTypes.GET_LIST_CAMPUS_LOADING:
      return {
        ...state,
        loading,
      };
    case RegisterTypes.GET_LIST_CAMPUS_SUCCESS:
      return {
        ...state,
        campus,
        loading,
        success,
      };
    default:
      return state;
  }
};
