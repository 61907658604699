
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { SuggestionsFilters, SuggestionsGet, SuggestionsPost, SuggestionsPut, SuggestionsTable } from "../../interfaces";

export class SuggestionsRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getSuggestions(filters?: SuggestionsFilters): Promise<AxiosResponse<SuggestionsGet>> {
    return this.lmBootApi.get<SuggestionsGet>("/suggestions/", { params: filters });
  }

  postSuggestions(suggestions: SuggestionsPost): Promise<AxiosResponse<SuggestionsTable>> {
    return this.lmBootApi.post<SuggestionsTable>("/suggestions/", suggestions);
  }

  putSuggestions(suggestions: SuggestionsPut): Promise<AxiosResponse<SuggestionsTable>> {
    return this.lmBootApi.put<SuggestionsTable>("/suggestions/", suggestions);
  }

  // dont response data
  deleteSuggestions(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/suggestions/${id}/`);
  }
}

