
export enum EventSaloonsTypes {
  GET_EVENTSALOONSPAG_LOADING = "GET_EVENTSALOONSPAG_LOADING",
  GET_EVENTSALOONSPAG_SUCCESS = "GET_EVENTSALOONSPAG_SUCCESS",
  GET_EVENTSALOONSPAG_FAILURE = "GET_EVENTSALOONSPAG_FAILURE",
  GET_EVENTSALOONS_LOADING = "GET_EVENTSALOONS_LOADING",
  GET_EVENTSALOONS_SUCCESS = "GET_EVENTSALOONS_SUCCESS",
  GET_EVENTSALOONS_FAILURE = "GET_EVENTSALOONS_FAILURE",
  GETONLY_EVENTSALOONS_LOADING = "GETONLY_EVENTSALOONS_LOADING",
  GETONLY_EVENTSALOONS_SUCCESS = "GETONLY_EVENTSALOONS_SUCCESS",
  GETONLY_EVENTSALOONS_FAILURE = "GETONLY_EVENTSALOONS_FAILURE",
  POST_EVENTSALOONS_LOADING = "POST_EVENTSALOONS_LOADING",
  POST_EVENTSALOONS_SUCCESS = "POST_EVENTSALOONS_SUCCESS",
  POST_EVENTSALOONS_FAILURE = "POST_EVENTSALOONS_FAILURE",
  PUT_EVENTSALOONS_LOADING = "PUT_EVENTSALOONS_LOADING",
  PUT_EVENTSALOONS_SUCCESS = "PUT_EVENTSALOONS_SUCCESS",
  PUT_EVENTSALOONS_FAILURE = "PUT_EVENTSALOONS_FAILURE",
  DELETE_EVENTSALOONS_LOADING = "DELETE_EVENTSALOONS_LOADING",
  DELETE_EVENTSALOONS_SUCCESS = "DELETE_EVENTSALOONS_SUCCESS",
  DELETE_EVENTSALOONS_FAILURE = "DELETE_EVENTSALOONS_FAILURE",
}