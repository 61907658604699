
import { CampusSurveyRepository } from "../repositories";
import { CampusSurveyFilters, CampusSurveyGet, CampusSurveyPost, CampusSurveyPut, CampusSurveyTable } from "../../interfaces";

export class CampusSurveyProcess {
  private campusSurveyRepository: CampusSurveyRepository;

  constructor() {
    this.campusSurveyRepository = new CampusSurveyRepository();
  }

  async getCampusSurvey(filters?: CampusSurveyFilters): Promise<CampusSurveyGet> {
    const response = await this.campusSurveyRepository.getCampusSurvey(filters);
    return response.data;
  }

  async postCampusSurvey(campusSurvey: CampusSurveyPost): Promise<CampusSurveyTable> {
    const response = await this.campusSurveyRepository.postCampusSurvey(campusSurvey);
    return response.data;
  }

  async putCampusSurvey(campusSurvey: CampusSurveyPut): Promise<CampusSurveyTable> {
    const response = await this.campusSurveyRepository.putCampusSurvey(campusSurvey);
    return response.data;
  }

  // dont response data
  async deleteCampusSurvey(id: number): Promise<any> {
    const response = await this.campusSurveyRepository.deleteCampusSurvey(id);
    return response.data;
  }
}
