export enum MultipleServicesScheduleTypes {
  GET_MULTIPLESERVICESSCHEDULE_LOADING = "GET_MULTIPLESERVICESSCHEDULE_LOADING",
  GET_MULTIPLESERVICESSCHEDULE_SUCCESS = "GET_MULTIPLESERVICESSCHEDULE_SUCCESS",
  GET_MULTIPLESERVICESSCHEDULE_FAILURE = "GET_MULTIPLESERVICESSCHEDULE_FAILURE",
  POST_MULTIPLESERVICESSCHEDULE_LOADING = "POST_MULTIPLESERVICESSCHEDULE_LOADING",
  POST_MULTIPLESERVICESSCHEDULE_SUCCESS = "POST_MULTIPLESERVICESSCHEDULE_SUCCESS",
  POST_MULTIPLESERVICESSCHEDULE_FAILURE = "POST_MULTIPLESERVICESSCHEDULE_FAILURE",
  PUT_MULTIPLESERVICESSCHEDULE_LOADING = "PUT_MULTIPLESERVICESSCHEDULE_LOADING",
  PUT_MULTIPLESERVICESSCHEDULE_SUCCESS = "PUT_MULTIPLESERVICESSCHEDULE_SUCCESS",
  PUT_MULTIPLESERVICESSCHEDULE_FAILURE = "PUT_MULTIPLESERVICESSCHEDULE_FAILURE",
  DELETE_MULTIPLESERVICESSCHEDULE_LOADING = "DELETE_MULTIPLESERVICESSCHEDULE_LOADING",
  DELETE_MULTIPLESERVICESSCHEDULE_SUCCESS = "DELETE_MULTIPLESERVICESSCHEDULE_SUCCESS",
  DELETE_MULTIPLESERVICESSCHEDULE_FAILURE = "DELETE_MULTIPLESERVICESSCHEDULE_FAILURE",
}