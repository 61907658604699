
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { CampusContributionFilters, CampusContributionGet, CampusContributionPost, CampusContributionPut, CampusContributionTable } from "../../interfaces";

export class CampusContributionRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCampusContribution(filters?: CampusContributionFilters): Promise<AxiosResponse<CampusContributionGet>> {
    return this.lmBootApi.get<CampusContributionGet>("/contribution-consultants/", { params: filters });
  }

  postCampusContribution(campusContribution: CampusContributionPost): Promise<AxiosResponse<CampusContributionTable>> {
    return this.lmBootApi.post<CampusContributionTable>("/contribution-consultants/", campusContribution);
  }

  putCampusContribution(campusContribution: CampusContributionPut): Promise<AxiosResponse<CampusContributionTable>> {
    return this.lmBootApi.put<CampusContributionTable>("/contribution-consultants/", campusContribution);
  }

  // dont response data
  deleteCampusContribution(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/contribution-consultants/${id}/`);
  }
}

