
import { CampusSurveyAskRepository } from "../repositories";
import { CampusSurveyAskDelete, CampusSurveyAskFilters, CampusSurveyAskGet, CampusSurveyAskPost, CampusSurveyAskPut, CampusSurveyAskTable } from "../../interfaces";

export class CampusSurveyAskProcess {
  private campusSurveyAskRepository: CampusSurveyAskRepository;

  constructor() {
    this.campusSurveyAskRepository = new CampusSurveyAskRepository();
  }

  async getCampusSurveyAsk(filters?: CampusSurveyAskFilters): Promise<CampusSurveyAskGet> {
    const response = await this.campusSurveyAskRepository.getCampusSurveyAsk(filters);
    return response.data;
  }

  async postCampusSurveyAsk(campusSurveyAsk: CampusSurveyAskPost): Promise<CampusSurveyAskTable> {
    const response = await this.campusSurveyAskRepository.postCampusSurveyAsk(campusSurveyAsk);
    return response.data;
  }

  async putCampusSurveyAsk(campusSurveyAsk: CampusSurveyAskPut): Promise<CampusSurveyAskTable> {
    const response = await this.campusSurveyAskRepository.putCampusSurveyAsk(campusSurveyAsk);
    return response.data;
  }

  // dont response data
  async deleteCampusSurveyAsk(campusSurveyAsk: CampusSurveyAskDelete): Promise<any> {
    const response = await this.campusSurveyAskRepository.deleteCampusSurveyAsk(campusSurveyAsk);
    return response.data;
  }
}
