import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getListCampus, getListUsers, getListUsersPag } from '../../redux/actions';
import { UserFilters, UserResponse } from '../../redux/interfaces';
// import xlsx
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { RootState } from "../../redux/store";
import useForm from "../../utils/hooks/useForm";
import { TableBody } from "../../components";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { eventReserveStatus } from "../../utils/eventReserveStatus";
import { rolsCampusAdmin } from "../../utils/rolsCampusAdmin";

export const UsersReportPage = () => {
    const dispatch = useDispatch();
    const { campus } = useSelector((state: RootState) => state.register);
    const { listUsersPag, loading } = useSelector((state: RootState) => state.user);
    const {
      results: listUser,
      count,
      next,
      previous,
  } = listUsersPag as UserResponse;
    // pagination
    const [filterState, setFilterState] = useState('');
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(6);
    // status values
    const statusCellOptions: {
        [key: string]: {
            label: string;
            color: string;
        };
    } = {};
    for (const status of eventReserveStatus) {
        statusCellOptions[status.value] = {
            label: status.label,
            color: status.color,
        };
    }
    // form values
    const { campusId, handleOnChangeInput } = useForm({campusId: ''});
    // fetch data with filters
    useEffect(() => {
        const filters: UserFilters = {
            page: pageCurrent,
            page_size: pageSize,
            campus_id: parseInt(campusId),
            is_active: filterState === 'active' ? 'true' : filterState === 'inactive' ? 'false' : '',
        }
        getListUsersPag(filters)(dispatch);
    }, [pageCurrent, pageSize, filterState, campusId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getListCampus()(dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type UsersType = {
        first_name: string;
        email: string;
        documentNumber: string;
        isActive: string;
        phone: string;
        emergencyNumber: string;
        dateBirth: string;
        address: string;
        campus: string;
        group: string;
    };

    const { data, columns } = useTableMemo<UsersType>({
        dataMemo: {
            factory: listUser.map((user) => ({
              first_name: user.first_name,
              email: user.email,
              documentNumber: user.document_number,
              isActive: !!user.is_active ? 'Activo' : 'Inactivo',
              phone: user.phone,
              emergencyNumber: user.emergency_number,
              dateBirth: new Date(user.date_birth).toLocaleDateString('es-ES', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
              }),
              address: user.address,
              campus: user.campus.name,
              group: rolsCampusAdmin.find((rol) => rol.value === user.groups[0].name)?.label || ''
          })),
            deps: [listUser]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Usuario',
                    accessor: 'first_name',
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                },
                {
                    Header: 'Cédula',
                    accessor: 'documentNumber',
                },
                {
                    Header: 'Está activo',
                    accessor: 'isActive',
                },
                {
                    Header: 'Télefono',
                    accessor: 'phone',
                },
                {
                    Header: 'Télefono emergencia',
                    accessor: 'emergencyNumber',
                },
                {
                    Header: 'Fecha de nacimiento',
                    accessor: 'dateBirth',
                },
                {
                    Header: 'Dirección',
                    accessor: 'address',
                },
                {
                    Header: 'Campus',
                    accessor: 'campus',
                },
                {
                    Header: 'Rol',
                    accessor: 'group',
                },
            ],
            deps: []
        }
    })

    const handleCreateDownloadReport = async () => {
        const filters: UserFilters = {
          page: pageCurrent,
          page_size: pageSize,
          campus_id: parseInt(campusId),
          is_active: filterState === 'active' ? 'true' : filterState === 'inactive' ? 'false' : '',
        }
        const { payload } = await getListUsers(filters)(dispatch);
        const listUsers = payload.listUsers || [];
        const data_excel = listUsers.map(user => ([
          user.first_name,
          user.email,
          user.document_number,
          !!user.is_active ? 'Activo' : 'Inactivo',
          user.phone,
          user.emergency_number,
          new Date(user.date_birth).toLocaleDateString('es-ES', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
          }),
          user.address,
          user.campus.name,
          rolsCampusAdmin.find((rol) => rol.value === user.groups[0].name)?.label || ''
        ]));
        const columns_titles = [
          'Usuario',
          'Email',
          'Cédula',
          'Está activo',
          'Télefono',
          'Télefono emergencia',
          'Fecha de nacimiento',
          'Dirección',
          'Campus',
          'Rol',
        ];
        // Code for excel download
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Reporte de servicios',
            Subject: 'Reporte de servicios',
            Author: 'Círculo Militar',
            CreatedDate: new Date(Date.now()),
        };
        wb.SheetNames.push('Reporte de servicios');
        const ws = XLSX.utils.json_to_sheet(data_excel);
        wb.Sheets['Reporte de servicios'] = ws;
        // add columns width
        // create array with columns width
        const wsCols = columns_titles.map(() => ({ wch: 35 }));
        ws['!cols'] = wsCols;
        // add columns title
        columns_titles.forEach((title, index) => {
            ws[`${String.fromCharCode(65 + index)}1`] = {
                v: title,
                t: 's',
                font: {
                    sz: 14,
                    bold: true,
                },
            };
        });


        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        const s2ab = (s: any) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${new Date().toLocaleDateString().split('/').reverse().join('_')}_Reporte_de_usuarios.xlsx`);
    }

    return (
        <>
            <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
                <div className="flex flex-row items-center justify-end gap-4 print:hidden">
                    Descargar reporte
                    <button
                        onClick={handleCreateDownloadReport}
                        className="rounded-lg bg-yellow-500 p-3 text-black hover:bg-amber-300 active:bg-amber-400"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                <select
                    id="campusId"
                    name="campusId"
                    value={campusId}
                    onChange={handleOnChangeInput}
                    required
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Campus (todos)</option>
                    {
                      campus && campus.map(camp => (
                        <option key={camp.id} value={camp.id}>{camp.name}</option>
                      ))
                    }
                </select>
                <select
                    id="filterState"
                    name="filterState"
                    value={filterState}
                    onChange={(e) => setFilterState(e.target.value)}
                    className="block h-11 basis-1/5 rounded-lg border-0 border-gray-300 bg-gray-50 py-3 px-2 font-bold leading-tight focus:border-yellow-500 focus:outline-none focus:ring-yellow-500"
                >
                    <option value="">Estados (Todos)</option>
                    <option value="active">Activo</option>
                    <option value="inactive">Inactivo</option>
                </select>
            </div>
            <div className="overflow-hidden">
                <TableBody
                    columns={columns} data={data} pageSize={pageSize} loading={loading}
                    renderRows={row => (
                        <tr
                            className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                            {...row.getRowProps()}
                        >
                            {
                                row.cells.map((cell) => {

                                    if (cell.column.Header === 'Fecha de Nacimiento') {
                                        return (
                                          <td
                                          className="whitespace-nowrap px-6 py-3"
                                          {...cell.getCellProps()}
                                      >
                                          <span className="inline-block first-letter:capitalize">{cell.render('Cell')}</span>
                                      </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Dirección') {
                                        return (
                                          <td
                                          className="whitespace-nowrap px-6 py-3"
                                          {...cell.getCellProps()}
                                      >
                                          <span className="inline-block first-letter:capitalize truncate max-w-xs">{cell.render('Cell')}</span>
                                      </td>
                                        );
                                    }

                                    return (
                                        <td
                                            className="whitespace-nowrap px-6 py-3"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    )}
                    // For footer
                    count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
                    blankMessage="No se encontraron registros"
                />
            </div>
        </>
    );
};
