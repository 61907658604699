
import { EventReservationsRepository } from "../repositories";
import { EventReservationsFilters, EventReservationsGet, EventReservationsPost, EventReservationsPut, EventReservationsTable } from "../../interfaces";

export class EventReservationsProcess {
  private eventReservationsRepository: EventReservationsRepository;

  constructor() {
    this.eventReservationsRepository = new EventReservationsRepository();
  }

  async getEventReservations(filters?: EventReservationsFilters): Promise<EventReservationsGet> {
    const response = await this.eventReservationsRepository.getEventReservations(filters);
    return response.data;
  }

  async postEventReservations(eventReservations: EventReservationsPost): Promise<EventReservationsTable> {
    const response = await this.eventReservationsRepository.postEventReservations(eventReservations);
    return response.data;
  }

  async putEventReservations(eventReservations: EventReservationsPut): Promise<EventReservationsTable> {
    const response = await this.eventReservationsRepository.putEventReservations(eventReservations);
    return response.data;
  }

  // dont response data
  async deleteEventReservations(id: number): Promise<any> {
    const response = await this.eventReservationsRepository.deleteEventReservations(id);
    return response.data;
  }
}
