
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { CampusChatbotFilters, CampusChatbotGet, CampusChatbotPost, CampusChatbotPut, CampusChatbotTable } from "../../interfaces";

export class CampusChatbotRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getCampusChatbot(filters?: CampusChatbotFilters): Promise<AxiosResponse<CampusChatbotGet>> {
    return this.lmBootApi.get<CampusChatbotGet>("/chatbots/", { params: filters });
  }

  postCampusChatbot(campusChatbot: CampusChatbotPost[]): Promise<AxiosResponse<CampusChatbotTable>> {
    return this.lmBootApi.post<CampusChatbotTable>("/chatbots/", campusChatbot);
  }

  putCampusChatbot(campusChatbot: CampusChatbotPut): Promise<AxiosResponse<CampusChatbotTable>> {
    return this.lmBootApi.put<CampusChatbotTable>("/chatbots/", campusChatbot);
  }

  // dont response data
  deleteCampusChatbot(ids: number[]): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/chatbots/`, { data: {
      chatbot_ids: ids
    }});
  }
}

