
import { RestaurantSchedulesProcess } from "../api";
import { RestaurantSchedulesAction, RestaurantSchedulesFilters, RestaurantSchedulesPost, RestaurantSchedulesPut,  } from "../interfaces";
import { RestaurantSchedulesTypes } from "../types";

export const getRestaurantSchedulesPags = (filters?: RestaurantSchedulesFilters) => async (dispatch: (state: RestaurantSchedulesAction) => RestaurantSchedulesAction) => {
  dispatch({
    type: RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULESPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantSchedulesProcess = new RestaurantSchedulesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantSchedulesPag = await restaurantSchedulesProcess.getRestaurantSchedules(filters);
    return dispatch({
      type: RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULESPAG_SUCCESS,
      payload: {
        pagRestaurantSchedules: restaurantSchedulesPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULESPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getRestaurantSchedules = (filters?: RestaurantSchedulesFilters) => async (dispatch: (state: RestaurantSchedulesAction) => RestaurantSchedulesAction) => {
  dispatch({
    type: RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantSchedulesProcess = new RestaurantSchedulesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantSchedulesPag = await restaurantSchedulesProcess.getRestaurantSchedules({
      ...filters,
      data_all: 'YES',
    });
    const restaurantSchedules = restaurantSchedulesPag.results;
    return dispatch({
      type: RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULES_SUCCESS,
      payload: {
        listRestaurantSchedules: restaurantSchedules,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantSchedulesTypes.GET_RESTAURANTSCHEDULES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postRestaurantSchedules = (restaurantSchedules: RestaurantSchedulesPost) => async (dispatch: (state: RestaurantSchedulesAction) => RestaurantSchedulesAction) => {
  dispatch({
    type: RestaurantSchedulesTypes.POST_RESTAURANTSCHEDULES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantSchedulesProcess = new RestaurantSchedulesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantSchedulesTable = await restaurantSchedulesProcess.postRestaurantSchedules(restaurantSchedules);
    return dispatch({
      type: RestaurantSchedulesTypes.POST_RESTAURANTSCHEDULES_SUCCESS,
      payload: {
        restaurantSchedules: restaurantSchedulesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantSchedulesTypes.POST_RESTAURANTSCHEDULES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putRestaurantSchedules = (restaurantSchedules: RestaurantSchedulesPut) => async (dispatch: (state: RestaurantSchedulesAction) => RestaurantSchedulesAction) => {
  dispatch({
    type: RestaurantSchedulesTypes.PUT_RESTAURANTSCHEDULES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantSchedulesProcess = new RestaurantSchedulesProcess();
    // dont'n import the result of the process, only that dont't have error
    const restaurantSchedulesTable = await restaurantSchedulesProcess.putRestaurantSchedules(restaurantSchedules);
    return dispatch({
      type: RestaurantSchedulesTypes.PUT_RESTAURANTSCHEDULES_SUCCESS,
      payload: {
        restaurantSchedules: restaurantSchedulesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantSchedulesTypes.PUT_RESTAURANTSCHEDULES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteRestaurantSchedules = (id: number) => async (dispatch: (state: RestaurantSchedulesAction) => RestaurantSchedulesAction) => {
  dispatch({
    type: RestaurantSchedulesTypes.DELETE_RESTAURANTSCHEDULES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const restaurantSchedulesProcess = new RestaurantSchedulesProcess();
    // dont'n import the result of the process, only that dont't have error
    await restaurantSchedulesProcess.deleteRestaurantSchedules(id);
    return dispatch({
      type: RestaurantSchedulesTypes.DELETE_RESTAURANTSCHEDULES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: RestaurantSchedulesTypes.DELETE_RESTAURANTSCHEDULES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

