import { AxiosInstance, AxiosResponse } from 'axios';
import { fetcher } from '../configurations';
import {
  CampusSurveyAnswerFilters,
  CampusSurveyAnswerReportFilters,
  CampusSurveyAnswerReportTable,
  CampusSurveyAnswerTable,
} from '../../interfaces';

export class CampusSurveyAnswerRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }
  /**
   * @description Get all CampusSurveyAnswer.
   * @param filters Filter for get data
   * @returns Promise AxiosResponse
   */
  getCampusSurveyAnswer(filters?: CampusSurveyAnswerFilters): Promise<AxiosResponse<CampusSurveyAnswerTable[]>> {
    return this.lmBootApi.get<CampusSurveyAnswerTable[]>('/survey-answers/', { params: filters });
  }
  /**
   * @description Get all CampusSurveyAnswerReport.
   * @param filters Filters for get data
   * @returns Promise AxiosResponse
   */
  getCampusSurveyAnswerReport(
    filters?: CampusSurveyAnswerReportFilters,
  ): Promise<AxiosResponse<CampusSurveyAnswerReportTable[]>> {
    return this.lmBootApi.get<CampusSurveyAnswerReportTable[]>('/survey-answers-report/', { params: filters });
  }
}
