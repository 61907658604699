
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { EventReservationsFilters, EventReservationsGet, EventReservationsPost, EventReservationsPut, EventReservationsTable } from "../../interfaces";

export class EventReservationsRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getEventReservations(filters?: EventReservationsFilters): Promise<AxiosResponse<EventReservationsGet>> {
    return this.lmBootApi.get<EventReservationsGet>("/event-reservations/", { params: filters });
  }

  postEventReservations(eventReservations: EventReservationsPost): Promise<AxiosResponse<EventReservationsTable>> {
    return this.lmBootApi.post<EventReservationsTable>("/event-reservations/", eventReservations);
  }

  putEventReservations(eventReservations: EventReservationsPut): Promise<AxiosResponse<EventReservationsTable>> {
    return this.lmBootApi.put<EventReservationsTable>("/event-reservations/", eventReservations);
  }

  // dont response data
  deleteEventReservations(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/event-reservations/${id}/`);
  }
}

