export enum UserRole {
  SUPER_ADMIN = "super_admin",
  RESTAURANT_ADMIN = "restaurant_admin",
  HOTEL_ADMIN = "hotel_admin",
  EVENT_ADMIN = "event_admin",
  MULTIPLE_SERVICE_ADMIN = "multiple_service_admin",
  CONTRIBUTION_ADMIN = "contribution_admin",
  MEMBER_USER = "member_user",
  FAMILY_USER = "family_user",
  GUEST_USER = "guest_user",
  COMMON_ADMIN = "common_admin",
  CAMPUS_ADMIN = "campus_admin",
}

