import { HotelRepository } from "../repositories";
import { HotelFilters, HotelGet, HotelPost, HotelPut, HotelTable } from "../../interfaces";

export class HotelProcess {
  private multipleServiceReservationRepository: HotelRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelRepository();
  }

  async getHotel(filters?: HotelFilters): Promise<HotelGet> {
    const response = await this.multipleServiceReservationRepository.getHotel(filters);
    return response.data;
  }

  async postHotel(hotel: HotelPost): Promise<HotelTable> {
    const response = await this.multipleServiceReservationRepository.postHotel(hotel);
    return response.data;
  }

  async putHotel(hotel: HotelPut): Promise<HotelTable> {
    const response = await this.multipleServiceReservationRepository.putHotel(hotel);
    return response.data;
  }

  // dont response data
  async deleteHotel(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotel(id);
    return response.data;
  }
}
