import { MultipleServicesProcess } from "../api";
import { MultipleServicesAction, MultipleServicesFilters, MultipleServicesPost, MultipleServicesPut } from "../interfaces";
import { MultipleServicesTypes } from "../types";

// apply the reducer
export const getMultipleServices = (filters?: MultipleServicesFilters) => async (dispatch: (state: MultipleServicesAction) => MultipleServicesAction) => {
  dispatch({
    type: MultipleServicesTypes.GET_MULTIPLESERVICES_LOADING,
    payload: {
      loading: true,
      success: false
    },
  });
  try {
    const multipleServicesProcess = new MultipleServicesProcess();
    const multipleServicesPag = await multipleServicesProcess.getMultipleServices({
      ...filters,
      data_all: 'YES',
    });
    const multipleServices = multipleServicesPag.results;

    return dispatch({
      type: MultipleServicesTypes.GET_MULTIPLESERVICES_SUCCESS,
      payload: {
        listMultipleServices: multipleServices,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesTypes.GET_MULTIPLESERVICES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postMultipleServices = (multipleService: MultipleServicesPost) => async (dispatch: (state: MultipleServicesAction) => MultipleServicesAction) => {
  dispatch({
    type: MultipleServicesTypes.POST_MULTIPLESERVICES_LOADING,
    payload: {
      loading: true,
      success: false
    },
  });
  try {
    const multipleServicesProcess = new MultipleServicesProcess();
    const multipleServicesTable = await multipleServicesProcess.postMultipleServices(multipleService);
    return dispatch({
      type: MultipleServicesTypes.POST_MULTIPLESERVICES_SUCCESS,
      payload: {
        multipleServices: multipleServicesTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesTypes.POST_MULTIPLESERVICES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putMultipleServices = (multipleService: MultipleServicesPut) => async (dispatch: (state: MultipleServicesAction) => MultipleServicesAction) => {
  dispatch({
    type: MultipleServicesTypes.PUT_MULTIPLESERVICES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesProcess = new MultipleServicesProcess();
    // dont'n import the result of the process, only that dont't have error
    const multipleServiceTable = await multipleServicesProcess.putMultipleServices(multipleService);
    return dispatch({
      type: MultipleServicesTypes.PUT_MULTIPLESERVICES_SUCCESS,
      payload: {
        multipleServices: multipleServiceTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesTypes.PUT_MULTIPLESERVICES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar el servicio múltiple. Por favor, intenta más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteMultipleServices = (id: number) => async (dispatch: (state: MultipleServicesAction) => MultipleServicesAction) => {
  dispatch({
    type: MultipleServicesTypes.DELETE_MULTIPLESERVICES_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const multipleServicesProcess = new MultipleServicesProcess();
    // dont'n import the result of the process, only that dont't have error
    await multipleServicesProcess.deleteMultipleServices(id);
    return dispatch({
      type: MultipleServicesTypes.DELETE_MULTIPLESERVICES_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: MultipleServicesTypes.DELETE_MULTIPLESERVICES_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};