
import { ProfilePage, SettingsPage } from "../../pages/users";
import { UserRole } from "../../utils/enums/userRoles.enum";

export const boilerplateRoutes = [
  // BOILERPLATE
  {
    path: '/profile',
    to: '/profile',
    name: 'Perfil',
    secondMenuShow: false,
    showMenu: true,
    roles: [UserRole.SUPER_ADMIN, UserRole.HOTEL_ADMIN, UserRole.MULTIPLE_SERVICE_ADMIN,
            UserRole.EVENT_ADMIN, UserRole.RESTAURANT_ADMIN, UserRole.CAMPUS_ADMIN],
    Component: ProfilePage,
    icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
        </svg>
    )
},
{
    path: '/settings',
    to: '/settings',
    name: 'Configuración',
    secondMenuShow: false,
    showMenu: false,
    roles: [UserRole.SUPER_ADMIN, UserRole.HOTEL_ADMIN, UserRole.MULTIPLE_SERVICE_ADMIN],
    Component: SettingsPage
},
]