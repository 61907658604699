
import { SuggestionsProcess } from "../api";
import { SuggestionsAction, SuggestionsFilters, SuggestionsPost, SuggestionsPut,  } from "../interfaces";
import { SuggestionsTypes } from "../types";

export const getSuggestionsPags = (filters?: SuggestionsFilters) => async (dispatch: (state: SuggestionsAction) => SuggestionsAction) => {
  dispatch({
    type: SuggestionsTypes.GET_SUGGESTIONSPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const suggestionsProcess = new SuggestionsProcess();
    // dont'n import the result of the process, only that dont't have error
    const suggestionsPag = await suggestionsProcess.getSuggestions(filters);
    return dispatch({
      type: SuggestionsTypes.GET_SUGGESTIONSPAG_SUCCESS,
      payload: {
        pagSuggestions: suggestionsPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: SuggestionsTypes.GET_SUGGESTIONSPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getSuggestions = (filters?: SuggestionsFilters) => async (dispatch: (state: SuggestionsAction) => SuggestionsAction) => {
  dispatch({
    type: SuggestionsTypes.GET_SUGGESTIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const suggestionsProcess = new SuggestionsProcess();
    // dont'n import the result of the process, only that dont't have error
    const suggestionsPag = await suggestionsProcess.getSuggestions({
      ...filters,
      data_all: 'YES',
    });
    const suggestions = suggestionsPag.results;
    return dispatch({
      type: SuggestionsTypes.GET_SUGGESTIONS_SUCCESS,
      payload: {
        listSuggestions: suggestions,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: SuggestionsTypes.GET_SUGGESTIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postSuggestions = (suggestions: SuggestionsPost) => async (dispatch: (state: SuggestionsAction) => SuggestionsAction) => {
  dispatch({
    type: SuggestionsTypes.POST_SUGGESTIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const suggestionsProcess = new SuggestionsProcess();
    // dont'n import the result of the process, only that dont't have error
    const suggestionsTable = await suggestionsProcess.postSuggestions(suggestions);
    return dispatch({
      type: SuggestionsTypes.POST_SUGGESTIONS_SUCCESS,
      payload: {
        suggestions: suggestionsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: SuggestionsTypes.POST_SUGGESTIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putSuggestions = (suggestions: SuggestionsPut) => async (dispatch: (state: SuggestionsAction) => SuggestionsAction) => {
  dispatch({
    type: SuggestionsTypes.PUT_SUGGESTIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const suggestionsProcess = new SuggestionsProcess();
    // dont'n import the result of the process, only that dont't have error
    const suggestionsTable = await suggestionsProcess.putSuggestions(suggestions);
    return dispatch({
      type: SuggestionsTypes.PUT_SUGGESTIONS_SUCCESS,
      payload: {
        suggestions: suggestionsTable,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: SuggestionsTypes.PUT_SUGGESTIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteSuggestions = (id: number) => async (dispatch: (state: SuggestionsAction) => SuggestionsAction) => {
  dispatch({
    type: SuggestionsTypes.DELETE_SUGGESTIONS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const suggestionsProcess = new SuggestionsProcess();
    // dont'n import the result of the process, only that dont't have error
    await suggestionsProcess.deleteSuggestions(id);
    return dispatch({
      type: SuggestionsTypes.DELETE_SUGGESTIONS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: SuggestionsTypes.DELETE_SUGGESTIONS_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

