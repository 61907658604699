import { HotelImageAction, HotelImagePayload } from "../interfaces";
import { HotelImageTypes } from "../types";

const initialState: HotelImagePayload = {
  loading: false,
  success: false,
  error: null,
  hotelImage: null,
  listHotelImage: [],
  pagHotelImage: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const hotelImage = (state = initialState, action: HotelImageAction): HotelImagePayload => {
  const { loading, success, error, hotelImage, listHotelImage, pagHotelImage } = action.payload || {};
  switch (action.type) {
    case HotelImageTypes.GET_HOTELIMAGEPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelImageTypes.GET_HOTELIMAGEPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelImageTypes.GET_HOTELIMAGEPAG_SUCCESS:
      return {
        ...state,
        pagHotelImage,
        loading,
        success,
      };
    case HotelImageTypes.GET_HOTELIMAGE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelImageTypes.GET_HOTELIMAGE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelImageTypes.GET_HOTELIMAGE_SUCCESS:
      return {
        ...state,
        listHotelImage,
        loading,
        success,
      };
    case HotelImageTypes.GETONLY_HOTELIMAGE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelImageTypes.GETONLY_HOTELIMAGE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelImageTypes.GETONLY_HOTELIMAGE_SUCCESS:
      return {
        ...state,
        hotelImage,
        loading,
        success,
      };
    case HotelImageTypes.POST_HOTELIMAGE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelImageTypes.POST_HOTELIMAGE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelImageTypes.POST_HOTELIMAGE_SUCCESS:
      return {
        ...state,
        hotelImage,
        loading,
        success,
      };
    case HotelImageTypes.PUT_HOTELIMAGE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelImageTypes.PUT_HOTELIMAGE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelImageTypes.PUT_HOTELIMAGE_SUCCESS:
      return {
        ...state,
        hotelImage,
        loading,
        success,
      };
    case HotelImageTypes.DELETE_HOTELIMAGE_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case HotelImageTypes.DELETE_HOTELIMAGE_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case HotelImageTypes.DELETE_HOTELIMAGE_SUCCESS:
      return {
        ...state,
        hotelImage,
        loading,
        success,
      };
    default:
      return state;
  }
};

