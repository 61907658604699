
import { CampusNotificationsRepository } from "../repositories";
import { CampusNotificationsFilters, CampusNotificationsGet, CampusNotificationsPost, CampusNotificationsPut, CampusNotificationsTable } from "../../interfaces";

export class CampusNotificationsProcess {
  private campusNotificationsRepository: CampusNotificationsRepository;

  constructor() {
    this.campusNotificationsRepository = new CampusNotificationsRepository();
  }

  async getCampusNotifications(filters?: CampusNotificationsFilters): Promise<CampusNotificationsGet> {
    const response = await this.campusNotificationsRepository.getCampusNotifications(filters);
    return response.data;
  }

  async postCampusNotifications(campusNotifications: CampusNotificationsPost): Promise<CampusNotificationsTable> {
    const response = await this.campusNotificationsRepository.postCampusNotifications(campusNotifications);
    return response.data;
  }

  async putCampusNotifications(campusNotifications: CampusNotificationsPut): Promise<CampusNotificationsTable> {
    const response = await this.campusNotificationsRepository.putCampusNotifications(campusNotifications);
    return response.data;
  }

  // dont response data
  async deleteCampusNotifications(id: number): Promise<any> {
    const response = await this.campusNotificationsRepository.deleteCampusNotifications(id);
    return response.data;
  }
}
