import { MultipleServicesImagesRepository } from "../repositories";
import { MultipleServicesImagesFilters, MultipleServicesImagesGet, MultipleServicesImagesTable } from "../../interfaces";

export class MultipleServicesImagesProcess {
  private multipleServiceImagesRepository: MultipleServicesImagesRepository;

  constructor() {
    this.multipleServiceImagesRepository = new MultipleServicesImagesRepository();
  }

  async getMultipleServicesImages(filters?: MultipleServicesImagesFilters): Promise<MultipleServicesImagesGet> {
    const response = await this.multipleServiceImagesRepository.getMultipleServicesImages(filters);
    return response.data;
  }

  async postMultipleServicesImages(msImages: FormData): Promise<MultipleServicesImagesTable> {
    const response = await this.multipleServiceImagesRepository.postMultipleServicesImages(msImages);
    return response.data;
  }

  async putMultipleServicesImages(msImages: FormData): Promise<MultipleServicesImagesTable> {
    const response = await this.multipleServiceImagesRepository.putMultipleServicesImages(msImages);
    return response.data;
  }

  // dont response data
  async deleteMultipleServicesImages(id: number): Promise<any> {
    const response = await this.multipleServiceImagesRepository.deleteMultipleServicesImages(id);
    return response.data;
  }
}
