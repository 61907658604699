export const rolsCampusAdmin = [
  {
    value: 'super_admin',
    label: 'Super Admin',
    needSuperAdmin: true
  },
  {
    value: 'campus_admin',
    label: 'Admin Campus',
    needSuperAdmin: true
  },
  {
    value: 'restaurant_admin',
    label: 'Admin Restaurantes',
    needSuperAdmin: false
  },
  {
    value: 'hotel_admin',
    label: 'Admin Hotel',
    needSuperAdmin: false
  },
  {
    value: 'event_admin',
    label: 'Admin Eventos',
    needSuperAdmin: false
  },
  {
    value: 'multiple_service_admin',
    label: 'Admin Servicios Múltiples',
    needSuperAdmin: false
  },
  {
    value: 'member_user',
    label: 'Socio',
    needSuperAdmin: false
  },
  {
    value: 'family_user',
    label: 'Familiar',
    needSuperAdmin: false
  },
  {
    value: 'guest_user',
    label: 'Invitado',
    needSuperAdmin: false
  },
  {
    value: 'common_admin',
    label: 'Sin rol',
    needSuperAdmin: false
  },
];