
import { EventTypesAction, EventTypesPayload } from "../interfaces";
import { EventTypesTypes } from "../types";

const initialState: EventTypesPayload = {
  loading: false,
  success: false,
  error: null,
  eventTypes: null,
  listEventTypes: [],
  pagEventTypes: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const eventTypes = (state = initialState, action: EventTypesAction): EventTypesPayload => {
  const { loading, success, error, eventTypes, listEventTypes, pagEventTypes } = action.payload || {};
  switch (action.type) {
    case EventTypesTypes.GET_EVENTTYPESPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventTypesTypes.GET_EVENTTYPESPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventTypesTypes.GET_EVENTTYPESPAG_SUCCESS:
      return {
        ...state,
        pagEventTypes,
        loading,
        success,
      };
    case EventTypesTypes.GET_EVENTTYPES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventTypesTypes.GET_EVENTTYPES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventTypesTypes.GET_EVENTTYPES_SUCCESS:
      return {
        ...state,
        listEventTypes,
        loading,
        success,
      };
    case EventTypesTypes.GETONLY_EVENTTYPES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventTypesTypes.GETONLY_EVENTTYPES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventTypesTypes.GETONLY_EVENTTYPES_SUCCESS:
      return {
        ...state,
        eventTypes,
        loading,
        success,
      };
    case EventTypesTypes.POST_EVENTTYPES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventTypesTypes.POST_EVENTTYPES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventTypesTypes.POST_EVENTTYPES_SUCCESS:
      return {
        ...state,
        eventTypes,
        loading,
        success,
      };
    case EventTypesTypes.PUT_EVENTTYPES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventTypesTypes.PUT_EVENTTYPES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventTypesTypes.PUT_EVENTTYPES_SUCCESS:
      return {
        ...state,
        eventTypes,
        loading,
        success,
      };
    case EventTypesTypes.DELETE_EVENTTYPES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventTypesTypes.DELETE_EVENTTYPES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventTypesTypes.DELETE_EVENTTYPES_SUCCESS:
      return {
        ...state,
        eventTypes,
        loading,
        success,
      };
    default:
      return state;
  }
};

