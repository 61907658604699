
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { deleteCampusSurvey, getCampusSurveyPags, putCampusSurvey } from '../../redux/actions';
import { CampusSurveyFilters, CampusSurveyGet, CampusSurveyTable, User } from '../../redux/interfaces';
import { RootState } from "../../redux/store";
import { TableBody } from "../../components";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { campusSurveysStatus } from "../../utils/campusReserveStatus";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import alerts from "../../utils/alerts";

export const CampusSurveysPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user = {} as User } = useSelector((state: RootState) => state.user);
    const { pagCampusSurvey, loading } = useSelector((state: RootState) => state.campusSurvey);
    const {
        results: campusSurvey,
        count,
        next,
        previous,
    } = pagCampusSurvey as CampusSurveyGet;
    // pagination
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize] = useState(4);
    // status values
    const statusCellOptions: {
        [key: string]: {
            label: string;
            color: string;
        };
    } = {};
    for (const status of campusSurveysStatus) {
        statusCellOptions[status.value] = {
            label: status.label,
            color: status.color,
        };
    }
    // form values
    // fetch data with filters
    const fetchData = () => {
        const filters: CampusSurveyFilters = {
            campus_id: user.campus.id,
            page: pageCurrent,
            page_size: pageSize,
        }
        getCampusSurveyPags(filters)(dispatch);
    }
    useEffect(() => {
        fetchData();
    }, [pageCurrent, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

    // Code for table
    type CampusSurveyType = {
        administrator: string;
        title: string;
        state: any;
        actions: any;
    };

    const { data, columns } = useTableMemo<CampusSurveyType>({
        dataMemo: {
            factory: campusSurvey.map((survey) => {
                return {
                    administrator: survey.user.first_name,
                    title: survey.title,
                    state: survey,
                    actions: survey
                };
            }),
            deps: [campusSurvey]
        },
        columnsMemo: {
            factory: [
                {
                    Header: 'Administrador',
                    accessor: 'administrator',
                },
                {
                    Header: 'Encuesta',
                    accessor: 'title',
                },
                {
                    Header: 'Estado',
                    accessor: 'state',
                },
                {
                    Header: 'Acciones',
                    accessor: 'actions',
                },
            ],
            deps: []
        }
    })

    const handleUpdateStatus = async (status: string, survey: CampusSurveyTable) => {
        const { payload } = await putCampusSurvey({
            ...survey,
            user_id: survey.user.id,
            status: status,
            campus_id: survey.campus.id,
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        toast.success('Estado de la encuesta actualizada con éxito.');
        fetchData();
    }

    const handleDeleteSurvey = async (survey: CampusSurveyTable) => {
        const { isConfirmed } = await alerts.warning({
            title: 'Última oportunidad',
            text: `Está apunto de eliminar <b>"${survey.title}"</b>. Una vez realizada esta acción no se podrá revertir.`,
        });

        if (isConfirmed) {
            const { payload } = await deleteCampusSurvey(survey.id)(dispatch);
            const { success } = payload;
            if (success) {
                toast.success('Encuesta eliminada con éxito.');
                fetchData();
            } else {
                toast.error(payload.error?.message, { type: 'error' });
            }
        }
    };


    return (
        <>
            <div className="mb-8 flex flex-row justify-end gap-4 print:hidden">
                <div className="flex items-center">
                <button
                    onClick={() =>
                        navigate('/campus/surveys/new')
                    }
                    className="btn-yellow flex flex-row items-center gap-2 h-11"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Añadir nueva encuesta
                </button>
                </div>
            </div>
            <div className="overflow-hidden">
                <TableBody
                    columns={columns} data={data} pageSize={pageSize} loading={loading}
                    renderRows={row => (
                        <tr
                            className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50"
                            {...row.getRowProps()}
                        >
                            {
                                row.cells.map((cell) => {
                                    if (cell.column.Header === 'Estado') {
                                        const survey: CampusSurveyTable = (cell.render('Cell') as any).props.value;
                                        // two buttons with taildwind
                                        return (
                                            <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                                <select
                                                    id="newState"
                                                    name="newState"
                                                    value={survey.status}
                                                    onChange={(e) => handleUpdateStatus(e.target.value, survey)}
                                                    className="select-yellow"
                                                >
                                                    {
                                                        campusSurveysStatus.map((status) => (
                                                            <option value={status.value}>{status.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Encuesta') {
                                        return (
                                            <td
                                                className="px-6 py-4"
                                                {...cell.getCellProps()}
                                            >
                                                <div className="truncate max-w-xs">
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Acciones') {
                                        const survey: CampusSurveyTable = (cell.render('Cell') as any).props.value;
                                        // two buttons with taildwind
                                        return (
                                            <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                                                <div className="flex flex-row justify-start gap-2">
                                                    <button
                                                    onClick={() =>
                                                        navigate('/campus/surveys/report', {
                                                        state: {
                                                            survey
                                                        },
                                                        })
                                                    }
                                                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                                                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                                                        </svg>
                                                    </button>
                                                    {
                                                        survey.is_editable ? (
                                                            <button
                                                                onClick={() =>
                                                                    navigate('/campus/surveys/edit', {
                                                                    state: {
                                                                        survey
                                                                    },
                                                                    })
                                                                }
                                                                className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5 text-gray-700"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                </svg>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                onClick={() => toast.info('No se puede editar una encuesta una vez que alguien ha contestado.')}
                                                                className="cursor-auto rounded-lg p-1.5 bg-gray-200 text-white"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5 "
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                </svg>
                                                            </button>
                                                        )
                                                    }
                                                    <button
                                                    onClick={() => handleDeleteSurvey(survey)}
                                                    className="cursor-pointer rounded-lg p-1.5 hover:bg-gray-200 active:bg-gray-300"
                                                    >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 text-gray-700"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                        fillRule="evenodd"
                                                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                        clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                                </div>
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            className="whitespace-nowrap px-6 py-3"
                                            {...cell.getCellProps()}
                                        >
                                            <span className="inline-block first-letter:capitalize">{cell.render('Cell')}</span>
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    )}
                    // For footer
                    count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
                    blankMessage="No hay encuestas"
                />
            </div>
        </>
    );
};
