import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addSeconMenuItems, getMultipleServicesCategory, getMultipleServicesReservationPags, getMultipleServicesSchedule, putMultipleServicesReservation } from "../../redux/actions";
import { MultipleServicesReservationFilters, MultipleServicesReservationGet, MultipleServicesReservationPayload, MultipleServicesReservationPut, SecondMenuItemsType, User } from "../../redux/interfaces";
import { TableBody } from "../../components";
import { useTableMemo } from "../../utils/hooks/useTableMemo";
import { RootState } from "../../redux/store";
import { useFormik } from "formik";

export const ServiceBookingsPage = () => {
  // Fetch data from the server
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { pagMultipleServicesReservation, loading }: MultipleServicesReservationPayload = useSelector((state: any) => state.multipleServicesReservation);
  const { results: msReservation, count, next, previous } = pagMultipleServicesReservation as MultipleServicesReservationGet;
  // pagination
  const { listMultipleServicesSchedule } = useSelector((state: RootState)  => state.multipleServicesSchedule);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize] = useState(6);
  const [msScheduleId, setMsScheduleId] = useState(-1);
  const [startDate, setStartDate] = useState(new Date(new Date().setHours(0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(new Date().setHours(23, 59, 59)));
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { getFieldProps, values } = useFormik({
    initialValues: {
      scheduleRange: '',
    },
    onSubmit: () => {}
  });

  const pullMultipleServicesReservationPags = () => {
    const filters: MultipleServicesReservationFilters = {
      page: pageCurrent,
      page_size: pageSize,
      multiple_service_schedule__multiple_service__multiple_service_category__campus_id: user.campus.id,
      multiple_service_schedule__multiple_service_id: msScheduleId,
      status: 'APPROVED',
      start_date__gte: new Date(new Date().setHours(0, 0, 0)).toISOString(),
      end_date__lte: new Date(new Date().setHours(23, 59, 59)).toISOString(),
    }
    if (startDate && endDate) {
        filters['start_date__gte'] = !values.scheduleRange ? new Date(startDate.setHours(0, 0, 0, 0)).toISOString() : new Date(startDate.setHours(parseInt(values.scheduleRange.split('-')[0].split(':')[0]), parseInt(values.scheduleRange.split('-')[0].split(':')[1]), 0, 0)).toISOString();
        filters['end_date__lte'] = !values.scheduleRange ? new Date(endDate.setHours(23, 59, 59, 0)).toISOString() : new Date(endDate.setHours(parseInt(values.scheduleRange.split('-')[1].split(':')[0]), parseInt(values.scheduleRange.split('-')[1].split(':')[1]), 0, 0)).toISOString();
    }
    getMultipleServicesReservationPags(filters)(dispatch);
  }

  useEffect(() => {
    pullMultipleServicesReservationPags();
    if (!endDate) return;
    const dayNames = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
    getMultipleServicesSchedule({
      day__icontains: dayNames[new Date(endDate).getDay()],
      multiple_service_id: msScheduleId,
    })(dispatch);
  }, [pageCurrent, pageSize, msScheduleId, endDate, values.scheduleRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pullCategoriesForFilters = async () => {
      const { payload } = await getMultipleServicesCategory({
        campus_id: user.campus.id,
      })(dispatch);
      const listMultipleServicesCategory = payload.listMultipleServicesCategory || [];
      const menuItemsServiceBookings: SecondMenuItemsType[] = listMultipleServicesCategory.map((item, i) => {
        return {
          label: item.name,
          activeTab: i === 0,
          children: item.multiple_service.map((ms, j) => {
            if (j === 0) { setMsScheduleId(ms.id); }
            return {
              label: ms.name,
              activeTab: j === 0,
              callback() {
                setMsScheduleId(ms.id);
              }
            };
          })
        }
      });
      addSeconMenuItems([...menuItemsServiceBookings])(dispatch);
    };
    pullCategoriesForFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBookingPut = async (msReservation: MultipleServicesReservationPut, status: string) => {
    const { payload } = await putMultipleServicesReservation({
      ...msReservation,
      status
    })(dispatch);
    if (!payload.success) return toast.error('Tuvimos un problema al actualizar el estado de la reserva. Por favor intentelo más tarde.');
    toast.success('Estado de la Reserva actualizada con éxito.');
    pullMultipleServicesReservationPags();
  }

  // Code for table
  type MultipleServicesReservationType = {
    people_total: number;
    start_date: string;
    first_name: string;
    status: MultipleServicesReservationPut;
  };

  const { data, columns } = useTableMemo<MultipleServicesReservationType>({
    dataMemo: {
        factory: msReservation.map((multipleServicesReservation) => {
          return {
            people_total: multipleServicesReservation.people_total,
            start_date: multipleServicesReservation.start_date,
            first_name: multipleServicesReservation.user.first_name,
            status: {
              id: multipleServicesReservation.id,
              status: multipleServicesReservation.status,
              start_date: multipleServicesReservation.start_date,
              end_date: multipleServicesReservation.end_date,
              family_number: multipleServicesReservation.family_number,
              guest_number:  multipleServicesReservation.guest_number,
              member_number: multipleServicesReservation.member_number,
              people_total: multipleServicesReservation.people_total,
              multiple_service_schedule_id: multipleServicesReservation.multiple_service_schedule.id,
              user_id: multipleServicesReservation.user.id,
            },
          }
        }),
        deps: [msReservation]
    },
    columnsMemo: {
        factory: [
          {
            Header: 'Socio',
            accessor: 'first_name', // accessor is the "key" in the data
          },
          {
            Header: 'Personas',
            accessor: 'people_total',
          },
          {
            Header: 'Hora de Entrada',
            accessor: 'start_date',
          },
          {
            Header: 'Estado',
            accessor: 'status',
          }
        ],
        deps: [],
    }
  });

  const ExampleCustomInput = forwardRef<any>(({ value, onClick }: any, ref) => (
    <button className="first-letter:capitalize bg-gray-50 font-bold flex flex-row gap-4 px-4 py-3 rounded-lg hover:bg-gray-200 active:bg-gray-300 text-gray-600 print:hidden" onClick={onClick} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      {value}
    </button>
  ));

  return (
    <>
      <div className="flex flex-row justify-end mb-4">
        <DatePicker
          dateFormat="MMMM d, yyyy"
          selected={startDate}
          locale={es}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          customInput={<ExampleCustomInput />}
          placeholderText="Rango de fecha"
          monthsShown={2}
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
          }: any) => (
            <div>
              <button
                aria-label="Mes anterior"
                className="react-datepicker__navigation react-datepicker__navigation--previous top-2"
                style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
                onClick={decreaseMonth}
              >
                <span
                  className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month first-letter:capitalize">
                {monthDate.toLocaleString("es-ES", {
                    month: "long",
                    year: "numeric",
                }).replace(/\b\w/, (l: string) => l.toUpperCase())}
              </span>
              <button
                aria-label="Mes siguiente"
                className="react-datepicker__navigation react-datepicker__navigation--next top-2"
                style={customHeaderCount === 0 ? { visibility: "hidden" } : undefined}
                onClick={increaseMonth}
              >
                <span
                  className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
        />
        <select
          className="select-yellow font-bold"
          {...getFieldProps('scheduleRange')}
        >
          <option value="">Rangos de horarios (Todos)</option>
          {
            listMultipleServicesSchedule && listMultipleServicesSchedule.map((multipleServicesSchedule) => {
              return (
                <option key={multipleServicesSchedule.id} value={`${multipleServicesSchedule.start_time}-${multipleServicesSchedule.end_time}`}>{multipleServicesSchedule.day} {multipleServicesSchedule.start_time} - {multipleServicesSchedule.end_time} </option>
              )
            })
          }
        </select>
      </div>
      <div className="overflow-hidden">
      <TableBody
          columns={columns} data={data} pageSize={pageSize} loading={loading}
          renderRows={row => (
            // Apply the row props
            <tr className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50" {...row.getRowProps()}>
              {// Loop over the rows cells
                row.cells.map(cell => {
                  // Apply the cell props
                  if (cell.column.Header === 'Estado') {
                    // two buttons with taildwind
                    return (
                      <td className="px-6 py-4 text-left" {...cell.getCellProps()}>
                        <div className="flex flex-row justify-start gap-2">
                          <button
                            onClick={() => handleBookingPut((cell.render('Cell') as any).props.value, 'FINISHED')}
                            className="bg-green-500 font-bold p-1 rounded-full hover:bg-green-400 active:bg-green-300 text-white print:hidden"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleBookingPut((cell.render('Cell') as any).props.value, 'INCOMPLETED')}
                            className="bg-red-500 font-bold p-1 rounded-full hover:bg-red-400 active:bg-red-300 text-white print:hidden"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          </button>
                        </div>
                      </td>
                    );
                  }

                  if (cell.column.Header === 'Hora de Entrada') {
                    const dateCell = new Date((cell.render('Cell') as any).props.value).toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit'
                    });
                    return (
                      <td className="px-6 py-4 text-left text-black flex flex-row gap-2 items-center" {...cell.getCellProps()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5 text-gray-500 print:hidden" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                        </svg>
                        {dateCell}
                      </td>
                    );
                  }
                  return (
                    <td className="px-6 py-3 whitespace-nowrap" {...cell.getCellProps()}>
                      {// Render the cell contents
                        cell.render('Cell')}
                    </td>
                  )
                })}
            </tr>
          )}
          // For footer
          count={count} next={next} pageCurrent={pageCurrent} previous={previous} setPageCurrent={setPageCurrent}
      />
      </div>
    </>
  )
}
