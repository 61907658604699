
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { EventWireTransfersFilters, EventWireTransfersGet, EventWireTransfersTable } from "../../interfaces";

export class EventWireTransfersRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getEventWireTransfers(filters?: EventWireTransfersFilters): Promise<AxiosResponse<EventWireTransfersGet>> {
    return this.lmBootApi.get<EventWireTransfersGet>("/event-wire-transfers/", { params: filters });
  }

  postEventWireTransfers(eventWireTransfers: FormData): Promise<AxiosResponse<EventWireTransfersTable>> {
    return this.lmBootApi.post<EventWireTransfersTable>("/event-wire-transfers/", eventWireTransfers);
  }

  putEventWireTransfers(eventWireTransfers: FormData): Promise<AxiosResponse<EventWireTransfersTable>> {
    return this.lmBootApi.put<EventWireTransfersTable>("/event-wire-transfers/", eventWireTransfers);
  }

  // dont response data
  deleteEventWireTransfers(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/event-wire-transfers/${id}/`);
  }
}

