import { AxiosInstance, AxiosResponse } from 'axios';
import { fetcher } from '../configurations';
import { ParametersFilters, ParametersGet, ParametersPost, ParametersPut, ParametersTable } from '../../interfaces';

export class ParametersRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getParameters(filters?: ParametersFilters): Promise<AxiosResponse<ParametersGet>> {
    return this.lmBootApi.get<ParametersGet>('/parameters/', { params: filters });
  }
  getParametersPublic(filters?: ParametersFilters): Promise<AxiosResponse<ParametersGet>> {
    return this.lmBootApi.get<ParametersGet>('/parameters-public/', { params: filters });
  }

  postParameters(parameters: ParametersPost): Promise<AxiosResponse<ParametersTable>> {
    return this.lmBootApi.post<ParametersTable>('/parameters/', parameters);
  }

  putParameters(parameters: ParametersPut): Promise<AxiosResponse<ParametersTable>> {
    return this.lmBootApi.put<ParametersTable>('/parameters/', parameters);
  }

  // dont response data
  deleteParameters(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/parameters/${id}/`);
  }
}
