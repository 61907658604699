
import { RestaurantLunchesRepository } from "../repositories";
import { RestaurantLunchesFilters, RestaurantLunchesGet, RestaurantLunchesTable } from "../../interfaces";

export class RestaurantLunchesProcess {
  private restaurantLunchesRepository: RestaurantLunchesRepository;

  constructor() {
    this.restaurantLunchesRepository = new RestaurantLunchesRepository();
  }

  async getRestaurantLunches(filters?: RestaurantLunchesFilters): Promise<RestaurantLunchesGet> {
    const response = await this.restaurantLunchesRepository.getRestaurantLunches(filters);
    return response.data;
  }

  async postRestaurantLunches(restaurantLunches: FormData): Promise<RestaurantLunchesTable> {
    const response = await this.restaurantLunchesRepository.postRestaurantLunches(restaurantLunches);
    return response.data;
  }

  async putRestaurantLunches(restaurantLunches: FormData): Promise<RestaurantLunchesTable> {
    const response = await this.restaurantLunchesRepository.putRestaurantLunches(restaurantLunches);
    return response.data;
  }

  // dont response data
  async deleteRestaurantLunches(id: number): Promise<any> {
    const response = await this.restaurantLunchesRepository.deleteRestaurantLunches(id);
    return response.data;
  }
}
