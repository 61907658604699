
export function ButtonSpinner({ isLoading, text, name = '', type = 'button', className, color = 'yellow', ...props }:
  { isLoading: boolean, text: string, type: "button" | "submit" | "reset" | undefined, className?: string, name?: string, color?: string }) {
  const colors: any = {
    yellow: {
      button: 'btn-yellow',
      spinner: 'text-black'
    },
    black: {
      button: 'btn-black',
      spinner: 'text-white'
    }
  }
  return (
    <button
      type={type}
      name={name}
      className={`inline-flex items-center justify-center px-4 py-2 w-full font-bold leading-6 text-sm shadow rounded-md ${colors[color].button} transition ease-in-out duration-150 ${isLoading ? 'cursor-not-allowed' : ''} ${className}`}
      disabled={isLoading}
    >
      {isLoading ? (
        <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 ${colors[color].spinner}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      ) : ''}
      {isLoading ? 'Cargando...' : text}
    </button>
  )
}
