
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { UserGroupFilters, UserGroupGet, UserGroupPost, UserGroupPut, UserGroupTable } from "../../interfaces";

export class UserGroupRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getUserGroup(filters?: UserGroupFilters): Promise<AxiosResponse<UserGroupGet>> {
    return this.lmBootApi.get<UserGroupGet>("/groups/", { params: filters });
  }

  postUserGroup(userGroup: UserGroupPost): Promise<AxiosResponse<UserGroupTable>> {
    return this.lmBootApi.post<UserGroupTable>("/groups/", userGroup);
  }

  putUserGroup(userGroup: UserGroupPut): Promise<AxiosResponse<UserGroupTable>> {
    return this.lmBootApi.put<UserGroupTable>(`/groups/${userGroup.id}/`, userGroup);
  }

  // dont response data
  deleteUserGroup(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/groups/${id}/`);
  }
}

