
import { CampusSurveyAction, CampusSurveyPayload } from "../interfaces";
import { CampusSurveyTypes } from "../types";

const initialState: CampusSurveyPayload = {
  loading: false,
  success: false,
  error: null,
  campusSurvey: null,
  listCampusSurvey: [],
  pagCampusSurvey: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const campusSurvey = (state = initialState, action: CampusSurveyAction): CampusSurveyPayload => {
  const { loading, success, error, campusSurvey, listCampusSurvey, pagCampusSurvey } = action.payload || {};
  switch (action.type) {
    case CampusSurveyTypes.GET_CAMPUSSURVEYPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyTypes.GET_CAMPUSSURVEYPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyTypes.GET_CAMPUSSURVEYPAG_SUCCESS:
      return {
        ...state,
        pagCampusSurvey,
        loading,
        success,
      };
    case CampusSurveyTypes.GET_CAMPUSSURVEY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyTypes.GET_CAMPUSSURVEY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyTypes.GET_CAMPUSSURVEY_SUCCESS:
      return {
        ...state,
        listCampusSurvey,
        loading,
        success,
      };
    case CampusSurveyTypes.GETONLY_CAMPUSSURVEY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyTypes.GETONLY_CAMPUSSURVEY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyTypes.GETONLY_CAMPUSSURVEY_SUCCESS:
      return {
        ...state,
        campusSurvey,
        loading,
        success,
      };
    case CampusSurveyTypes.POST_CAMPUSSURVEY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyTypes.POST_CAMPUSSURVEY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyTypes.POST_CAMPUSSURVEY_SUCCESS:
      return {
        ...state,
        campusSurvey,
        loading,
        success,
      };
    case CampusSurveyTypes.PUT_CAMPUSSURVEY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyTypes.PUT_CAMPUSSURVEY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyTypes.PUT_CAMPUSSURVEY_SUCCESS:
      return {
        ...state,
        campusSurvey,
        loading,
        success,
      };
    case CampusSurveyTypes.DELETE_CAMPUSSURVEY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case CampusSurveyTypes.DELETE_CAMPUSSURVEY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case CampusSurveyTypes.DELETE_CAMPUSSURVEY_SUCCESS:
      return {
        ...state,
        campusSurvey,
        loading,
        success,
      };
    default:
      return state;
  }
};

