
import { EventSaloonsRepository } from "../repositories";
import { EventSaloonsFilters, EventSaloonsGet, EventSaloonsPost, EventSaloonsPut, EventSaloonsTable } from "../../interfaces";

export class EventSaloonsProcess {
  private eventSaloonsRepository: EventSaloonsRepository;

  constructor() {
    this.eventSaloonsRepository = new EventSaloonsRepository();
  }

  async getEventSaloons(filters?: EventSaloonsFilters): Promise<EventSaloonsGet> {
    const response = await this.eventSaloonsRepository.getEventSaloons(filters);
    return response.data;
  }

  async postEventSaloons(eventSaloons: EventSaloonsPost): Promise<EventSaloonsTable> {
    const response = await this.eventSaloonsRepository.postEventSaloons(eventSaloons);
    return response.data;
  }

  async putEventSaloons(eventSaloons: EventSaloonsPut): Promise<EventSaloonsTable> {
    const response = await this.eventSaloonsRepository.putEventSaloons(eventSaloons);
    return response.data;
  }

  // dont response data
  async deleteEventSaloons(id: number): Promise<any> {
    const response = await this.eventSaloonsRepository.deleteEventSaloons(id);
    return response.data;
  }
}
