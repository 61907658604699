export enum RegisterTypes {
  CREATE_USER_LOADING = "CREATE_USER_LOADING",
  CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE = "CREATE_USER_FAILURE",
  GET_USER_DATA_LOADING = "GET_USER_DATA_LOADING",
  GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE",
  GET_LIST_CAMPUS_LOADING = "GET_LIST_CAMPUS_LOADING",
  GET_LIST_CAMPUS_SUCCESS = "GET_LIST_CAMPUS_SUCCESS",
  GET_LIST_CAMPUS_FAILURE = "GET_LIST_CAMPUS_FAILURE",
}