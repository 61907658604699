import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { Modal } from '../../components';
import { useEffect, useState } from 'react';
import { getEventCoordinators, getEventReservations, getUserById, putEventReservations } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { EventReservationsTable, User } from '../../redux/interfaces';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { hotelPaymentMethods } from '../../utils/hotelPaymentMethods';
import { RootState } from '../../redux/store';
import useForm from '../../utils/hooks/useForm';

export const EventCalendarPage = () => {
  const dispatch = useDispatch();
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const { listEventCoordinators: listEventCoordinatorsFull = [] } = useSelector((state: RootState) => state.eventCoordinators);
  // slice first item from listEventCoordinators
  const listEventCoordinators = listEventCoordinatorsFull.slice(1);
  const [isOpen, setIsOpen] = useState(false);
  const [reservationModalData, setReservationModalData] = useState<ReservationModalData>();
  const [reservationModalDataPaids, setReservationModalDataPaids] = useState<any>({
    has_paid: true
  });
  const [scheduleReservations, setScheduleReservations] = useState<ScheduleType[]>([]);

  const { eventCoordinatorId, handleOnChangeInput, handleUpdateField } = useForm({
    eventCoordinatorId: ''
  });
  interface ScheduleType {
    title: string;
    date: string;
    data: any;
  }
  interface ReservationModalData {
    reservation: EventReservationsTable;
    [key: string]: any;
  }
  interface ReservationStatusType {
    [key: string]: {
      labelState: string;
      labelStateColor: string;
      btnSuccessColor: string;
      btnDangerColor: string;
      btnSuccessText: string;
      btnDangerText: string;
      btnSuccessShow: boolean;
      btnDangerShow: boolean;
      btnSuccessClick: (...args: any) => void,
      btnDangerClick: (...args: any) => void,
      introSectionColor: string;
      endSectionColor: string;
      cardBgColor: string;
      cardTextColor: string;
      cardIcon: any;
    }
  }
  const reservationStatus: ReservationStatusType = {
    PENDING: {
      labelState: 'Pendiente',
      labelStateColor: 'bg-yellow-500',
      btnSuccessColor: 'bg-yellow-500 text-black disabled:text-gray-500 disabled:bg-yellow-200 disabled:hover:bg-yellow-200',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Aceptar Solicitud',
      btnDangerText: 'Rechazar',
      btnSuccessShow: true,
      btnDangerShow: true,
      btnSuccessClick: async (reservation: EventReservationsTable, eventCoordinatorId: string) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: parseInt(eventCoordinatorId),
          event_salon_id: reservation.event_salon.id,
          status: 'RESERVED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      btnDangerClick: async (reservation: EventReservationsTable, eventCoordinatorId: string) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: 1,
          event_salon_id: reservation.event_salon.id,
          status: 'CANCELLED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      introSectionColor: 'bg-gray-200',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-yellow-100 border-yellow-500',
      cardTextColor: 'text-yellow-500',
      cardIcon: <></>,
    },
    RESERVED: {
      labelState: 'Reservado',
      labelStateColor: 'bg-orange-500',
      btnSuccessColor: 'bg-orange-500 text-white disabled:text-gray-500 disabled:bg-orange-300 disabled:hover:bg-orange-300',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Contrato',
      btnDangerText: 'Cancelar',
      btnSuccessShow: true,
      btnDangerShow: true,
      btnSuccessClick: async (reservation: EventReservationsTable, eventCoordinatorId: string) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: parseInt(eventCoordinatorId),
          event_salon_id: reservation.event_salon.id,
          status: 'CONTRACTED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: 1,
          event_salon_id: reservation.event_salon.id,
          status: 'CANCELLED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      introSectionColor: 'bg-orange-100',
      endSectionColor: 'bg-orange-50',
      cardBgColor: 'bg-orange-100 border-orange-500',
      cardTextColor: 'text-orange-500',
      cardIcon: <></>,
    },
    CONTRACTED: {
      labelState: 'Firmado contrato',
      labelStateColor: 'bg-green-500',
      btnSuccessColor: 'bg-green-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Facturado',
      btnDangerText: 'Cancelar',
      btnSuccessShow: true,
      btnDangerShow: true,
      btnSuccessClick: async (reservation: EventReservationsTable, eventCoordinatorId: string) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: parseInt(eventCoordinatorId),
          event_salon_id: reservation.event_salon.id,
          status: 'INVOICED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: 1,
          event_salon_id: reservation.event_salon.id,
          status: 'CANCELLED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      introSectionColor: 'bg-green-100',
      endSectionColor: 'bg-green-50',
      cardBgColor: 'bg-green-100 border-green-500',
      cardTextColor: 'text-green-500',
      cardIcon: <></>,
    },
    INVOICED: {
      labelState: 'Facturado',
      labelStateColor: 'bg-violet-500',
      btnSuccessColor: 'bg-violet-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Check-out Completo',
      btnDangerText: 'Cancelar',
      btnSuccessShow: false,
      btnDangerShow: false,
      btnSuccessClick: async (reservation: EventReservationsTable, eventCoordinatorId: string) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: parseInt(eventCoordinatorId),
          event_salon_id: reservation.event_salon.id,
          status: 'BY_CONFIRMED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      btnDangerClick: async (reservation) => {
        const { payload } = await putEventReservations({
          ...reservation,
          user_id: reservation.user.id,
          event_type_id: reservation.event_type.id,
          event_coordinator_id: 1,
          event_salon_id: reservation.event_salon.id,
          status: 'CANCELLED',
          total_price: parseFloat(reservation.total_price),
        })(dispatch);
        if (!payload.success && payload.error) return toast.error(payload.error.message);
        setIsOpen(false);
        toast.success('Estado de la Reserva actualizada con éxito.');
        fetchReservations();
      },
      introSectionColor: 'bg-violet-100',
      endSectionColor: 'bg-violet-50',
      cardBgColor: 'bg-violet-100 border-violet-500',
      cardTextColor: 'text-violet-500',
      cardIcon: <></>,
    },
    CANCELLED: {
      labelState: 'Cancelado',
      labelStateColor: 'bg-gray-500',
      btnSuccessColor: 'bg-gray-500 text-white',
      btnDangerColor: 'bg-gray-200',
      btnSuccessText: 'Check-in Completo',
      btnDangerText: 'El socio no se presentó',
      btnSuccessShow: false,
      btnDangerShow: false,
      btnSuccessClick: () => {},
      btnDangerClick: () => {},
      introSectionColor: 'bg-gray-100',
      endSectionColor: 'bg-gray-100',
      cardBgColor: 'bg-gray-100 border-gray-500',
      cardTextColor: 'text-gray-500',
      cardIcon: <></>,
    }
  };

  const fetchReservations = async () => {
    const { payload } = await getEventReservations({
      event_type__campus_id: user.campus.id
    })(dispatch);
    if (!payload.success && payload.error) { return toast.error(payload.error.message) }
    const listEventReservations = payload.listEventReservations as EventReservationsTable[];
    const listHotelReservationFiltered = listEventReservations.filter(reservation => reservation.status !== 'CANCELED');
    setScheduleReservations(listHotelReservationFiltered.map(reservation => ({
      title: reservation.event_salon.name,
      date: reservation.start_date,
      data: {
        reservation: {
          ...reservation
        } as EventReservationsTable,
        ...reservationStatus[reservation.status]
      }
    })));
  }

  useEffect(() => {
    fetchReservations();
    getEventCoordinators({
      campus_id: user.campus.id
    })(dispatch);
  }, []); // eslint-disable-line

  const renderEventContent = (eventInfo: any) => {
    const {cardIcon, cardTextColor, cardBgColor } = eventInfo.event.extendedProps.data;
    const reservation: EventReservationsTable = eventInfo.event.extendedProps.data.reservation;
    const { start_date } = reservation;
    const dateStart = new Date(start_date).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return (
      <div className={`relative border-0 border-l-4 px-2 py-1 text-black rounded-md cursor-pointer w-full ${cardBgColor}`}>
        <p className={`text-xs font-semibold text-stone-700`}>{dateStart}</p>
        <h1 className="font-bold text-base text-ellipsis whitespace-nowrap overflow-hidden">{eventInfo.event.title}</h1>
        <p className={`text-xs font-semibold text-stone-700`}>{reservation.event_type.name}</p>
        <span className={`absolute top-2 right-2 ${cardTextColor}`}>
          {cardIcon}
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-row justify-end gap-4 mb-10">
        <NavLink
          to="/event/calendar/table"
          className={({ isActive }) => isActive ? 'btn-yellow max-h-9 min-w-[120px]' : 'btn-gray max-h-9 min-w-[120px]'}
        >
          Lista
        </NavLink>
        <NavLink
          to="/event/calendar"
          className={({ isActive }) => isActive ? 'btn-yellow max-h-9 min-w-[120px]' : 'btn-gray max-h-9 min-w-[120px]'}
        >
          Calendario
        </NavLink>
      </div>
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        eventContent={renderEventContent}
        events={scheduleReservations}
        dayMaxEventRows={2}
        locale="es"
        locales={[ esLocale ]}
        eventClick={async (info: any) => {
          setIsOpen(true);
          setReservationModalData(info.event.extendedProps.data);
          handleUpdateField('eventCoordinatorId', info.event.extendedProps.data.reservation.event_coordinator.id === 1 ? '' : info.event.extendedProps.data.reservation.event_coordinator.id);
          const { payload } = await getUserById(info.event.extendedProps.data.reservation.user.id)(dispatch);
          if (payload.error) { return toast.error('Tuvimos problemas trayendo los pagos del miembro. Por favor intentelo más tarde.') }
          setReservationModalDataPaids(payload.userById);
        }}
      />
      <section className="flex flex-row gap-4 py-8">
          {
            Object.entries(reservationStatus).map(([_, reservation]) => (
              <div className="flex flex-row items-center gap-2">
                  <div className={`h-5 w-5 rounded-full ${reservation.labelStateColor}`} />
                  {reservation.labelState}
              </div>
            ))
          }
      </section>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} className="max-w-3xl" >
        <div className="p-3">
          <section className="flex flex-row justify-between bg-yellow-200 rounded-lg p-4 my-8">
            <section className="flex flex-row gap-4 items-center">
              {!reservationModalData?.reservation?.user.photo_url
                  ? <img className="block h-14 aspect-square rounded-full object-cover hover:opacity-80 active:opacity-70" src="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Profile" />
                  : <img className="block h-14 aspect-square rounded-full object-cover hover:opacity-80 active:opacity-70" src={reservationModalData?.reservation?.user.photo_url} alt="Profile" />
              }
              <div className="flex flex-col justify-center items-start gap-1">
                <h2 className="font-bold">{reservationModalData?.reservation?.user.first_name}</h2>
                {
                  reservationModalDataPaids && reservationModalDataPaids.has_paid ? (
                    <span className="bg-white rounded-xl py-1 px-3 text-green-700 text-sm">Al día</span>
                  ) : (
                    <span className="bg-white rounded-xl py-1 px-3 text-red-500 text-sm">{`Pago retrasado ($${reservationModalDataPaids.amount_pay})`}</span>
                  )
                }
              </div>
            </section>
            <section className="text-right">
              <h2 className="font-bold">Correo</h2>
              <p>{reservationModalData?.reservation?.user.email}</p>
            </section>
            <section className="text-right">
              <h2 className="font-bold">Teléfono</h2>
              <p>{reservationModalData?.reservation?.user.phone}</p>
            </section>
          </section>
          <section className="flex flex-row justify-between gap-6 mb-6">
            <div className="grow">
              <h1 className="font-bold text-lg mb-2">{reservationModalData?.reservation?.event_type?.name}</h1>
              <p className="mb-4 font-bold">Salon : {reservationModalData?.reservation?.event_salon?.name}</p>
              <p className="mb-4">{reservationModalData?.reservation?.event_package}</p>
              <span className="flex flex-row items-center gap-4 mb-4">
                <span className="p-2 bg-gray-200 rounded-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </span>
                <span>{hotelPaymentMethods[reservationModalData?.reservation?.payment_method || 'CASH']}</span>
              </span>
              <select
                  id="eventCoordinatorId"
                  name="eventCoordinatorId"
                  value={eventCoordinatorId}
                  onChange={handleOnChangeInput}
                  required
                  className="select-yellow text-sm h-9"
              >
                  <option value="" disabled>Seleccione un Coordinador</option>
                  {
                      listEventCoordinators && listEventCoordinators.map((coordinator) => (
                          <option key={`coordinator-${coordinator.id}`} value={coordinator.id}>{coordinator.name}</option>
                      ))
                  }
              </select>
              <div className="mt-6 mb-4 w-full border-0 border-b-2 border-gray-200"></div>
              <p className="flex flex-row justify-between mb-1 text-gray-500">Costo de reservación: <span>${reservationModalData?.reservation?.total_price}</span></p>
            </div>
            <div>
              <div className={`text-gray-600 rounded-t-xl p-4 max-w-xs ${reservationModalData?.introSectionColor}`}>
                <h3 className="mb-2">Entrada</h3>
                <p className="flex flex-row items-center gap-2 mb-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                    </svg>
                  </span>
                  {
                    // hide hours for all dates
                    new Date(reservationModalData?.reservation?.start_date || "").toLocaleTimeString('es-ES', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }).split(' ').splice(0, 6).join(' ')
                  }
                </p>
                <p className="flex flex-row items-center gap-2 mb-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                    </svg>
                  </span>
                  {
                    new Date(reservationModalData?.reservation?.start_date || "").toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })
                  }
                </p>
              </div>
              <div className={`text-gray-600 rounded-b-xl p-4 max-w-xs ${reservationModalData?.endSectionColor}`}>
                <h3 className="mb-2">Salida</h3>
                <p className="flex flex-row items-center gap-2 mb-2">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                    </svg>
                  </span>
                  {
                    new Date(reservationModalData?.reservation?.end_date || "").toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })
                  }
                </p>
              </div>
            </div>
          </section>
          <footer className="flex flex-row justify-end gap-4">
          {
            reservationModalData?.btnDangerShow &&
            (
              <button
                onClick={() => reservationModalData?.btnDangerClick(reservationModalData.reservation)}
                className={`btn-gray w-fit ${reservationModalData?.btnDangerColor}`}
              >
                {reservationModalData?.btnDangerText}
              </button>
            )
          }
          {
            reservationModalData?.btnSuccessShow &&
            (
              <button
                onClick={() => reservationModalData?.btnSuccessClick(reservationModalData.reservation, eventCoordinatorId)}
                className={`btn-yellow w-fit ${reservationModalData?.btnSuccessColor}`}
                disabled={reservationModalData?.labelState === 'Pendiente' && !eventCoordinatorId}
              >
                {reservationModalData?.btnSuccessText}
              </button>
            )
          }
          </footer>
        </div>
      </Modal>
    </>
  )
};
