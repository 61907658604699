import { MultipleServicesCategoryAction, MultipleServicesCategoryPayload } from "../interfaces";
import { MultipleServicesCategoryTypes } from "../types";

const initialState: MultipleServicesCategoryPayload = {
  loading: false,
  success: false,
  error: null,
  listMultipleServicesCategory: [],
  multipleServicesCategory: null,
};

export const multipleServicesCategory = (state = initialState, action: MultipleServicesCategoryAction): MultipleServicesCategoryPayload => {
  const { loading, success, error, multipleServicesCategory, listMultipleServicesCategory } = action.payload || {};
  switch (action.type) {
    case MultipleServicesCategoryTypes.GET_MULTIPLESERVICESCATEGORY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesCategoryTypes.GET_MULTIPLESERVICESCATEGORY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesCategoryTypes.GET_MULTIPLESERVICESCATEGORY_SUCCESS:
      return {
        ...state,
        listMultipleServicesCategory,
        loading,
        success,
      };
    case MultipleServicesCategoryTypes.POST_MULTIPLESERVICESCATEGORY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesCategoryTypes.POST_MULTIPLESERVICESCATEGORY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesCategoryTypes.POST_MULTIPLESERVICESCATEGORY_SUCCESS:
      return {
        ...state,
        multipleServicesCategory,
        loading,
        success,
      };
    case MultipleServicesCategoryTypes.PUT_MULTIPLESERVICESCATEGORY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesCategoryTypes.PUT_MULTIPLESERVICESCATEGORY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesCategoryTypes.PUT_MULTIPLESERVICESCATEGORY_SUCCESS:
      return {
        ...state,
        multipleServicesCategory,
        loading,
        success,
      };
    case MultipleServicesCategoryTypes.DELETE_MULTIPLESERVICESCATEGORY_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesCategoryTypes.DELETE_MULTIPLESERVICESCATEGORY_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesCategoryTypes.DELETE_MULTIPLESERVICESCATEGORY_SUCCESS:
      return {
        ...state,
        multipleServicesCategory,
        loading,
        success,
      };
    default:
      return state;
  }
};

