import { Campus, Error } from ".";

export interface MemberRegisterValues {
  email: string;
  password: string;
  campusFrecuency: number;
  name: string;
  documentNumber: string;
  cellNumber: string;
  birthday: string;
  emergencyNumber: string;
}

export interface MemberRequest {
  username: string;
  email: string;
  password: string;
  first_name: string;
  phone: string;
  document_number: string;
  date_birth: string;
  emergency_number: string;
  address: string;
  campus: number;
  user_group: string;
  is_active: boolean;
}

export interface MemberResponse {
  cedula: string;
  grado: string;
  fecha_nac: string;
  edad: string;
  telefono1: string;
  telefono2: string;
  celular: string;
  direccion: string;
  condicion: string;
  ciudad: string;
  parroquia: string;
  provincia: string;
  correo: string;
  estado_civil: string;
  genero: string;
  tipo: number;
  correo_app: string | null;
  user_group: 'member_user' | 'guest_user' | 'family_user';
}

export interface RegisterResponse {
  id: number;
  email: string;
  username: string;
  document_number: string;
  last_login: string | null;
  is_superuser: boolean;
  first_name: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  phone: string;
  photo_url: string;
  date_birth: string;
  emergency_number: string;
  address: string;
  campus: {
    id: number;
    is_deleted: boolean;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    name: string;
  };
  groups: Array<{
    id: number;
    permissions: Array<any> | null;
    name: string;
  }>
  user_permissions: Array<any> | null;
}

export interface RegisterStore {
  member?: MemberResponse | null;
  isRegistered: boolean | null;
  campus?: Campus[] | null;
  loading: boolean;
  success: boolean;
  error?: Error
}

export interface RegisterAction {
  type: string;
  payload: RegisterStore;
}

export const membershipTypes: any = {
  member_user: 'Socio',
  family_user: 'Familiar',
  guest_user: 'Invitado',
};
