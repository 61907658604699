
import { EventWireTransfersAction, EventWireTransfersPayload } from "../interfaces";
import { EventWireTransfersTypes } from "../types";

const initialState: EventWireTransfersPayload = {
  loading: false,
  success: false,
  error: null,
  eventWireTransfers: null,
  listEventWireTransfers: [],
  pagEventWireTransfers: {
    results: [],
    count: 1,
    next: null,
    previous: null,
  },
};

export const eventWireTransfers = (state = initialState, action: EventWireTransfersAction): EventWireTransfersPayload => {
  const { loading, success, error, eventWireTransfers, listEventWireTransfers, pagEventWireTransfers } = action.payload || {};
  switch (action.type) {
    case EventWireTransfersTypes.GET_EVENTWIRETRANSFERSPAG_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventWireTransfersTypes.GET_EVENTWIRETRANSFERSPAG_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventWireTransfersTypes.GET_EVENTWIRETRANSFERSPAG_SUCCESS:
      return {
        ...state,
        pagEventWireTransfers,
        loading,
        success,
      };
    case EventWireTransfersTypes.GET_EVENTWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventWireTransfersTypes.GET_EVENTWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventWireTransfersTypes.GET_EVENTWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        listEventWireTransfers,
        loading,
        success,
      };
    case EventWireTransfersTypes.GETONLY_EVENTWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventWireTransfersTypes.GETONLY_EVENTWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventWireTransfersTypes.GETONLY_EVENTWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        eventWireTransfers,
        loading,
        success,
      };
    case EventWireTransfersTypes.POST_EVENTWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventWireTransfersTypes.POST_EVENTWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventWireTransfersTypes.POST_EVENTWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        eventWireTransfers,
        loading,
        success,
      };
    case EventWireTransfersTypes.PUT_EVENTWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventWireTransfersTypes.PUT_EVENTWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventWireTransfersTypes.PUT_EVENTWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        eventWireTransfers,
        loading,
        success,
      };
    case EventWireTransfersTypes.DELETE_EVENTWIRETRANSFERS_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case EventWireTransfersTypes.DELETE_EVENTWIRETRANSFERS_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case EventWireTransfersTypes.DELETE_EVENTWIRETRANSFERS_SUCCESS:
      return {
        ...state,
        eventWireTransfers,
        loading,
        success,
      };
    default:
      return state;
  }
};

