import { RegisterTypes } from "../types";
import { RegisterProccess } from "../api/process";
import * as localStorage from "../../utils/localStorage";
import { CampusStore, GenericAction, MemberRegisterValues, RegisterAction, UserFilters } from "../interfaces";

export const registerUser = (member: MemberRegisterValues) =>
  async (dispatch: (state: RegisterAction) => RegisterAction) => {
    try {
      const registerProcess = new RegisterProccess();
      // dont'n import the result of the process, only that dont't have error
      await registerProcess.registerUser(member);
      return dispatch({
        type: RegisterTypes.CREATE_USER_SUCCESS,
        payload: {
          isRegistered: true,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      localStorage.clear();
      const errors = error.response.data || {};
      return dispatch({
        type: RegisterTypes.CREATE_USER_FAILURE,
        payload: {
          error: {
            message: errors || 'Tuvimos un problema al registrar sus datos. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          isRegistered: false,
          loading: false,
          success: false,
        },
      });
    }
  };

  // Doesn't use / refactor after
export const getUserData =
  (documentNumber: string, birthday: string) => async (dispatch: (state: GenericAction) => GenericAction) => {
    try {
      if (documentNumber && birthday) {
        const registerProcess = new RegisterProccess();
        const response = await registerProcess.getMemberByDocumentAndBirthday(documentNumber, birthday);
        return dispatch({
          type: RegisterTypes.GET_USER_DATA_SUCCESS,
          payload: {
            member: response,
            isRegistered: false,
            loading: false,
            success: true,
          },
        });
      } else {
        return dispatch({
          type: RegisterTypes.GET_USER_DATA_FAILURE,
          payload: {
            loading: false,
            success: false,
          },
        });
      }
    } catch (error: any) {
      localStorage.clear();
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: RegisterTypes.GET_USER_DATA_FAILURE,
        payload: {
          error: {
            message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };

export const getUser =
(filters?: UserFilters) => async (dispatch: (state: GenericAction) => GenericAction) => {
  try {
      const registerProcess = new RegisterProccess();
      const response = await registerProcess.getMember({
        ...filters,
        data_all: 'YES',
      });
      return dispatch({
        type: RegisterTypes.GET_USER_DATA_SUCCESS,
        payload: {
          member: response,
          isRegistered: false,
          loading: false,
          success: true,
        },
      });
  } catch (error: any) {
    localStorage.clear();
    const { message, detail } = error.response.data || {};
    return dispatch({
      type: RegisterTypes.GET_USER_DATA_FAILURE,
      payload: {
        error: {
          message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
        },
        loading: false,
        success: false,
      },
    });
  }
};

export const getListCampus = () => async (dispatch: (state: CampusStore) => CampusStore) => {
  try {
    const registerProcess = new RegisterProccess();
    const response = await registerProcess.getListCampus();
    return dispatch({
      type: RegisterTypes.GET_LIST_CAMPUS_SUCCESS,
      payload: {
        loading: false,
        success: true,
        campus: response,
      },
    });
  } catch (error: any) {
    localStorage.clear();
    const { message, detail } = error.response.data || {};
    return dispatch({
      type: RegisterTypes.GET_LIST_CAMPUS_FAILURE,
      payload: {
        error: {
          message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
        },
        loading: false,
        success: false,
      },
    });
  }
};
