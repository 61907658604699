import { HotelBankAccountRepository } from "../repositories";
import { HotelBankAccountFilters, HotelBankAccountGet, HotelBankAccountPost, HotelBankAccountPut, HotelBankAccountTable } from "../../interfaces";

export class HotelBankAccountProcess {
  private multipleServiceReservationRepository: HotelBankAccountRepository;

  constructor() {
    this.multipleServiceReservationRepository = new HotelBankAccountRepository();
  }

  async getHotelBankAccount(filters?: HotelBankAccountFilters): Promise<HotelBankAccountGet> {
    const response = await this.multipleServiceReservationRepository.getHotelBankAccount(filters);
    return response.data;
  }

  async postHotelBankAccount(hotelBankAccount: HotelBankAccountPost): Promise<HotelBankAccountTable> {
    const response = await this.multipleServiceReservationRepository.postHotelBankAccount(hotelBankAccount);
    return response.data;
  }

  async putHotelBankAccount(hotelBankAccount: HotelBankAccountPut): Promise<HotelBankAccountTable> {
    const response = await this.multipleServiceReservationRepository.putHotelBankAccount(hotelBankAccount);
    return response.data;
  }

  // dont response data
  async deleteHotelBankAccount(id: number): Promise<any> {
    const response = await this.multipleServiceReservationRepository.deleteHotelBankAccount(id);
    return response.data;
  }
}
