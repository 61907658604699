import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { HotelImageFilters, HotelImageGet, HotelImageTable } from "../../interfaces";

export class HotelImageRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getHotelImage(filters?: HotelImageFilters): Promise<AxiosResponse<HotelImageGet>> {
    return this.lmBootApi.get<HotelImageGet>("/hotel-bedroom-images/", { params: filters });
  }

  postHotelImage(hotelImage: FormData): Promise<AxiosResponse<HotelImageTable>> {
    return this.lmBootApi.post<HotelImageTable>("/hotel-bedroom-images/", hotelImage);
  }

  putHotelImage(hotelImage: FormData): Promise<AxiosResponse<HotelImageTable>> {
    return this.lmBootApi.put<HotelImageTable>("/hotel-bedroom-images/", hotelImage);
  }

  // dont response data
  deleteHotelImage(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/hotel-bedroom-images/${id}/`);
  }
}
