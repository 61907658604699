import { ParametersRepository } from '../repositories';
import { ParametersFilters, ParametersGet, ParametersPost, ParametersPut, ParametersTable } from '../../interfaces';

export class ParametersProcess {
  private parametersRepository: ParametersRepository;

  constructor() {
    this.parametersRepository = new ParametersRepository();
  }

  async getParameters(filters?: ParametersFilters): Promise<ParametersGet> {
    const response = await this.parametersRepository.getParameters(filters);
    return response.data;
  }
  async getParametersPublic(filters?: ParametersFilters): Promise<ParametersGet> {
    const response = await this.parametersRepository.getParametersPublic(filters);
    return response.data;
  }

  async postParameters(parameters: ParametersPost): Promise<ParametersTable> {
    const response = await this.parametersRepository.postParameters(parameters);
    return response.data;
  }

  async putParameters(parameters: ParametersPut): Promise<ParametersTable> {
    const response = await this.parametersRepository.putParameters(parameters);
    return response.data;
  }

  // dont response data
  async deleteParameters(id: number): Promise<any> {
    const response = await this.parametersRepository.deleteParameters(id);
    return response.data;
  }
}
