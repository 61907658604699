import { ParametersProcess } from '../api';
import { ParametersAction, ParametersFilters, ParametersPost, ParametersPut } from '../interfaces';
import { ParametersTypes } from '../types';

export const getParametersPags =
  (filters?: ParametersFilters) => async (dispatch: (state: ParametersAction) => ParametersAction) => {
    dispatch({
      type: ParametersTypes.GET_PARAMETERSPAG_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const parametersProcess = new ParametersProcess();
      // dont'n import the result of the process, only that dont't have error
      const parametersPag = await parametersProcess.getParameters(filters);
      return dispatch({
        type: ParametersTypes.GET_PARAMETERSPAG_SUCCESS,
        payload: {
          pagParameters: parametersPag,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: ParametersTypes.GET_PARAMETERSPAG_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };

export const getParameters =
  (filters?: ParametersFilters) => async (dispatch: (state: ParametersAction) => ParametersAction) => {
    dispatch({
      type: ParametersTypes.GET_PARAMETERS_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const parametersProcess = new ParametersProcess();
      // dont'n import the result of the process, only that dont't have error
      const parametersPag = await parametersProcess.getParameters({
        ...filters,
        data_all: 'YES',
      });
      const parameters = parametersPag.results;
      return dispatch({
        type: ParametersTypes.GET_PARAMETERS_SUCCESS,
        payload: {
          listParameters: parameters,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: ParametersTypes.GET_PARAMETERS_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };
export const getParametersPublic =
  (filters?: ParametersFilters) => async (dispatch: (state: ParametersAction) => ParametersAction) => {
    dispatch({
      type: ParametersTypes.GET_PARAMETERS_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const parametersProcess = new ParametersProcess();
      // dont'n import the result of the process, only that dont't have error
      const parametersPag = await parametersProcess.getParametersPublic({
        ...filters,
        data_all: 'YES',
      });
      const parameters = parametersPag.results;
      return dispatch({
        type: ParametersTypes.GET_PARAMETERS_SUCCESS,
        payload: {
          listParameters: parameters,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: ParametersTypes.GET_PARAMETERS_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };

export const postParameters =
  (parameters: ParametersPost) => async (dispatch: (state: ParametersAction) => ParametersAction) => {
    dispatch({
      type: ParametersTypes.POST_PARAMETERS_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const parametersProcess = new ParametersProcess();
      // dont'n import the result of the process, only that dont't have error
      const parametersTable = await parametersProcess.postParameters(parameters);
      return dispatch({
        type: ParametersTypes.POST_PARAMETERS_SUCCESS,
        payload: {
          parameters: parametersTable,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: ParametersTypes.POST_PARAMETERS_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };

export const putParameters =
  (parameters: ParametersPut) => async (dispatch: (state: ParametersAction) => ParametersAction) => {
    dispatch({
      type: ParametersTypes.PUT_PARAMETERS_LOADING,
      payload: {
        loading: true,
        success: false,
      },
    });
    try {
      const parametersProcess = new ParametersProcess();
      // dont'n import the result of the process, only that dont't have error
      const parametersTable = await parametersProcess.putParameters(parameters);
      return dispatch({
        type: ParametersTypes.PUT_PARAMETERS_SUCCESS,
        payload: {
          parameters: parametersTable,
          loading: false,
          success: true,
        },
      });
    } catch (error: any) {
      const { message, detail } = error.response.data || {};
      return dispatch({
        type: ParametersTypes.PUT_PARAMETERS_FAILURE,
        payload: {
          error: {
            message: message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
            code: error?.code,
          },
          loading: false,
          success: false,
        },
      });
    }
  };

export const deleteParameters = (id: number) => async (dispatch: (state: ParametersAction) => ParametersAction) => {
  dispatch({
    type: ParametersTypes.DELETE_PARAMETERS_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const parametersProcess = new ParametersProcess();
    // dont'n import the result of the process, only that dont't have error
    await parametersProcess.deleteParameters(id);
    return dispatch({
      type: ParametersTypes.DELETE_PARAMETERS_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
      type: ParametersTypes.DELETE_PARAMETERS_FAILURE,
      payload: {
        error: {
          message: message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
          code: error?.code,
        },
        loading: false,
        success: false,
      },
    });
  }
};
