export enum UserTypes {
  GET_USERBYID_LOADING = "GET_USERBYID_LOADING",
  GET_USERBYID_SUCCESS = "GET_USERBYID_SUCCESS",
  GET_USERBYID_FAILURE = "GET_USERBYID_FAILURE",
  GET_USER_LOADING = "GET_USER_LOADING",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_FAILURE = "GET_USER_FAILURE",
  GET_LIST_USER_LOADING = "GET_LIST_USER_LOADING",
  GET_LIST_USER_SUCCESS = "GET_LIST_USER_SUCCESS",
  GET_LIST_USER_FAILURE = "GET_LIST_USER_FAILURE",
  GET_LIST_USERPAG_LOADING = "GET_LIST_USERPAG_LOADING",
  GET_LIST_USERPAG_SUCCESS = "GET_LIST_USERPAG_SUCCESS",
  GET_LIST_USERPAG_FAILURE = "GET_LIST_USERPAG_FAILURE",
  PUT_USER_LOADING = "PUT_USER_LOADING",
  PUT_USER_SUCCESS = "PUT_USER_SUCCESS",
  PUT_USER_FAILURE = "PUT_USER_FAILURE",
  PUT_USERPHOTO_LOADING = "PUT_USERPHOTO_LOADING",
  PUT_USERPHOTO_SUCCESS = "PUT_USERPHOTO_SUCCESS",
  PUT_USERPHOTO_FAILURE = "PUT_USERPHOTO_FAILURE",
}