export enum HotelWireTransfersTypes {
  GET_HOTELWIRETRANSFERSPAG_LOADING = "GET_HOTELWIRETRANSFERSPAG_LOADING",
  GET_HOTELWIRETRANSFERSPAG_SUCCESS = "GET_HOTELWIRETRANSFERSPAG_SUCCESS",
  GET_HOTELWIRETRANSFERSPAG_FAILURE = "GET_HOTELWIRETRANSFERSPAG_FAILURE",
  GET_HOTELWIRETRANSFERS_LOADING = "GET_HOTELWIRETRANSFERS_LOADING",
  GET_HOTELWIRETRANSFERS_SUCCESS = "GET_HOTELWIRETRANSFERS_SUCCESS",
  GET_HOTELWIRETRANSFERS_FAILURE = "GET_HOTELWIRETRANSFERS_FAILURE",
  GETONLY_HOTELWIRETRANSFERS_LOADING = "GETONLY_HOTELWIRETRANSFERS_LOADING",
  GETONLY_HOTELWIRETRANSFERS_SUCCESS = "GETONLY_HOTELWIRETRANSFERS_SUCCESS",
  GETONLY_HOTELWIRETRANSFERS_FAILURE = "GETONLY_HOTELWIRETRANSFERS_FAILURE",
  POST_HOTELWIRETRANSFERS_LOADING = "POST_HOTELWIRETRANSFERS_LOADING",
  POST_HOTELWIRETRANSFERS_SUCCESS = "POST_HOTELWIRETRANSFERS_SUCCESS",
  POST_HOTELWIRETRANSFERS_FAILURE = "POST_HOTELWIRETRANSFERS_FAILURE",
  PUT_HOTELWIRETRANSFERS_LOADING = "PUT_HOTELWIRETRANSFERS_LOADING",
  PUT_HOTELWIRETRANSFERS_SUCCESS = "PUT_HOTELWIRETRANSFERS_SUCCESS",
  PUT_HOTELWIRETRANSFERS_FAILURE = "PUT_HOTELWIRETRANSFERS_FAILURE",
  DELETE_HOTELWIRETRANSFERS_LOADING = "DELETE_HOTELWIRETRANSFERS_LOADING",
  DELETE_HOTELWIRETRANSFERS_SUCCESS = "DELETE_HOTELWIRETRANSFERS_SUCCESS",
  DELETE_HOTELWIRETRANSFERS_FAILURE = "DELETE_HOTELWIRETRANSFERS_FAILURE",
}