import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { getCurrentUser, getUserAuth, logoutUser } from "../redux/actions";
import { PublicRoute } from "./PublicRoute";
import { RootState } from "../redux/store";
import { PrivateRoute } from "./PrivateRoute";
import { LoadingPage } from "../pages/states";
import { LoginPage, PasswordResetPage, PrivacyPoliciesPage, RegisterPage } from "../pages/boilerplate";
import { UserRole } from "../utils/enums/userRoles.enum";
import { routes, RouteType } from "./private.routes";
import alerts from "../utils/alerts";
import { SendPasswordResetPage } from "../pages/boilerplate/SendPasswordResetPage";
import { SuccessfulPayment } from '../pages/boilerplate/SuccessfulPayment';
const DashboardPrivateRoutes = React.lazy(() => import('./DashboardPrivateRoutes'));

export const AppRouter: React.FC<{}> = () => {
  const dispatch = useDispatch();
  // States
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { user, success, error: errorUser } = useSelector((state: RootState) => state.user);
  const [canLogin, setCanLogin] = useState(false);
  const [routesByRol, setRoutesByModule] = useState<RouteType[]>([]);

  // Effects
  useEffect(() => {
    dispatch(getUserAuth());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isLoggedIn) getCurrentUser()(dispatch);
    if (!isLoggedIn && canLogin) setCanLogin(false);
  }, [isLoggedIn]); // eslint-disable-line

  useEffect(() => {
    if (errorUser && errorUser.message) {
      dispatch(logoutUser());
      alerts.error({
        title: 'Error',
        text: errorUser.message,
      });
    }
  }, [errorUser]); // eslint-disable-line

  useEffect(() => {
    if (!success || !isLoggedIn) return;
    const validateRoles = () => {
      const rolesDenied = [
        UserRole.COMMON_ADMIN as string,
        UserRole.MEMBER_USER as string,
        UserRole.FAMILY_USER as string,
        UserRole.GUEST_USER as string,
      ];
      if (user && user.groups) {
        const groupUser = user.groups[0];
        if (rolesDenied.includes(groupUser.name)) {
          dispatch(logoutUser());
          return alerts.error({
            title: 'Lo sentimos',
            text: 'Esta sección es solo para administradores.',
          });
        }
        const routesFilter = [];
        for (const route of routes) {
          if (user && user.groups) {
            for (const rol of route.roles) {
              if (groupUser.name === rol) {
                routesFilter.push(route);
              }
            }
          }
        }
        if (routesFilter.length === 0) {
          dispatch(logoutUser());
          return alerts.error({
            title: 'Lo sentimos',
            text: 'Esta sección está en mantenimiento.',
          });
        }
        setRoutesByModule(routesFilter);
        setCanLogin(true);
      }
    };
    validateRoles();
  }, [user]); // eslint-disable-line

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute isAuthenticated={canLogin}>
                  <LoginPage />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/register"
              element={
                <PublicRoute isAuthenticated={canLogin}>
                  <RegisterPage />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/privacy-policies"
              element={
                <PublicRoute isAuthenticated={canLogin}>
                  <PrivacyPoliciesPage />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/password/reset/:token"
              element={
                <PublicRoute isAuthenticated={canLogin}>
                  <PasswordResetPage />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/payment/confirm/:token"
              element={
                <PublicRoute isAuthenticated={canLogin}>
                  <SuccessfulPayment />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/send-password-reset"
              element={
                <PublicRoute isAuthenticated={canLogin}>
                  <SendPasswordResetPage />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/*"
              element={
                <PrivateRoute isAuthenticated={canLogin}>
                  <DashboardPrivateRoutes routesByRol={routesByRol} />
                </PrivateRoute>
              }
            ></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
