import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteHolidays, getHolidays, postHolidays, putHolidays } from "../../redux/actions";
import { HolidaysPost, HolidaysPut } from "../../redux/interfaces";

export const HolidaysPage = () => {
  const dispatch = useDispatch();

  type HolidayType = {
    id?: number;
    name: string;
    start_date: string;
    end_date: string;
  };

  const [holidays, setHolidays] = useState<HolidayType[]>([
    {
      name: "",
      start_date: "",
      end_date: "",
    }
  ]);
  const [holidaysOriginal, setHolidaysOriginal] = useState<HolidayType[]>([]);

  useEffect(() => {
    const pullHolidays = async () => {
      const { payload } = await getHolidays()(dispatch);
      const listHolidays = payload.listHolidays || [];
      const holidaysGetted = listHolidays.map(holiday => ({
        id: holiday.id,
        name: holiday.name,
        // format from 2022-02-28T00:00:00Z to 'YYYY-MM-DD',
        start_date: holiday.start_date.split('T')[0],
        end_date: holiday.end_date.split('T')[0],
      } as HolidayType));
      setHolidays([
        ...holidaysGetted,
      ]);
      setHolidaysOriginal([
        ...holidaysGetted
      ]);
    };
    pullHolidays();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitHolidays = (e: FormEvent) => {
    e.preventDefault();
    // get name from button
    // const name = e.currentTarget.name;
    const holidaysToSendPost: HolidaysPost[] = [];
    const holidaysToSendPut: HolidaysPut[] = [];
    for (const i in holidays) {
      if (Object.prototype.hasOwnProperty.call(holidays, i)) {
        const holiday = holidays[i];
        if (holiday.id) {
          holidaysToSendPut.push({
            id: holiday.id,
            name: holiday.name,
            // format from 'YYYY-MM-DD' to 2022-02-28T00:00:00Z
            start_date: `${holiday.start_date}T00:00:00Z`,
            end_date: `${holiday.end_date}T00:00:00Z`,
          });
        } else {
          holidaysToSendPost.push({
            name: holiday.name,
            // format from 'YYYY-MM-DD' to 2022-02-28T00:00:00Z
            start_date: `${holiday.start_date}T00:00:00Z`,
            end_date: `${holiday.end_date}T00:00:00Z`,
          });
        }
      }
    }
    const holidaysToSendPutOriginal: HolidaysPut[] = [];
    // Compare original days with new days, if there is a difference, delete the old days
    for (const i in holidaysOriginal) {
      if (Object.prototype.hasOwnProperty.call(holidaysOriginal, i)) {
        const holiday = holidaysOriginal[i];
        if (holiday.id) {
          holidaysToSendPutOriginal.push({
            id: holiday.id,
            name: holiday.name,
            // format from 'YYYY-MM-DD' to 2022-02-28T00:00:00Z
            start_date: `${holiday.start_date}T00:00:00Z`,
            end_date: `${holiday.end_date}T00:00:00Z`,
          });
        }
      }
    }
    const holidaysToDelete = holidaysToSendPutOriginal.filter(day => holidaysToSendPut.findIndex(day2 => day2.id === day.id) === -1);
    try {
      for (const holidayToSendPost of holidaysToSendPost) {
        postHolidays(holidayToSendPost)(dispatch);
      }
  
      for (const holidayToSendPut of holidaysToSendPut) {
        putHolidays(holidayToSendPut)(dispatch);
      }
  
      for (const holidayToDelete of holidaysToDelete) {
        deleteHolidays(holidayToDelete.id)(dispatch);
      }
      toast.success('Se ha guardado los feriados.');
    } catch (error) {
      toast.error('¡Cáspita!: parece que tenemos problemas de conexión. Por favor vuelva a intentarlo más tarde.');
    }
  }

  return (
    <section className="mx-auto max-w-3xl">
      <h1 className="font-bold text-gray-500 mb-10">Días feriados</h1>
      <form onSubmit={handleSubmitHolidays}>
        <div className="relative flex row-row flex-wrap gap-8 mb-10">
          {
            holidays && holidays.map((holiday, i) => (
              <div key={`holidays-${i}`} className="w-full flex flex-row items-center justify-between gap-4">
                <input
                  type="text" name="name" id="name"
                  value={holiday.name}
                  // replace value from state with value from onChange
                  onChange={(e) => setHolidays(holidays.map((h, j) => (i === j ? { ...h, name: e.target.value } : h)))}
                  className="input-yellow"
                  required
                  placeholder="Nombre del feriado"
                />
                <input
                  type="date" name="start_date" id="start_date"
                  value={holiday.start_date}
                  // replace value from state with value from onChange
                  onChange={(e) => setHolidays(holidays.map((h, j) => (i === j ? { ...h, start_date: e.target.value } : h)))}
                  className="input-yellow"
                  required
                />
                <input
                  type="date" name="end_date" id="end_date"
                  value={holiday.end_date}
                  // replace value from state with value from onChange
                  onChange={(e) => setHolidays(holidays.map((h, j) => (i === j ? { ...h, end_date: e.target.value } : h)))}
                  className="input-yellow"
                  required
                />
                {
                  i === 0 ? (
                    <button
                      type="button"
                      // add new holiday to state
                      onClick={() => setHolidays([...holidays, { name: "", start_date: "", end_date: "" }])}
                      className="rounded-full bg-yellow-500 p-2 text-white ring-offset-2 hover:bg-amber-300 active:bg-amber-400 active:ring active:ring-amber-300"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      type="button"
                      // remove actual holiday from state
                      onClick={() => setHolidays(holidays.filter(h => h !== holiday))}
                      className="rounded-full p-2 px-1.5 text-black hover:bg-red-50 ring-offset-2 active:bg-red-300 active:ring active:ring-red-300"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )
                }
              </div>
            ))
          }
        </div>
        <div className="flex justify-center">
          <button type="submit" className="mb-4 w-full rounded-lg bg-yellow-500 p-2 font-bold">Actualizar</button>
        </div>
      </form>
    </section>
  );
};