import { MultipleServicesImagesAction, MultipleServicesImagesPayload } from "../interfaces";
import { MultipleServicesImagesTypes } from "../types";

const initialState: MultipleServicesImagesPayload = {
  loading: false,
  success: true,
  error: undefined,
  multipleServicesImages: null,
  listMultipleServicesImages: [],
};

export const multipleServicesImages = (state = initialState, action: MultipleServicesImagesAction): MultipleServicesImagesPayload => {
  const { loading, success, error, multipleServicesImages, listMultipleServicesImages } = action.payload || {};
  switch (action.type) {
    case MultipleServicesImagesTypes.GET_MULTIPLESERVICESIMAGES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesImagesTypes.GET_MULTIPLESERVICESIMAGES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesImagesTypes.GET_MULTIPLESERVICESIMAGES_SUCCESS:
      return {
        ...state,
        listMultipleServicesImages,
        loading,
        success,
      };
    case MultipleServicesImagesTypes.POST_MULTIPLESERVICESIMAGES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesImagesTypes.POST_MULTIPLESERVICESIMAGES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesImagesTypes.POST_MULTIPLESERVICESIMAGES_SUCCESS:
      return {
        ...state,
        multipleServicesImages,
        loading,
        success,
      };
    case MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesImagesTypes.PUT_MULTIPLESERVICESIMAGES_SUCCESS:
      return {
        ...state,
        multipleServicesImages,
        loading,
        success,
      };
    case MultipleServicesImagesTypes.DELETE_MULTIPLESERVICESIMAGES_FAILURE:
      return {
        ...state,
        loading,
        success,
        error
      };
    case MultipleServicesImagesTypes.DELETE_MULTIPLESERVICESIMAGES_LOADING:
      return {
        ...state,
        loading,
        success
      };
    case MultipleServicesImagesTypes.DELETE_MULTIPLESERVICESIMAGES_SUCCESS:
      return {
        ...state,
        multipleServicesImages,
        loading,
        success,
      };
    default:
      return state;
  }
};

