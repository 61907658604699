import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { MultipleServicesFilters, MultipleServicesGet, MultipleServicesPost, MultipleServicesPut, MultipleServicesTable } from "../../interfaces";

export class MultipleServicesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getMultipleServices(filters?: MultipleServicesFilters): Promise<AxiosResponse<MultipleServicesGet>> {
    return this.lmBootApi.get<MultipleServicesGet>("/multiple-services/", { params: filters });
  }

  postMultipleServices(multipleService: MultipleServicesPost): Promise<AxiosResponse<MultipleServicesTable>> {
    return this.lmBootApi.post<MultipleServicesTable>("/multiple-services/", multipleService);
  }

  putMultipleServices(multipleService: MultipleServicesPut): Promise<AxiosResponse<MultipleServicesTable>> {
    return this.lmBootApi.put<MultipleServicesTable>("/multiple-services/", multipleService);
  }

  // dont response data
  deleteMultipleServices(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/multiple-services/${id}/`);
  }
}
