import { HotelReservationProcess } from "../api";
import { HotelReservationAction, HotelReservationFilters, HotelReservationPost, HotelReservationPut } from "../interfaces";
import { HotelReservationTypes } from "../types";

// apply the reducer
export const getHotelReservationPags = (filters?: HotelReservationFilters) => async (dispatch: (state: HotelReservationAction) => HotelReservationAction) => {
  dispatch({
    type: HotelReservationTypes.GET_HOTELRESERVATIONPAG_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelReservationProcess = new HotelReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelReservationPag = await hotelReservationProcess.getHotelReservation(filters);
    return dispatch({
      type: HotelReservationTypes.GET_HOTELRESERVATIONPAG_SUCCESS,
      payload: {
        pagHotelReservation: hotelReservationPag,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelReservationTypes.GET_HOTELRESERVATIONPAG_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const getHotelReservation = (filters?: HotelReservationFilters) => async (dispatch: (state: HotelReservationAction) => HotelReservationAction) => {
  dispatch({
    type: HotelReservationTypes.GET_HOTELRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelReservationProcess = new HotelReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const hotelReservationPag = await hotelReservationProcess.getHotelReservation({
      ...filters,
      data_all: 'YES',
    });
    const hotelReservation = hotelReservationPag.results;
    return dispatch({
      type: HotelReservationTypes.GET_HOTELRESERVATION_SUCCESS,
      payload: {
        listHotelReservation: hotelReservation,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelReservationTypes.GET_HOTELRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al cargar los datos. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const postHotelReservation = (hotelReservation: HotelReservationPost) => async (dispatch: (state: HotelReservationAction) => HotelReservationAction) => {
  dispatch({
    type: HotelReservationTypes.POST_HOTELRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelReservationProcess = new HotelReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelReservationProcess.postHotelReservation(hotelReservation);
    return dispatch({
      type: HotelReservationTypes.POST_HOTELRESERVATION_SUCCESS,
      payload: {
        hotelReservation: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelReservationTypes.POST_HOTELRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al crear. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const putHotelReservation = (hotelReservation: HotelReservationPut) => async (dispatch: (state: HotelReservationAction) => HotelReservationAction) => {
  dispatch({
    type: HotelReservationTypes.PUT_HOTELRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelReservationProcess = new HotelReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    const msReservationRes = await hotelReservationProcess.putHotelReservation(hotelReservation);
    return dispatch({
      type: HotelReservationTypes.PUT_HOTELRESERVATION_SUCCESS,
      payload: {
        hotelReservation: msReservationRes,
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelReservationTypes.PUT_HOTELRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al actualizar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};

export const deleteHotelReservation = (id: number) => async (dispatch: (state: HotelReservationAction) => HotelReservationAction) => {
  dispatch({
    type: HotelReservationTypes.DELETE_HOTELRESERVATION_LOADING,
    payload: {
      loading: true,
      success: false,
    },
  });
  try {
    const hotelReservationProcess = new HotelReservationProcess();
    // dont'n import the result of the process, only that dont't have error
    await hotelReservationProcess.deleteHotelReservation(id);
    return dispatch({
      type: HotelReservationTypes.DELETE_HOTELRESERVATION_SUCCESS,
      payload: {
        loading: false,
        success: true,
      },
    });
  } catch (error: any) {
    const { message, detail } = error.response.data || {};
    return dispatch({
        type: HotelReservationTypes.DELETE_HOTELRESERVATION_FAILURE,
        payload: {
            error: {
              message:
                message || detail || 'Tuvimos un problema al eliminar. Por favor, inténtelo más tarde.',
              code: error?.code,
            },
            loading: false,
            success: false,
        },
    });
  }
};