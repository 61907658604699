import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoHeader from '../../components/LogoHeader';
import { useDispatch } from 'react-redux';
import { getParametersPublic } from '../../redux/actions';
import { toast } from 'react-toastify';
import MDEditor from '@uiw/react-md-editor';

export const PrivacyPoliciesPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [description, setDescription] = useState<any>('');

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await getParametersPublic({
        code: 'privacy_policies',
      })(dispatch);

      const { success } = payload;
      if (!success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      if (payload.listParameters && payload.listParameters?.length > 0) {
        const parameterPrivacyPolicies = payload.listParameters.find((x) => x.code === 'privacy_policies');
        if (!!parameterPrivacyPolicies) {
          setDescription(parameterPrivacyPolicies.description);
        }
      }
    };
    fetchData();
  }, []); // eslint-disable-line

  return (
    <>
      <div className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="w-full max-w-6xl rounded-[15px] bg-white p-12 shadow-lg">
          <button className="btn-gray p-2" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <LogoHeader />
          <div className="mt-8 space-y-6">
            <div className="-space-y-px rounded-md">
              {/* Markdown Editor */}
              <div className="mb-10 flex flex-col border-t-2 border-gray-200 pt-4 lg:flex-row">
                <div className="w-full">
                  <MDEditor.Markdown source={description} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
