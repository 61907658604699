import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { putRestaurant } from '../../redux/actions';
import { RestaurantPut, RestaurantTable, User } from '../../redux/interfaces';
import { MiniCarouselImg } from '../../components';
import { RootState } from '../../redux/store';
import { FormikErrors, useFormik } from 'formik';
import { testSizeImg } from '../../utils/utils';

type ImageType = {
  id?: number;
  url: string;
  restaurant?: number;
  file?: File
}

export const RestaurantEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { restaurant } = state as { restaurant: RestaurantTable };
  const { user = {} as User } = useSelector((state: RootState) => state.user);
  const [images, setImages] = useState<ImageType[]>(
    [
      { id: restaurant?.id || undefined, url: restaurant?.cover_image || '' },
    ]
  );

  const {
    getFieldProps,
    handleSubmit,
    touched,
    errors
  } = useFormik({
    initialValues: {
      id: restaurant.id || -1,
      nameRestaurant: restaurant?.name || '',
      description: restaurant?.description || '',
      phone: restaurant?.phone || '',
      // for consistency
      casualGuestPrice: restaurant?.casual_guest_price || '',
      memberPrice: restaurant?.member_price || '',
      specialGuestPrice: restaurant?.special_guest_price || '',
      sponsorGuestPrice: restaurant?.sponsor_guest_price || '',
      // for errors
      cover_image: '',
    },
    validate: (values) => {
      const errors: FormikErrors<any> = {};
      if (!values.nameRestaurant) {
        errors.nameRestaurant = 'El nombre es requerido';
      }
      if (!values.description) {
        errors.description = 'La descripción es requerida';
      }
      if (!values.phone) {
        errors.phone = 'El número es requerido';
      }
      if (!images || images.length === 0) {
        errors.cover_image = 'Subir al menos una imagen';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      if (!images || images.length === 0) return;

      const formData = new FormData();
      const formDataFields: RestaurantPut = {
        id: values.id,
        name: values.nameRestaurant,
        description: values.description,
        phone: values.phone,
        cover_image: images[0]?.file || images[0]?.url,
        casual_guest_price: !!parseFloat(values.casualGuestPrice) ? parseFloat(values.casualGuestPrice) : 0,
        member_price: !!parseFloat(values.memberPrice) ? parseFloat(values.memberPrice) : 0,
        special_guest_price: !!parseFloat(values.specialGuestPrice) ? parseFloat(values.specialGuestPrice) : 0,
        sponsor_guest_price: !!parseFloat(values.sponsorGuestPrice) ? parseFloat(values.sponsorGuestPrice) : 0,
        campus_id: user.campus.id,
      };

      toast.info(`Actualizando ${restaurant?.name}...`);

      Object.entries(formDataFields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const { payload } = await putRestaurant(formData)(dispatch);
      if (!payload.success) {
        return toast.error(payload.error?.message, { type: 'error' });
      }
      toast.success(`${restaurant?.name} actualizado con éxito.`);
      helpers.resetForm();
      setImages([]);
      navigate(-1);
    }
  });

  const handleCatchImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (!testSizeImg(e.target.files)) {
      return toast.error('¡Oops!, la imagen es demasiado grande. Por favor intente con otra menos pesada.', { type: 'error' });
    }

    const imagesList: ImageType[] = Object.values(e.target.files || {})
      .map(file => ({ url: URL.createObjectURL(file), file }));
    setImages((prevState) => [...prevState, ...imagesList]);
  }

  const removeImage = (image: ImageType) => {
    setImages(images.filter((imageItem) => imageItem !== image));
  }

  return (
    <>
      <button className="btn-gray p-2 mb-4" onClick={() => navigate(-1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      <div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
        >
          <h2 className="font-bold text-gray-500">
            Actualizando {restaurant?.name}
          </h2>
          <div className="mt-4 grid grid-cols-2 items-center gap-4">
            <input
              id="nameRestaurant"
              type="text"
              maxLength={250}
              {...getFieldProps('nameRestaurant')}
              required
              className="input-yellow"
              placeholder="Nombre del local"
            />
            <input
              id="phone"
              type="text"
              maxLength={250}
              {...getFieldProps('phone')}
              required
              className="input-yellow"
              placeholder="Número de WhatsApp"
            />
          </div>
          <div className="grid grid-cols-2 -mt-4">
            <span className="grow text-rose-500">{ touched.nameRestaurant && errors.nameRestaurant && errors.nameRestaurant}</span>
            <span className="grow order-last ml-4 text-rose-500">{ touched.phone && errors.phone && errors.phone}</span>
          </div>
          <textarea
            id="description"
            rows={4}
            {...getFieldProps('description')}
            required
            placeholder="Descripción corta"
            className="input-yellow w-full"
          ></textarea>
          { touched.description && errors.description && <span className="text-rose-500">{errors.description}</span> }
          <div>
            <h2 className="font-bold text-gray-500">
              Imágenes <span className="text-sm font-normal text-orange-500">(Tamaño máximo 10Mb, Dimensión mínima 640x640)</span>
            </h2>
            <div className="mt-4 flex flex-row justify-between">
              <MiniCarouselImg images={images} onRemoveImage={removeImage} />
              <div className="ml-8 shrink-0">
                <div className="block mb-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    onChange={handleCatchImages}
                    disabled={images.length > 0}
                    className="w-0 h-0 opacity-0 overflow-hidden absolute -z-10"
                  />
                  <label htmlFor="file" className={`btn-gray ${images.length > 0 ? 'cursor-default' : ''}`}>Subir Imagen</label>
                </div>
                <button
                  type="submit"
                  className="mt-2 w-full btn-black"
                >
                  Actualizar
                </button>
              </div>
            </div>
            { errors.cover_image && <span className="text-rose-500">{errors.cover_image}</span> }
          </div>
        </form>
      </div>
    </>
  );
};
