
import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../configurations";
import { RestaurantLunchesFilters, RestaurantLunchesGet, RestaurantLunchesTable } from "../../interfaces";

export class RestaurantLunchesRepository {
  private lmBootApi: AxiosInstance;

  constructor() {
    this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
  }

  getRestaurantLunches(filters?: RestaurantLunchesFilters): Promise<AxiosResponse<RestaurantLunchesGet>> {
    return this.lmBootApi.get<RestaurantLunchesGet>("/restaurant-lunches/", { params: filters });
  }

  postRestaurantLunches(restaurantLunches: FormData): Promise<AxiosResponse<RestaurantLunchesTable>> {
    return this.lmBootApi.post<RestaurantLunchesTable>("/restaurant-lunches/", restaurantLunches);
  }

  putRestaurantLunches(restaurantLunches: FormData): Promise<AxiosResponse<RestaurantLunchesTable>> {
    return this.lmBootApi.put<RestaurantLunchesTable>("/restaurant-lunches/", restaurantLunches);
  }

  // dont response data
  deleteRestaurantLunches(id: number): Promise<AxiosResponse<any>> {
    return this.lmBootApi.delete<any>(`/restaurant-lunches/${id}/`);
  }
}

